import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    apiAlertsAlertsList: build.query<
      ApiAlertsAlertsListApiResponse,
      ApiAlertsAlertsListApiArg
    >({
      query: () => ({ url: `/api/alerts/alerts/` }),
    }),
    apiAlertsAlertsCreate: build.mutation<
      ApiAlertsAlertsCreateApiResponse,
      ApiAlertsAlertsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/alerts/alerts/`,
        method: "POST",
        body: queryArg.alertRequest,
      }),
    }),
    apiAlertsAlertsRetrieve: build.query<
      ApiAlertsAlertsRetrieveApiResponse,
      ApiAlertsAlertsRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/alerts/alerts/${queryArg.id}/` }),
    }),
    apiAlertsAlertsUpdate: build.mutation<
      ApiAlertsAlertsUpdateApiResponse,
      ApiAlertsAlertsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/alerts/alerts/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.alertRequest,
      }),
    }),
    apiAlertsAlertsPartialUpdate: build.mutation<
      ApiAlertsAlertsPartialUpdateApiResponse,
      ApiAlertsAlertsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/alerts/alerts/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedAlertRequest,
      }),
    }),
    apiAlertsAlertsDestroy: build.mutation<
      ApiAlertsAlertsDestroyApiResponse,
      ApiAlertsAlertsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/alerts/alerts/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    apiAlertsUpdateAlertPartialUpdate: build.mutation<
      ApiAlertsUpdateAlertPartialUpdateApiResponse,
      ApiAlertsUpdateAlertPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/alerts/update_alert/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.patchedAlertUpdateRequest,
      }),
    }),
    apiBqDashboardDataList: build.query<
      ApiBqDashboardDataListApiResponse,
      ApiBqDashboardDataListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bq/dashboard_data`,
        method: "POST",
        body: queryArg.dashboardRequestRequest,
      }),
    }),
    apiBqIwAccountsList: build.query<
      ApiBqIwAccountsListApiResponse,
      ApiBqIwAccountsListApiArg
    >({
      query: () => ({ url: `/api/bq/iw_accounts` }),
    }),
    apiCoreAllPartnerAccountsList: build.query<
      ApiCoreAllPartnerAccountsListApiResponse,
      ApiCoreAllPartnerAccountsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/all_partner_accounts`,
        method: "POST",
        body: queryArg.partnerAllAccountsRequestRequest,
      }),
    }),
    apiCoreAmzAccountsList: build.query<
      ApiCoreAmzAccountsListApiResponse,
      ApiCoreAmzAccountsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/amz_accounts/`,
        params: {
          partner_amz_accounts__partner__id:
            queryArg.partnerAmzAccountsPartnerId,
        },
      }),
    }),
    apiCoreAmzAccountsCreate: build.mutation<
      ApiCoreAmzAccountsCreateApiResponse,
      ApiCoreAmzAccountsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/amz_accounts/`,
        method: "POST",
        body: queryArg.amzAccountCreateRequest,
      }),
    }),
    apiCoreAmzAccountsRetrieve: build.query<
      ApiCoreAmzAccountsRetrieveApiResponse,
      ApiCoreAmzAccountsRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/core/amz_accounts/${queryArg.id}/` }),
    }),
    apiCoreAmzAccountsUpdate: build.mutation<
      ApiCoreAmzAccountsUpdateApiResponse,
      ApiCoreAmzAccountsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/amz_accounts/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.amzAccountRequest,
      }),
    }),
    apiCoreAmzAccountsPartialUpdate: build.mutation<
      ApiCoreAmzAccountsPartialUpdateApiResponse,
      ApiCoreAmzAccountsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/amz_accounts/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedAmzAccountRequest,
      }),
    }),
    apiCoreAmzAdAccountsList: build.query<
      ApiCoreAmzAdAccountsListApiResponse,
      ApiCoreAmzAdAccountsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/amz_ad_accounts/`,
        params: {
          partner_amz_ad_accounts__partner__id:
            queryArg.partnerAmzAdAccountsPartnerId,
        },
      }),
    }),
    apiCoreAmzAdAccountsCreate: build.mutation<
      ApiCoreAmzAdAccountsCreateApiResponse,
      ApiCoreAmzAdAccountsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/amz_ad_accounts/`,
        method: "POST",
        body: queryArg.amzAdAccountCreateRequest,
      }),
    }),
    apiCoreAmzAdAccountsUpdate: build.mutation<
      ApiCoreAmzAdAccountsUpdateApiResponse,
      ApiCoreAmzAdAccountsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/amz_ad_accounts/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.amzAdAccountRequest,
      }),
    }),
    apiCoreAmzAdAccountsPartialUpdate: build.mutation<
      ApiCoreAmzAdAccountsPartialUpdateApiResponse,
      ApiCoreAmzAdAccountsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/amz_ad_accounts/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedAmzAdAccountRequest,
      }),
    }),
    apiCoreAsyncTasksList: build.query<
      ApiCoreAsyncTasksListApiResponse,
      ApiCoreAsyncTasksListApiArg
    >({
      query: () => ({ url: `/api/core/async_tasks/` }),
    }),
    apiCoreAsyncTasksRetrieve: build.query<
      ApiCoreAsyncTasksRetrieveApiResponse,
      ApiCoreAsyncTasksRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/core/async_tasks/${queryArg.id}/` }),
    }),
    apiCoreCurrentUserRetrieve: build.query<
      ApiCoreCurrentUserRetrieveApiResponse,
      ApiCoreCurrentUserRetrieveApiArg
    >({
      query: () => ({ url: `/api/core/current_user` }),
    }),
    apiCorePartnerAmzAccountsCreate: build.mutation<
      ApiCorePartnerAmzAccountsCreateApiResponse,
      ApiCorePartnerAmzAccountsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partner_amz_accounts/`,
        method: "POST",
        body: queryArg.partnerAmzAccountRequest,
      }),
    }),
    apiCorePartnerAmzAdAccountsCreate: build.mutation<
      ApiCorePartnerAmzAdAccountsCreateApiResponse,
      ApiCorePartnerAmzAdAccountsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partner_amz_ad_accounts/`,
        method: "POST",
        body: queryArg.partnerAmzAdAccountRequest,
      }),
    }),
    apiCorePartnerIntakeCreate: build.mutation<
      ApiCorePartnerIntakeCreateApiResponse,
      ApiCorePartnerIntakeCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partner_intake/`,
        method: "POST",
        body: queryArg.partnerIntakeRequest,
      }),
    }),
    apiCorePartnerIntakeRetrieve: build.query<
      ApiCorePartnerIntakeRetrieveApiResponse,
      ApiCorePartnerIntakeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partner_intake/${queryArg.id}/`,
      }),
    }),
    apiCorePartnerIntakeUpdate: build.mutation<
      ApiCorePartnerIntakeUpdateApiResponse,
      ApiCorePartnerIntakeUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partner_intake/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.partnerIntakeRequest,
      }),
    }),
    apiCorePartnerIntakePartialUpdate: build.mutation<
      ApiCorePartnerIntakePartialUpdateApiResponse,
      ApiCorePartnerIntakePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partner_intake/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPartnerIntakeRequest,
      }),
    }),
    apiCorePartnerIntakeGetPartnerIntakeUnprocessedList: build.query<
      ApiCorePartnerIntakeGetPartnerIntakeUnprocessedListApiResponse,
      ApiCorePartnerIntakeGetPartnerIntakeUnprocessedListApiArg
    >({
      query: () => ({
        url: `/api/core/partner_intake/get_partner_intake_unprocessed/`,
      }),
    }),
    apiCorePartnersList: build.query<
      ApiCorePartnersListApiResponse,
      ApiCorePartnersListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partners/`,
        params: {
          assigned_only: queryArg.assignedOnly,
          is_active: queryArg.isActive,
        },
      }),
    }),
    apiCorePartnersCreate: build.mutation<
      ApiCorePartnersCreateApiResponse,
      ApiCorePartnersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partners/`,
        method: "POST",
        body: queryArg.partnerListRequest,
        params: { assigned_only: queryArg.assignedOnly },
      }),
    }),
    apiCorePartnersRetrieve: build.query<
      ApiCorePartnersRetrieveApiResponse,
      ApiCorePartnersRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partners/${queryArg.id}/`,
        params: { assigned_only: queryArg.assignedOnly },
      }),
    }),
    apiCorePartnersUpdate: build.mutation<
      ApiCorePartnersUpdateApiResponse,
      ApiCorePartnersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partners/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.partnerListRequest,
        params: { assigned_only: queryArg.assignedOnly },
      }),
    }),
    apiCorePartnersPartialUpdate: build.mutation<
      ApiCorePartnersPartialUpdateApiResponse,
      ApiCorePartnersPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partners/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPartnerDetailWriteRequest,
        params: { assigned_only: queryArg.assignedOnly },
      }),
    }),
    apiCorePartnersDestroy: build.mutation<
      ApiCorePartnersDestroyApiResponse,
      ApiCorePartnersDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partners/${queryArg.id}/`,
        method: "DELETE",
        params: { assigned_only: queryArg.assignedOnly },
      }),
    }),
    apiCorePartnersGetPartnersNotOnboardedList: build.query<
      ApiCorePartnersGetPartnersNotOnboardedListApiResponse,
      ApiCorePartnersGetPartnersNotOnboardedListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/partners/get_partners_not_onboarded/`,
        params: {
          assigned_only: queryArg.assignedOnly,
          is_active: queryArg.isActive,
        },
      }),
    }),
    apiCoreRolesList: build.query<
      ApiCoreRolesListApiResponse,
      ApiCoreRolesListApiArg
    >({
      query: () => ({ url: `/api/core/roles/` }),
    }),
    apiCoreUserRolesCreate: build.mutation<
      ApiCoreUserRolesCreateApiResponse,
      ApiCoreUserRolesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/user_roles/`,
        method: "POST",
        body: queryArg.userRoleCreateRequest,
      }),
    }),
    apiCoreUserRolesUpdate: build.mutation<
      ApiCoreUserRolesUpdateApiResponse,
      ApiCoreUserRolesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/user_roles/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.userRoleCreateRequest,
      }),
    }),
    apiCoreUserRolesPartialUpdate: build.mutation<
      ApiCoreUserRolesPartialUpdateApiResponse,
      ApiCoreUserRolesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/user_roles/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserRoleCreateRequest,
      }),
    }),
    apiCoreUsersList: build.query<
      ApiCoreUsersListApiResponse,
      ApiCoreUsersListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/users/`,
        params: {
          is_active: queryArg.isActive,
          is_nectar: queryArg.isNectar,
          is_partner: queryArg.isPartner,
        },
      }),
    }),
    apiCoreUsersCreate: build.mutation<
      ApiCoreUsersCreateApiResponse,
      ApiCoreUsersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/users/`,
        method: "POST",
        body: queryArg.userCreateRequest,
      }),
    }),
    apiCoreUsersRetrieve: build.query<
      ApiCoreUsersRetrieveApiResponse,
      ApiCoreUsersRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/core/users/${queryArg.id}/` }),
    }),
    apiCoreUsersUpdate: build.mutation<
      ApiCoreUsersUpdateApiResponse,
      ApiCoreUsersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/users/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.userRequest,
      }),
    }),
    apiCoreUsersPartialUpdate: build.mutation<
      ApiCoreUsersPartialUpdateApiResponse,
      ApiCoreUsersPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/users/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserUpdateRequest,
      }),
    }),
    apiCoreUsersDestroy: build.mutation<
      ApiCoreUsersDestroyApiResponse,
      ApiCoreUsersDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/users/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    apiGoalsCreateGoalCreate: build.mutation<
      ApiGoalsCreateGoalCreateApiResponse,
      ApiGoalsCreateGoalCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/goals/create_goal`,
        method: "POST",
        body: queryArg.goalCreateRequest,
      }),
    }),
    apiGoalsCurrentGoalsList: build.query<
      ApiGoalsCurrentGoalsListApiResponse,
      ApiGoalsCurrentGoalsListApiArg
    >({
      query: () => ({ url: `/api/goals/current_goals` }),
    }),
    apiGoalsGoalOpsList: build.query<
      ApiGoalsGoalOpsListApiResponse,
      ApiGoalsGoalOpsListApiArg
    >({
      query: () => ({ url: `/api/goals/goal_ops` }),
    }),
    apiGoalsGoalTypesList: build.query<
      ApiGoalsGoalTypesListApiResponse,
      ApiGoalsGoalTypesListApiArg
    >({
      query: () => ({ url: `/api/goals/goal_types/` }),
    }),
    apiGoalsOngoingGoalsList: build.query<
      ApiGoalsOngoingGoalsListApiResponse,
      ApiGoalsOngoingGoalsListApiArg
    >({
      query: () => ({ url: `/api/goals/ongoing_goals/` }),
    }),
    apiGoalsResolveAllGoalsCreate: build.mutation<
      ApiGoalsResolveAllGoalsCreateApiResponse,
      ApiGoalsResolveAllGoalsCreateApiArg
    >({
      query: () => ({ url: `/api/goals/resolve_all_goals`, method: "POST" }),
    }),
    apiGoalsResolveGoalCreate: build.mutation<
      ApiGoalsResolveGoalCreateApiResponse,
      ApiGoalsResolveGoalCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/goals/resolve_goal`,
        method: "POST",
        params: { pk: queryArg.pk },
      }),
    }),
    apiGoalsTimelineGoalsList: build.query<
      ApiGoalsTimelineGoalsListApiResponse,
      ApiGoalsTimelineGoalsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/goals/timeline_goals/`,
        params: {
          end_date: queryArg.endDate,
          goal_targets__object_id__in: queryArg.goalTargetsObjectIdIn,
        },
      }),
    }),
    apiPdmGetBrandsRetrieve: build.query<
      ApiPdmGetBrandsRetrieveApiResponse,
      ApiPdmGetBrandsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/get_brands`,
        params: { account: queryArg.account, partner: queryArg.partner },
      }),
    }),
    apiPdmGetListingEventCountList: build.query<
      ApiPdmGetListingEventCountListApiResponse,
      ApiPdmGetListingEventCountListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/get_listing_event_count`,
        params: { date: queryArg.date, partner: queryArg.partner },
      }),
    }),
    apiPdmGetListingEventRangeCountList: build.query<
      ApiPdmGetListingEventRangeCountListApiResponse,
      ApiPdmGetListingEventRangeCountListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/get_listing_event_range_count`,
        params: {
          end: queryArg.end,
          partner: queryArg.partner,
          start: queryArg.start,
        },
      }),
    }),
    apiPdmGetListingEventXlsRetrieve: build.query<
      ApiPdmGetListingEventXlsRetrieveApiResponse,
      ApiPdmGetListingEventXlsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/get_listing_event_xls`,
        params: { date: queryArg.date, partner: queryArg.partner },
      }),
    }),
    apiPdmGetListingsForPartnerCreate: build.mutation<
      ApiPdmGetListingsForPartnerCreateApiResponse,
      ApiPdmGetListingsForPartnerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/get_listings_for_partner`,
        method: "POST",
        body: queryArg.partnerOnlyRequest,
        params: { update: queryArg.update },
      }),
    }),
    apiPdmGetWholesalerDetailsCreate: build.mutation<
      ApiPdmGetWholesalerDetailsCreateApiResponse,
      ApiPdmGetWholesalerDetailsCreateApiArg
    >({
      query: () => ({ url: `/api/pdm/get_wholesaler_details`, method: "POST" }),
    }),
    apiPdmGetWholesalerOffersForPartnerCreate: build.mutation<
      ApiPdmGetWholesalerOffersForPartnerCreateApiResponse,
      ApiPdmGetWholesalerOffersForPartnerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/get_wholesaler_offers_for_partner`,
        method: "POST",
        body: queryArg.partnerOnlyRequest,
      }),
    }),
    apiPdmListingEventsList: build.query<
      ApiPdmListingEventsListApiResponse,
      ApiPdmListingEventsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/listing_events/`,
        params: {
          date: queryArg.date,
          listing: queryArg.listing,
          listing__partner: queryArg.listingPartner,
        },
      }),
    }),
    apiPdmListingEventsRetrieve: build.query<
      ApiPdmListingEventsRetrieveApiResponse,
      ApiPdmListingEventsRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/pdm/listing_events/${queryArg.id}/` }),
    }),
    apiPdmListingsList: build.query<
      ApiPdmListingsListApiResponse,
      ApiPdmListingsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/listings/`,
        params: { partner: queryArg.partner },
      }),
    }),
    apiPdmListingsCreate: build.mutation<
      ApiPdmListingsCreateApiResponse,
      ApiPdmListingsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/listings/`,
        method: "POST",
        body: queryArg.listingDetailRequest,
      }),
    }),
    apiPdmListingsRetrieve: build.query<
      ApiPdmListingsRetrieveApiResponse,
      ApiPdmListingsRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/pdm/listings/${queryArg.id}/` }),
    }),
    apiPdmListingsUpdate: build.mutation<
      ApiPdmListingsUpdateApiResponse,
      ApiPdmListingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/listings/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.listingDetailRequest,
      }),
    }),
    apiPdmListingsPartialUpdate: build.mutation<
      ApiPdmListingsPartialUpdateApiResponse,
      ApiPdmListingsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/listings/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedListingDetailRequest,
      }),
    }),
    apiPdmListingsDestroy: build.mutation<
      ApiPdmListingsDestroyApiResponse,
      ApiPdmListingsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/listings/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    apiPdmMetaList: build.query<
      ApiPdmMetaListApiResponse,
      ApiPdmMetaListApiArg
    >({
      query: () => ({ url: `/api/pdm/meta/` }),
    }),
    apiPdmMetaCreate: build.mutation<
      ApiPdmMetaCreateApiResponse,
      ApiPdmMetaCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/meta/`,
        method: "POST",
        body: queryArg.productListingAmzMetaRequest,
      }),
    }),
    apiPdmMetaRetrieve: build.query<
      ApiPdmMetaRetrieveApiResponse,
      ApiPdmMetaRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/pdm/meta/${queryArg.id}/` }),
    }),
    apiPdmMetaUpdate: build.mutation<
      ApiPdmMetaUpdateApiResponse,
      ApiPdmMetaUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/meta/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.productListingAmzMetaRequest,
      }),
    }),
    apiPdmMetaPartialUpdate: build.mutation<
      ApiPdmMetaPartialUpdateApiResponse,
      ApiPdmMetaPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/meta/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedProductListingAmzMetaRequest,
      }),
    }),
    apiPdmMetaDestroy: build.mutation<
      ApiPdmMetaDestroyApiResponse,
      ApiPdmMetaDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/meta/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    apiPdmProductGroupList: build.query<
      ApiPdmProductGroupListApiResponse,
      ApiPdmProductGroupListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/product_group/`,
        params: { partner: queryArg.partner },
      }),
    }),
    apiPdmProductGroupCreate: build.mutation<
      ApiPdmProductGroupCreateApiResponse,
      ApiPdmProductGroupCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/product_group/`,
        method: "POST",
        body: queryArg.productGroupRequest,
      }),
    }),
    apiPdmProductGroupRetrieve: build.query<
      ApiPdmProductGroupRetrieveApiResponse,
      ApiPdmProductGroupRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/pdm/product_group/${queryArg.id}/` }),
    }),
    apiPdmProductGroupUpdate: build.mutation<
      ApiPdmProductGroupUpdateApiResponse,
      ApiPdmProductGroupUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/product_group/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.productGroupRequest,
      }),
    }),
    apiPdmProductGroupPartialUpdate: build.mutation<
      ApiPdmProductGroupPartialUpdateApiResponse,
      ApiPdmProductGroupPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/product_group/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedProductGroupRequest,
      }),
    }),
    apiPdmProductGroupDestroy: build.mutation<
      ApiPdmProductGroupDestroyApiResponse,
      ApiPdmProductGroupDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/product_group/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    apiPdmProductProductTagsCreate: build.mutation<
      ApiPdmProductProductTagsCreateApiResponse,
      ApiPdmProductProductTagsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/product_product_tags/`,
        method: "POST",
        body: queryArg.productProductTagRequest,
      }),
    }),
    apiPdmProductProductTagsDestroy: build.mutation<
      ApiPdmProductProductTagsDestroyApiResponse,
      ApiPdmProductProductTagsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/product_product_tags/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    apiPdmProductProductTagsBulkUpdateUpdate: build.mutation<
      ApiPdmProductProductTagsBulkUpdateUpdateApiResponse,
      ApiPdmProductProductTagsBulkUpdateUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/product_product_tags/bulk_update/`,
        method: "PUT",
        body: queryArg.productProductTagBulkRequest,
      }),
    }),
    apiPdmProductTagsList: build.query<
      ApiPdmProductTagsListApiResponse,
      ApiPdmProductTagsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/product_tags/`,
        params: { partner: queryArg.partner },
      }),
    }),
    apiPdmProductTagsCreate: build.mutation<
      ApiPdmProductTagsCreateApiResponse,
      ApiPdmProductTagsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/product_tags/`,
        method: "POST",
        body: queryArg.productTagRequest,
      }),
    }),
    apiPdmTempSendSlackXlsToSlackCreate: build.mutation<
      ApiPdmTempSendSlackXlsToSlackCreateApiResponse,
      ApiPdmTempSendSlackXlsToSlackCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdm/temp_send_slack_xls_to_slack`,
        method: "POST",
        params: { partner: queryArg.partner },
      }),
    }),
    apiSpApiCatalogItemsCreate: build.mutation<
      ApiSpApiCatalogItemsCreateApiResponse,
      ApiSpApiCatalogItemsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/sp_api/catalog_items`,
        method: "POST",
        body: queryArg.catalogItemsRequestRequest,
      }),
    }),
    apiSpApiCreateNotificationSubscriptionsCreate: build.mutation<
      ApiSpApiCreateNotificationSubscriptionsCreateApiResponse,
      ApiSpApiCreateNotificationSubscriptionsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/sp_api/create_notification_subscriptions`,
        method: "POST",
        body: queryArg.partnerOnlyRequest,
      }),
    }),
    apiSpApiExchangeSpApiCredentialsCreate: build.mutation<
      ApiSpApiExchangeSpApiCredentialsCreateApiResponse,
      ApiSpApiExchangeSpApiCredentialsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/sp_api/exchange_sp_api_credentials`,
        method: "POST",
        body: queryArg.spApiTokenExchangeRequest,
      }),
    }),
    apiSpApiFbaInventoryCreate: build.mutation<
      ApiSpApiFbaInventoryCreateApiResponse,
      ApiSpApiFbaInventoryCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/sp_api/fba_inventory`,
        method: "POST",
        body: queryArg.fbaInventorySummaryRequestRequest,
      }),
    }),
    apiSpApiListingsItemCreate: build.mutation<
      ApiSpApiListingsItemCreateApiResponse,
      ApiSpApiListingsItemCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/sp_api/listings_item`,
        method: "POST",
        body: queryArg.listingsItemRequestRequest,
      }),
    }),
    apiSpApiSalesAndTrafficReportCreate: build.mutation<
      ApiSpApiSalesAndTrafficReportCreateApiResponse,
      ApiSpApiSalesAndTrafficReportCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/sp_api/sales_and_traffic_report`,
        method: "POST",
        body: queryArg.salesAndTrafficReportRequestRequest,
      }),
    }),
    authTokenCreate: build.mutation<
      AuthTokenCreateApiResponse,
      AuthTokenCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/token/`,
        method: "POST",
        body: queryArg.tokenObtainPairRequest,
      }),
    }),
    authTokenRefreshCreate: build.mutation<
      AuthTokenRefreshCreateApiResponse,
      AuthTokenRefreshCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/token/refresh/`,
        method: "POST",
        body: queryArg.tokenRefreshRequest,
      }),
    }),
    slackConversationsList: build.query<
      SlackConversationsListApiResponse,
      SlackConversationsListApiArg
    >({
      query: () => ({ url: `/slack/conversations` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as Api };
export type ApiAlertsAlertsListApiResponse = /** status 200  */ Alert[];
export type ApiAlertsAlertsListApiArg = void;
export type ApiAlertsAlertsCreateApiResponse = /** status 201  */ Alert;
export type ApiAlertsAlertsCreateApiArg = {
  alertRequest: AlertRequest;
};
export type ApiAlertsAlertsRetrieveApiResponse = /** status 200  */ Alert;
export type ApiAlertsAlertsRetrieveApiArg = {
  /** A UUID string identifying this alert. */
  id: string;
};
export type ApiAlertsAlertsUpdateApiResponse = /** status 200  */ Alert;
export type ApiAlertsAlertsUpdateApiArg = {
  /** A UUID string identifying this alert. */
  id: string;
  alertRequest: AlertRequest;
};
export type ApiAlertsAlertsPartialUpdateApiResponse = /** status 200  */ Alert;
export type ApiAlertsAlertsPartialUpdateApiArg = {
  /** A UUID string identifying this alert. */
  id: string;
  patchedAlertRequest: PatchedAlertRequest;
};
export type ApiAlertsAlertsDestroyApiResponse = unknown;
export type ApiAlertsAlertsDestroyApiArg = {
  /** A UUID string identifying this alert. */
  id: string;
};
export type ApiAlertsUpdateAlertPartialUpdateApiResponse =
  /** status 200  */ Alert;
export type ApiAlertsUpdateAlertPartialUpdateApiArg = {
  id: string;
  patchedAlertUpdateRequest: PatchedAlertUpdateRequest;
};
export type ApiBqDashboardDataListApiResponse =
  /** status 200  */ DashBoardResponse[];
export type ApiBqDashboardDataListApiArg = {
  dashboardRequestRequest: DashboardRequestRequest;
};
export type ApiBqIwAccountsListApiResponse = /** status 200  */ IwAccount[];
export type ApiBqIwAccountsListApiArg = void;
export type ApiCoreAllPartnerAccountsListApiResponse =
  /** status 200  */ PartnerAccount[];
export type ApiCoreAllPartnerAccountsListApiArg = {
  partnerAllAccountsRequestRequest: PartnerAllAccountsRequestRequest;
};
export type ApiCoreAmzAccountsListApiResponse = /** status 200  */ AmzAccount[];
export type ApiCoreAmzAccountsListApiArg = {
  partnerAmzAccountsPartnerId?: string;
};
export type ApiCoreAmzAccountsCreateApiResponse =
  /** status 201  */ AmzAccountCreate;
export type ApiCoreAmzAccountsCreateApiArg = {
  amzAccountCreateRequest: AmzAccountCreateRequest;
};
export type ApiCoreAmzAccountsRetrieveApiResponse =
  /** status 200  */ AmzAccount;
export type ApiCoreAmzAccountsRetrieveApiArg = {
  /** A UUID string identifying this amz account. */
  id: string;
};
export type ApiCoreAmzAccountsUpdateApiResponse = /** status 200  */ AmzAccount;
export type ApiCoreAmzAccountsUpdateApiArg = {
  /** A UUID string identifying this amz account. */
  id: string;
  amzAccountRequest: AmzAccountRequest;
};
export type ApiCoreAmzAccountsPartialUpdateApiResponse =
  /** status 200  */ AmzAccount;
export type ApiCoreAmzAccountsPartialUpdateApiArg = {
  /** A UUID string identifying this amz account. */
  id: string;
  patchedAmzAccountRequest: PatchedAmzAccountRequest;
};
export type ApiCoreAmzAdAccountsListApiResponse =
  /** status 200  */ AmzAdAccount[];
export type ApiCoreAmzAdAccountsListApiArg = {
  partnerAmzAdAccountsPartnerId?: string;
};
export type ApiCoreAmzAdAccountsCreateApiResponse =
  /** status 201  */ AmzAdAccountCreate;
export type ApiCoreAmzAdAccountsCreateApiArg = {
  amzAdAccountCreateRequest: AmzAdAccountCreateRequest;
};
export type ApiCoreAmzAdAccountsUpdateApiResponse =
  /** status 200  */ AmzAdAccount;
export type ApiCoreAmzAdAccountsUpdateApiArg = {
  /** A UUID string identifying this amz ad account. */
  id: string;
  amzAdAccountRequest: AmzAdAccountRequest;
};
export type ApiCoreAmzAdAccountsPartialUpdateApiResponse =
  /** status 200  */ AmzAdAccount;
export type ApiCoreAmzAdAccountsPartialUpdateApiArg = {
  /** A UUID string identifying this amz ad account. */
  id: string;
  patchedAmzAdAccountRequest: PatchedAmzAdAccountRequest;
};
export type ApiCoreAsyncTasksListApiResponse = /** status 200  */ AsyncTask[];
export type ApiCoreAsyncTasksListApiArg = void;
export type ApiCoreAsyncTasksRetrieveApiResponse = /** status 200  */ AsyncTask;
export type ApiCoreAsyncTasksRetrieveApiArg = {
  /** A UUID string identifying this async task. */
  id: string;
};
export type ApiCoreCurrentUserRetrieveApiResponse = /** status 200  */ User;
export type ApiCoreCurrentUserRetrieveApiArg = void;
export type ApiCorePartnerAmzAccountsCreateApiResponse =
  /** status 201  */ PartnerAmzAccount;
export type ApiCorePartnerAmzAccountsCreateApiArg = {
  partnerAmzAccountRequest: PartnerAmzAccountRequest;
};
export type ApiCorePartnerAmzAdAccountsCreateApiResponse =
  /** status 201  */ PartnerAmzAdAccount;
export type ApiCorePartnerAmzAdAccountsCreateApiArg = {
  partnerAmzAdAccountRequest: PartnerAmzAdAccountRequest;
};
export type ApiCorePartnerIntakeCreateApiResponse =
  /** status 201  */ PartnerIntake;
export type ApiCorePartnerIntakeCreateApiArg = {
  partnerIntakeRequest: PartnerIntakeRequest;
};
export type ApiCorePartnerIntakeRetrieveApiResponse =
  /** status 200  */ PartnerIntake;
export type ApiCorePartnerIntakeRetrieveApiArg = {
  /** A UUID string identifying this partner intake. */
  id: string;
};
export type ApiCorePartnerIntakeUpdateApiResponse =
  /** status 200  */ PartnerIntake;
export type ApiCorePartnerIntakeUpdateApiArg = {
  /** A UUID string identifying this partner intake. */
  id: string;
  partnerIntakeRequest: PartnerIntakeRequest;
};
export type ApiCorePartnerIntakePartialUpdateApiResponse =
  /** status 200  */ PartnerIntake;
export type ApiCorePartnerIntakePartialUpdateApiArg = {
  /** A UUID string identifying this partner intake. */
  id: string;
  patchedPartnerIntakeRequest: PatchedPartnerIntakeRequest;
};
export type ApiCorePartnerIntakeGetPartnerIntakeUnprocessedListApiResponse =
  /** status 200  */ PartnerIntake[];
export type ApiCorePartnerIntakeGetPartnerIntakeUnprocessedListApiArg = void;
export type ApiCorePartnersListApiResponse = /** status 200  */ PartnerList[];
export type ApiCorePartnersListApiArg = {
  assignedOnly?: boolean;
  isActive?: boolean;
};
export type ApiCorePartnersCreateApiResponse = /** status 201  */ PartnerList;
export type ApiCorePartnersCreateApiArg = {
  assignedOnly?: boolean;
  partnerListRequest: PartnerListRequest;
};
export type ApiCorePartnersRetrieveApiResponse =
  /** status 200  */ PartnerDetailRead;
export type ApiCorePartnersRetrieveApiArg = {
  assignedOnly?: boolean;
  /** A UUID string identifying this partner. */
  id: string;
};
export type ApiCorePartnersUpdateApiResponse = /** status 200  */ PartnerList;
export type ApiCorePartnersUpdateApiArg = {
  assignedOnly?: boolean;
  /** A UUID string identifying this partner. */
  id: string;
  partnerListRequest: PartnerListRequest;
};
export type ApiCorePartnersPartialUpdateApiResponse =
  /** status 200  */ PartnerDetailWrite;
export type ApiCorePartnersPartialUpdateApiArg = {
  assignedOnly?: boolean;
  /** A UUID string identifying this partner. */
  id: string;
  patchedPartnerDetailWriteRequest: PatchedPartnerDetailWriteRequest;
};
export type ApiCorePartnersDestroyApiResponse = unknown;
export type ApiCorePartnersDestroyApiArg = {
  assignedOnly?: boolean;
  /** A UUID string identifying this partner. */
  id: string;
};
export type ApiCorePartnersGetPartnersNotOnboardedListApiResponse =
  /** status 200  */ PartnerList[];
export type ApiCorePartnersGetPartnersNotOnboardedListApiArg = {
  assignedOnly?: boolean;
  isActive?: boolean;
};
export type ApiCoreRolesListApiResponse = /** status 200  */ Role[];
export type ApiCoreRolesListApiArg = void;
export type ApiCoreUserRolesCreateApiResponse =
  /** status 201  */ UserRoleCreate;
export type ApiCoreUserRolesCreateApiArg = {
  userRoleCreateRequest: UserRoleCreateRequest;
};
export type ApiCoreUserRolesUpdateApiResponse =
  /** status 200  */ UserRoleCreate;
export type ApiCoreUserRolesUpdateApiArg = {
  /** A UUID string identifying this user role. */
  id: string;
  userRoleCreateRequest: UserRoleCreateRequest;
};
export type ApiCoreUserRolesPartialUpdateApiResponse =
  /** status 200  */ UserRoleCreate;
export type ApiCoreUserRolesPartialUpdateApiArg = {
  /** A UUID string identifying this user role. */
  id: string;
  patchedUserRoleCreateRequest: PatchedUserRoleCreateRequest;
};
export type ApiCoreUsersListApiResponse = /** status 200  */ User[];
export type ApiCoreUsersListApiArg = {
  isActive?: boolean;
  isNectar?: boolean;
  isPartner?: boolean;
};
export type ApiCoreUsersCreateApiResponse = /** status 201  */ UserCreate;
export type ApiCoreUsersCreateApiArg = {
  userCreateRequest: UserCreateRequest;
};
export type ApiCoreUsersRetrieveApiResponse = /** status 200  */ User;
export type ApiCoreUsersRetrieveApiArg = {
  /** A UUID string identifying this user. */
  id: string;
};
export type ApiCoreUsersUpdateApiResponse = /** status 200  */ User;
export type ApiCoreUsersUpdateApiArg = {
  /** A UUID string identifying this user. */
  id: string;
  userRequest: UserRequest;
};
export type ApiCoreUsersPartialUpdateApiResponse =
  /** status 200  */ UserUpdate;
export type ApiCoreUsersPartialUpdateApiArg = {
  /** A UUID string identifying this user. */
  id: string;
  patchedUserUpdateRequest: PatchedUserUpdateRequest;
};
export type ApiCoreUsersDestroyApiResponse = unknown;
export type ApiCoreUsersDestroyApiArg = {
  /** A UUID string identifying this user. */
  id: string;
};
export type ApiGoalsCreateGoalCreateApiResponse = /** status 200  */ GoalList;
export type ApiGoalsCreateGoalCreateApiArg = {
  goalCreateRequest: GoalCreateRequest;
};
export type ApiGoalsCurrentGoalsListApiResponse = /** status 200  */ GoalList[];
export type ApiGoalsCurrentGoalsListApiArg = void;
export type ApiGoalsGoalOpsListApiResponse = /** status 200  */ Success[];
export type ApiGoalsGoalOpsListApiArg = void;
export type ApiGoalsGoalTypesListApiResponse = /** status 200  */ GoalType[];
export type ApiGoalsGoalTypesListApiArg = void;
export type ApiGoalsOngoingGoalsListApiResponse =
  /** status 200  */ OngoingGoalList[];
export type ApiGoalsOngoingGoalsListApiArg = void;
export type ApiGoalsResolveAllGoalsCreateApiResponse =
  /** status 200  */ Success;
export type ApiGoalsResolveAllGoalsCreateApiArg = void;
export type ApiGoalsResolveGoalCreateApiResponse = unknown;
export type ApiGoalsResolveGoalCreateApiArg = {
  pk?: string;
};
export type ApiGoalsTimelineGoalsListApiResponse =
  /** status 200  */ TimeLineGoalList[];
export type ApiGoalsTimelineGoalsListApiArg = {
  endDate?: string;
  /** Multiple values may be separated by commas. */
  goalTargetsObjectIdIn?: string[];
};
export type ApiPdmGetBrandsRetrieveApiResponse = unknown;
export type ApiPdmGetBrandsRetrieveApiArg = {
  account?: string;
  partner?: string;
};
export type ApiPdmGetListingEventCountListApiResponse =
  /** status 200  */ ListingEventCount[];
export type ApiPdmGetListingEventCountListApiArg = {
  date?: string;
  partner?: string;
};
export type ApiPdmGetListingEventRangeCountListApiResponse =
  /** status 200  */ ListingEventCount[];
export type ApiPdmGetListingEventRangeCountListApiArg = {
  end: string;
  partner: string;
  start: string;
};
export type ApiPdmGetListingEventXlsRetrieveApiResponse =
  /** status 200  */ Blob;
export type ApiPdmGetListingEventXlsRetrieveApiArg = {
  date: string;
  partner: string;
};
export type ApiPdmGetListingsForPartnerCreateApiResponse =
  /** status 200  */ Success;
export type ApiPdmGetListingsForPartnerCreateApiArg = {
  update?: boolean;
  partnerOnlyRequest: PartnerOnlyRequest;
};
export type ApiPdmGetWholesalerDetailsCreateApiResponse =
  /** status 200  */ Success;
export type ApiPdmGetWholesalerDetailsCreateApiArg = void;
export type ApiPdmGetWholesalerOffersForPartnerCreateApiResponse =
  /** status 200  */ Success;
export type ApiPdmGetWholesalerOffersForPartnerCreateApiArg = {
  partnerOnlyRequest: PartnerOnlyRequest;
};
export type ApiPdmListingEventsListApiResponse =
  /** status 200  */ ListingEvent[];
export type ApiPdmListingEventsListApiArg = {
  date?: string;
  listing?: string;
  listingPartner?: string;
};
export type ApiPdmListingEventsRetrieveApiResponse =
  /** status 200  */ ListingEvent;
export type ApiPdmListingEventsRetrieveApiArg = {
  /** A UUID string identifying this listing event. */
  id: string;
};
export type ApiPdmListingsListApiResponse = /** status 200  */ ListingList[];
export type ApiPdmListingsListApiArg = {
  partner?: string;
};
export type ApiPdmListingsCreateApiResponse = /** status 201  */ ListingDetail;
export type ApiPdmListingsCreateApiArg = {
  listingDetailRequest: ListingDetailRequest;
};
export type ApiPdmListingsRetrieveApiResponse =
  /** status 200  */ ListingDetail;
export type ApiPdmListingsRetrieveApiArg = {
  /** A UUID string identifying this product listing amazon. */
  id: string;
};
export type ApiPdmListingsUpdateApiResponse = /** status 200  */ ListingDetail;
export type ApiPdmListingsUpdateApiArg = {
  /** A UUID string identifying this product listing amazon. */
  id: string;
  listingDetailRequest: ListingDetailRequest;
};
export type ApiPdmListingsPartialUpdateApiResponse =
  /** status 200  */ ListingDetail;
export type ApiPdmListingsPartialUpdateApiArg = {
  /** A UUID string identifying this product listing amazon. */
  id: string;
  patchedListingDetailRequest: PatchedListingDetailRequest;
};
export type ApiPdmListingsDestroyApiResponse = unknown;
export type ApiPdmListingsDestroyApiArg = {
  /** A UUID string identifying this product listing amazon. */
  id: string;
};
export type ApiPdmMetaListApiResponse =
  /** status 200  */ ProductListingAmzMeta[];
export type ApiPdmMetaListApiArg = void;
export type ApiPdmMetaCreateApiResponse =
  /** status 201  */ ProductListingAmzMeta;
export type ApiPdmMetaCreateApiArg = {
  productListingAmzMetaRequest: ProductListingAmzMetaRequest;
};
export type ApiPdmMetaRetrieveApiResponse =
  /** status 200  */ ProductListingAmzMeta;
export type ApiPdmMetaRetrieveApiArg = {
  /** A UUID string identifying this product listing amz meta. */
  id: string;
};
export type ApiPdmMetaUpdateApiResponse =
  /** status 200  */ ProductListingAmzMeta;
export type ApiPdmMetaUpdateApiArg = {
  /** A UUID string identifying this product listing amz meta. */
  id: string;
  productListingAmzMetaRequest: ProductListingAmzMetaRequest;
};
export type ApiPdmMetaPartialUpdateApiResponse =
  /** status 200  */ ProductListingAmzMeta;
export type ApiPdmMetaPartialUpdateApiArg = {
  /** A UUID string identifying this product listing amz meta. */
  id: string;
  patchedProductListingAmzMetaRequest: PatchedProductListingAmzMetaRequest;
};
export type ApiPdmMetaDestroyApiResponse = unknown;
export type ApiPdmMetaDestroyApiArg = {
  /** A UUID string identifying this product listing amz meta. */
  id: string;
};
export type ApiPdmProductGroupListApiResponse =
  /** status 200  */ ProductGroup[];
export type ApiPdmProductGroupListApiArg = {
  partner?: string;
};
export type ApiPdmProductGroupCreateApiResponse =
  /** status 201  */ ProductGroup;
export type ApiPdmProductGroupCreateApiArg = {
  productGroupRequest: ProductGroupRequest;
};
export type ApiPdmProductGroupRetrieveApiResponse =
  /** status 200  */ ProductGroup;
export type ApiPdmProductGroupRetrieveApiArg = {
  /** A UUID string identifying this product group. */
  id: string;
};
export type ApiPdmProductGroupUpdateApiResponse =
  /** status 200  */ ProductGroup;
export type ApiPdmProductGroupUpdateApiArg = {
  /** A UUID string identifying this product group. */
  id: string;
  productGroupRequest: ProductGroupRequest;
};
export type ApiPdmProductGroupPartialUpdateApiResponse =
  /** status 200  */ ProductGroup;
export type ApiPdmProductGroupPartialUpdateApiArg = {
  /** A UUID string identifying this product group. */
  id: string;
  patchedProductGroupRequest: PatchedProductGroupRequest;
};
export type ApiPdmProductGroupDestroyApiResponse = unknown;
export type ApiPdmProductGroupDestroyApiArg = {
  /** A UUID string identifying this product group. */
  id: string;
};
export type ApiPdmProductProductTagsCreateApiResponse =
  /** status 201  */ ProductProductTag;
export type ApiPdmProductProductTagsCreateApiArg = {
  productProductTagRequest: ProductProductTagRequest;
};
export type ApiPdmProductProductTagsDestroyApiResponse = unknown;
export type ApiPdmProductProductTagsDestroyApiArg = {
  /** A UUID string identifying this product product tag. */
  id: string;
};
export type ApiPdmProductProductTagsBulkUpdateUpdateApiResponse =
  /** status 200  */ Success;
export type ApiPdmProductProductTagsBulkUpdateUpdateApiArg = {
  productProductTagBulkRequest: ProductProductTagBulkRequest;
};
export type ApiPdmProductTagsListApiResponse = /** status 200  */ ProductTag[];
export type ApiPdmProductTagsListApiArg = {
  partner?: string;
};
export type ApiPdmProductTagsCreateApiResponse = /** status 201  */ ProductTag;
export type ApiPdmProductTagsCreateApiArg = {
  productTagRequest: ProductTagRequest;
};
export type ApiPdmTempSendSlackXlsToSlackCreateApiResponse = unknown;
export type ApiPdmTempSendSlackXlsToSlackCreateApiArg = {
  partner: string;
};
export type ApiSpApiCatalogItemsCreateApiResponse = /** status 200  */ {
  [key: string]: any;
};
export type ApiSpApiCatalogItemsCreateApiArg = {
  catalogItemsRequestRequest: CatalogItemsRequestRequest;
};
export type ApiSpApiCreateNotificationSubscriptionsCreateApiResponse =
  /** status 200  */ {
    [key: string]: any;
  };
export type ApiSpApiCreateNotificationSubscriptionsCreateApiArg = {
  partnerOnlyRequest: PartnerOnlyRequest;
};
export type ApiSpApiExchangeSpApiCredentialsCreateApiResponse =
  /** status 200  */ Success;
export type ApiSpApiExchangeSpApiCredentialsCreateApiArg = {
  spApiTokenExchangeRequest: SpApiTokenExchangeRequest;
};
export type ApiSpApiFbaInventoryCreateApiResponse =
  /** status 200  */ GetInventorySummariesResult;
export type ApiSpApiFbaInventoryCreateApiArg = {
  fbaInventorySummaryRequestRequest: FbaInventorySummaryRequestRequest;
};
export type ApiSpApiListingsItemCreateApiResponse = /** status 200  */ {
  [key: string]: any;
};
export type ApiSpApiListingsItemCreateApiArg = {
  listingsItemRequestRequest: ListingsItemRequestRequest;
};
export type ApiSpApiSalesAndTrafficReportCreateApiResponse =
  /** status 200  */ SalesAndTrafficReportResponse;
export type ApiSpApiSalesAndTrafficReportCreateApiArg = {
  salesAndTrafficReportRequestRequest: SalesAndTrafficReportRequestRequest;
};
export type AuthTokenCreateApiResponse = /** status 200  */ TokenObtainPair;
export type AuthTokenCreateApiArg = {
  tokenObtainPairRequest: TokenObtainPairRequest;
};
export type AuthTokenRefreshCreateApiResponse = /** status 200  */ TokenRefresh;
export type AuthTokenRefreshCreateApiArg = {
  tokenRefreshRequest: TokenRefreshRequest;
};
export type SlackConversationsListApiResponse =
  /** status 200  */ ConversationsList[];
export type SlackConversationsListApiArg = void;
export type AlertTypeEnum = "GOAL_PROJECTION" | "ALERT_TRIGGER_COUNT";
export type Alert = {
  id: string;
  is_active: boolean;
  type: AlertTypeEnum;
  alert_message: string;
  action_taken?: string;
  first_triggered: string;
  date_action_taken?: string | null;
  snooze_until?: string | null;
  current_date: string;
  trigger_count?: number;
  object_id: string;
  owner: string;
  content_type: number;
};
export type AlertRequest = {
  is_active: boolean;
  type: AlertTypeEnum;
  alert_message: string;
  action_taken?: string;
  date_action_taken?: string | null;
  snooze_until?: string | null;
  current_date: string;
  trigger_count?: number;
  object_id: string;
  owner: string;
  content_type: number;
};
export type PatchedAlertRequest = {
  is_active?: boolean;
  type?: AlertTypeEnum;
  alert_message?: string;
  action_taken?: string;
  date_action_taken?: string | null;
  snooze_until?: string | null;
  current_date?: string;
  trigger_count?: number;
  object_id?: string;
  owner?: string;
  content_type?: number;
};
export type PatchedAlertUpdateRequest = {
  snooze_until?: string;
  is_active?: boolean;
  action_taken?: string;
};
export type DashboardDataResult = {
  account_id: number;
  is_total?: boolean;
  product_sales?: string;
  product_sales_comp?: string;
  sourcing_shipped_revenue?: string;
  sourcing_shipped_revenue_comp?: string;
  ad_spend?: string;
  ad_spend_comp?: string;
  ad_revenue?: string;
  ad_revenue_comp?: string;
  tacos?: string;
  tacos_comp?: string;
  data_begin: string;
  data_end: string;
};
export type DashBoardResponse = {
  name: string;
  accounts: number[];
  data: DashboardDataResult[];
};
export type KpisEnum =
  | "SALES_SC"
  | "SALES_VC"
  | "AD_SPEND"
  | "AD_SALES"
  | "TACOS";
export type DashboardRequestRequest = {
  filter_partners?: string[];
  kpis: KpisEnum[];
  start_date: string;
  end_date: string;
  compare_start?: string;
  compare_end?: string;
};
export type IwAccount = {
  account_name: string;
  account_id: number;
};
export type MarketplaceEnum =
  | "US"
  | "CA"
  | "MX"
  | "BR"
  | "ES"
  | "UK"
  | "FR"
  | "BE"
  | "NL"
  | "DE"
  | "IT"
  | "SE"
  | "PL"
  | "EG"
  | "TR"
  | "SA"
  | "AE"
  | "IN"
  | "SG"
  | "AU"
  | "JP";
export type AmzAccount = {
  id: string;
  name: string;
  iw_id?: number | null;
  marketplace?: MarketplaceEnum;
  is_sc: boolean;
  is_vc: boolean;
  login_url?: string;
};
export type AmzAdAccount = {
  id: string;
  name: string;
  iw_id?: number | null;
};
export type PartnerAccount = {
  partner_name: string;
  partner_id: string;
  has_custom_data_strategy: boolean;
  amz_accounts: AmzAccount[];
  amz_ad_accounts: AmzAdAccount[];
};
export type PartnerAllAccountsRequestRequest = {
  partners: string[];
};
export type AmzAccountCreate = {
  id: string;
  name: string;
  iw_id?: number | null;
  marketplace?: MarketplaceEnum;
  is_sc: boolean;
  is_vc: boolean;
  login_url?: string;
  partner_id: string;
};
export type AmzAccountCreateRequest = {
  name: string;
  iw_id?: number | null;
  marketplace?: MarketplaceEnum;
  is_sc: boolean;
  is_vc: boolean;
  login_url?: string;
  partner_id: string;
};
export type AmzAccountRequest = {
  name: string;
  iw_id?: number | null;
  marketplace?: MarketplaceEnum;
  is_sc: boolean;
  is_vc: boolean;
  login_url?: string;
};
export type PatchedAmzAccountRequest = {
  name?: string;
  iw_id?: number | null;
  marketplace?: MarketplaceEnum;
  is_sc?: boolean;
  is_vc?: boolean;
  login_url?: string;
};
export type AmzAdAccountCreate = {
  id: string;
  name: string;
  iw_id?: number | null;
  partner_id: string;
};
export type AmzAdAccountCreateRequest = {
  name: string;
  iw_id?: number | null;
  partner_id: string;
};
export type AmzAdAccountRequest = {
  name: string;
  iw_id?: number | null;
};
export type PatchedAmzAdAccountRequest = {
  name?: string;
  iw_id?: number | null;
};
export type StatusEnum = "PENDING" | "STARTED" | "SUCCESS" | "ERROR";
export type AsyncTask = {
  id: string;
  task_id: string;
  task: string;
  status: StatusEnum;
  note?: string;
  created: string;
  updated: string;
  requester?: string | null;
};
export type DepartmentNameEnum =
  | "MANAGEMENT"
  | "GRAPHICS"
  | "COPYWRITING"
  | "ACCOUNT_MANAGEMENT"
  | "ADVERTISING"
  | "BACK_OFFICE"
  | "SALES"
  | "DIGITAL_MARKETING"
  | "ANALYST"
  | "PARTNER";
export type Department = {
  id: string;
  name: DepartmentNameEnum;
};
export type RoleNameEnum =
  | "DIRECTOR"
  | "MANAGER"
  | "SM"
  | "COPYWRITER"
  | "AM"
  | "DESIGNER"
  | "SALESPERSON"
  | "ANALYST"
  | "AD_MANAGER"
  | "ASSISTANT"
  | "VA"
  | "PARTNER"
  | "PARTNER_EMPLOYEE";
export type RoleLevelEnum = 5 | 4 | 3 | 2 | 1;
export type Role = {
  id: string;
  department: Department;
  name: RoleNameEnum;
  role_level: RoleLevelEnum;
};
export type UserRoleList = {
  id: string;
  role: Role;
};
export type PartnerMinimal = {
  id: string;
  name: string;
};
export type User = {
  id: string;
  user_roles: UserRoleList[];
  user_partners: PartnerMinimal[];
  last_login: string | null;
  email: string;
  first_name: string;
  last_name: string;
  is_active?: boolean;
  is_staff?: boolean;
  is_nectar?: boolean;
  is_admin?: boolean;
  is_superuser?: boolean;
  is_partner?: boolean;
  slack_id?: string | null;
};
export type PartnerAmzAccount = {
  id: string;
  partner: string;
  account: string;
};
export type PartnerAmzAccountRequest = {
  partner: string;
  account: string;
};
export type PartnerAmzAdAccount = {
  id: string;
  partner: string;
  account: string;
};
export type PartnerAmzAdAccountRequest = {
  partner: string;
  account: string;
};
export type PartnerTypeEnum = "FM" | "AD";
export type PartnerIntake = {
  id: string;
  name: string;
  contract_link: string;
  partner_type: PartnerTypeEnum;
  onboarding_contact_first: string;
  onboarding_contact_last: string;
  onboarding_contact_email: string;
  num_ad_accounts: number;
  num_sc_accounts: number;
  num_vc_accounts: number;
  pain_points: string;
  notes?: string;
  created_by: string;
  partner?: string | null;
};
export type PartnerIntakeRequest = {
  name: string;
  contract_link: string;
  partner_type: PartnerTypeEnum;
  onboarding_contact_first: string;
  onboarding_contact_last: string;
  onboarding_contact_email: string;
  num_ad_accounts: number;
  num_sc_accounts: number;
  num_vc_accounts: number;
  pain_points: string;
  notes?: string;
  partner?: string | null;
};
export type PatchedPartnerIntakeRequest = {
  name?: string;
  contract_link?: string;
  partner_type?: PartnerTypeEnum;
  onboarding_contact_first?: string;
  onboarding_contact_last?: string;
  onboarding_contact_email?: string;
  num_ad_accounts?: number;
  num_sc_accounts?: number;
  num_vc_accounts?: number;
  pain_points?: string;
  notes?: string;
  partner?: string | null;
};
export type UserNameRead = {
  id: string;
  first_name: string;
  last_name: string;
};
export type PartnerList = {
  id: string;
  name: string;
  short_code: string;
  is_active: boolean;
  logo_url?: string;
  slack_channel?: string;
  partner_intake?: string;
  group_email_address?: string;
  partner_type: PartnerTypeEnum;
  partner_am?: UserNameRead | null;
  partner_sm?: UserNameRead | null;
  has_custom_data_strategy?: boolean;
};
export type UserNameReadRequest = {
  first_name: string;
  last_name: string;
};
export type PartnerListRequest = {
  name: string;
  short_code: string;
  logo_url?: string;
  slack_channel?: string;
  partner_intake?: string;
  group_email_address?: string;
  partner_type: PartnerTypeEnum;
  partner_am?: UserNameReadRequest | null;
  partner_sm?: UserNameReadRequest | null;
  has_custom_data_strategy?: boolean;
};
export type PartnerDetailRead = {
  id: string;
  name: string;
  short_code: string;
  is_active?: boolean;
  slack_channel?: string;
  logo_url?: string;
  active_sp_api_credentials: boolean;
  partner_sm: UserNameRead | null;
  partner_am: UserNameRead | null;
  partner_employees: UserNameRead[];
  group_email_address?: string;
  intake_form: string;
  has_custom_data_strategy?: boolean;
};
export type PartnerDetailWrite = {
  id: string;
  name: string;
  short_code: string;
  is_active?: boolean;
  slack_channel?: string;
  logo_url?: string;
  partner_sm?: string;
  partner_am?: string;
  group_email_address?: string;
  has_custom_data_strategy?: boolean;
};
export type PatchedPartnerDetailWriteRequest = {
  name?: string;
  short_code?: string;
  is_active?: boolean;
  slack_channel?: string;
  logo_url?: string;
  partner_sm?: string;
  partner_am?: string;
  group_email_address?: string;
  has_custom_data_strategy?: boolean;
};
export type UserRoleCreate = {
  id: string;
  user: string;
  role: string;
};
export type UserRoleCreateRequest = {
  user: string;
  role: string;
};
export type PatchedUserRoleCreateRequest = {
  user?: string;
  role?: string;
};
export type UserCreate = {
  id: string;
  user_roles: string[];
  user_partners?: string[];
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  is_staff: boolean;
  is_partner: boolean;
  slack_id?: string | null;
  is_superuser: boolean;
};
export type UserCreateRequest = {
  user_roles: string[];
  user_partners?: string[];
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  slack_id?: string | null;
};
export type UserRequest = {
  email: string;
  first_name: string;
  last_name: string;
  is_active?: boolean;
  is_staff?: boolean;
  is_nectar?: boolean;
  is_admin?: boolean;
  is_superuser?: boolean;
  is_partner?: boolean;
  slack_id?: string | null;
};
export type UserUpdate = {
  id: string;
  user_roles: string[];
  user_partners?: string[];
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  slack_id?: string | null;
};
export type PatchedUserUpdateRequest = {
  user_roles?: string[];
  user_partners?: string[];
  first_name?: string;
  last_name?: string;
  slack_id?: string | null;
};
export type FavorabilityEnum = "ABOVE" | "BELOW" | "EXACT";
export type GoalOperationEnum =
  | "HOLD_VALUE"
  | "EXACT_VALUE"
  | "INCREASE_VALUE"
  | "DECREASE_VALUE"
  | "INCREASE_PERCENT"
  | "DECREASE_PERCENT";
export type GoalList = {
  id: string;
  name: string;
  revision_number?: number | null;
  is_active?: boolean;
  start_date: string;
  end_date?: string | null;
  favorability: FavorabilityEnum;
  goal_operation: GoalOperationEnum;
  goal_operation_value_low?: string | null;
  goal_operation_value_middle?: string | null;
  goal_operation_value_high?: string | null;
  target_value_low?: string | null;
  target_value_middle?: string | null;
  target_value_high?: string | null;
  initial_value?: string | null;
  original_initial_value?: string | null;
  revision_of?: string | null;
  goal_type: string;
};
export type OwnerTypeEnum =
  | "PARTNER"
  | "EMPLOYEE"
  | "DEPARTMENT"
  | "ROLE"
  | "NECTAR";
export type GoalOwnerCreateRequest = {
  owner_type: OwnerTypeEnum;
  partner?: string;
  employee?: string;
  role?: string;
  department?: string;
};
export type TargetTypeEnum =
  | "PARTNER"
  | "ACCOUNT"
  | "PRODUCT_GROUP"
  | "PRODUCT"
  | "PRODUCT_TAG";
export type GoalTargetCreateRequest = {
  target_type: TargetTypeEnum;
  partner?: string;
  account?: string;
  product_group?: string;
  product?: string;
  product_tag?: string;
};
export type DateRangeRequest = {
  start_date: string;
  end_date: string;
};
export type GoalOperationRequest = {
  goal_operation: GoalOperationEnum;
  goal_operation_value: string;
  favorability: FavorabilityEnum;
};
export type GoalCreateRequest = {
  goal_type: string;
  goal_owners: GoalOwnerCreateRequest[];
  goal_targets: GoalTargetCreateRequest[];
  goal_name: string;
  date_ranges: DateRangeRequest[];
  goal_operation_low?: GoalOperationRequest;
  goal_operation_middle?: GoalOperationRequest;
  goal_operation_high?: GoalOperationRequest;
};
export type Success = {
  name: string;
  value: string;
};
export type KpiEnum = "TOTAL_SALES" | "TACOS" | "AD_SPEND";
export type PeriodEnum = "MONTHLY" | "QUARTERLY" | "YEARLY";
export type GoalType = {
  id: string;
  kpi: KpiEnum;
  ongoing: boolean;
  period: PeriodEnum;
};
export type GoalResultMethod = {
  id: string;
  result_date: string;
  data_end_date: string;
  value: string;
  projected_value: string;
  performance_low?: string | null;
  performance_middle?: string | null;
  performance_high?: string | null;
  is_final?: boolean;
  goal: string;
};
export type GoalOwnerGoal = {
  string_name: string;
  owner_type: OwnerTypeEnum;
  object_id?: string | null;
};
export type GoalTargetGoal = {
  string_name: string;
  target_type: TargetTypeEnum;
  object_id: string;
};
export type OngoingGoalList = {
  id: string;
  prev_results: GoalResultMethod[];
  current_result: GoalResultMethod;
  goal_owners: GoalOwnerGoal[];
  goal_targets: GoalTargetGoal[];
  name: string;
  revision_number?: number | null;
  is_active?: boolean;
  start_date: string;
  end_date?: string | null;
  favorability: FavorabilityEnum;
  goal_operation: GoalOperationEnum;
  goal_operation_value_low?: string | null;
  goal_operation_value_middle?: string | null;
  goal_operation_value_high?: string | null;
  target_value_low?: string | null;
  target_value_middle?: string | null;
  target_value_high?: string | null;
  initial_value?: string | null;
  original_initial_value?: string | null;
  revision_of?: string | null;
  goal_type: string;
};
export type TimeLineGoalList = {
  id: string;
  last_result: GoalResultMethod;
  goal_owners: GoalOwnerGoal[];
  goal_targets: GoalTargetGoal[];
  name: string;
  revision_number?: number | null;
  is_active?: boolean;
  start_date: string;
  end_date?: string | null;
  favorability: FavorabilityEnum;
  goal_operation: GoalOperationEnum;
  goal_operation_value_low?: string | null;
  goal_operation_value_middle?: string | null;
  goal_operation_value_high?: string | null;
  target_value_low?: string | null;
  target_value_middle?: string | null;
  target_value_high?: string | null;
  initial_value?: string | null;
  original_initial_value?: string | null;
  revision_of?: string | null;
  goal_type: string;
};
export type ListingEventCount = {
  count_low: number;
  count_medium: number;
  count_high: number;
  count_total: number;
  partner: string;
  date: string;
};
export type PartnerOnlyRequest = {
  partner: string;
};
export type ListingEventTypeEnum = "CATALOG" | "LISTING" | "PARTNER" | "GLOBAL";
export type SeverityEnum = 1 | 2 | 3;
export type ListingEvent = {
  id: string;
  partner_sku: string;
  sku: string;
  field: string;
  type: ListingEventTypeEnum;
  description?: string;
  severity: SeverityEnum;
  old_value?: string;
  new_value?: string;
  date: string;
  listing?: string | null;
  partner?: string | null;
};
export type ListingList = {
  id: string;
  partner: string;
  sku: string;
  partner_sku?: string;
  partner_brand?: string;
  asin: string;
  tier?: number | null;
  tags: string[];
  amz_account: string;
  meta: string;
  product_group?: string | null;
  intended_parent_asin?: string;
  last_pulled: string;
  is_fba?: boolean | null;
  unit_cost?: string | null;
  main_image: string;
  short_description?: string;
};
export type ListingDetail = {
  id: string;
  partner: string;
  sku: string;
  partner_sku?: string;
  partner_brand?: string;
  asin: string;
  tier?: number | null;
  tags: string[];
  amz_account: string;
  meta: string;
  product_group?: string;
  intended_parent_asin?: string;
  last_pulled: string;
  is_fba?: boolean | null;
  unit_cost?: string | null;
  main_image: string;
  short_description?: string;
};
export type ListingDetailRequest = {
  partner: string;
  sku: string;
  partner_sku?: string;
  partner_brand?: string;
  asin: string;
  tier?: number | null;
  amz_account: string;
  meta: string;
  product_group?: string;
  intended_parent_asin?: string;
  is_fba?: boolean | null;
  unit_cost?: string | null;
  short_description?: string;
};
export type PatchedListingDetailRequest = {
  partner?: string;
  sku?: string;
  partner_sku?: string;
  partner_brand?: string;
  asin?: string;
  tier?: number | null;
  amz_account?: string;
  meta?: string;
  product_group?: string;
  intended_parent_asin?: string;
  is_fba?: boolean | null;
  unit_cost?: string | null;
  short_description?: string;
};
export type ProductListingAmzMeta = {
  id: string;
  listing_type?: string;
  offer_price?: string | null;
  item_type_keyword?: string;
  item_type_keyword_listing?: string;
  asin: string;
  brand?: string;
  brand_listing?: string;
  image_main?: string;
  image_main_listing?: string;
  image_pt01?: string;
  image_pt01_listing?: string;
  image_pt02?: string;
  image_pt02_listing?: string;
  image_pt03?: string;
  image_pt03_listing?: string;
  image_pt04?: string;
  image_pt04_listing?: string;
  image_pt05?: string;
  image_pt05_listing?: string;
  image_pt06?: string;
  image_pt06_listing?: string;
  image_pt07?: string;
  image_pt07_listing?: string;
  image_pt08?: string;
  image_pt08_listing?: string;
  parent_asin?: string;
  product_type?: string;
  product_type_listing?: string;
  upc?: string;
  upc_listing?: string;
  title?: string;
  title_listing?: string;
  bullet1?: string;
  bullet1_listing?: string;
  bullet2?: string;
  bullet2_listing?: string;
  bullet3?: string;
  bullet3_listing?: string;
  bullet4?: string;
  bullet4_listing?: string;
  bullet5?: string;
  bullet5_listing?: string;
  description?: string;
  description_listing?: string;
  generic_keywords?: string;
  last_pulled: string;
};
export type ProductListingAmzMetaRequest = {
  listing_type?: string;
  offer_price?: string | null;
  item_type_keyword?: string;
  item_type_keyword_listing?: string;
  asin: string;
  brand?: string;
  brand_listing?: string;
  image_main?: string;
  image_main_listing?: string;
  image_pt01?: string;
  image_pt01_listing?: string;
  image_pt02?: string;
  image_pt02_listing?: string;
  image_pt03?: string;
  image_pt03_listing?: string;
  image_pt04?: string;
  image_pt04_listing?: string;
  image_pt05?: string;
  image_pt05_listing?: string;
  image_pt06?: string;
  image_pt06_listing?: string;
  image_pt07?: string;
  image_pt07_listing?: string;
  image_pt08?: string;
  image_pt08_listing?: string;
  parent_asin?: string;
  product_type?: string;
  product_type_listing?: string;
  upc?: string;
  upc_listing?: string;
  title?: string;
  title_listing?: string;
  bullet1?: string;
  bullet1_listing?: string;
  bullet2?: string;
  bullet2_listing?: string;
  bullet3?: string;
  bullet3_listing?: string;
  bullet4?: string;
  bullet4_listing?: string;
  bullet5?: string;
  bullet5_listing?: string;
  description?: string;
  description_listing?: string;
  generic_keywords?: string;
};
export type PatchedProductListingAmzMetaRequest = {
  listing_type?: string;
  offer_price?: string | null;
  item_type_keyword?: string;
  item_type_keyword_listing?: string;
  asin?: string;
  brand?: string;
  brand_listing?: string;
  image_main?: string;
  image_main_listing?: string;
  image_pt01?: string;
  image_pt01_listing?: string;
  image_pt02?: string;
  image_pt02_listing?: string;
  image_pt03?: string;
  image_pt03_listing?: string;
  image_pt04?: string;
  image_pt04_listing?: string;
  image_pt05?: string;
  image_pt05_listing?: string;
  image_pt06?: string;
  image_pt06_listing?: string;
  image_pt07?: string;
  image_pt07_listing?: string;
  image_pt08?: string;
  image_pt08_listing?: string;
  parent_asin?: string;
  product_type?: string;
  product_type_listing?: string;
  upc?: string;
  upc_listing?: string;
  title?: string;
  title_listing?: string;
  bullet1?: string;
  bullet1_listing?: string;
  bullet2?: string;
  bullet2_listing?: string;
  bullet3?: string;
  bullet3_listing?: string;
  bullet4?: string;
  bullet4_listing?: string;
  bullet5?: string;
  bullet5_listing?: string;
  description?: string;
  description_listing?: string;
  generic_keywords?: string;
};
export type ProductGroup = {
  id: string;
  partner: string;
  name: string;
  parent_group?: string | null;
};
export type ProductGroupRequest = {
  partner: string;
  name: string;
  parent_group?: string | null;
};
export type PatchedProductGroupRequest = {
  partner?: string;
  name?: string;
  parent_group?: string | null;
};
export type ProductProductTag = {
  id: string;
  tag: string;
  listing: string;
};
export type ProductProductTagRequest = {
  tag: string;
  listing: string;
};
export type ProductProductTagBulkRequest = {
  partner: string;
  listing: string;
  product_tags: string[];
};
export type ProductTag = {
  id: string;
  name: string;
  partner: string;
};
export type ProductTagRequest = {
  name: string;
  partner: string;
};
export type CatalogItemsRequestRequest = {
  asin: string;
};
export type SpApiTokenExchangeRequest = {
  sp_lwa_auth_token: string;
  amz_account: string;
  marketplace: string;
  sp_id: string;
};
export type Granularity = {
  granularityType: string | null;
  granularityId: string | null;
};
export type ReservedQuantity = {
  totalReservedQuantity: number | null;
  pendingCustomerOrderQuantity: number | null;
  pendingTransshipmentQuantity: number | null;
  fcProcessingQuantity: number | null;
};
export type ResearchingQuantityEntryNameEnum =
  | "researchingQuantityInShortTerm"
  | "researchingQuantityInMidTerm"
  | "researchingQuantityInLongTerm";
export type ResearchingQuantityEntry = {
  name: ResearchingQuantityEntryNameEnum;
  quantity: number;
};
export type ResearchingQuantity = {
  totalResearchingQuantity: number | null;
  researchingQuantityBreakdown: ResearchingQuantityEntry[] | null;
};
export type UnfulfillableQuantity = {
  totalUnfulfillableQuantity: number | null;
  customerDamagedQuantity: number | null;
  warehouseDamagedQuantity: number | null;
  distributorDamagedQuantity: number | null;
  carrierDamagedQuantity: number | null;
  defectiveQuantity: number | null;
  expiredQuantity: number | null;
};
export type InventoryDetails = {
  fulfillableQuantity: number | null;
  inboundWorkingQuantity: number | null;
  inboundShippedQuantity: number | null;
  inboundReceivingQuantity: number | null;
  reservedQuantity: ReservedQuantity | null;
  researchingQuantity: ResearchingQuantity | null;
  unfulfillableQuantity: UnfulfillableQuantity | null;
};
export type InventorySummary = {
  asin: string | null;
  fnSku: string | null;
  sellerSku: string | null;
  condition: string | null;
  inventoryDetails: InventoryDetails | null;
  lastUpdatedTime: string | null;
  productName: string | null;
  totalQuantity: number | null;
};
export type GetInventorySummariesResult = {
  granularity: Granularity;
  inventorySummaries: InventorySummary[];
};
export type FbaInventoryCallSignatureRequest = {
  details: boolean | null;
  sellerSkus: string[] | null;
  startDateTime: string | null;
};
export type FbaInventorySummaryRequestRequest = {
  partner?: string;
  amz_account?: string;
  params: FbaInventoryCallSignatureRequest;
};
export type ListingsItemRequestRequest = {
  listing: string;
};
export type DateGranularityEnum = "DAY" | "WEEK" | "MONTH";
export type NullEnum = null;
export type AsinGranularityEnum = "PARENT" | "CHILD" | "SKU";
export type SalesAndTrafficReportOptions = {
  dateGranularity?: (DateGranularityEnum | NullEnum) | null;
  asinGranularity?: (AsinGranularityEnum | NullEnum) | null;
};
export type SalesAndTrafficReportCallSignature = {
  reportType: string | null;
  marketplaceIds?: string[] | null;
  reportOptions?: SalesAndTrafficReportOptions | null;
  dataStartTime?: string | null;
  dataEndTime?: string | null;
};
export type AmountCurrency = {
  amount: string;
  currencyCode: string;
};
export type SalesByDate = {
  orderedProductSales: AmountCurrency;
  orderedProductSalesB2B: AmountCurrency;
  unitsOrdered: number;
  unitsOrderedB2B: number;
  totalOrderItems: number;
  totalOrderItemsB2B: number;
  averageSalesPerOrderItem: AmountCurrency;
  averageSalesPerOrderItemB2B: AmountCurrency;
  averageUnitsPerOrderItem: string;
  averageUnitsPerOrderItemB2B: string;
  averageSellingPrice: AmountCurrency;
  averageSellingPriceB2B: AmountCurrency;
  unitsRefunded: number;
  refundRate: string;
  claimsGranted: number;
  claimsAmount: AmountCurrency;
  shippedProductSales: AmountCurrency;
  unitsShipped: number;
  ordersShipped: number;
};
export type TrafficByDate = {
  browserPageViews: number;
  browserPageViewsB2B: number;
  mobileAppPageViews: number;
  mobileAppPageViewsB2B: number;
  pageViews: number;
  pageViewsB2B: number;
  browserSessions: number;
  browserSessionsB2B: number;
  mobileAppSessions: number;
  mobileAppSessionsB2B: number;
  sessions: number;
  sessionsB2B: number;
  buyBoxPercentage: string;
  buyBoxPercentageB2B: string;
  orderItemSessionPercentage: string;
  orderItemSessionPercentageB2B: string;
  unitSessionPercentage: string;
  unitSessionPercentageB2B: string;
  averageOfferCount: number;
  averageParentItems: number;
  feedbackReceived: number;
  negativeFeedbackReceived: number;
  receivedNegativeFeedbackRate: string;
};
export type SalesAndTrafficByDate = {
  date: string;
  salesByDate: SalesByDate;
  trafficByDate: TrafficByDate;
};
export type SalesByAsin = {
  unitsOrdered: number;
  unitsOrderedB2B: number;
  orderedProductSales: AmountCurrency;
  orderedProductSalesB2B: AmountCurrency;
  totalOrderItems: number;
  totalOrderItemsB2B: number;
};
export type TrafficByAsin = {
  browserSessions: number;
  browserSessionsB2B: number;
  mobileAppSessions: number;
  mobileAppSessionsB2B: number;
  sessions: number;
  sessionsB2B: number;
  browserSessionPercentage: string;
  browserSessionPercentageB2B: string;
  mobileAppSessionPercentage: string;
  mobileAppSessionPercentageB2B: string;
  sessionPercentage: string;
  sessionPercentageB2B: string;
  browserPageViews: number;
  browserPageViewsB2B: number;
  mobileAppPageViews: number;
  mobileAppPageViewsB2B: number;
  pageViews: number;
  pageViewsB2B: number;
  browserPageViewsPercentage: string;
  browserPageViewsPercentageB2B: string;
  mobileAppPageViewsPercentage: string;
  mobileAppPageViewsPercentageB2B: string;
  pageViewsPercentage: string;
  pageViewsPercentageB2B: string;
  buyBoxPercentage: string;
  buyBoxPercentageB2B: string;
  unitSessionPercentage: string;
  unitSessionPercentageB2B: string;
};
export type SalesAndTrafficByAsin = {
  parentAsin: string;
  childAsin: string | null;
  sku: string | null;
  salesByAsin: SalesByAsin;
  trafficByAsin: TrafficByAsin;
};
export type SalesAndTrafficReportResult = {
  reportSpecification: SalesAndTrafficReportCallSignature;
  salesAndTrafficByDate: SalesAndTrafficByDate[];
  salesAndTrafficByAsin: SalesAndTrafficByAsin[];
};
export type SalesAndTrafficReportResponse = {
  status: string;
  report: SalesAndTrafficReportResult;
  message: string;
};
export type SalesAndTrafficReportOptionsRequest = {
  dateGranularity?: (DateGranularityEnum | NullEnum) | null;
  asinGranularity?: (AsinGranularityEnum | NullEnum) | null;
};
export type SalesAndTrafficReportCallSignatureRequest = {
  marketplaceIds?: string[] | null;
  reportOptions?: SalesAndTrafficReportOptionsRequest | null;
  dataStartTime?: string | null;
  dataEndTime?: string | null;
};
export type SalesAndTrafficReportRequestRequest = {
  partner?: string;
  amz_account?: string;
  params: SalesAndTrafficReportCallSignatureRequest;
};
export type TokenObtainPair = {
  access: string;
  refresh: string;
};
export type TokenObtainPairRequest = {
  email: string;
  password: string;
};
export type TokenRefresh = {
  access: string;
  refresh: string;
};
export type TokenRefreshRequest = {
  refresh: string;
};
export type ConversationsList = {
  name: string;
  id: string;
};
export const {
  useApiAlertsAlertsListQuery,
  useApiAlertsAlertsCreateMutation,
  useApiAlertsAlertsRetrieveQuery,
  useApiAlertsAlertsUpdateMutation,
  useApiAlertsAlertsPartialUpdateMutation,
  useApiAlertsAlertsDestroyMutation,
  useApiAlertsUpdateAlertPartialUpdateMutation,
  useApiBqDashboardDataListQuery,
  useApiBqIwAccountsListQuery,
  useApiCoreAllPartnerAccountsListQuery,
  useApiCoreAmzAccountsListQuery,
  useApiCoreAmzAccountsCreateMutation,
  useApiCoreAmzAccountsRetrieveQuery,
  useApiCoreAmzAccountsUpdateMutation,
  useApiCoreAmzAccountsPartialUpdateMutation,
  useApiCoreAmzAdAccountsListQuery,
  useApiCoreAmzAdAccountsCreateMutation,
  useApiCoreAmzAdAccountsUpdateMutation,
  useApiCoreAmzAdAccountsPartialUpdateMutation,
  useApiCoreAsyncTasksListQuery,
  useApiCoreAsyncTasksRetrieveQuery,
  useApiCoreCurrentUserRetrieveQuery,
  useApiCorePartnerAmzAccountsCreateMutation,
  useApiCorePartnerAmzAdAccountsCreateMutation,
  useApiCorePartnerIntakeCreateMutation,
  useApiCorePartnerIntakeRetrieveQuery,
  useApiCorePartnerIntakeUpdateMutation,
  useApiCorePartnerIntakePartialUpdateMutation,
  useApiCorePartnerIntakeGetPartnerIntakeUnprocessedListQuery,
  useApiCorePartnersListQuery,
  useApiCorePartnersCreateMutation,
  useApiCorePartnersRetrieveQuery,
  useApiCorePartnersUpdateMutation,
  useApiCorePartnersPartialUpdateMutation,
  useApiCorePartnersDestroyMutation,
  useApiCorePartnersGetPartnersNotOnboardedListQuery,
  useApiCoreRolesListQuery,
  useApiCoreUserRolesCreateMutation,
  useApiCoreUserRolesUpdateMutation,
  useApiCoreUserRolesPartialUpdateMutation,
  useApiCoreUsersListQuery,
  useApiCoreUsersCreateMutation,
  useApiCoreUsersRetrieveQuery,
  useApiCoreUsersUpdateMutation,
  useApiCoreUsersPartialUpdateMutation,
  useApiCoreUsersDestroyMutation,
  useApiGoalsCreateGoalCreateMutation,
  useApiGoalsCurrentGoalsListQuery,
  useApiGoalsGoalOpsListQuery,
  useApiGoalsGoalTypesListQuery,
  useApiGoalsOngoingGoalsListQuery,
  useApiGoalsResolveAllGoalsCreateMutation,
  useApiGoalsResolveGoalCreateMutation,
  useApiGoalsTimelineGoalsListQuery,
  useApiPdmGetBrandsRetrieveQuery,
  useApiPdmGetListingEventCountListQuery,
  useApiPdmGetListingEventRangeCountListQuery,
  useApiPdmGetListingEventXlsRetrieveQuery,
  useApiPdmGetListingsForPartnerCreateMutation,
  useApiPdmGetWholesalerDetailsCreateMutation,
  useApiPdmGetWholesalerOffersForPartnerCreateMutation,
  useApiPdmListingEventsListQuery,
  useApiPdmListingEventsRetrieveQuery,
  useApiPdmListingsListQuery,
  useApiPdmListingsCreateMutation,
  useApiPdmListingsRetrieveQuery,
  useApiPdmListingsUpdateMutation,
  useApiPdmListingsPartialUpdateMutation,
  useApiPdmListingsDestroyMutation,
  useApiPdmMetaListQuery,
  useApiPdmMetaCreateMutation,
  useApiPdmMetaRetrieveQuery,
  useApiPdmMetaUpdateMutation,
  useApiPdmMetaPartialUpdateMutation,
  useApiPdmMetaDestroyMutation,
  useApiPdmProductGroupListQuery,
  useApiPdmProductGroupCreateMutation,
  useApiPdmProductGroupRetrieveQuery,
  useApiPdmProductGroupUpdateMutation,
  useApiPdmProductGroupPartialUpdateMutation,
  useApiPdmProductGroupDestroyMutation,
  useApiPdmProductProductTagsCreateMutation,
  useApiPdmProductProductTagsDestroyMutation,
  useApiPdmProductProductTagsBulkUpdateUpdateMutation,
  useApiPdmProductTagsListQuery,
  useApiPdmProductTagsCreateMutation,
  useApiPdmTempSendSlackXlsToSlackCreateMutation,
  useApiSpApiCatalogItemsCreateMutation,
  useApiSpApiCreateNotificationSubscriptionsCreateMutation,
  useApiSpApiExchangeSpApiCredentialsCreateMutation,
  useApiSpApiFbaInventoryCreateMutation,
  useApiSpApiListingsItemCreateMutation,
  useApiSpApiSalesAndTrafficReportCreateMutation,
  useAuthTokenCreateMutation,
  useAuthTokenRefreshCreateMutation,
  useSlackConversationsListQuery,
} = injectedRtkApi;
