import { TableCell, Tooltip } from "@mui/material";
import { ReactNode } from "react";
import { SxProps } from "@mui/system";

export interface IBaseCellProps {
    content: ReactNode
    toolTip?: string
    sx?: SxProps
}
export const BaseCell = (props: IBaseCellProps) => {
    // TODO Add base styling
    // TODO Add border/divider prop
    // TODO Add money or percent formatting props

    let cell
    if (props.toolTip) {
        cell = (
            <Tooltip title={props.toolTip}>
                <TableCell sx={props.sx}>
                    {props.content}
                </TableCell>
            </Tooltip>
        )
    } else {
        cell = <TableCell sx={props.sx}>{props.content}</TableCell>
    }

    return (
        cell
    )
}