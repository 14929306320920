import { Card, CardContent, Typography, Stack } from "@mui/material";
import { DeltaCardBadge } from "../badges/DeltaCardBadge";

export type DeltaType = 'severeDecrease' | 'moderateDecrease' | 'moderateIncrease' | 'severeIncrease' | 'even';

interface IBaseMinimalScoreCardProps {
    title: string
    value: string
}

// Possibly allow a badge with no delta
// interface IMinimalScoreCardPropsBadgeOnly extends IBaseMinimalScoreCardProps {
//     badgeContent: string
// }

interface IMinimalScoreCardPropsWithDelta extends IBaseMinimalScoreCardProps {
    comparison: string
    comparisonPercent: string
    deltaType: DeltaType
    downGood?: boolean
}

export type IMinimalScoreCardProps = IBaseMinimalScoreCardProps | IMinimalScoreCardPropsWithDelta

const isPropsWithDelta = (props: IMinimalScoreCardProps): props is IMinimalScoreCardPropsWithDelta => {
    return 'comparison' in props
}

const styles = {
    card: {
        maxWidth: 250
    },
    title: {
        fontSize: 14,
        textAlign: 'left'
    },
    firstStack: {
        gap: 2
    },
    secondStack: {
        gap: 1
    }
}

export const MinimalScoreCard = (props: IMinimalScoreCardProps) => {
    // TODO MaxWidth or use in a grid?

    // TODO Rename to ScoreCard

    let deltaBadge
    let deltaText
    if (isPropsWithDelta(props)) {
       deltaBadge = (
           <DeltaCardBadge
               deltaType={props.deltaType}
               downGood={props.downGood}
           >
               {props.comparisonPercent}
           </DeltaCardBadge>
       )
        deltaText = (
            <Typography variant='caption' component='body'>{`from ${props.comparison}`}</Typography>
        )
    }

    return (
        <Card sx={styles.card}>
            <CardContent >
                <Stack direction='row' justifyContent='space-between' gap={styles.firstStack.gap}>
                    <Typography
                        variant='subtitle2'
                        component='h2'
                        sx={styles.title}
                    >
                        {props.title}
                    </Typography>
                    {deltaBadge}
                </Stack>
                <Stack direction='row' alignItems='flex-end' gap={styles.firstStack.gap}>
                    <Typography variant='h5' component='body'>{props.value}</Typography>
                    {deltaText}
                </Stack>
            </CardContent>
        </Card>
    )
}