import { BaseApiForm } from "../../common/forms/BaseForm/BaseAPIForm";
import { MakeTextFormField } from "../../common/forms/BaseForm/fields/TextFormField";
import { MakeChipSelectFormField } from "../../common/forms/BaseForm/fields/ChipSelectFormField";
import { useApiCorePartnersListQuery, useApiCoreRolesListQuery, useApiCoreUsersCreateMutation, UserCreateRequest, Role
} from "../../../services/generatedApi";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../common/cards/BodyCard";
import { navLinks } from "./navLinks";
import { partnerChoiceToDisplayName, partnerNameFromId, roleChoiceToDisplayName,
    roleNameFromId, sortPartnersByName, sortRolesByDepartment} from "../../../util/FormUtil";
import { AppURLS } from "../../../appURLS";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../services/auth/authSlice";

const filterEmployeeCreateRoles = (roles: Role[]): Role[] => (
    roles.filter((role) => !(
        ['MANAGEMENT', 'PARTNER'].includes(role.department.name) ||
            ['DIRECTOR', 'MANAGER'].includes(role.name)
    ) )
)

const filterEmployeeCreateRolesSuperUser = (roles: Role[]): Role[] => (
    roles.filter((role) => !['PARTNER'].includes(role.department.name))
)

interface IFormInput extends UserCreateRequest {}

export const CreateUser = () => {
    const initialValues: IFormInput = {
        first_name: '',
        last_name: '',
        user_roles: [],
        user_partners: [],
        email: '',
        password: '',
        slack_id: ''
    }
    const currentUser = useSelector(selectCurrentUser)
    const makeTextFormField = MakeTextFormField
    const makeChipSelectFormField = MakeChipSelectFormField
    const [ createUser ] = useApiCoreUsersCreateMutation()
    const rolesRes = useApiCoreRolesListQuery()
    // Update below to true when deactivating Partners removes user_partners record
    const partnersRes = useApiCorePartnersListQuery({isActive: undefined})
    const { control, ...useFormRest } = useForm<IFormInput>({defaultValues: initialValues})
    const formStateHooks = useState<IFormInput>(initialValues)

    const createUserAction = (formInput: IFormInput) => (
        createUser(
            {userCreateRequest: {...formInput}}
        )
    )

    const fields = [
        makeTextFormField(control, 'first_name', 'First Name', true),
        makeTextFormField(control, 'last_name', 'Last Name', true),
        makeTextFormField(control, 'email', 'Email', true),
        makeTextFormField(control, 'password', 'Password', true),
        makeTextFormField(control, 'slack_id', 'Slack Id'),
        makeChipSelectFormField(
            control,
            'user_roles',
            'Roles',
            rolesRes,
            roleNameFromId,
            roleChoiceToDisplayName,
            true,
            undefined,
            currentUser?.is_superuser ? filterEmployeeCreateRolesSuperUser : filterEmployeeCreateRoles,
            sortRolesByDepartment
        ),
        makeChipSelectFormField(
            control,
            'user_partners',
            'Partners',
            partnersRes,
            partnerNameFromId,
            partnerChoiceToDisplayName,
            true,
            undefined,
            undefined,
            sortPartnersByName
        )
    ]

    const form = <BaseApiForm
        recordName='User'
        formActionType='Create'
        fields={fields}
        useFormRest={useFormRest}
        formStateHooks={formStateHooks}
        // Required attributes likely can't be known at checking - revisit
        // @ts-ignore
        formAPIAction={createUserAction}
        formSuccessRedirectPathParam={AppURLS.detailEmployee}
    />

    const cardBody = <BodyCard header={{title: 'Create New Employee'}} body={form} />

    return (
        <HeaderSideBarBody body={cardBody} pageTitle='Create Employee' navLinks={navLinks} />
    )
}