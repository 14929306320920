import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { Tabs, Tab, Box, Typography, Divider, Button } from "@mui/material";
import { ReactNode, useState, ChangeEvent } from "react";
import { BodyCard } from "../../common/cards/BodyCard";
import { useParams } from "react-router-dom";
import { useApiPdmListingsRetrieveQuery, ProductListingAmzMeta, useApiPdmMetaRetrieveQuery } from "../../../services/generatedApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { navLinks } from "./navLinks";
import { ListingViewForm } from "./forms/ListingViewForm";
import { ListingMetaViewForm } from "./forms/ListingMetaViewForm";
import { Image } from "mui-image";


interface TabPanelProps {
    children?: ReactNode;
    index: any;
    value: any;
}

const styles = {
    imageBox: {
        minHeight: 250,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    images: {
        maxHeight: 250,
        minHeight: '100%',
        width: 'auto',
        display: 'block',
        margin: 'auto'
    },
    scoreCards: {
        maxWidth: '50%',
        margin: 'auto',
        mb: 2
        // float: 'left'
    }
}


export const ProductDetail = () => {
    const listingId = useParams<{id: string}>();
    const listingRes = useApiPdmListingsRetrieveQuery(listingId.id ? {id:listingId.id} : skipToken);
    const metaRes = useApiPdmMetaRetrieveQuery(listingRes.data ? {id: listingRes.data.meta} : skipToken)
    const [ value, setValue ] = useState(0);

    let meta: ProductListingAmzMeta | undefined = undefined
    if (metaRes.isSuccess) {
        meta = metaRes.data
    }


    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography component={'div'}>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: any) {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    let tabs;
    let tabPanels;
    let imageBlock

    if (listingRes.isSuccess) {
        const listing = listingRes.data

        imageBlock = (
                <Box>
                    <Typography>Amz Main Image</Typography>
                    <Box sx={styles.imageBox}>
                        <Image src={listing.main_image ?? ''} style={styles.images}/>
                    </Box>
                </Box>
        )

        let productTabs = [
            // TODO Just make an object with the data
            {name: 'listing', data: listing},
            {name: 'Amazon Meta', data: listing}
        ]

        tabs = productTabs.map((tab, index) =>
            <Tab label={tab.name} key={tab.name} {...a11yProps(index)}/>
        )

        tabPanels = productTabs.map((tab, index) => {
            // TODO Skip mapping and just generate tabs unless this becomes far more
            //  dynamic and re-usable go generate specific component based off schema
            if (tab.name === 'listing') {
                return (
                    <TabPanel index={index} value={value} key='product'>
                        <ListingViewForm listing={listing} listingRefetch={listingRes.refetch}/>
                    </TabPanel>
                )
            } else if (tab.name === 'Amazon Meta') {
                return (
                    <TabPanel index={index} value={value} key='amzMeta'>
                        {meta &&
                            <ListingMetaViewForm
                                meta={meta}
                                listingId={listing.id}
                                listingSku={listing.sku}
                                intendedParentAsin={listing.intended_parent_asin ?? undefined}
                            />
                        }
                    </TabPanel>
                )
            } else {
                // TODO this will never return, product tabs are hard coded
                return (
                    <TabPanel index={index} value={value} key={tab.name}>
                        <div>{JSON.stringify(tab.data)}</div>
                    </TabPanel>
                )
            }
        })
    }

    let scoreCards = <></>

    // TODO Remake and set up correctly with single listing (Possibly all listings for same ASIN)
    // if (listings) {
    //     const asins = listings.map((listing) => listing.asin)
    //     scoreCards = (
    //         // TODO Move into separate component
    //         <Box sx={styles.scoreCards} >
    //             <Grid container justifyContent={'center'} spacing={2}>
    //                 <Grid item xs={6}>
    //                     {/*TODO Change over to new style like the others*/}
    //                     <MinimalScSalesCompScoreCard asins={asins} />
    //                 </Grid>
    //                 <Grid item xs={6}>
    //                     <ProductSummaryAggAdSpendScoreCard asins={asins} />
    //                 </Grid>
    //                 <Grid item xs={6}>
    //                     <ProductSummaryAggAdRevenueScoreCard asins={asins} />
    //                 </Grid>
    //                 <Grid item xs={6}>
    //                     <ProductSummaryAggBuyBoxScoreCard asins={asins} />
    //                 </Grid>
    //             </Grid>
    //         </Box>
    //     )
    // }


    const cardBody = (
        !tabs ? <Typography variant='h4'> Product not found </Typography> :
            <>
                {scoreCards}
                {imageBlock}
                <Divider />
                <Tabs value={value}
                      onChange={handleChange}
                      aria-label="product detail tabs"
                      centered>
                    {tabs}
                </Tabs>
                {tabPanels}
            </>
    )

    let body
    if (listingRes.isSuccess) {
        const amzButton = (
                <Button
                    variant='contained'
                    onClick={() => window.open(`https://www.amazon.com/dp/${listingRes.data.asin}`)}
                >View On Amazon
                </Button>
        )

        body = (
            <BodyCard
                header={
                    {title: listingRes.data.partner_sku ?? listingRes.data.sku + " Detail", action: amzButton}
                }
                body={cardBody}
            />
        )
    } else {
        body = (
            <BodyCard header={{title: " Detail"}} body={cardBody} />
        )
    }

    return(
        <HeaderSideBarBody body={body} pageTitle={"Product Detail"} navLinks={navLinks}/>
    )
}