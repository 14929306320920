import { useRef, useEffect } from "react";

export const useInterval= (callback: Function, delay: number | undefined) => {
    //https://overreacted.io/making-setinterval-declarative-with-react-hooks/
    // Make sure setting callback twice to the first value isn't a problem
    const savedCallback = useRef<Function>(callback);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = () => savedCallback.current();
        if (typeof delay === 'number') {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}