import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { PartnerBaseLayout } from "../../layout/partnerLayout/PartnerBaseLayout";
import { BodyCard } from "../../common/cards/BodyCard";
// import { useLocation, useParams } from "react-router-dom";
import { useParams } from "react-router-dom";
// import { PartnerDetailRead, useApiCorePartnersRetrieveQuery, useApiCoreAmzAccountsRetrieveQuery,
//     AmzAccount } from "../../../services/generatedApi";
import { useApiCoreAmzAccountsRetrieveQuery, AmzAccount } from "../../../services/generatedApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { Stack, Button, Typography } from "@mui/material";


const styles = {
    authButton: {
        maxWidth: 500,
        margin: 'auto',
        mt: 5,
        mb: 2
    }
}

export const SPAPIAuthPage = () => {
    // Think through access control, this page should only be accessed once and only with valid Partner in link state
    // TODO Don't allow or if they are a partner user type but not the requested partner

    // const location = useLocation();
    // const reAuth = new URLSearchParams(location.search).get('repeat_auth')
    // TODO changed this to be account ID - Verify operation later
    // const partnerId = useParams<{ id: PartnerDetailRead['id'] }>();
    // const partnerRes = useApiCorePartnersRetrieveQuery(partnerId.id ? {id: partnerId.id} : skipToken)
    const accountId = useParams<{id: AmzAccount['id']}>()
    // Need to look up type here in case it is Ad account
    const amzAccountRes = useApiCoreAmzAccountsRetrieveQuery(accountId.id ? {id:accountId.id} : skipToken)

    // TODO Changed to Account
    // let partner
    // if (partnerRes.isSuccess) {
    //     partner = partnerRes.data
    // }
    let amzAccount: AmzAccount | undefined = undefined
    if (amzAccountRes.isSuccess) {
        amzAccount = amzAccountRes.data
    }

    // TODO nicer error handling, make a component
    let body = <Typography variant={'h4'}>Account Not Found</Typography>

    const vcUri = 'https://vendorcentral.amazon.com'
    const scUri = 'https://sellercentral.amazon.com'

    const stagingRedirect = 'https://staging.ambrosia.thinknectar.com/sp_api_auth/return'
    const clickHandler = () => {
        // Need to handle advertising, SC, and VC differently
        let uriSlug: string = ''
        if (amzAccount?.is_sc) {
            uriSlug = scUri
        } else if (amzAccount?.is_vc) {
            uriSlug = vcUri
        }
        const url = `${uriSlug}/apps/authorize/consent?application_id=` +
            `${process.env.REACT_APP_SP_APP_ID}&state=${accountId.id}&redirect_uri=${stagingRedirect}`
        window.location.replace(url)
    }

    const button = (
        <Button variant='contained' sx={styles.authButton} onClick={clickHandler}>
            {/*Handle Ad accounts*/}
            Click Here To Authorize With {amzAccount?.is_vc ? "Vendor" : 'Seller'} Central
        </Button>
    )

    // let buttonEl = <Typography variant={'h5'}>You are already authorized.</Typography>

    // Rework this to handle interplay between partners and accounts
    // if (partner) {
    //     if (!partner.active_sp_api_credentials || reAuth) {
    //         buttonEl = button
    //     }
    //     body = (
    //         <Stack>
    //             <Typography variant={'h5'}>Hello {partner.name}!</Typography>
    //             {buttonEl}
    //         </Stack>
    //     )
    // }
    if (amzAccount) {
        // if (!partner.active_sp_api_credentials || reAuth) {
        //     buttonEl = button
        // }
        body = (
            <Stack>
                <Typography variant={'h5'}>Authorizing {amzAccount.name}</Typography>
                <Typography variant={'h6'}>Please make sure you are signed into the correct account!</Typography>
                {button}
            </Stack>
        )
    }

    const bodyCard = <BodyCard body={body} header={{title: 'SP-API Authorization'}}/>

    return (
        // <HeaderSideBarBody body={bodyCard} pageTitle='SP-API Authorization' />
        <PartnerBaseLayout body={bodyCard} />
    )
}