import { green, red, yellow, blue, grey } from "@mui/material/colors";
import { Theme } from "@mui/material";

type MuiColorTypeBase = {
    [K in keyof typeof green]: string;
};

type MuiColorOptional = {
    A100?: string
    A200?: string
    A400?: string
    A700?: string
}

type MuiColorType = MuiColorTypeBase & MuiColorOptional

const makeShades = (color: MuiColorType) => {
    return {
        canvasBackground: color[50],
        lightBackground: color[100],
        background: color[500],
        darkBackground: color[600],
        darkestBackground: color[800],
        lightBorder: color[200],
        border: color[500],
        darkBorder: color[700],
        lightRing: color[200],
        ring: color[300],
        lightText: color[400],
        text: color[500],
        darkText: color[700],
        darkestText: color[900],
        icon: color[500],
    }

};

export const badgePalette: Pick<Theme['palette'], 'badgeGreen' | 'badgeRed' | 'badgeYellow' | 'badgeBlue' | 'badgeGrey'
> = {
    badgeGreen: makeShades(green),
    badgeRed: makeShades(red),
    badgeYellow: makeShades(yellow),
    badgeBlue: makeShades(blue),
    badgeGrey: makeShades(grey)
}