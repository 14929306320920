import { HeaderSideBarBody } from "../../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../../common/cards/BodyCard";
import { Box, Typography, ToggleButton, ToggleButtonGroup, CircularProgress, Card, Tooltip,
    Stack } from "@mui/material";
import { DashboardScoreCardBlock } from "../scorecardBlock/DashboardScoreCardBlock";
import { AmSmLandingDashGoalView } from "./AmSmLandingDashGoalView";
import { navLinks } from "../navLinks";
import dayjs from "dayjs";
import {
    DashboardRequestRequest,
    useApiCoreAllPartnerAccountsListQuery,
    useApiBqDashboardDataListQuery,
    ApiCoreAllPartnerAccountsListApiResponse,
    ApiBqDashboardDataListApiResponse,
    useApiGoalsTimelineGoalsListQuery,
    ApiGoalsTimelineGoalsListApiResponse
} from "../../../../services/generatedApi";
import { getStartEndCompareMonth, getStartEndCompareTrailing } from "../../../../util/DateTimeUtils";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import { selectDataDates } from "../../../../services/data/dataSlice";
import { selectUserPartners } from "../../../../services/partnerConfig/partnerConfigSlice";
import { useState, MouseEvent, useEffect, ReactElement } from "react";


const styles = {
    acctBlock: {
        mb: 5,
        backgroundColor: "WhiteSmoke"
    },
    subAcctBlock: {
        // mb: 5,
        // backgroundColor: 'gainsboro',
        width: '90%',
        margin: 'auto'
    },
    toggleGroupGroup: {
        mb: 5
    }
}


export const AmSmLandingDash = () => {
    const userPartners = useSelector(selectUserPartners).filter((partner) => partner.is_active)
    const dataDates = useSelector(selectDataDates)
    const [month] = useState<Date>(
        dataDates?.latestSCSalesData ? new Date(dataDates.latestSCSalesData) : dayjs().toDate()
    )
    const [dateType, setDateType] = useState<string>('MTD')
    const [ compType, setCompType ] = useState<string>('MoM')
    const [
        query,
        setQuery
    ] = useState<DashboardRequestRequest | null>(null)
    const kpiDataRes = useApiBqDashboardDataListQuery(query ? {dashboardRequestRequest: query} : skipToken)
    const partnerAccountsRes = useApiCoreAllPartnerAccountsListQuery(
        userPartners
            ? {partnerAllAccountsRequestRequest: {partners: userPartners.map((partner) => partner.id)}}
            : skipToken)
    const [ goalTargetIds, setGoalTargetIds ] = useState<string[]>()
    const goalsRes = useApiGoalsTimelineGoalsListQuery(
        goalTargetIds ? {
            endDate: dayjs(month).endOf('month').format('YYYY-MM-DD'),
                goalTargetsObjectIdIn: goalTargetIds
        }
            : skipToken
    )
    const handleDateType = (event: MouseEvent<HTMLElement>, newDateType: string) => {
        setDateType(newDateType)
    }

    const handleCompType = (event: MouseEvent<HTMLElement>, newCompType: string) => {
        setCompType(newCompType)
    }

    const momDates = getStartEndCompareMonth(month, 'MoM', dataDates)
    const trailingDates = getStartEndCompareTrailing(7, dataDates)

    let goals: ApiGoalsTimelineGoalsListApiResponse = []
    let partnerAccounts: ApiCoreAllPartnerAccountsListApiResponse = []
    let kpiData: ApiBqDashboardDataListApiResponse = []

    useEffect(() => {
        // TODO set dates based on selected date type
        const start = dayjs(month).startOf('month')
        let end = dayjs(month).endOf('month')
        const dataEnd = dayjs(dataDates.latestSCSalesData)
        if (end > dataEnd) {
            end = dataEnd
        }
        const builtQuery: DashboardRequestRequest = {
            // TODO Use MTD/Trailing 7 for date ranges
            filter_partners: userPartners.map((partner) => partner.id),
            kpis: ['SALES_SC', 'SALES_VC', 'AD_SPEND', "AD_SALES", 'TACOS'],
            start_date: start.format('YYYY-MM-DD'),
            end_date: end.format('YYYY-MM-DD'),
            compare_start: start.subtract(1, 'month').format('YYYY-MM-DD'),
            // TODO handle this properly for full and partial months
            // Probably just a separate function for full and partial months
            // Can be an issue if previous month has more or less days
            // TODO consider adding separate compare end range for VC data
            compare_end: end.subtract(1, 'month').format('YYYY-MM-DD')
        }
        setQuery(builtQuery)
    }, [month])

    useEffect(() => {
        // Get Partner and account Ids for Goal Target Query Filtering
        // Alternatively, allow filtering on goal_owner and just use userPartner ids
        if (goalTargetIds) {
            return
        }
        const partnerAndAccountIds = partnerAccounts.flatMap((data) => {
            return (
                data.partner_id,
                    data.amz_accounts.flatMap((account) => account.id)
            )
        })
        setGoalTargetIds(partnerAndAccountIds)
    }, [partnerAccounts, setGoalTargetIds]);


    if (kpiDataRes.isSuccess) {
        kpiData = kpiDataRes.data
    }

    if (partnerAccountsRes.isSuccess) {
        partnerAccounts = partnerAccountsRes.data
    }


    if (goalsRes.isSuccess) {
        goals = goalsRes.data
    }

    const dateTypeToggle = (
        // TODO Add SC and VC date ranges for each option as tooltip over buttons
        <ToggleButtonGroup value={dateType} exclusive onChange={handleDateType} aria-label='date type'>
            <ToggleButton value='MTD' aria-label='Month To Date'>
                <Tooltip title={`Start: ${momDates.start} SC End: ${momDates.scEnd} VC End: ${momDates.vcEnd}`}>
                    <span>MTD</span>
                </Tooltip>
            </ToggleButton>
            <ToggleButton value='Trailing 7' aria-label='Trailing 7' disabled>
                <Tooltip
                    title={`SC: ${trailingDates.scStart}:${trailingDates.scEnd} 
                vs ${trailingDates.scCompareStart}:${trailingDates.scCompareEnd} \n
                VC: ${trailingDates.vcStart}:${trailingDates.vcEnd} 
                vs ${trailingDates.vcCompareStart}:${trailingDates.vcCompareEnd}
                `}>
                    <span>*Trailing 7</span>
                </Tooltip>
            </ToggleButton>
        </ToggleButtonGroup>
    )

    const compTypeToggle = (
        // TODO Display again when ready
        // TODO Add SC and VC date ranges for each option as tooltip over buttons
        <ToggleButtonGroup value={compType} exclusive onChange={handleCompType} aria-label='comparison type' >
                <ToggleButton value='MoM' aria-label='Month Over Month'>
                    MoM
                </ToggleButton>
                <ToggleButton value='YoY' aria-label='Year Over Year'>
                    *YoY
                </ToggleButton>
        </ToggleButtonGroup>
    )

    let body
    if (kpiDataRes.isLoading || partnerAccountsRes.isLoading || goalsRes.isLoading) {
        body = <CircularProgress />
    } else if (kpiData && partnerAccounts && goals) {
        const sortedKpiData = [...kpiData].sort(
            (a, b) => {
                const aTotal = a.data.find(
                    (data) => data.is_total)?.product_sales ?? 0
                const bTotal = b.data.find(
                    (data) => data.is_total)?.product_sales ?? 0
                return (Number(aTotal) - Number(bTotal)) * -1
            }
        )

        body = sortedKpiData.map((partnerKpi) => {
            const partnerId = userPartners.find(
                (partner) => partner.name === partnerKpi.name)
            const accountsObj = partnerAccounts.find(
                (account) => account.partner_id === partnerId?.id
            )
            const totalValuesIndex = partnerKpi.data.findIndex(
                (data) => data.is_total === true
            )
            const allData = partnerKpi.data.slice()
            const totalValues = allData.splice(totalValuesIndex, 1)[0]
            const totalGoals = goals.filter((goal) => {
                return goal.goal_targets.some((target) => target.object_id === partnerId?.id)
            })
            // Skip if Partner has no data because accounts are not set up
            if (allData.length === 0) {
                return (
                    <Card sx={styles.acctBlock}>
                        <Typography variant='h5'>{partnerKpi.name}</Typography>
                        <Typography variant={'body1'}>Partner Not Set Up</Typography>
                    </Card>
                )
            }
            let scoreCardBlocks: ReactElement[] = []
            const totalBlocks = (
                <>
                    <DashboardScoreCardBlock
                        acctType='SC' //TODO see if this needs changing
                        accountName='Total'
                        sales={Number(totalValues.product_sales ?? 0)}
                        // TODO Handle this - make sure comparison data is present
                        salesComp={Number(totalValues.product_sales_comp ?? 0)}
                        shippedRev={Number(totalValues.sourcing_shipped_revenue ?? 0)}
                        shippedRevComp={Number(totalValues.sourcing_shipped_revenue_comp ?? 0)}
                        adSales={Number(totalValues.ad_revenue ?? 0)}
                        adSalesComp={Number(totalValues.ad_revenue_comp ?? 0)}
                        adSpend={Number(totalValues.ad_spend ?? 0)}
                        adSpendComp={Number(totalValues.ad_spend_comp ?? 0)}
                    />
                    {/*// TODO*/}
                    <AmSmLandingDashGoalView goals={totalGoals}/>
                </>
            )
            // TODO Finish DashBoardGoalBlock component
            if (allData.length > 1) {
                allData.forEach((data) => {
                    const accountDetails = accountsObj?.amz_accounts.find(
                        (account) => account.iw_id === data.account_id
                    )
                    const accountGoals = goals.filter((goal) => {
                        return goal.goal_targets.some(
                            (target) => target.object_id === accountDetails?.id
                        )
                    })
                    scoreCardBlocks.push(
                        <DashboardScoreCardBlock
                            acctType={accountDetails?.is_sc ? 'SC' : 'VC'}
                            accountName={accountDetails?.name ?? 'Unknown'}
                            sales={Number(data.product_sales ?? 0)}
                            // TODO Handle this - make sure comparison data is present
                            salesComp={Number(data.product_sales_comp ?? 0)}
                            shippedRev={Number(data.sourcing_shipped_revenue ?? 0)}
                            shippedRevComp={Number(data.sourcing_shipped_revenue_comp ?? 0)}
                            adSales={Number(data.ad_revenue ?? 0)}
                            adSalesComp={Number(data.ad_revenue_comp ?? 0)}
                            adSpend={Number(data.ad_spend ?? 0)}
                            adSpendComp={Number(data.ad_spend_comp ?? 0)}
                        />,
                        // TODO
                        <AmSmLandingDashGoalView goals={accountGoals}/>
                    )
                })
            }
            return (
                <Card sx={styles.acctBlock}>
                    <Typography variant='h5'>{partnerKpi.name}</Typography>
                    {totalBlocks}
                    {scoreCardBlocks.length > 0
                        ? <Box sx={styles.subAcctBlock}>{scoreCardBlocks}</Box>
                        : undefined}
                </Card>
            )
        })
    }

    const toggleGroupGroup = (
        <Stack sx={styles.toggleGroupGroup}>
            {dateTypeToggle}
            {/*{compTypeToggle}*/}
        </Stack>
    )

    const cardBody = (
        <BodyCard body={body} header={{title: 'My Partners', action: toggleGroupGroup}}/>
    )
    return (
        <>
            <HeaderSideBarBody body={cardBody} pageTitle={"Home"} navLinks={navLinks}/>
        </>
    )
}