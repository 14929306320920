import { MenuItem, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { IBaseAPIFormProps } from "../BaseAPIForm";
import { ITextFormFieldProps } from "./TextFormField";

const styles = {
    selectBox: {
        textAlign: 'left'
    }
}

interface IChoiceItem {
    name: string,
    value: string
}
export interface IEnumSelectFormFieldProps extends ITextFormFieldProps {
    choicesList: IChoiceItem[]
}

export const MakeEnumSelectFormField = (
    control: Control<any>,
    fieldName: string,
    fieldLabel: string,
    choices: IEnumSelectFormFieldProps["choicesList"],
    required: boolean | undefined = undefined,
): IBaseAPIFormProps['fields'][number] => {
    const noResolve = (value: string) => value

    return {
        meta: {
            fieldName: fieldName,
            fieldLabel: fieldLabel,
            nameResolver: noResolve
        },
        field:
            <EnumSelectFormField
                key={fieldName}
                control={control}
                meta={{fieldName: fieldName, fieldLabel: fieldLabel, required: required}}
                choicesList={choices}
            />
    }
}
export const EnumSelectFormField = (props: IEnumSelectFormFieldProps) => {
    // TODO Ideally would accept an ENUM and validate on only that instead of making choicesList
    // TODO Also allow multiple

    return (
        <Controller
            name={props.meta.fieldName}
            control={props.control}
            rules={{required: props.meta.required}}
            render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                    label={props.meta.fieldLabel}
                    select
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    SelectProps={{sx:styles.selectBox}}
                >
                    {props.choicesList.map((choice) => (
                        <MenuItem key={choice.value} value={choice.value}>
                            {choice.name}
                        </MenuItem>
                    ))}
                </TextField>
            )}/>
    )
}