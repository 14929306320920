import { ProductGroup, ListingDetail, ProductTag } from "../../../../services/generatedApi";
import { useApiPdmProductGroupListQuery,
    useApiPdmProductTagsListQuery, useApiPdmListingsPartialUpdateMutation } from "../../../../services/generatedApi";
import { productGroupNameFromId, productGroupChoiceToDisplayName, productTagNameFromId
} from "../../../../util/FormUtil";
import { InfoViewEdit, IInfoViewEditProps } from "../../../common/viewEditBlock/InfoViewEdit";
import { ISingleFieldFormProps, SingleFieldForm } from "../../../common/forms/BaseForm/SingleFieldForm";
import { ProductTagForm } from "./ProductTagForm";

const TEMP_VC_SKU = 'TEMP_VC_SKU'

export interface IListingViewFormProps {
    listing: ListingDetail
    listingRefetch: () => void
}

interface IFormInput extends Partial<ListingDetail> {}

export const ListingViewForm = (props: IListingViewFormProps) => {
    const [ updateListing ] = useApiPdmListingsPartialUpdateMutation()
    const productGroupsRes = useApiPdmProductGroupListQuery({partner: props.listing.partner})
    const productTagsRes = useApiPdmProductTagsListQuery({partner: props.listing.partner})

    let productGroups: ProductGroup[] | [] = []
    if (productGroupsRes.isSuccess) {
        productGroups = productGroupsRes.data
    }

    let productTags: ProductTag[] | [] = []
    if (productTagsRes.isSuccess) {
        productTags = productTagsRes.data
    }

    const updateListingAction = ((formInput: IFormInput) => (
        updateListing({
            id: props.listing.id,
            patchedListingDetailRequest: {...formInput}
        })
    ))

    const makeForm = (field: ISingleFieldFormProps['fieldData']) => {
        return (
            <SingleFieldForm
                fieldData={field}
                recordName='Listing'
                actionType='Update'
                formAction={updateListingAction}
                refetch={props.listingRefetch}
            />
        )
    }


    const viewNodeData: IInfoViewEditProps['nodes'] = [
        {title: 'Amazon SKU',
            data: [props.listing.sku],
            editable: props.listing.sku.includes(TEMP_VC_SKU),
            form: makeForm({
                type: 'Text',
                fieldName: 'sku',
                fieldLabel: 'Amazon SKU',
                initialValue: props.listing.sku
            })
        },
        {title: 'Partner SKU',
            data: [props.listing.partner_sku],
            tooltip: "Partner's Internal SKU - May be different than SKU used on Amazon",
            editable: true,
            form: makeForm({
                type: 'Text',
                fieldName: 'partner_sku',
                fieldLabel: 'Partner SKU',
                initialValue:props.listing.partner_sku}
            )
        },
        {title: 'Is FBA',
            data: [props.listing.is_fba ? 'Yes' : 'No'],
            editable: false
        },
        {title: 'Product Group*',
            data: [
                props.listing.product_group
                    ? productGroupNameFromId(productGroups, props.listing.product_group)
                    : undefined
            ],
            editable: true,
            form: makeForm({
                type: 'ChipSelect',
                fieldName: 'product_group',
                fieldLabel: 'Product Group',
                multiple: false,
                initialValue: props.listing.product_group ?? '',
                recordsRes: productGroupsRes,
                nameFromIdFunc: productGroupNameFromId,
                choiceToDisplayFunc: productGroupChoiceToDisplayName
            })
        },
        {title: 'Product Tags',
            data:
                props.listing.tags?.length > 0
                    ? props.listing.tags.map((tag) => productTagNameFromId(productTags, tag))
                    : [undefined],
            editable: true,
            form: <ProductTagForm
                partnerId={props.listing.partner}
                listingId={props.listing.id}
                productTags={props.listing.tags}
                refetch={props.listingRefetch}
            />
        },
        {title: 'ASIN',
            data: [props.listing.asin],
            editable: false
        },
        {title: 'Partner Brand',
            data: [props.listing.partner_brand],
            tooltip: "Partner's Internal Brand - May be different than Brand used on Amazon",
            editable: true,
            form: makeForm({
                type: 'Text',
                fieldName: 'partner_brand',
                fieldLabel: 'Partner Brand',
                initialValue: props.listing.partner_brand
            })
        },
        {title: 'Product Tier',
            data: [props.listing.tier ? props.listing.tier.toString() : undefined],
            editable: true,
            form: makeForm({
                type: 'Number',
                fieldName: 'tier',
                fieldLabel: 'Product Tier',
                initialValue: props.listing.tier ?? undefined
            })
        },
        {
            title: 'Intended Parent ASIN',
            data: [props.listing.intended_parent_asin ?? undefined],
            editable: true,
            form: makeForm({
                type: 'Text',
                fieldName: 'intended_parent_asin',
                fieldLabel: 'Intended Parent ASIN'
            })
        },
        {title: 'Unit Cost',
                data: [props.listing.unit_cost ?? undefined],
                editable: true,
                // TODO Make a Decimal form field - should convert properly between text and number as required by API
                form: makeForm({
                    type: 'Text',
                    fieldName: 'unit_cost',
                    fieldLabel: 'Unit Cost',
                    initialValue:props.listing.unit_cost ?? undefined}
                )
            },
        // TODO add back in when model updated
        // {title: 'UPC',
        //     data: [props.listing.upc ? props.listing.upc : undefined],
        //     editable: true,
        // },
    ]

    return (
        <InfoViewEdit nodes={viewNodeData} />
    )
}
