import {
    AmzAdAccountCreateRequest, useApiCorePartnerAmzAdAccountsCreateMutation, PartnerAmzAdAccountRequest,
    useApiCoreAmzAdAccountsListQuery } from "../../../../services/generatedApi";
import { MakeChipSelectFormField } from "../../../common/forms/BaseForm/fields/ChipSelectFormField";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { amzAdAccountNameFromId, amzAdAccountChoiceToDisplayName } from "../../../../util/FormUtil";
import { BaseApiForm } from "../../../common/forms/BaseForm/BaseAPIForm";

export interface IAmzAdAccountLinkFormProps {
    partnerId: AmzAdAccountCreateRequest['partner_id']
    refetch: () => void
}

interface IFormInput extends Omit<PartnerAmzAdAccountRequest, 'partner'> {}
export const AmzAdAccountLinkForm = (props: IAmzAdAccountLinkFormProps) => {
    const initialValues: IFormInput = {account: ''}
    const [ createPartnerAmzAdAccount ] = useApiCorePartnerAmzAdAccountsCreateMutation()
    const makeChipSelectFormField = MakeChipSelectFormField
    const { control, ...useFormRest } = useForm<IFormInput>({defaultValues: initialValues});
    const formStateHooks = useState<IFormInput>(initialValues)
    const amzAccountsRes = useApiCoreAmzAdAccountsListQuery({})

    const createPartnerAmzAdAccountAction = (formInput: IFormInput) => (
        createPartnerAmzAdAccount({partnerAmzAdAccountRequest: {
                partner: props.partnerId, ...formInput
            }})
    )

    const fields = [
        makeChipSelectFormField(
            control,
            'account',
            'Account',
            amzAccountsRes,
            amzAdAccountNameFromId,
            amzAdAccountChoiceToDisplayName,
            false,
            true
        )
    ]


    return (
        <BaseApiForm
            recordName='Amazon Ad Account'
            formActionType='Update'
            fields={fields}
            useFormRest={useFormRest}
            formStateHooks={formStateHooks}
            // @ts-ignore
            formAPIAction={createPartnerAmzAdAccountAction}
            refetch={props.refetch}
            resetAfterSubmit={true}
        />
    )
}