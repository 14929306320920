import { ReactNode, Dispatch, SetStateAction } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

export interface IGenericDialogProps {
    title?: string
    children: string | ReactNode
    message?: string | ReactNode
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}

const styles = {
    centered: {
        margin: 'auto'
    }
}

export const GenericDialog = (props: IGenericDialogProps) => {

    return (
        <Dialog
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="generic-dialog"
        >
            <DialogTitle id="generic-dialog" style={styles.centered}>{props.title}</DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            {props.message && <Typography style={styles.centered}>{props.message}</Typography>}
            <DialogActions style={styles.centered}>
                <Button
                    variant='contained'
                    onClick={() => {
                        props.setOpen(false);
                    }}
                    color='primary'
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}