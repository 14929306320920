import { useAppDispatch } from "../../hooks";
import { setToken, setLoggedIn, setUser, handleLogOut, selectLoggedIn } from "../../services/auth/authSlice";
import {
    useAuthTokenRefreshCreateMutation, useApiCoreCurrentUserRetrieveQuery, User
} from "../../services/generatedApi";
import Cookies from "universal-cookie";
import { useEffect, useState, useMemo } from "react";
import { batch, useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import { useLocation, useNavigate } from "react-router-dom";
import { AppURLS } from "../../appURLS";

export const AuthInitializer = () => {
    const [ refresh ] = useAuthTokenRefreshCreateMutation()
    const authCookie = new Cookies().get('auth')
    const dispatch = useAppDispatch();
    const [ skip, setSkip ] = useState<typeof skipToken | undefined>(skipToken)
    const loggedIn = useSelector(selectLoggedIn)
    const navigate = useNavigate()
    const location = useLocation()

    let userData: User | {} = useMemo(() => {
        return {}
    }, [])

    const handleNoAuth = () => {
        const next = location.pathname;
        dispatch(handleLogOut)
        // TODO this shouldn't add login page as continue parameter
        navigate(AppURLS.authPage.getFullPath(undefined, {continue: next}))
    }


    useEffect(() => {
        // TODO handle infinite loop in dev. Likely due to cookie existing from alternate deployment
        //  So refresh action fails but possibly cookie doesn't clear or stays in state
        //  Might need mess with dependency array and/or useMemo for functions
        const getAccess = async (token: string) => {
            const res = await refresh({tokenRefreshRequest: {refresh: token}}).unwrap()
            if (res.access !== undefined) {
                setSkip(undefined)
                dispatch(setToken({token: res.access}))
            }
        }
        if (authCookie != null) {
            getAccess(authCookie).catch((_) => {
                    handleNoAuth()
                }
            )
        } else if (location.pathname === AppURLS.authPage.getFullPath()) {
            // Don't append login to url if page already is login
            // Make sure that SPAPI auth pages work here as well
            return
        } else {
            handleNoAuth()

        }
    }, [authCookie, refresh, dispatch, loggedIn])

    // Don't get current user until access token is set so that we can determine current user instead of 401
    // Set it when we set the token
    const user = useApiCoreCurrentUserRetrieveQuery(skip)

    const tryGetUser = () => {
        if (user.isSuccess) {
            userData = user.data
        }
        return userData
    }


    useEffect(() => {
        function isUser(userData: any): userData is User {
            return (userData as User).user_partners !== undefined
        }
        tryGetUser()

        if (isUser(userData)) {
            const definitelyAUser = userData
            batch(() => {
                dispatch(setLoggedIn({value: true}))
                dispatch(setUser({user: definitelyAUser}))
            })
        }
    }, [userData, dispatch, user, loggedIn, skip])

    return <></>
};