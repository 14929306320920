import { DoubleBodyCard } from "../../../common/cards/DoubleBodyCard";
import { InfoView, IInfoViewProps } from "../../../common/infoView/InfoView";
import { useApiCoreUsersRetrieveQuery, User } from "../../../../services/generatedApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams} from "react-router-dom";
import { Typography} from "@mui/material";
import { BodyCard} from "../../../common/cards/BodyCard";
import { HeaderSideBarBody} from "../../../layout/headerSideBarBody/HeaderSideBarBody";
import { navLinks } from "../navLinks";
import { Button } from "@mui/material";
import { UserDetailForm } from "./UserDetailForm";


export const UserDetail = () => {
    const userId = useParams<{ id: User['id'] }>();
    const userRes = useApiCoreUsersRetrieveQuery(userId.id ? {id: userId.id} : skipToken)

    let user
    if (userRes.isSuccess) {
        user = userRes.data
    }

    // Handle more gracefully, loading states and better error presentation
    const fallback = <Typography>User Not Found</Typography>
    let viewNode = fallback
    let editNode = fallback

    if (user) {
        const viewNodeData: IInfoViewProps['nodes'] = [
            {title: 'First Name', data: [user.first_name]},
            {title: 'Last Name', data: [user.last_name]},
            {title: 'Email', data: [user.email]},
            {title: 'Slack Id', data: [user.slack_id ?? '']},
            {title: 'Last Seen', data: [user.last_login ?? '']},
            {title: 'Roles', data: user.user_roles.map((userRole) => (
                userRole.role.department.name + " " + userRole.role.name
                ))},
            {title: 'Partners', data: user.user_partners.map((userPartner) => (
                userPartner.name
                ))}
        ]
        viewNode = <InfoView nodes={viewNodeData} />

        editNode = (
            <UserDetailForm record={user} refetch={userRes.refetch} />
        )
    }

    const deactivateButton = (
        <Button onClick={() => window.alert('Deactivate not implemented yet')}>**Deactivate User</Button>
    )

    const bodyCard = (
        <DoubleBodyCard
            title="Employee Detail"
            action={deactivateButton}
            leftCard={<BodyCard header={{title: 'Information'}} body={viewNode} />}
            rightCard={<BodyCard header={{title: 'Edit Form'}} body={editNode} />}
        />
    )
    return (
        <HeaderSideBarBody body={bodyCard} pageTitle='Employee Detail' navLinks={navLinks} />
    )
}