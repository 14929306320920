import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../common/cards/BodyCard";
import { navLinks } from "./navLinks";
import { Link } from "react-router-dom";
import { Table, TableBody, TableHead, TableRow, TableContainer, TableCell, Button, Switch } from "@mui/material";
import {
    useApiCorePartnersListQuery, useApiCorePartnersGetPartnersNotOnboardedListQuery, ApiCorePartnersListApiResponse
} from "../../../services/generatedApi";
import React, { useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { AppURLS } from "../../../appURLS";

const getRandom = (low: number, high: number) => {
    // TODO Remove after demo
    return Math.floor(Math.random() * (high - low + 1)) + low
}


export interface IListPartnersProps {

}

export const ListPartners = (props: IListPartnersProps) => {
    const queryTypes = {
        all: 'All Partners',
        new: 'Not Onboarded Partners'
    }
    const [notOnboarded, setNotOnboarded] = useState(false)

    const allPartnersList = useApiCorePartnersListQuery(notOnboarded ? skipToken : {isActive: true})
    const notOnboardedList = useApiCorePartnersGetPartnersNotOnboardedListQuery(
        !notOnboarded ? skipToken : {isActive: true})


    let header
    if (!notOnboarded) {
        header = queryTypes.all;

    } else {
        header = queryTypes.new
    }

    let partners:ApiCorePartnersListApiResponse | [] = []
    if (!notOnboarded && allPartnersList.isSuccess) {
        partners = allPartnersList.data
    }

    if (notOnboarded && notOnboardedList.isSuccess) {
        partners = notOnboardedList.data
    }

    if (partners) {
        partners = [...partners].sort((a, b) => {
                if (a.name < b.name) {
                    return -1
                }
                else if ( a.name > b.name) {
                    return 1
                }
                else {
                    return 0
                }
            }
        )
    }


    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotOnboarded(event.target.checked)
    }

    // TODO update to use new simple Table component
    const table = (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Partner</TableCell>
                        <TableCell>Short Code</TableCell>
                        <TableCell>SM</TableCell>
                        <TableCell>AM</TableCell>
                        <TableCell>*Days Until Contract Renewal</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {partners.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>
                                <Button component={Link} to={AppURLS.detailPartner.getFullPath(row.id)}>
                                    {row.name}
                                </Button>
                            </TableCell>
                            <TableCell>{row.short_code}</TableCell>
                            <TableCell>{ row.partner_sm ?
                                <Button component={Link}
                                        to={AppURLS.detailEmployee.getFullPath(row.partner_sm.id)}
                                >
                                    {`${row.partner_sm.first_name} ${row.partner_sm.last_name}`}
                                </Button>
                                : ''
                            }</TableCell>
                            <TableCell>{ row.partner_am ?
                                <Button component={Link}
                                        to={AppURLS.detailEmployee.getFullPath(row.partner_am.id)}
                                >
                                    {`${row.partner_am.first_name} ${row.partner_am.last_name}`}
                                </Button>
                                : ''
                            }</TableCell>
                            <TableCell>{getRandom(20, 365)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )

    const toggle = <>
        <Switch
        checked={notOnboarded}
        onChange={handleToggle}
        /> *Filter Not Onboarded
    </>
    const cardBody = <BodyCard body={table} header={{title: header, action: toggle}}/>



    return (
        <HeaderSideBarBody body={cardBody} navLinks={navLinks} pageTitle={"Partner Directory"}/>
    )
}