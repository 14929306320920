import { AppURLS } from "../../../appURLS";

export const navLinks = [
    {
        to: AppURLS.createPartner.getFullPath(),
        label: 'Add Partner',
        end: true
    },
    {
        to: AppURLS.listPartners.getFullPath(),
        label: 'View Partners',
        end: true
    }
]