import { emptySplitApi } from "./emptyApi";
import { ApiPdmGetListingEventXlsRetrieveApiArg, ApiPdmGetListingEventXlsRetrieveApiResponse } from "./generatedApi";

// Note that endpoints are not automatically regenerated by script - see if this can be made to happen
const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        listingEventsXls: builder.mutation<
            ApiPdmGetListingEventXlsRetrieveApiResponse, ApiPdmGetListingEventXlsRetrieveApiArg
        >({
            query(arg) {
                return {
                    url: `/api/pdm/get_listing_event_xls`,
                    method: "GET",
                    params:{partner: arg['partner'], date: arg['date']},
                    responseHandler: async (response) => {
                        const filename =  response.headers?.get('Content-Disposition')?.split(
                            'filename=')[1];
                        response.blob()
                            .then((blob) => URL.createObjectURL(blob))
                            .then((href) => {
                                Object.assign(document.createElement('a'), {
                                    href,
                                    download: filename,
                                }).click()
                            })
                    },
                    cache: "no-cache",
                };
            },
        })
    }),
    overrideExisting: false,
})

export const {
    useListingEventsXlsMutation
} = extendedApi