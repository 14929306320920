import { BodyCard } from "../../../common/cards/BodyCard";
import { HeaderSideBarBody } from "../../../layout/headerSideBarBody/HeaderSideBarBody";
import { navLinks } from "../navLinks";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ApiPdmGetListingEventRangeCountListApiArg, ApiPdmGetListingEventRangeCountListApiResponse, PartnerList,
    useApiPdmGetListingEventRangeCountListQuery } from "../../../../services/generatedApi";
import { useListingEventsXlsMutation } from "../../../../services/extendedApi";
import { partnerNameFromId } from "../../../../util/FormUtil";
import { useSelector} from "react-redux";
import { selectUserPartners } from "../../../../services/partnerConfig/partnerConfigSlice";
import { useState, useEffect } from "react";
import { AppURLS } from "../../../../appURLS";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Stack, CircularProgress, Tooltip, Button, Typography, Box } from "@mui/material";
import { BaseCell } from "../../../common/basicTable/cells/BaseCell";
import { BasicTable } from "../../../common/basicTable/BasicTable";


const styles = {
    extraCounts: {
        pl: 2
    }
}


export const ListingEventsPartnerHistory = () => {
    const partner = useParams<{partner: PartnerList['id']}>();
    const navigate = useNavigate();

    if (!partner) {navigate(AppURLS.listingEventsOverview.getFullPath())}

    const partners = useSelector(selectUserPartners);
    const partnerName = partnerNameFromId(partners, partner.partner ?? "")
    const [ startDate, setStartDate ] = useState<Dayjs | null>(dayjs().add(-7, "day"))
    const [ endDate, setEndDate ] = useState<Dayjs | null>(dayjs())
    const [query, setQuery] = useState<
        ApiPdmGetListingEventRangeCountListApiArg>({
        partner: partner['partner'] ?? '',
        start: startDate!.format('YYYY-MM-DD'),
        end: endDate!.format('YYYY-MM-DD')
        })
    const listingEventCountRes = useApiPdmGetListingEventRangeCountListQuery(query)
    const [ getXls ] = useListingEventsXlsMutation()

    let listingEventCount: ApiPdmGetListingEventRangeCountListApiResponse = []
    if (listingEventCountRes.isSuccess) {
        listingEventCount = listingEventCountRes.data
    }

    useEffect(() => {
        if (!startDate) return
        setQuery((q) => {
            return {...q, start: startDate.format("YYYY-MM-DD")}
        })
    }, [startDate, setQuery]);

    useEffect(() => {
        if (!endDate) return
        setQuery((q) => {
            return {...q, end: endDate.format("YYYY-MM-DD")}
        })
    }, [endDate, setQuery]);


    const dateSelect = (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction='row' gap={2}>
                <DatePicker
                    label='Start Date'
                    maxDate={dayjs()}
                    value={startDate}
                    onChange={(newDate) => setStartDate(newDate)}
                /><DatePicker
                label='End Date'
                maxDate={dayjs()}
                value={endDate}
                onChange={(newDate) => setEndDate(newDate)}
            />
            </Stack>
        </LocalizationProvider>
    )

    let body = <CircularProgress />

    if ( listingEventCount && listingEventCount.length > 0 ) {
        const columns = ['Date', 'Change Count', 'Download']
        const rows = listingEventCount.map((result) => {
            return {
                rowKey: result.partner + result.date,
                cells: [
                    <BaseCell content={
                        <Typography>{result.date}</Typography>
                    }
                    />,
                    <BaseCell content={
                        <Tooltip title='Click to view  Day Detail'>
                            <Button component={Link} to={
                                AppURLS.listingEventsDetail.getFullPath(
                                    undefined,
                                    {
                                        partner: result.partner,
                                        date: result.date})
                            }>
                                <>
                                    {result.count_total}
                                    {result.count_total > 0 && <Box sx={styles.extraCounts}>
                                        {`   (${result.count_high} high,
                                        ${result.count_medium} medium,
                                        ${result.count_low} low)`}
                                </Box>}
                                </>
                            </Button>
                        </Tooltip>
                    }
                    />,
                    <BaseCell content={
                        <Button
                            onClick={() => getXls({partner: result.partner, date: result.date})}
                        >Download As CSV</Button>
                    }/>
                ]
            }
        })
        body = (
            <BasicTable columns={columns} rows={rows} />
        )
    } else if ( listingEventCount && listingEventCount.length === 0 ) {
        body = <Typography>No listing events in current date range</Typography>
    }

    const cardBody = (
        <BodyCard
            body={body}
            header={{title: `${partnerName} Listing Events History`, action: dateSelect}}/>
    )

    return (
        <HeaderSideBarBody body={cardBody} navLinks={navLinks} pageTitle='Listing Events History'/>
    )
}