import East from "@mui/icons-material/East";
import React from "react";
import NorthEast from "@mui/icons-material/NorthEast";
import SouthEast from "@mui/icons-material/SouthEast";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import { TableCell } from "@mui/material";
import { GoalType, GoalResultMethod } from "../services/generatedApi";
import { theme } from '../App'
import { DeltaType } from "../components/dataVis/scorecards/MinimalScoreCard";

interface IGoalMeta {type: '$' | '%' | 'number', upGood: boolean}

const goodArrow = <NorthEast color='success' />
const badArrow = <SouthEast color='warning' />
const check = <Check color='success' />
const ex = <Close color='warning' />

export const asPercent = (value: number | string, divide: boolean = false) => {
    let num = Number(value)
    if (isNaN(num)) {
        return 'N/A'
    }
    if (divide) {
        num = num / 100
    }
    // TODO add this as an argument?
    return num.toLocaleString("en", {style: "percent", maximumFractionDigits: 2})
}

export const toCapital = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const asMoney = (value: number | string, maxFraction: number = 2) =>{
    return Number(value).toLocaleString(
        'en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: maxFraction }) }

export const  returnTrendArrow = (value: number | string, comparison: number | string, target: number | string) => {
    // TODO Create getUpGood function/method/record
    const upGood = true
    // const isGood = Number(value) <= Number(target) ? Number(value) > Number(target) : Number(target) > Number(value)
    if (value === comparison) {return <East />}
    const isUp = upGood ? Number(value) > Number(comparison) : Number(value) < Number(comparison)
    return isUp ? goodArrow : badArrow
}

// TODO Probably refactor inputs as needed
export const getOnTrack = (completion: number, time: number) => {
    if (time >= completion) {return check}
    return ex
}

// export const onTrackFromPerformance = (performance: GoalResultMethod['performance']) => {
//     if ( Number(performance) > 1 ) {return check}
//     return ex
// }

export const formatValue = (value: number | string, goalTypeId: GoalType['id'], goalTypes: GoalType[]) => {
    // Think through how to keep updated with new KPIs
    // Redo this whole function, possible return more friendly output from API
    const goalType = goalTypes.find((goalType) => goalType.id === goalTypeId)
    if (!goalType) {return value}
    const kpi = goalType.kpi.replace('_', ' ').toLowerCase()
    if (kpi === 'total sales') {
        return asMoney(value)
    } else if (kpi === 'Percent stuff') {
        return asPercent(value)
        // No current Percentage goals in DB - Update as needed
    } else {
        return value
    }
}

export const returnColoredCell = (initial: number | string, value: number | string , target: number | string,
                                  goalTypeId: GoalType['id'], goalTypes: GoalType[]) => {
    // This doesn't account for case when initial is exactly target
    // Should be redone. At minimum value can be passed in as child
    // Might also want to wait a certain period of time, shouldn't show red all month for on track sales numbers
    const isGood = Number(initial) <= Number(target) ? Number(value) > Number(target) : Number(target) > Number(value)
    return (
        // @ts-ignore
        <TableCell sx={{color: isGood ? theme.palette.success.main : theme.palette.error.main}} >
            {formatValue(value, goalTypeId, goalTypes)}
        </TableCell>
    )
}

export const dateToGo = (target: string, start: string, dataEnd: string) => {
    const targetDate = new Date(target)
    const startDate = new Date(start)
    const dataEndDate = new Date(dataEnd)
    // @ts-ignore
    return asPercent(Math.max((targetDate - dataEndDate) / (targetDate - startDate), 0))
}

export const toGo = (current: string, target: string, initial: string) => (
    asPercent((Number(target) - Number(current)) / (Number(target) - Number(initial)))
)

export interface IGetDeltaTypeConfig {
    // Uses percentages as a fraction of 1, ex: 10% = 0.1 and -5% = -0.05
    severeIncreaseFloor: number
    moderateIncreaseFloor: number
    moderateDecreaseCeil: number
    severeDecreaseCeil: number
}
export const getDeltaType = (change: number, config: IGetDeltaTypeConfig): DeltaType => {
    const isBetween = (number: number, min: number, max: number): boolean => {
        return number >= min && number <= max;
    }
    if (change >= config['severeIncreaseFloor']) {
        return 'severeIncrease'
    } else if (change <= config['severeDecreaseCeil']) {
        return 'severeDecrease'
    } else if (isBetween(change, config['moderateDecreaseCeil'], config['moderateIncreaseFloor'])) {
        return 'even'
    } else if (isBetween(change, config['moderateIncreaseFloor'], config['severeIncreaseFloor'])) {
        return 'moderateIncrease'
    } else if (isBetween(change, config['severeDecreaseCeil'], config['moderateDecreaseCeil'])) {
        return 'moderateDecrease'
    }
    return 'even'
}

export const getChangePct = (value: number, comparison: number): number => {
    return ((value - comparison) / comparison)
}
