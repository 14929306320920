import { Navigate, Outlet, useLocation } from "react-router-dom"
import { AppURLS } from "../../appURLS";
import { selectCurrentUser } from "../../services/auth/authSlice";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";


export const EmployeeRoutes = () => {
    const user = useSelector(selectCurrentUser)
    const location = useLocation()
    const next = location.pathname + location.search

    let body = <CircularProgress />

    if (user) {
        body = (
            user!.is_nectar // User is likely never undefined because authInitializer and PrivateRoutes
                ? <Outlet />
                // TODO redirect to partner dash instead
                : <Navigate to={AppURLS.authPage.getFullPath(undefined, {continue: next})} />
        )
    }

    return (
        body
    )
}