import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/store'
import Cookies from "universal-cookie";
import { User } from "../generatedApi"


type AuthState = {
    user: User | null
    access_token: string | null
    loggedIn: boolean | undefined
    shouldPollLongRunningTasks: boolean | undefined
}

const cookies = new Cookies()

const slice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        access_token: null,
        loggedIn: undefined,
        shouldPollLongRunningTasks: undefined} as AuthState,
    reducers: {
        setToken: (
            state,
                {payload: { token } }: PayloadAction<{ token: string }>
        ) => {
            state.access_token = token
        },
        setUser: (
            state,
            {payload: { user } }: PayloadAction<{ user: User }>
        ) => {
            state.user = user
        },
        setShouldPollLongRunningTasks: (
            state,
                {payload: { value } }: PayloadAction<{ value: boolean }>
        ) => {
            state.shouldPollLongRunningTasks = value
        },
        setLoggedIn: (
            state,
            {payload: { value } }: PayloadAction<{ value: boolean }>
        ) => {
            state.loggedIn = value
        },
        handleLogOut: (state) => {
            state.user=null
            state.loggedIn = false
            state.access_token = null
            cookies.remove('auth')
        }
    }
})

export const { setToken, setUser, setLoggedIn, handleLogOut, setShouldPollLongRunningTasks } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user

export const selectLoggedIn = (state: RootState) => state.auth.loggedIn

export const selectShouldPollLongRunningTasks = (state: RootState) => state.auth.shouldPollLongRunningTasks