import { BodyCard } from "../../../common/cards/BodyCard";
import { HeaderSideBarBody } from "../../../layout/headerSideBarBody/HeaderSideBarBody";
import { ApiPdmListingEventsListApiResponse, ListingEvent, useApiPdmListingEventsListQuery
} from "../../../../services/generatedApi";
import { useListingEventsXlsMutation } from "../../../../services/extendedApi";
import { navLinks } from "../navLinks";
import { Link, useLocation, useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserPartners } from "../../../../services/partnerConfig/partnerConfigSlice";
import { AppURLS } from "../../../../appURLS";
import { Box, Button, CircularProgress, Typography, useTheme, Divider, Stack } from "@mui/material";
import { BaseCell } from "../../../common/basicTable/cells/BaseCell";
import { BasicTable } from "../../../common/basicTable/BasicTable";
import { partnerNameFromId } from "../../../../util/FormUtil";
import { Image as ImageIcon } from "@mui/icons-material";
import { Image } from "mui-image";


const sortListingEvent = (a: ListingEvent, b: ListingEvent) => {
    // TODO Handle non listing events
    if (a.listing! < b.listing!) return 1;
    if (a.listing! > b.listing!) return -1;
    return a.field.localeCompare(b.field)
}

const formatImage = (oldUrl: ListingEvent['old_value'], newUrl: ListingEvent['new_value']) => {
    let oldImage = <ImageIcon />
    let newImage = <ImageIcon />
    if (oldUrl !== undefined && oldUrl !== '') {
        oldImage = (
            <a href={oldUrl} target="_blank" rel="noopener noreferrer">
                <Image src={oldUrl} style={{maxHeight: 100, width: 'auto'}} />
            </a>
        )
    }
    if (newUrl !== undefined && newUrl !== '') {
        newImage = (
            <a href={newUrl} target="_blank" rel='noopener noreferrer'>
                <Image src={newUrl} style={{maxHeight: 100, width: 'auto'}}/>
            </a>
        )
    }
    return (
        <Stack
            direction="row"
            justifyContent='flex-start'
            divider={<Divider orientation="vertical" flexItem />}
        >
            {oldImage}
            {newImage}
        </Stack>
    )
}


export const ListingEventsDetail = () => {
    const theme = useTheme()
    const styles = {
        noDataDisplay: {
            color: theme.palette.warning.main,
        }
    }
    // TODO Likely able to type date string better
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const partner = params.get("partner");
    const date = params.get("date");
    const navigate = useNavigate()
    // TODO use All Partners selector when implemented
    const partners = useSelector(selectUserPartners)
    // TODO Look into useSearchParams hook, might need to update package
    if (!date || !partner) { navigate(AppURLS.listingEventsOverview.getFullPath()) }
    const partnerName = partnerNameFromId(partners, partner ?? "")
    const listingEventsDetailRes = useApiPdmListingEventsListQuery({listingPartner: partner!, date: date!})
    const [ getXls ] = useListingEventsXlsMutation()

    let listingEventsDetail: ApiPdmListingEventsListApiResponse = []
    if (listingEventsDetailRes.isSuccess) {
        listingEventsDetail = listingEventsDetailRes.data
    }

    let body = <CircularProgress />

    const noDataDisplay = <Box sx={styles.noDataDisplay}>None</Box>

    const formatString = (oldValue: ListingEvent['old_value'], newValue: ListingEvent['new_value']) => {
        let oldEl = <Typography>{oldValue}</Typography>
        let newEl = <Typography>{newValue}</Typography>

        if (oldValue === undefined || oldValue === '') {
            oldEl = noDataDisplay
        }
        if (newValue === undefined || newValue === '') {
            newEl = noDataDisplay
        }

        return (
            <Box>
                {oldEl}
                <Divider />
                {newEl}
            </Box>
        )
    }

    if ( listingEventsDetail && listingEventsDetail.length > 0 ) {
        // TODO Add extra columns, allow filtering and sorting
        //  Load images, or have button to view in a modal
        const columns = ['Listing', 'Severity', 'Description', 'Field', 'Old/New Value']
        const rows = [...listingEventsDetail].sort(sortListingEvent).map((result) => {
            return {
                rowKey: result.listing + result.field,
                cells: [
                    <BaseCell content={
                        // TODO Handle non listing events
                        <Button component={Link} to={AppURLS.detailProduct.getFullPath(result.listing ?? "")}>
                            {result.partner_sku === "" ? result.sku : result.partner_sku}
                        </Button>
                    }
                    />,
                    <BaseCell content={result.severity} />,
                    <BaseCell content={result.description} />,
                    <BaseCell content={result.field} />,
                    <BaseCell content={
                        <>
                            {result.field.includes('image') ?
                                formatImage(result.old_value, result.new_value) :
                                formatString(result.old_value, result.new_value)
                            }
                        </>
                    }/>
                ]
            }
        })
        body = (
            <BasicTable columns={columns} rows={rows} />
        )
    // TODO Handle with better pattern, loading + error states
    } else if (listingEventsDetailRes.isSuccess && listingEventsDetail.length === 0) {
        body = <Typography>No listing events for {partnerName} on {date}</Typography>
    }

    const downloadButton = (
        // Will always be set, page redirects if they are null
        <Button onClick={() => getXls({partner: partner!, date: date!})}>Download As CSV*</Button>
    )

    const bodyCard = (
        <BodyCard
            body={body}
            header={{title: `${partnerName} ${date} Listing Events`, action: downloadButton}}/>
    )

    return (
        <HeaderSideBarBody body={bodyCard} navLinks={navLinks} pageTitle='Listing Events Detail'/>
    )
}