import { List, ListSubheader } from "@mui/material";
import { SideBarNavModule } from "../SideBarNavModule/SideBarNavModule";
import { AppURLS } from "../../../../appURLS";
import { IPathBase } from "../../../../util/PathUtil";
import { IDeptLevelConfigList } from "../../../../rbacConfigs";
import { IUserLevelCheckProps } from "../../../rbac/UserLevelCheck";
// import { RbacConfigs } from "../../../../rbacConfigs";

interface ISideBarNavGroupChildBase {
    subName: string
    link: ReturnType<IPathBase['getFullPath']>
}

interface ISideBarNavGroupChildWithRbac extends ISideBarNavGroupChildBase {
    rbacConfig?: IDeptLevelConfigList
    userLevel?: never
}

interface ISideBarNavGroupChildWithUserLevel extends ISideBarNavGroupChildBase {
    rbacConfig?: never
    userLevel?: IUserLevelCheckProps
}

type ISideBarNavGroupChild = ISideBarNavGroupChildWithRbac | ISideBarNavGroupChildWithUserLevel

interface ISideBarNavGroupItemBase {
    category: string
    children: ISideBarNavGroupChild[]
}

interface ISideBarNavGroupItemWithRbac extends ISideBarNavGroupItemBase{
    rbacConfig?: IDeptLevelConfigList
    userLevel?: never
}

interface ISideBarNavGroupItemWithUserLevel extends ISideBarNavGroupItemBase{
    rbacConfig?: never
    userLevel?: IUserLevelCheckProps
}

export type ISideBarNavGroupItem = ISideBarNavGroupItemWithRbac | ISideBarNavGroupItemWithUserLevel

const tempItems: ISideBarNavGroupItem[] = [
    // TODO Move to Separate File
    // Note that if all items in a category have the same permission, or a matching permission, it should be set
    //  on the category ex: Category Sales.1 min level 3 and Sales.2 min level 4, Category.minlevel should be set at 3
    //  and Sales.2 should be set at for or else empty nav list will render
    // set an assert that this doesn't happen?
    // This happens because RBAC component does not evaluate until category collapse is opened
    // If all sub items have a RBAC or UserLevel, category should as well
    {
        category: '**Demo Items**',
        // Convenience list that shows up in user has user_partners?
        children: [
            {
                subName: 'Partner Onboarding',
                link: AppURLS.partnerOnboarding.getFullPath()
            },
            {
                subName: 'Manage Goals',
                link: AppURLS.listGoals.getFullPath()
            }
        ]

    },
    {
        category: 'Partner Management',
        children: [
            {
                subName: 'Unprocessed Intake Forms',
                link: AppURLS.listPartnerIntakes.getFullPath()
            },
            {
                subName: 'Create',
                link: AppURLS.createPartner.getFullPath()
            },
            {
                subName: 'All Partners',
                link: AppURLS.listPartners.getFullPath()
            }
        ]

    },
    {
        category: 'Employee Management',
        children: [
            {
                subName: 'Create',
                link: AppURLS.createEmployee.getFullPath()
            },
            {
                subName: 'All Users',
                link: AppURLS.listEmployees.getFullPath()
            }
        ]

    },
    {
        category: 'Catalog',
        children: [
            {
                subName: 'All Products',
                link: AppURLS.listProducts.getFullPath()
            },
            {
                subName: 'Product Groups',
                link: AppURLS.productGroups.getFullPath()
            },
            {
                subName: 'Listing Events',
                link: AppURLS.listingEventsOverview.getFullPath()
            }
        ]
    },
    {
        category: 'Sales Team',
        children: [
            {
                subName: 'New Partner Intake Form',
                link: AppURLS.createPartnerIntake.getFullPath()
            }
        ]
    },
    {
        category: '*Inventory',
        children: [
            {
                subName: '*View Current Forecasts',
                link: '/1'
            },
            {
                subName: '*Manage Virtual Warehouses',
                link: '/2'
            }
        ]
    }];

const styles = {
    SideBarNavGroup: {
        backgroundColor: "white"
    },
    NavLabel: {
        textAlign: 'center' as const
    }
};

export const SideBarNavGroup = () => {

    return <List
        sx={styles.SideBarNavGroup}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
            <ListSubheader component="div" id="nested-list-subheader" sx={styles.NavLabel}>
                Navigation
            </ListSubheader>
        }
    >
        {tempItems.map(item => <SideBarNavModule item={item} key={item.category}/>)}
    </List>
};