import { AppURLS } from "../../../appURLS";

export const navLinks = [
    {
        to: AppURLS.createEmployee.getFullPath(),
        label: 'Add Employee',
        end: true
    },
    {
        to: AppURLS.listEmployees.getFullPath(),
        label: 'View Employees',
        end: true
    }
]