import { PartnerList, ListingDetail, ProductTagRequest, useApiPdmProductTagsCreateMutation,
    useApiPdmProductTagsListQuery, useApiPdmProductProductTagsBulkUpdateUpdateMutation,
    ProductProductTagBulkRequest} from "../../../../services/generatedApi";
import { ISingleFieldFormProps, SingleFieldForm } from "../../../common/forms/BaseForm/SingleFieldForm";
import { productTagChoiceToDisplayName, productTagNameFromId } from "../../../../util/FormUtil";
import { useState} from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";


export interface IProductTagFormProps {
    partnerId: PartnerList['id']
    listingId: ListingDetail['id']
    productTags: ListingDetail['tags']
    refetch: () => void
}

const styles = {
    dialogContent: {
        // && for higher CSS specificity than regular 'sx' prop
        '&&': {
            pt: 2
        }
    }
}

interface ITagFormInput extends Omit<ProductTagRequest, 'partner'> {}
interface IPPTFormInput extends Omit<ProductProductTagBulkRequest, 'partner' | 'listing'> {}

export const ProductTagForm = (props: IProductTagFormProps) => {
    const tagsRes = useApiPdmProductTagsListQuery({partner: props.partnerId})
    const [ createTag ] = useApiPdmProductTagsCreateMutation()
    const [ updatePPT ] = useApiPdmProductProductTagsBulkUpdateUpdateMutation()
    const [ showForm, setShowForm ] = useState<boolean>(false)

    const createTagAction = ((formInput: ITagFormInput) => (
            createTag({productTagRequest: {partner: props.partnerId, ...formInput}})
        )
    )

    const bulkPPTAction = ((formInput: IPPTFormInput) => (
        updatePPT({productProductTagBulkRequest: {
            partner: props.partnerId,
                listing: props.listingId,
                ...formInput}})
    ))

    const tagCreateField: ISingleFieldFormProps['fieldData'] = {
        type: 'Text',
        fieldName: 'name',
        fieldLabel: 'Tag Name',
    }

    const dialogForm = (
        <>
            <Dialog open={showForm}>
                <DialogTitle>Add Product Tag</DialogTitle>
                <DialogContent sx={styles.dialogContent}>
                    <SingleFieldForm
                        fieldData={tagCreateField}
                        recordName='Product Tag'
                        actionType='Create'
                        //@ts-ignore
                        formAction={createTagAction}
                        refetch={tagsRes.refetch}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={() => setShowForm(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setShowForm(!showForm)}>Add Product Tags</Button>
        </>

    )

    const tagField: ISingleFieldFormProps['fieldData'] = {
        type: 'ChipSelect',
        fieldName: 'product_tags',
        fieldLabel: 'Product Tags',
        multiple: true,
        initialValue: props.productTags,
        recordsRes: tagsRes,
        nameFromIdFunc: productTagNameFromId,
        choiceToDisplayFunc: productTagChoiceToDisplayName,
    }

    const productProductTagForm = (
        <SingleFieldForm
            fieldData={tagField}
            recordName='Product Tags'
            actionType='Update'
            //@ts-ignore
            formAction={bulkPPTAction}
            refetch={props.refetch}
        />
    )

    return (
        <>
            {productProductTagForm}
            {dialogForm}
        </>
    )
}