import { Key } from "react";
import { Control, Controller } from "react-hook-form";
import { IBaseAPIFormProps } from "../BaseAPIForm";
import { TextField } from "@mui/material";


export interface IBooleanFormFieldProps {
    key: Key
    control: Control
    meta: {
        fieldName: string
        fieldLabel: string
        required?: boolean
    }
}

export const MakeBooleanFormField = (
    control: Control<any>,
    fieldName: string,
    fieldLabel: string,
    required: boolean | undefined = undefined): IBaseAPIFormProps['fields'][number] => {

    const booleanResolve = (value:string) => value ? 'True' : 'False'
    return {
        meta: {
            fieldName: fieldName,
            fieldLabel: fieldLabel,
            nameResolver: booleanResolve
        },
        field: <BooleanFormField
            key={fieldName}
            control={control}
            meta={{
                fieldName: fieldName,
                fieldLabel: fieldLabel,
                required: required
            }}
        />
    }
}

// TODO Styling (just allow sx prop?)
const styles = {
    root: {
        maxWidth: "10%",
    },
    box: {
        marginTop: 15,
        marginBottom: 15
    }
}

export const BooleanFormField = (props: IBooleanFormFieldProps) => {

    return (
        <Controller
            name={props.meta.fieldName}
            control={props.control}
            rules={{required: props.meta.required}}
            render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                    label={props.meta.fieldLabel}
                    variant="outlined"
                    value={value}
                    type='checkbox'
                    sx={styles.root}
                    inputProps={{style: styles.box, checked: value}}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                />
            )}/>
    )


    // return (
    //     <Controller
    //         name={props.meta.fieldName}
    //         control={props.control}
    //         rules={{required: props.meta.required}}
    //         render={({field: {onChange, value}, fieldState: {error}}) => (
    //             <FormControlLabel
    //                 sx={styles.root}
    //                 control={
    //                 <Checkbox
    //                     checked={value}
    //                     onChange={onChange}
    //                     value={value}
    //                 />
    //             }
    //                               label={props.meta.fieldLabel}
    //                               labelPlacement='start'
    //             />
    //
    //         )
    //     }
    //     />
    // )
}