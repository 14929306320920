import { Typography, List, ListItem, ListItemText, IconButton, Button, Box, Collapse, Stack, Grid, Tooltip
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { ReactElement, useEffect, useState, MouseEvent as ReactMouseEvent, PropsWithChildren } from "react";

export interface IInfoViewEditProps {
    nodes: InfoViewEditNode[]
    disableEdit?: boolean
    gridWidth?: number
}

interface InfoViewEditNode {
    title: string
    data: (string | ReactElement | undefined)[]
    editable?: boolean
    form?: ReactElement
    tooltip?: string
}

type ExtendedNode = IInfoViewEditProps['nodes'][number] & {
    formIsOpen?: boolean
}

const styles = {
    fieldset: {
        border: '1px solid',
        width: '100%'
    },
    listItem: {
        pb: 0
    },
    secondaryText: {
        display: 'block'
    },
    content: {
        display: "flex",
        justifyContent: "center"
    },
    icon: {
        //Add padding to icon to align with non-editable items that get inset prop when icon is shown
        pr: 3
    },
    collapse: {
        minWidth: 250
    }
}

const isNothing = (input: any) => {
    if (Array.isArray(input) && input.length === 0) {
        return true
    } else if (input === undefined || input === '' || input === " " || input === null) {
        return true
    }
}

export const InfoViewEdit = (props: IInfoViewEditProps) => {
    const [showEdit, setShowEdit] = useState<boolean>(false)
    const [objectList, setObjectList] = useState<ExtendedNode[]>([])

    useEffect(() => {
        setObjectList(
            props.nodes.map(node => ({...node, formIsOpen: false}))
        )
    }, [props.nodes])
    // Empty Dependency array so that form doesn't collapse when data is first entered
    // }, [props.nodes])

    const handleCollapseClick = (node: ExtendedNode) => {
        setObjectList(
            objectList.map(object => object.title === node.title ? (
                    {...object, formIsOpen: !object.formIsOpen})
                : (object))
        )
    }

    const handleEditClick = (_: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (showEdit) {
            setObjectList(
                objectList.map((node) => ({...node, formIsOpen: false}))
            )
        }
        setShowEdit(!showEdit)
    }

    const ConditionalGrid = (({children}: {children : PropsWithChildren['children']}) => {
        if (props.gridWidth) {
            return (
                <Grid container columns={props.gridWidth}  justifyContent='center'>
                    {children}
                </Grid>
            )
        } else {
            return <>{children}</>
        }
    })

    const ConditionalGridItem = (({children}: {children : PropsWithChildren['children']} ) => {
        if (props.gridWidth) {
            return (
                <Grid item maxWidth={`${95/props.gridWidth}%`} >
                    {children}
                </Grid>
            )
        } else {
            return <>{children}</>
        }
    })

    // Copied from CardBadge - move these components to another file to be reusable
    const ConditionalToolTip = ((
        {tooltip, children}: {children : PropsWithChildren['children'], tooltip: string | undefined}
    ) => {
        // Tooltips don't render with Fragment, needs to be a Box wrapper
        if (tooltip) {
            return (
                <Tooltip title={tooltip}>
                    <Box>{children}</Box>
                </Tooltip>
            )
        } else {
            return <>{children}</>
        }
    })


    return (
        <Box sx={styles.content}>
            <List>
                <ConditionalGrid >
                {props.nodes.map((node: ExtendedNode) => (
                    <ConditionalGridItem >
                    <ListItem key={node.title} sx={styles.listItem} alignItems='flex-start'>

                        {showEdit && node.editable &&
                            <IconButton onClick={() => handleCollapseClick(node)} sx={styles.icon}>
                                <EditIcon/>
                            </IconButton>
                        }
                        <Stack>
                                <ListItemText
                                    inset={showEdit && !node.editable}
                                    primary={
                                    <ConditionalToolTip tooltip={node.tooltip}>
                                        {node.title}
                                    </ConditionalToolTip>
                                }
                                    secondary={node.data.map((item, index) => (
                                        <Typography
                                            key={node.title + index}
                                            component='span'
                                            sx={styles.secondaryText}
                                        >
                                            {isNothing(item) ? '--' : item}
                                        </Typography>

                                    ))}
                                />

                            <Collapse unmountOnExit
                                      sx={styles.collapse}
                                      in={objectList.find(
                                (listNode) => listNode.title === node.title)?.formIsOpen}
                            >
                                {node.form}
                            </Collapse>
                        </Stack>
                    </ListItem>
                    </ConditionalGridItem>
                ))}
                {!props.disableEdit &&
                    <Button onClick={handleEditClick}>{showEdit ? 'Stop Editing' : 'Edit'}</Button>
                }
                </ConditionalGrid>
            </List>
        </Box>
    )
}