import { HeaderSideBarBody } from "../../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../../common/cards/BodyCard";
import {
    PartnerDetailRead, useApiCoreAmzAccountsListQuery, useApiCoreAmzAdAccountsListQuery, ApiCorePartnersListApiResponse,
    ApiCoreAmzAccountsListApiResponse, ApiCoreAmzAdAccountsListApiResponse, useApiCorePartnersListQuery,
    useApiCoreAmzAccountsPartialUpdateMutation, AmzAccount, useApiCoreAmzAdAccountsPartialUpdateMutation, AmzAdAccount
} from "../../../../services/generatedApi";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { Stack, Collapse } from "@mui/material";
import { BasicTable } from "../../../common/basicTable/BasicTable";
import { BaseCell } from "../../../common/basicTable/cells/BaseCell";
import { LinkCell } from "../../../common/basicTable/cells/LinkCell";
import { Button } from "@mui/material";
import { ReactElement, useState } from "react";
import { AmzAccountCreateForm } from "./AmzAccountCreateForm";
import { AmzAdAccountCreateForm } from "./AmzAdAccountCreateForm";
import { partnerNameFromId } from "../../../../util/FormUtil";
import { AmzAccountLinkForm } from "./AmzAccountLinkForm";
import { AmzAdAccountLinkForm } from "./AmzAdAccountLinkform";
import { AppURLS } from "../../../../appURLS";
import {ISingleFieldFormProps, SingleFieldForm} from "../../../common/forms/BaseForm/SingleFieldForm";

const styles = {
    outerCard: {
        backgroundColor: '#F8F8F8'
    },
    innerCards: {
        mt: 1
    }
}

export const PartnerAccounts = () => {
    const partnerId = useParams<{ id: PartnerDetailRead['id'] }>();
    const [ updateIwIdAmzAcct ] = useApiCoreAmzAccountsPartialUpdateMutation()
    const [ updateIwIdAmzAdAcct ] = useApiCoreAmzAdAccountsPartialUpdateMutation()
    const amzAccountsRes = useApiCoreAmzAccountsListQuery(
        partnerId.id ? {partnerAmzAccountsPartnerId: partnerId.id} : skipToken)
    const amzAdAccountsRes = useApiCoreAmzAdAccountsListQuery(
        partnerId.id ? {partnerAmzAdAccountsPartnerId: partnerId.id} : skipToken)
    const partnerListRes = useApiCorePartnersListQuery({})
    const [scAddOpen, setScAddOpen] = useState(false)
    const [scConnectOpen, setScConnectOpen] = useState(false)
    const [vcAddOpen, setVcAddOpen] = useState(false)
    const [vcConnectOpen, setVcConnectOpen] = useState(false)
    const [adAddOpen, setAdAddOpen] = useState(false)
    const [adConnectOpen, setAdConnectOpen] = useState(false)

    let partnerList: ApiCorePartnersListApiResponse = []
    if (partnerListRes.isSuccess) {
        partnerList = partnerListRes.data
    }

    let partnerAdAccounts: ApiCoreAmzAdAccountsListApiResponse = []
    if (amzAdAccountsRes.isSuccess) {
        partnerAdAccounts = amzAdAccountsRes.data
    }

    let partnerAmzAccounts: ApiCoreAmzAccountsListApiResponse = []
    if (amzAccountsRes.isSuccess) {
        partnerAmzAccounts = amzAccountsRes.data
    }

    const makeIwIdAmzAcctAction = ((id: string) => {
        return (
            ((formInput: Partial<AmzAccount>) => (
                updateIwIdAmzAcct({
                    id: id,
                    patchedAmzAccountRequest: {...formInput}
                })
            ))
        )
    })

    const makeIwIdAmzAdAcctAction = ((id: string) => {
        return (
            ((formInput: Partial<AmzAdAccount>) => (
                updateIwIdAmzAdAcct({
                    id: id,
                    patchedAmzAdAccountRequest: {...formInput}
                })
            ))
        )
    })

    const makeAmzAcctForm = (field: ISingleFieldFormProps['fieldData'], action: any) => {
        return (
            <SingleFieldForm
                fieldData={field}
                recordName='Amz Account'
                actionType='Update'
                formAction={action}
                refetch={amzAccountsRes.refetch}
            />
        )
    }

    const makeAmzAdAcctForm = (field: ISingleFieldFormProps['fieldData'], action: any) => {
        return (
            <SingleFieldForm
                fieldData={field}
                recordName='Amz Ad Account'
                actionType='Update'
                formAction={action}
                refetch={amzAdAccountsRes.refetch}
            />
        )
    }

    let scTable: ReactElement | string = 'No Seller Central Accounts'
    let vcTable: ReactElement| string = 'No Vendor Central Accounts'
    let adTable: ReactElement | string = 'No Ad Accounts'

    if (partnerAmzAccounts || partnerAdAccounts) {
        const scAccounts = partnerAmzAccounts.filter((account) => account.is_sc)
        const vcAccounts = partnerAmzAccounts.filter((account) => account.is_vc)

        if (scAccounts.length !== 0) {
            const scColumns = ['Name', 'IntentWise Id*', 'Nectar Access Given', 'Has API Credentials']
            const scRows = scAccounts.map((account) => (
                {
                    rowKey: account.id,
                    cells:
                        [
                            <LinkCell
                                url={AppURLS.spAuth.getFullPath(account.id)}
                                content={account.name}/>,
                            <BaseCell content={makeAmzAcctForm(
                                {type: 'Number',
                                    fieldName: 'iw_id',
                                    fieldLabel:'IW ID',
                                    initialValue: account.iw_id ?? undefined
                                }
                                , makeIwIdAmzAcctAction(account.id)
                            )
                            } />,
                            // TODO implement in API
                            <BaseCell content='*False*'/>,
                            <BaseCell content='*False'/>
                        ]
                }
            ))
            scTable = <BasicTable columns={scColumns} rows={scRows} />
        }

        if (vcAccounts.length !== 0) {
            const vcColumns = ['Name', 'IntentWise Id', 'Nectar Access Given', 'Has API Credentials']
            const vcRows = vcAccounts.map((account) => (
                {
                    rowKey: account.id,
                    cells:
                        [
                            <LinkCell
                                url={AppURLS.spAuth.getFullPath(account.id)}
                                content={account.name}/>,
                            <BaseCell content={makeAmzAcctForm(
                                {type: 'Number',
                                    fieldName: 'iw_id',
                                    fieldLabel:'IW ID',
                                    initialValue: account.iw_id ?? undefined
                                }
                                , makeIwIdAmzAcctAction(account.id)
                            )
                            } />,
                            // TODO implement in API
                            <BaseCell content='*False*'/>,
                            <BaseCell content='*False'/>
                        ]
                }
            ))
            vcTable = <BasicTable columns={vcColumns} rows={vcRows} />
        } else {
            vcTable = 'No Vendor Central Accounts'
        }

        if (partnerAdAccounts.length !== 0) {
            const adColumns = ['Name', 'IntentWise Id', 'Nectar Access Given', 'Has API Credentials']
            const adRows = partnerAdAccounts.map((account) => (
                {
                    rowKey: account.id,
                    cells: [
                        <BaseCell content={account.name}/>,
                        <BaseCell content={makeAmzAdAcctForm(
                            {type: 'Number',
                                fieldName: 'iw_id',
                                fieldLabel:'IW ID',
                                initialValue: account.iw_id ?? undefined
                            }
                            , makeIwIdAmzAdAcctAction(account.id)
                        )
                        } />,
                        // TODO implement in API
                        <BaseCell content='*False*'/>,
                        <BaseCell content='*False'/>
                    ]
                }
            ))
            adTable = <BasicTable columns={adColumns} rows={adRows} />
        }
    }

    const scBody = (
        <Stack>
            {scTable}
            <Button onClick={() => setScAddOpen(!scAddOpen)}>Add SC Accounts</Button>
            <Collapse in={scAddOpen} timeout='auto' unmountOnExit>
                {/*Partner id will always exist if form should be shown, handle conditionally with fallback*/}
                <AmzAccountCreateForm
                    partnerId={partnerId.id!}
                    accountType='SC'
                    refetch={amzAccountsRes.refetch}
                />
            </Collapse>
            <Button onClick={() => setScConnectOpen(!scConnectOpen)}>Connect Existing SC Account</Button>
            <Collapse in={scConnectOpen} timeout='auto' unmountOnExit>
                <AmzAccountLinkForm
                    accountType='SC'
                    partnerId={partnerId.id!}
                    refetch={amzAccountsRes.refetch}
                />
            </Collapse>
        </Stack>
    )
    const vcBody = (
        <Stack>
            {vcTable}
            <Button onClick={() => setVcAddOpen(!vcAddOpen)}>Add VC Accounts</Button>
            <Collapse in={vcAddOpen} timeout='auto' unmountOnExit>
                <AmzAccountCreateForm
                    partnerId={partnerId.id!}
                    accountType='VC'
                    refetch={amzAccountsRes.refetch}
                />
            </Collapse>
            <Button onClick={() => setVcConnectOpen(!vcConnectOpen)}>Connect Existing VC Account</Button>
            <Collapse in={vcConnectOpen} timeout='auto' unmountOnExit>
                <AmzAccountLinkForm
                    accountType='VC'
                    partnerId={partnerId.id!}
                    refetch={amzAccountsRes.refetch}
                />
            </Collapse>
        </Stack>
    )
    const adBody = (
        <Stack>
            {adTable}
            <Button onClick={() => setAdAddOpen(!adAddOpen)}>Add Ad Accounts</Button>
            <Collapse in={adAddOpen} timeout='auto' unmountOnExit>
                <AmzAdAccountCreateForm partnerId={partnerId.id!} refetch={amzAdAccountsRes.refetch} />
            </Collapse>
            <Button onClick={() => setAdConnectOpen(!adConnectOpen)}>Connect Existing Account</Button>
            <Collapse in={adConnectOpen} timeout='auto' unmountOnExit>
                <AmzAdAccountLinkForm
                    partnerId={partnerId.id!}
                    refetch={amzAdAccountsRes.refetch}
                />
            </Collapse>
        </Stack>
    )


    const body = (
        // TODO Set Fallback
        <Stack>
            <BodyCard body={scBody} header={{title: 'Seller Central Accounts'}} sx={styles.innerCards} />
            <BodyCard body={vcBody} header={{title: 'Vendor Central Accounts'}} sx={styles.innerCards} />
            <BodyCard body={adBody} header={{title: 'Advertising Accounts'}} sx={styles.innerCards} />
        </Stack>
    )
    // Partner id will always exist if form should be shown, handle conditionally with fallback
    const partnerName = partnerNameFromId(partnerList, partnerId.id!)
    // The below is a string comparison to a placeholder return that is easy to break.
    // Should be replaced by name lookup in global store
    const title = partnerName === 'Partner Not Found' ? partnerName : `${partnerName} Accounts`
    const bodyCard = (
        <BodyCard body={body} header={{title: title}} sx={styles.outerCard} />
    )

    return (
        <HeaderSideBarBody body={bodyCard} pageTitle='Partner Accounts' />
    )
}