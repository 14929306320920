import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Avatar } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React, {useState} from "react";
import { handleLogOut } from "../../../../services/auth/authSlice";
import { useAppDispatch } from "../../../../hooks";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../services/auth/authSlice";

export interface IUserMenuProps {
        sx?: object
}

const styles = {
        name: {
                pl: 1,
                pr: 1
        }
}

export const UserMenu = (props: IUserMenuProps) => {
        const dispatch = useAppDispatch()
        const currentUser = useSelector(selectCurrentUser)
        const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

        const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget)
        };

        const closeHandler = () => {
                setAnchorEl(null)
        };

        return <Box sx={props.sx}>
                <IconButton onClick={clickHandler} size="large">
                        {currentUser ?
                            <Avatar sx={{bgcolor: '#275064'}}>
                                    {currentUser.first_name.slice(0, 1)}
                                    {currentUser.last_name.slice(0, 1)}
                            </Avatar>
                            : <AccountCircleIcon style={{fontSize: 50}} />
                        }

                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={closeHandler}>
                        <Typography variant='subtitle2' sx={styles.name}>
                                {currentUser ? `${currentUser.first_name} ${currentUser.last_name}` : ''}
                        </Typography>
                        <MenuItem>*Settings</MenuItem>
                        <MenuItem onClick={() => dispatch(handleLogOut())}>Logout</MenuItem>
                </Menu>
        </Box>;
};
