import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
    useApiPdmListingsListQuery, useApiPdmProductGroupListQuery, ApiPdmProductGroupListApiResponse,
    ApiPdmListingsListApiResponse, ListingDetail, useApiPdmProductTagsListQuery, ProductTag
} from "../../../services/generatedApi";
import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { Button, Box, CircularProgress, Backdrop } from "@mui/material";
import { Link } from "react-router-dom"
import MUIDataTable from "mui-datatables";
import { skipToken } from "@reduxjs/toolkit/query";
import { navLinks } from "./navLinks";
import { AppURLS } from "../../../appURLS";
import { Image } from 'mui-image'
import { Image as ImageIcon } from '@mui/icons-material'
import { ToggleProductTagForm } from "./forms/ToggleProductTagForm";
import { productTagNameFromId } from "../../../util/FormUtil";

const stylesTable = {
    maxWidth: "80%",
    m: 'auto',
    mt: "5em",
    mb: "5em"
}

export const ListProducts = () => {
    // TODO likely need to implement pagination here, but allow filtering on entire result set
    //  Either pass all required meta separately or handle some other way
    //  Pagination in table might be enough - revisit if issues
    const currentPartner = useSelector((state: RootState) => state.partnerConfig.selectedPartner)
    const query = currentPartner?.id ? {partner: currentPartner.id.toString()} : skipToken
    const listingsListRes = useApiPdmListingsListQuery(query)
    const productGroupsRes = useApiPdmProductGroupListQuery(query)
    const tagsRes = useApiPdmProductTagsListQuery(currentPartner?.id ? {partner: currentPartner.id} : skipToken)


    let tags: ProductTag[] = []
    let tagFilterNames: string[] = ['']
    if (tagsRes.isSuccess) {
        tags = tagsRes.data
    }

    let listings: ApiPdmListingsListApiResponse | [] = []
    if (listingsListRes.isSuccess) {
        listings = listingsListRes.data
    }

    let groups: ApiPdmProductGroupListApiResponse | [] = []
    if (productGroupsRes.isSuccess) {
        groups = productGroupsRes.data
    }

    const columns = [
        {name: 'id',
        options: {
            display: 'excluded',
            filter: false
        }},
        {name: 'sku',
            options: {
                display: 'excluded',
                filter: true
            }},
        {
            name: 'partner_sku',
            label: 'Partner/Amazon SKU',
            options: {
                customBodyRender: (value: string, tableMeta: { rowData: [] }) => {
                    let id = ''
                    let amzSku = 'N/A'
                    for (const el of columns)
                        if (el.name) {
                            if (el.name === 'id') {
                                id = tableMeta.rowData[columns.indexOf(el)]
                            }
                            if (el.name === 'sku') {
                                amzSku = tableMeta.rowData[columns.indexOf(el)]
                            }
                        }
                    return (
                        <Button component={Link} to={AppURLS.detailProduct.getFullPath(id)}>
                            {value.length > 0 ? value : 'N/A'}
                            <br/>
                            {amzSku}
                        </Button>
                    )
                },
                hint: 'Click to view Detail Page'
            }
        },
        {name: 'asin', label: 'ASIN', options: {
                filterOptions: {
                    renderValue: (val:any) => {
                        if (val === "") {return 'None'}
                        return val
                    }

                }
            }
        },
    {
            name:'main_image',
            label: 'Main Image',
            options: {
                customBodyRender: (value: string | undefined) => {
                    if (value === undefined) {
                        return <ImageIcon />
                    }
                    return <Image
                        src={value}
                        style={{maxHeight: 100, width: 'auto'}}
                    />
                }
            }
        },
        {
            label:'Product Group',
            name: 'product_group',
            options: {
                filterOptions: {
                    renderValue: (val:any) => {
                        if (val === null) {return 'None'}
                        return val
                    }
                    },
                customBodyRender: (value: string | null) => {
                    const group = groups.filter(group => group.id === value)
                    if (group[0]?.hasOwnProperty('name')) {
                        return group[0].name
                    } else {
                        return null
                    }
                }
            }
        },
        {
            label: 'Product Tags',
            name: 'tags',
            options: {
                filterList: [],
                filterType:'multiselect',
                // TODO customSearch:
                customFilterListOptions:{
                    render: (v: ListingDetail['tags'][number]) => {
                        const name = v !== '' ? productTagNameFromId(tags, v) : "None"
                        return `Tag: ${name}`}
                },
                filterOptions: {
                    logic: (tags:ListingDetail['tags'], filters: any) => {
                        // Only products with zero tags if "None" selected
                        if (filters.includes('')) {
                            if (filters.length > 1) { return true }
                            return tags.length !== 0
                        }
                        if (filters.length) {
                            // Make sure every selected filter matches
                            return filters.map((filter: string) => tags.indexOf(filter)).includes(-1)
                        }
                        return false
                    },
                    names: tagFilterNames.concat(tags.map((tag) => tag.id)),
                    renderValue: (val: ListingDetail['tags'][number]) => {
                        if (val === '') {
                            return 'None'
                        }
                        return productTagNameFromId(tags, val)
                    }
                },
                customBodyRender: (value: ListingDetail['tags'], tableMeta: { rowData: {}[] }) => {
                    let index = 0
                    for (const el of columns)
                        if (el.name) {
                            if (el.name === 'productID') {
                                index = columns.indexOf(el)
                            }
                        }
                    return (
                        <ToggleProductTagForm tagFormProps={
                            {
                                partnerId: currentPartner?.id ?? '',
                                //@ts-ignore
                                productId: tableMeta.rowData[index],
                                productTags: value,
                                refetch: listingsListRes.refetch
                            }
                        } />
                    )
                }
            }
        }
    ];


    const options = {
        print: false,
        rowsPerPage: 50,
        selectableRowsHideCheckboxes: true
        // TODO Redo custom toolbar, at least remove existing one
        // @ts-ignore
        // customToolbarSelect: (selectedRows, displayData) => (
        //     <ListProductsToolBar productGroups={groups} selectedRows={selectedRows} displayData={displayData}/>
        // )
    }


    const body = (
        <Box sx={{...stylesTable}}>
            <Backdrop
                open={listingsListRes.isFetching}
                sx={{zIndex: 1}}
            >
                <CircularProgress />
            </Backdrop>
            {!currentPartner?.id && <h1>Please select a partner</h1>}
            {/*// TODO Type params*/}
            {/*// @ts-ignore*/}
            {currentPartner?.id && <MUIDataTable columns={columns}
                           data={listings}
                           title={`All ${currentPartner.name} Listings`}
                           options={options}/>}
            </Box>
    )

    return(
        <HeaderSideBarBody body={body} pageTitle={"All Listings"} navLinks={navLinks}/>
    );
};