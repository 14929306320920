import {Stack, Typography, Card, IconButton} from "@mui/material";
import { ResponsiveContainer, PieChart, Pie, Tooltip, Legend, Cell, RadialBarChart, RadialBar, PolarAngleAxis } from "recharts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from "react";
import { AppURLS } from "../../../appURLS";
import { useNavigate } from "react-router-dom";

export interface IGoalOverviewProps {}

const randRange = (rangeLow:number, rangeHigh:number) => Math.floor(
    Math.random() * (rangeHigh - rangeLow ) + rangeLow)

const tempData = {
    goalsMetTotal: [
        {
            name: 'Not Met',
            value: randRange(1, 10)
        },
        {
            name: 'Met',
            value: randRange(5, 20)
        }
    ],
    goalsMetPartner: [
        {
            name: 'Not Met',
            value: randRange(2, 8)
        },
        {
            name: 'Met',
            value: randRange(6, 12)
        }
    ],
    goalsOnTrack: [
        {
            name: 'Partner',
            value: randRange(2, 10)
        },
        {
            name: 'Employee',
            value: randRange(2, 10)
        },
        {
            name: 'Nectar',
            value: randRange(2, 10)
        }
    ],
    goalsOnTrack2: [
        {
            name: 'Partner',
            value: randRange(2, 10),
            max: 10
        },
        {
            name: 'Employee',
            value: randRange(2, 6),
            max: 6
        },
        {
            name: 'Nectar',
            value: randRange(10, 15),
            max: 15
        }
    ]
}

const styles = {
    card: {
        backgroundColor: 'whiteSmoke' as const,
        minWidth: 200
    }
}

export const _GoalOverview = (props: IGoalOverviewProps) => {
    const pieColors = ['red', 'green']
    const barColors = ['green', 'blue', 'blueViolet']
    const navigate = useNavigate()

    const clickHandler = () => {
        navigate(AppURLS.detailEmployee.getFullPath('c17649f7-99ae-425c-9053-ea7319283943'))
    }

    return (
        <Stack direction='row' justifyContent='space-evenly' gap={2}>
            <Card sx={styles.card}>
                <Typography>Total Goals Met</Typography>
                <ResponsiveContainer width={200} height={200}>
                    <PieChart width={100} height={100}>
                        <Pie data={tempData.goalsMetTotal} dataKey='value' nameKey='name'>
                            {
                                tempData.goalsMetTotal.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={pieColors[index]}/>
                                ))
                            }
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </Card>
            <Card sx={styles.card}>
                <Typography>Partner Goals Met</Typography>
                <ResponsiveContainer width={200} height={200}>
                    <PieChart width={100} height={100}>
                        <Pie data={tempData.goalsMetPartner} dataKey='value' nameKey='name'>
                            {
                                tempData.goalsMetPartner.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={pieColors[index]}/>
                                ))
                            }
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </Card>
            <Card sx={styles.card}>
                <Typography>Goals On Track By Type</Typography>
                <ResponsiveContainer width={300} height={200}>
                    <RadialBarChart
                        // width={500}
                        // height={200}
                        innerRadius="30%"
                        outerRadius="100%"
                        barSize={20}
                        barGap={2}
                        data={tempData.goalsOnTrack2}
                        startAngle={180}
                        endAngle={0}
                    >
                        {/*<RadialBar dataKey='value' />*/}
                        {/*<PolarAngleAxis type='number' domain={[0, tempData.goalsOnTrack2[0].max]} angleAxisId={0} tick={false}/>*/}
                        <Tooltip />
                        {
                            tempData.goalsOnTrack2.map((entry, index) => (
                                    <>
                                        <RadialBar dataKey='value' angleAxisId={index} data={[entry]} fill={barColors[index]}/>
                                        <PolarAngleAxis type={'number'} domain={[0, entry.max]} angleAxisId={index} tick={false}/>
                                    </>
                                )
                            )
                        }
                        <Legend />
                    </RadialBarChart>
                </ResponsiveContainer>
            </Card>
            <Card sx={styles.card}>
                <Typography>Top Performer</Typography>
                <Typography sx={{pt: 3}}>Alan Jackson</Typography>
                <IconButton onClick={clickHandler} size="large">
                    <AccountCircleIcon style={{fontSize: 50}} />
                </IconButton>
                <Typography>{`Completed ${randRange(6, 15)} of their goals this period`}</Typography>
            </Card>
        </Stack>
    )
}