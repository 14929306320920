import {SideBar} from "../sideBar/SideBar/SideBar";
import {Header} from "../header/Header/Header";
import { Box } from "@mui/material";
import {ReactNode} from "react";
import {ILink} from "../header/HeaderNavList/HeaderNavList";

export interface IHeaderSideBarBodyProps {
    body?: ReactNode
    pageTitle?: string
    navLinks?: ILink[]
}

const sideBarWidth = 150;

const styles = {
    root: {
        display: 'flex',
        height: "100%",
        width: "100%",
        backgroundColor: 'lightGray'
    },
    HeaderSideBarBody_sideBar: {
        width: sideBarWidth,
        overscrollBehaviorY: 'none'
    },
    headerBody: {
        width: "100%",
        overflowY: 'auto',
        overscrollBehaviorY: 'none'
    },
    contentBody:{
        textAlign: "center",
        justifyContent: "center",
    }
};

export const HeaderSideBarBody = (props: IHeaderSideBarBodyProps) => {
    return (
        <Box  sx={styles.root}>
            <SideBar/>
            <Box sx={styles.headerBody}>
                <Header page_title={props.pageTitle} navLinks={props.navLinks}/>
                {/*// @ts-ignore*/}
                <Box sx={styles.contentBody}>
                    {props.body}
                </Box>
            </Box>
        </Box>
    )
};