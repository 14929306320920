import {
    AmzAccountCreateRequest, useApiCorePartnerAmzAccountsCreateMutation, PartnerAmzAccountRequest,
    useApiCoreAmzAccountsListQuery, AmzAccount
} from "../../../../services/generatedApi";
import { MakeChipSelectFormField } from "../../../common/forms/BaseForm/fields/ChipSelectFormField";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { amzAccountNameFromId, amzAccountChoiceToDisplayName } from "../../../../util/FormUtil";
import { BaseApiForm } from "../../../common/forms/BaseForm/BaseAPIForm";

export interface IAmzAccountLinkFormProps {
    accountType: 'SC' | 'VC'
    partnerId: AmzAccountCreateRequest['partner_id']
    refetch: () => void
}

interface IFormInput extends Omit<PartnerAmzAccountRequest, 'partner'> {}
export const AmzAccountLinkForm = (props: IAmzAccountLinkFormProps) => {
    const initialValues: IFormInput = {account: ''}
    const [ createPartnerAmzAccount ] = useApiCorePartnerAmzAccountsCreateMutation()
    const makeChipSelectFormField = MakeChipSelectFormField
    const { control, ...useFormRest } = useForm<IFormInput>({defaultValues: initialValues});
    const formStateHooks = useState<IFormInput>(initialValues)
    const amzAccountsRes = useApiCoreAmzAccountsListQuery({})

    const createPartnerAmzAccountAction = (formInput: IFormInput) => (
        createPartnerAmzAccount({partnerAmzAccountRequest: {
            partner: props.partnerId, ...formInput
            }})
    )

    const filterSc = (accounts: AmzAccount[]): AmzAccount[] => (
        accounts.filter((account) => account.is_sc)
    )
    const filterVc = (accounts: AmzAccount[]): AmzAccount[] => (
        accounts.filter((account) => account.is_vc)
    )
    let filterFunc
    if (props.accountType === 'SC') {filterFunc = filterSc}
    else if (props.accountType === 'VC') {filterFunc = filterVc}

    const fields = [
        makeChipSelectFormField(
            control,
            'account',
            'Account',
            amzAccountsRes,
            amzAccountNameFromId,
            amzAccountChoiceToDisplayName,
            false,
            true,
            filterFunc
            )
    ]


    return (
        <BaseApiForm
            recordName={`Amazon ${props.accountType} Account`}
            formActionType='Update'
            fields={fields}
            useFormRest={useFormRest}
            formStateHooks={formStateHooks}
            // @ts-ignore
            formAPIAction={createPartnerAmzAccountAction}
            refetch={props.refetch}
            resetAfterSubmit={true}
        />
    )
}