import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { Api } from "../services/generatedApi"
import authReducer from "../services/auth/authSlice"
import partnerConfigReducer from "../services/partnerConfig/partnerConfigSlice"
import dataDatesReducer from "../services/data/dataSlice"

export const store = configureStore({
    reducer: {
        [Api.reducerPath]: Api.reducer,
        auth: authReducer,
        partnerConfig: partnerConfigReducer,
        dataDates: dataDatesReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(Api.middleware)
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
