import { navLinks } from "./navLinks";
import { AppURLS } from "../../../appURLS";
import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { useApiCorePartnersCreateMutation, PartnerListRequest, PartnerTypeEnum } from "../../../services/generatedApi";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { MakeTextFormField } from "../../common/forms/BaseForm/fields/TextFormField";
import { MakeEnumSelectFormField } from "../../common/forms/BaseForm/fields/EnumSelectFormField";
import { BaseApiForm } from "../../common/forms/BaseForm/BaseAPIForm";
import { BodyCard } from "../../common/cards/BodyCard";
import { useLocation } from "react-router-dom";

interface IFormInput extends PartnerListRequest{}

export const CreatePartner = () => {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const partnerIntakeId = params.get('partnerIntakeId')
    const partnerName = params.get('partnerName')
    const partnerType = params.get('partnerType')
    const initialValues: IFormInput = {
        name: '',
        short_code: '',
        //@ts-ignore
        partner_type: ''
    }
    const [ createPartner ] = useApiCorePartnersCreateMutation()
    const makeTextFormField = MakeTextFormField
    const makeEnumSelectFormField = MakeEnumSelectFormField
    const { control, ...useFormRest } = useForm<IFormInput>({defaultValues: initialValues});
    const formStateHooks = useState<IFormInput>(initialValues)

    useEffect(() => {
        function isPartnerType(payload: any): payload is IPartnerType['value'] {
            const PartnerTypes: PartnerTypeEnum[] = ['FM', 'AD']
            return PartnerTypes.includes(payload)
        }
        if (isPartnerType(partnerType)) {
            useFormRest.setValue('partner_type', partnerType, {shouldDirty: true})
        }
        if (partnerName) {
            useFormRest.setValue('name', partnerName, {shouldDirty: true})
        }
    }, [partnerName, partnerType, useFormRest])

    const createPartnerAction = (formInput: IFormInput) => (
        createPartner(
            {partnerListRequest: {partner_intake: partnerIntakeId ?? undefined, ...formInput}}
        )
    )

    interface IPartnerType {
        name: 'Full Management' | 'Advertising Only'
        value: PartnerListRequest['partner_type']
    }

    const partnerTypeChoices: IPartnerType[] = [
        {name: 'Full Management', value: 'FM'},
        {name: 'Advertising Only', value: 'AD'}
    ]

    const fields = [
        makeTextFormField(control, 'name', 'Partner Name', true),
        makeTextFormField(control, 'short_code', 'Partner Short Code', true),
        makeEnumSelectFormField(
            control,
            'partner_type',
            'Partner Type',
            partnerTypeChoices,
            true)
    ]

    const form = (
        <BaseApiForm
            recordName='Partner'
            formActionType='Create'
            fields={fields}
            useFormRest={useFormRest}
            formStateHooks={formStateHooks}
            // @ts-ignore
            formAPIAction={createPartnerAction}
            formSuccessRedirectPathParam={AppURLS.detailPartner}
        />
    )

    const cardBody = <BodyCard header={{title: 'Create New Partner'}} body={form} />


    return (
        <HeaderSideBarBody body={cardBody} pageTitle='Create Partner' navLinks={navLinks}/>
    )
}