import { AmzAdAccountCreateRequest, useApiCoreAmzAdAccountsCreateMutation } from "../../../../services/generatedApi";
import { BaseApiForm } from "../../../common/forms/BaseForm/BaseAPIForm";
import { MakeTextFormField } from "../../../common/forms/BaseForm/fields/TextFormField";
import { useForm } from "react-hook-form";
import { useState } from "react";

export interface IAmzAdAccountCreateFormProps {
    partnerId: AmzAdAccountCreateRequest['partner_id']
    refetch: () => void
}

interface IFormInput extends Omit<AmzAdAccountCreateRequest, 'iw_id' | 'partner_id'> {}
export const AmzAdAccountCreateForm = (props: IAmzAdAccountCreateFormProps) => {
    const initialValues: IFormInput = {name: ''}
    const [ createAmzAdAccount ] = useApiCoreAmzAdAccountsCreateMutation()
    const makeTextFormField = MakeTextFormField
    const { control, ...useFormRest } = useForm<IFormInput>({defaultValues: initialValues});
    const formStateHooks = useState<IFormInput>(initialValues)

    const createAmzAdAccountAction = (formInput: IFormInput) => (
        createAmzAdAccount({amzAdAccountCreateRequest: {partner_id: props.partnerId, ...formInput}})
    )

    const fields = [
        makeTextFormField(control, 'name', 'Account Name', true)
    ]

    const form = (
        <BaseApiForm
            recordName='Amazon Ad Account'
            formActionType='Create'
            fields={fields}
            useFormRest={useFormRest}
            formStateHooks={formStateHooks}
            // @ts-ignore
            formAPIAction={createAmzAdAccountAction}
            refetch={props.refetch}
            resetAfterSubmit={true}
        />
    )

    return (
        form
    )
}