import { IDeptLevelConfigList } from "../rbacConfigs";

interface IQueryParams {
    [key: string]: string
}

export interface IPathBase {
    path: string
    pathSchema: () => string
    getFullPath: (arg0: string | number, queryParams:  IQueryParams) => string
    pathParam?: never
    allowedQueryParams: string[]
    rbacConfig?: IDeptLevelConfigList
    derivablePaths: string[]
    getDerivedPath: (arg0: string) => string
}

interface IPathParam extends Omit<IPathBase, "pathParam"> {
    pathParam: string
}

export class PathBase implements IPathBase {
    path: string;
    allowedQueryParams: string[]
    rbacConfig: IDeptLevelConfigList
    derivablePaths: string[]

    constructor(
        path: string,
        allowedQueryParams: string[] = [],
        rbacConfig: IDeptLevelConfigList = []
    ) {
        this.path = path
        this.allowedQueryParams = allowedQueryParams
        this.derivablePaths = ['create']
        this.rbacConfig = rbacConfig
    }

    pathSchema(): string {
        return this.path;
    }

    getDerivedPath(method: string): string {
        if (!this.derivablePaths.includes(method.toString())) {
            throw Error('Passed unsupported method to getDerivedPath()')
        }
        return this.path + '/' + method
    }

    // Determine if inheritance is worth it since we have to add extra signatures here
    getFullPath(_: string | number = '', queryParams:  IQueryParams | undefined = undefined): string {
        const queryString = this.handleQueryParams(queryParams)
        if (_ !== '') {
            throw Error('Passed extra argument to PathBase method. Use PathParam class.')
        }
        return this.path + queryString
    }

    protected handleQueryParams(params: IQueryParams | undefined): string {
        let querystring: string = ''
        if (params) {
            this.checkQueryArgs(params);
            querystring = this.generateQueryString(params)
        }
        return querystring
    }

    private checkQueryArgs(params: IQueryParams): void {
        // Throws error if a link is generated in code with invalid query params but not if typed in url bar
        for (const param in params) {
            if (!this.allowedQueryParams.includes(param)) {
                throw Error('Passed non-allowed query param')
            }
        }

    }

    private generateQueryString(params: IQueryParams): string {
        return '?' + new URLSearchParams(params).toString()
    }

}

export class PathParam extends PathBase implements IPathParam {
    pathParam: string
    rbacConfig: IDeptLevelConfigList

    constructor(
        path: string, pathParam: string,
        allowedQueryArgs: string[] = [],
    rbacConfig: IDeptLevelConfigList = []
    ) {
        super(path, allowedQueryArgs)
        this.pathParam = pathParam
        this.derivablePaths = []
        this.rbacConfig = rbacConfig
    }

    getDerivedPath(method: string): string {
        throw Error('Do not call getDerivedPath from PathParam class')
    }

    pathSchema(): string {
        return this.path + '/:' + this.pathParam
    }

    getFullPath(pathParamActual: string | number, queryParams:  IQueryParams | undefined = undefined): string {
        const queryString = this.handleQueryParams(queryParams)
        return this.path + '/' + pathParamActual.toString() + queryString
    }
}