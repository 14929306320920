import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../common/cards/BodyCard";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Collapse,
    IconButton,
    Box,
    Typography
} from "@mui/material";
import React from "react";
import NorthEast from '@mui/icons-material/NorthEast'
import SouthEast from "@mui/icons-material/SouthEast";
import East from "@mui/icons-material/East"
import Check from "@mui/icons-material/Check"
import Close from "@mui/icons-material/Close"
import { useTheme } from "@mui/styles";
import { navLinks } from "./navLinks";
import { _GoalOverview } from "./_GoalOverview";
import { ResponsiveContainer, LineChart, CartesianGrid, YAxis, XAxis, Line, Tooltip, ReferenceLine } from "recharts";

import { KeyboardArrowUp, KeyboardArrowDown} from "@mui/icons-material";

const goodArrow = <NorthEast color='success' />
const badArrow = <SouthEast color='warning' />
const check = <Check color='success' />
const ex = <Close color='warning' />


interface IGoalTarget {target: string, type: string}
interface IGoalMeta {type: '$' | '%' | 'number', upGood: boolean}
interface IPeriodType {type: 'weekly'| 'monthly' | 'quarterly' | 'yearly'}
interface IHistoricalPeriod {period: string, value: number}

const asPercent = (value: number) => value.toLocaleString("en", {style: "percent"})

const toGo = (current: number, goal: number, starting: number) => (
    // asPercent((goal - current) / (goal - starting))
    Math.max((goal - current) / (goal - starting), 0)
)

const dateToGo = (target: Date, start: Date) => {
    const now = new Date()
    // @ts-ignore
    // return asPercent((target - now) / (target - start))
    return Math.max((target - now) / (target - start), 0)
}

const getProjected = (value: number, period: IPeriodType['type'], valType: IGoalMeta['type']) => {
    // This doesn't actually work. Not worth building for demo based off dummy data
    if (valType === '%') {return value}
    const randValue = value * (Math.random() + 1)
    return Math.floor(randValue)
}

const getPreviousPeriods = (periodType: IPeriodType['type'], numPeriods: number, rangeLow: number, rangeHigh: number): IHistoricalPeriod[] => {
    const tempQuarters = ['Q2-22', 'Q1-22', 'Q4-21', 'Q3-21']
    const tempMonths = ['September', 'August', 'July', 'June', 'May', 'April']
    let result = []
    for (let iter= 0; iter < numPeriods; iter++) {
        let value
        if (rangeLow > 1) {
            value = Math.floor(
                Math.random() * ((rangeHigh * 1.5) - (rangeLow * 0.5 + 1))) + Math.floor((rangeLow * 0.5)
            )
        } else {
            value = Math.random() * (rangeHigh * 1.5 - rangeLow * 0.5) + rangeLow * 0.5
        }
        if (periodType === 'quarterly') {
            result.push({period: tempQuarters[iter], value: value})
        } else if (periodType === 'monthly') {
            result.push({period: tempMonths[iter], value: value})
        } else {
            result.push({period: 'Unkonwn', value: 0})
        }
    }
    return result
}

// TODO Date parsing is wrong but it was figured out before in task system, use that solution

const makeDummyDataTimed = (type: string, goalOwner: string, KPI: string, target: IGoalTarget, initialValue: number,
                            currentValue: number, targetValue: number, targetDate: Date, initialDate: Date,
                            partnerFacing: boolean, goalMeta: IGoalMeta) => (
    {
        type: type, goalOwner: goalOwner, KPI: KPI, targetName: target.target, targetType: target.type,
        initialValue: initialValue, currentValue: currentValue, targetValue: targetValue, targetDate: targetDate,
        partnerFacing: partnerFacing, goalType: goalMeta.type, upGood: goalMeta.upGood, initialDate: initialDate,
        toGoPercent: toGo(currentValue, targetValue, initialValue),
        togoTime: dateToGo(targetDate, initialDate)
    }
)

const dummyDataTimed = [
    makeDummyDataTimed('Partner', 'A Cheerful Giver','Total Sales',{target: 'Entire Catalog', type: 'Partner'},
        55132,59765, 65000, new Date('2022-11-15'), new Date('2022-10-01'),
        true, {type: '$', upGood: true} ),
    makeDummyDataTimed('Department', 'Copywriting','Title Rewrites',{target: 'General Tools - Hand Tools', type: 'Product Group'},
        0,32, 30, new Date('2022-11-01'), new Date('2022-09-01'),
        false, {type: "number", upGood: true} ),
    makeDummyDataTimed('Partner', 'Bask Candles', 'Total Sales',{target: 'Entire Catalog', type: 'Partner'},
        39538, 42754, 50000, new Date('2022-12-10'), new Date('2022-08-21'),
        true, {type: "$", upGood: true} ),
    makeDummyDataTimed('Nectar', 'Nectar','Total Sales',{target: 'Entire Catalog', type: 'All'},
        27436765,29147395, 29000000, new Date('2022-11-15'), new Date('2022-10-01'),
        false, {type: '$', upGood: true} ),
    makeDummyDataTimed('Employee', 'Daniel Park','ACoS',{target: 'Bear Walk Comforters', type: 'Product Group'},
        0.25,0.23, 0.21, new Date('2022-12-25'), new Date('2022-11-01'),
        true, {type: "%", upGood: false} )
]

const makeDummyDataOngoing = (type: string, goalOwner: string, KPI: string, target: IGoalTarget, prevValue: number,
                              currentValue: number, targetValue: number, targetPeriod: IPeriodType['type'], initialDate: Date,
                              partnerFacing: boolean, goalMeta: IGoalMeta, numHistorical: number) => (
    {
        type: type, goalOwner: goalOwner, KPI: KPI, targetName: target.target, targetType: target.type,
        prevValue: prevValue, currentValue: currentValue, targetValue: targetValue, targetPeriod: targetPeriod,
        partnerFacing: partnerFacing, goalType: goalMeta.type, upGood: goalMeta.upGood, initialDate: initialDate,
        projected: getProjected(currentValue, targetPeriod, goalMeta.type),
        collapseData: getPreviousPeriods(targetPeriod, numHistorical, prevValue, targetValue)
    }
)

const dummyDataOngoing = [
    makeDummyDataOngoing('Department', 'Graphic Design','Storefront Designs',{target: 'All', type: 'All'},
        5,4, 7, 'quarterly', new Date('2022-06-02'),
        false, {type: "number", upGood: true}, 4),
    makeDummyDataOngoing('Employee', 'Carlos Bonilla','ACoS',{target: 'Turkey Brine', type: 'Product Group'},
        0.20,0.19, 0.21, 'monthly', new Date('2022-06-02'),
        true, {type: "%", upGood: false}, 6),
    makeDummyDataOngoing('Partner', 'Mushroom & Co.','Sales',{target: 'Entire catalog', type: 'Partner'},
        12000,11000, 18000, 'monthly', new Date('2022-08-02'),
        true, {type: "$", upGood: true}, 6)
]


export const _GoalsTable = () => {
    const ExpandRow = (props: {goal: ReturnType<typeof makeDummyDataOngoing>}) => {
        const { goal } = props
        const [open, setOpen] = React.useState(false)

        return (
            <>
                <TableRow>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </TableCell>
                    <TableCell>{goal.type}</TableCell>
                    <TableCell>{goal.goalOwner}</TableCell>
                    <TableCell>{goal.KPI}</TableCell>
                    <TableCell>{goal.targetName}</TableCell>
                    <TableCell>{goal.targetType}</TableCell>
                    <TableCell>{toCapital(goal.targetPeriod)}</TableCell>
                    <TableCell>{goal.initialDate.toLocaleDateString()}</TableCell>
                    {returnColored(goal.prevValue, goal.targetValue, goal.upGood, goal.goalType)}
                    {returnColored(goal.currentValue, goal.targetValue, goal.upGood, goal.goalType)}
                    {returnColored(goal.projected, goal.targetValue, goal.upGood, goal.goalType)}
                    <TableCell>{goal.goalType === '$' ? asMoney(goal.targetValue) : goal.goalType === '%' ? asPercent(goal.targetValue) : goal.targetValue}</TableCell>
                    <TableCell>{returnTrendArrow(goal.projected, goal.prevValue, goal.upGood)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Previous Periods
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Period</TableCell>
                                            <TableCell>Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {goal.collapseData.map((collapseRow, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {collapseRow.period}
                                                </TableCell>
                                                {returnColored(
                                                    collapseRow.value, goal.targetValue, goal.upGood, goal.goalType)
                                                }
                                            </TableRow>
                                        ))}
                                        <ResponsiveContainer width='100%' height={250}>
                                            <LineChart data={goal.collapseData}
                                                       margin={{top: 5, right: 50, left: 15, bottom: 5}}
                                            >
                                                <CartesianGrid strokeDasharray='3 3' />
                                                <XAxis dataKey='period' reversed />
                                                <YAxis />
                                                <Tooltip />
                                                <Line dataKey='value' />
                                                <ReferenceLine y={goal.targetValue} label='Target' stroke='red' strokeDasharray='3 3'/>
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        )
    }

    const theme = useTheme()

    const toCapital = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

    const asMoney = (value: number) =>
        value.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })

    const getOnTrack = (completion: number, time: number) => {
        if (time >= completion) {return check}
        return ex
    }

    const returnColored = (value: number, comparison: number, goodUp: boolean, type: IGoalMeta['type']) => {
        const isGood = goodUp ? value > comparison : comparison > value
        return (
            // @ts-ignore
            <TableCell sx={{color: isGood ? theme.palette.success.main : theme.palette.error.main}} >
                {type === '$' ? asMoney(value) : type === '%' ? asPercent(value) : value}
            </TableCell>
        )
    }

    const returnTrendArrow = (value: number, comparison: number, upGood: boolean) => {
        if (value === comparison) {return <East />}
        const isUp = upGood ? value > comparison : value < comparison
        return isUp ? goodArrow : badArrow
    }

    const timeLineTable = (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Goal Type</TableCell>
                        <TableCell>Goal Owner</TableCell>
                        <TableCell>KPI</TableCell>
                        <TableCell>Target Name</TableCell>
                        <TableCell>Target Type</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>Initial Value</TableCell>
                        <TableCell>Current Value</TableCell>
                        <TableCell>Target Value</TableCell>
                        <TableCell>% To Go</TableCell>
                        <TableCell>% Of Time To Go</TableCell>
                        <TableCell>Target Date</TableCell>
                        <TableCell>On Track</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dummyDataTimed.map((goal, index) =>
                        <TableRow key={goal.type + index}>
                            <TableCell>{goal.type}</TableCell>
                            <TableCell>{goal.goalOwner}</TableCell>
                            <TableCell>{goal.KPI}</TableCell>
                            <TableCell>{goal.targetName}</TableCell>
                            <TableCell>{goal.targetType}</TableCell>
                            <TableCell>{goal.initialDate?.toLocaleDateString()}</TableCell>
                            <TableCell>
                                {goal.goalType === '$' ? asMoney(goal.initialValue) : goal.goalType === '%' ? asPercent(goal.initialValue) : goal.initialValue}
                            </TableCell>
                            {returnColored(goal.currentValue, goal.targetValue, goal.upGood, goal.goalType)}
                            <TableCell>
                                {goal.goalType === '$' ? asMoney(goal.targetValue) : goal.goalType === '%' ? asPercent(goal.targetValue) : goal.targetValue}
                            </TableCell>
                            <TableCell>{asPercent(goal.toGoPercent)}</TableCell>
                            <TableCell>{asPercent(goal.togoTime)}</TableCell>
                            <TableCell>{goal.targetDate?.toLocaleDateString()}</TableCell>
                            <TableCell>{getOnTrack(goal.toGoPercent, goal.togoTime)}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )

    const ongoingTable = (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Goal Type</TableCell>
                        <TableCell>Goal Owner</TableCell>
                        <TableCell>KPI</TableCell>
                        <TableCell>Target Name</TableCell>
                        <TableCell>Target Type</TableCell>
                        <TableCell>Period Type</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>Prev Period</TableCell>
                        <TableCell>Current Period</TableCell>
                        <TableCell>Projected*</TableCell>
                        <TableCell>Target Value</TableCell>
                        <TableCell>Trend</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dummyDataOngoing.map((goal, index) =>
                        <ExpandRow goal={goal} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )

    const overview = (
        <_GoalOverview />
    )

    const overviewCard = <BodyCard header={{title: 'Goals Overview'}} body={overview}/>
    const timeLineCard = <BodyCard header={{title: 'Timeline Goals'}} body={timeLineTable}/>
    const ongoingCard = <BodyCard header={{title: 'Ongoing Goals'}} body={ongoingTable}/>

    const cardBody = (
        <Stack>{overviewCard}{timeLineCard}{ongoingCard}</Stack>
    )

    return (
        <HeaderSideBarBody body={cardBody} pageTitle='View Goals' navLinks={navLinks}/>
    )
}