import { useState, useEffect } from "react";

export const usePersistedPartner = () => {
    // Could be generalized to take lookup keys for different values
    // From: https://www.joshwcomeau.com/react/persisting-react-state-in-localstorage/
    const [value, setValue] = useState(() => {
        const stickyValue = window.localStorage.getItem('selectedPartner');
        return stickyValue !== null
            ? JSON.parse(stickyValue)
            : '';
    });
    useEffect(() => {
        window.localStorage.setItem('selectedPartner', JSON.stringify(value));
    }, [value]);
    return [value, setValue];
}