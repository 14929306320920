import { HeaderSideBarBody } from "../../../layout/headerSideBarBody/HeaderSideBarBody";
import {
    useApiCorePartnerIntakeCreateMutation,
    PartnerIntakeRequest,
    PartnerListRequest
} from "../../../../services/generatedApi";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { MakeTextFormField } from "../../../common/forms/BaseForm/fields/TextFormField";
import { MakeEnumSelectFormField } from "../../../common/forms/BaseForm/fields/EnumSelectFormField";
import { BaseApiForm } from "../../../common/forms/BaseForm/BaseAPIForm";
import { BodyCard } from "../../../common/cards/BodyCard";
import { AppURLS } from "../../../../appURLS";


interface IFormInput extends PartnerIntakeRequest {}

export const PartnerIntakeForm = () => {

    const initialValues: IFormInput = {
        // Ignores so that number can be blank instead of 0. See about doing this more elegantly
        // Possibly through a "Blank Number" bool prop to TextFormField
        name: '',
        contract_link: '',
        //@ts-ignore
        partner_type: undefined,
        onboarding_contact_first: '',
        onboarding_contact_last: '',
        onboarding_contact_email: '',
        // @ts-ignore
        num_ad_accounts: undefined,
        // @ts-ignore
        num_sc_accounts: undefined,
        // @ts-ignore
        num_vc_accounts: undefined,
        pain_points: '',
        notes: undefined,
    }
    const [ createPartnerIntake ] = useApiCorePartnerIntakeCreateMutation()
    const makeTextFormField = MakeTextFormField
    const makeEnumSelectFormField = MakeEnumSelectFormField
    const { control, ...useFormRest } = useForm<IFormInput>({defaultValues: initialValues});
    const formStateHooks = useState<IFormInput>(initialValues)

    const createPartnerIntakeAction = (formInput: IFormInput) => (
        createPartnerIntake(
            {partnerIntakeRequest: {...formInput}}
        )
    )
    // Replace this code duplicated between this and Partner Create form when true Enums generated
    interface IPartnerType {
        name: 'Full Management' | 'Advertising Only'
        value: PartnerListRequest['partner_type']
    }

    const partnerTypeChoices: IPartnerType[] = [
        {name: 'Full Management', value: 'FM'},
        {name: 'Advertising Only', value: 'AD'}
    ]

    const fields = [
        makeTextFormField(control, 'name', 'Partner Name', true),
        makeTextFormField(control, 'contract_link', 'Contract Link', true),
        makeEnumSelectFormField(
            control,
            'partner_type',
            'Partner Type',
            partnerTypeChoices,
            true
        ),
        makeTextFormField(
            control,
            'onboarding_contact_first',
            'Onboarding Contact First Name',
            true
        ),
        makeTextFormField(
            control,
            'onboarding_contact_last',
            'Onboarding Contact Last Name',
            true
        ),
        makeTextFormField(
            control,
            'onboarding_contact_email',
            'Onboarding Contact Email',
            true
        ),
        makeTextFormField(
            control,
            'num_ad_accounts',
            'Number of Amz Ad Accounts',
            true,
            true),
        makeTextFormField(
            control,
            'num_sc_accounts',
            'Number of Seller Central Accounts',
            true,
            true),
        makeTextFormField(
            control,
            'num_vc_accounts',
            'Number of Vendor Central Accounts',
            true,
            true),
        makeTextFormField(control, 'pain_points', 'Specific Pain Points', true),
        makeTextFormField(control, 'notes', 'Additional Notes', false)
    ]

    const form = (
        <BaseApiForm
            recordName='Partner Intake'
            formActionType='Create'
            fields={fields}
            useFormRest={useFormRest}
            formStateHooks={formStateHooks}
            // @ts-ignore
            formAPIAction={createPartnerIntakeAction}
            formSuccessRedirectPathParam={AppURLS.detailPartnerIntake}
        />
    )

    const cardBody = <BodyCard header={{title: 'Submit Partner Intake Details'}} body={form}/>

    return (
       <HeaderSideBarBody body={cardBody} pageTitle="Partner Intake Form"/>
    )
}