import React from "react";
import { Stack, Typography } from "@mui/material";
import { HeaderNavList } from "../HeaderNavList/HeaderNavList";
import { UserMenu } from "../UserMenu/UserMenu";
import { PartnerSelect } from "../PartnerSelect/PartnerSelect";
import { IHeaderNavListProps } from "../HeaderNavList/HeaderNavList";
import { Box } from "@mui/material"
import { AlertMenu } from "../AlertMenu";


export interface IHeaderProps {
    page_title?: string
    navLinks?: IHeaderNavListProps['links']
}

export const Header = (props: IHeaderProps) => {
    const styles = {
        header: {
            backgroundColor: '#A0CC73',
        },
        controlBox: {
            mt: 'auto',
            mb: 'auto'
        },
        titleNav: {
            'textAlign': 'left',
        },
        notificationIcon:{
            paddingLeft: 1
        },
        partner: {
            margin: "auto 0 auto auto",
            '& .MuiFormControl-root': {
                minWidth: '8em',
                margin: 'auto'
            }
        }
    } as const;

    return (
        <Stack direction='row' justifyContent='space-between' sx={styles.header}>
            <Box>
                <Typography sx={styles.titleNav} variant='h4'>
                    {props.page_title}
                </Typography>
                <HeaderNavList links={props.navLinks}/>
            </Box>
            <Box sx={styles.controlBox}>
                <Stack direction='row' justifyContent='center' alignItems='center'>
                    <PartnerSelect sx={styles.partner} />
                    <AlertMenu sx={styles.notificationIcon} />
                    <UserMenu />
                </Stack>
            </Box>
        </Stack>
    )
};