import { BaseApiForm } from "../../../common/forms/BaseForm/BaseAPIForm";
import { MakeTextFormField } from "../../../common/forms/BaseForm/fields/TextFormField";
import { AmzAccountCreateRequest, useApiCoreAmzAccountsCreateMutation } from "../../../../services/generatedApi";
import { useForm } from "react-hook-form";
import { useState } from "react";

export interface IAmzAccountCreateFormProps {
    accountType: 'SC' | 'VC'
    partnerId: AmzAccountCreateRequest['partner_id']
    refetch: () => void
}
interface IFormInput extends Omit<AmzAccountCreateRequest, 'partner_id' | "is_sc" | 'is_vc'> {}

export const AmzAccountCreateForm = (props: IAmzAccountCreateFormProps) => {
    const initialValues: IFormInput = {name: ''}
    const [ createAmzAccount ] = useApiCoreAmzAccountsCreateMutation()
    const makeTextFormField = MakeTextFormField
    const { control, ...useFormRest } = useForm<IFormInput>({defaultValues: initialValues});
    const formStateHooks = useState<IFormInput>(initialValues)

    const createAmzAccountAction = (formInput: IFormInput) => {
        const is_sc = props.accountType === 'SC'
        const is_vc = props.accountType === 'VC'
        return createAmzAccount(
            {
                amzAccountCreateRequest: {
                    partner_id: props.partnerId,
                    is_sc: is_sc,
                    is_vc: is_vc,
                    ...formInput
                }
            })
    }

    const fields = [
        makeTextFormField(control, 'name', 'Account Name', true)
    ]

    const form = (
        <BaseApiForm
            recordName={`Amazon ${props.accountType} Account`}
            formActionType='Create'
            fields={fields}
            useFormRest={useFormRest}
            formStateHooks={formStateHooks}
            // @ts-ignore
            formAPIAction={createAmzAccountAction}
            refetch={props.refetch}
            resetAfterSubmit={true}
        />
    )

    return (
        form
    )
}