import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../common/cards/BodyCard";
import {
    useApiGoalsOngoingGoalsListQuery,
    useApiGoalsTimelineGoalsListQuery,
    ApiGoalsOngoingGoalsListApiResponse,
    ApiGoalsTimelineGoalsListApiResponse,
    useApiGoalsGoalTypesListQuery,
    ApiGoalsGoalTypesListApiResponse,
    GoalList,
    OngoingGoalList
} from "../../../services/generatedApi";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Collapse,
    IconButton,
    Box,
    Typography
} from "@mui/material";
import { goalTypeNameFromId } from "../../../util/FormUtil";
import React from "react";
import { returnTrendArrow, returnColoredCell, formatValue, dateToGo, toGo  // onTrackFromPerformance,
} from "../../../util/ChartUtils";
import { navLinks } from "./navLinks";
import { GoalOverview } from "./GoalOverview";
import { ResponsiveContainer, LineChart, CartesianGrid, YAxis, XAxis, Line, Tooltip, ReferenceLine } from "recharts";

import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";

interface IPeriodType {type: 'weekly'| 'monthly' | 'quarterly' | 'yearly'}
interface IHistoricalPeriod {period: string, value: number}


//TODO Probably need the below handled
const getPreviousPeriods = (periodType: IPeriodType['type'], numPeriods: number, rangeLow: number, rangeHigh: number): IHistoricalPeriod[] => {
    const tempQuarters = ['Q2-22', 'Q1-22', 'Q4-21', 'Q3-21']
    const tempMonths = ['September', 'August', 'July', 'June', 'May', 'April']
    let result = []
    for (let iter= 0; iter < numPeriods; iter++) {
        let value
        if (rangeLow > 1) {
            value = Math.floor(
                Math.random() * ((rangeHigh * 1.5) - (rangeLow * 0.5 + 1))) + Math.floor((rangeLow * 0.5)
            )
        } else {
            value = Math.random() * (rangeHigh * 1.5 - rangeLow * 0.5) + rangeLow * 0.5
        }
        if (periodType === 'quarterly') {
            result.push({period: tempQuarters[iter], value: value})
        } else if (periodType === 'monthly') {
            result.push({period: tempMonths[iter], value: value})
        } else {
            result.push({period: 'Unkonwn', value: 0})
        }
    }
    return result
}

// TODO Date parsing is wrong but it was figured out before in task system, use that solution

// const makeDummyDataTimed = (type: string, goalOwner: string, KPI: string, target: IGoalTarget, initialValue: number,
//                             currentValue: number, targetValue: number, targetDate: Date, initialDate: Date,
//                             partnerFacing: boolean, goalMeta: IGoalMeta) => (
//     {
//         type: type, goalOwner: goalOwner, KPI: KPI, targetName: target.target, targetType: target.type,
//         initialValue: initialValue, currentValue: currentValue, targetValue: targetValue, targetDate: targetDate,
//         partnerFacing: partnerFacing, goalType: goalMeta.type, upGood: goalMeta.upGood, initialDate: initialDate,
//         toGoPercent: toGo(currentValue, targetValue, initialValue),
//         togoTime: dateToGo(targetDate, initialDate)
//     }
// )

// const dummyDataTimed = [
//     makeDummyDataTimed('Partner', 'A Cheerful Giver','Total Sales',{target: 'Entire Catalog', type: 'Partner'},
//         55132,59765, 65000, new Date('2022-11-15'), new Date('2022-10-01'),
//         true, {type: '$', upGood: true} ),
//     makeDummyDataTimed('Department', 'Copywriting','Title Rewrites',{target: 'General Tools - Hand Tools', type: 'Product Group'},
//         0,32, 30, new Date('2022-11-01'), new Date('2022-09-01'),
//         false, {type: "number", upGood: true} ),
//     makeDummyDataTimed('Partner', 'Bask Candles', 'Total Sales',{target: 'Entire Catalog', type: 'Partner'},
//         39538, 42754, 50000, new Date('2022-12-10'), new Date('2022-08-21'),
//         true, {type: "$", upGood: true} ),
//     makeDummyDataTimed('Nectar', 'Nectar','Total Sales',{target: 'Entire Catalog', type: 'All'},
//         27436765,29147395, 29000000, new Date('2022-11-15'), new Date('2022-10-01'),
//         false, {type: '$', upGood: true} ),
//     makeDummyDataTimed('Employee', 'Daniel Park','ACoS',{target: 'Bear Walk Comforters', type: 'Product Group'},
//         0.25,0.23, 0.21, new Date('2022-12-25'), new Date('2022-11-01'),
//         true, {type: "%", upGood: false} )
// ]

// const makeDummyDataOngoing = (type: string, goalOwner: string, KPI: string, target: IGoalTarget, prevValue: number,
//                               currentValue: number, targetValue: number, targetPeriod: IPeriodType['type'], initialDate: Date,
//                               partnerFacing: boolean, goalMeta: IGoalMeta, numHistorical: number) => (
//     {
//         type: type, goalOwner: goalOwner, KPI: KPI, targetName: target.target, targetType: target.type,
//         prevValue: prevValue, currentValue: currentValue, targetValue: targetValue, targetPeriod: targetPeriod,
//         partnerFacing: partnerFacing, goalType: goalMeta.type, upGood: goalMeta.upGood, initialDate: initialDate,
//         projected: getProjected(currentValue, targetPeriod, goalMeta.type),
//         collapseData: getPreviousPeriods(targetPeriod, numHistorical, prevValue, targetValue)
//     }
// )
//
// const dummyDataOngoing = [
//     makeDummyDataOngoing('Department', 'Graphic Design','Storefront Designs',{target: 'All', type: 'All'},
//         5,4, 7, 'quarterly', new Date('2022-06-02'),
//         false, {type: "number", upGood: true}, 4),
//     makeDummyDataOngoing('Employee', 'Carlos Bonilla','ACoS',{target: 'Turkey Brine', type: 'Product Group'},
//         0.20,0.19, 0.21, 'monthly', new Date('2022-06-02'),
//         true, {type: "%", upGood: false}, 6),
//     makeDummyDataOngoing('Partner', 'Mushroom & Co.','Sales',{target: 'Entire catalog', type: 'Partner'},
//         12000,11000, 18000, 'monthly', new Date('2022-08-02'),
//         true, {type: "$", upGood: true}, 6)
// ]


export const GoalsTable = () => {
    // const ongoingGoalsRes = useApiGoalsOngoingGoalsListQuery()
    // const timelineGoalsRes = useApiGoalsTimelineGoalsListQuery()
    // const goalTypesRes = useApiGoalsGoalTypesListQuery()
    //
    // let ongoingGoals: ApiGoalsOngoingGoalsListApiResponse | [] = []
    // if (ongoingGoalsRes.isSuccess) {
    //     ongoingGoals = ongoingGoalsRes.data
    // }
    //
    // let timelineGoals: ApiGoalsTimelineGoalsListApiResponse | [] = []
    // if (timelineGoalsRes.isSuccess) {
    //     timelineGoals = timelineGoalsRes.data
    // }
    //
    // let goalTypes: ApiGoalsGoalTypesListApiResponse | [] = []
    // if (goalTypesRes.isSuccess) {
    //     goalTypes = goalTypesRes.data
    // }
    //
    // const getOngoingPrevious = (goal: OngoingGoalList) => {
    //     if (goal.prev_results && goal.prev_results.length > 0) {
    //         return goal.prev_results.sort((a, b) => {
    //             // TODO Test this
    //             // + (unary plus) converts date to a number
    //             return +new Date(a.data_end_date) - +new Date(b.data_end_date)
    //     })[0].value
    //     } else {
    //         return goal.initial_value
    //     }
    // }
    //
    // const ExpandRow = (props: {goal: ApiGoalsOngoingGoalsListApiResponse[number]}) => {
    //     const { goal } = props
    //     const [open, setOpen] = React.useState(false)
    //
    //     console.log(goal)
    //
    //     return (
    //         <>
    //             <TableRow>
    //                 <TableCell>
    //                     <IconButton
    //                         aria-label="expand row"
    //                         size="small"
    //                         onClick={() => setOpen(!open)}
    //                     >
    //                         {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    //                     </IconButton>
    //                     {goal.name}
    //                 </TableCell>
    //                 <TableCell>{goalTypeNameFromId(goalTypes, goal.goal_type)}</TableCell>
    //                 <TableCell>{goal.goal_owners.map((owner) => <>{owner.string_name}<br /></>)}</TableCell>
    //                 <TableCell>{goal.goal_targets.map((target) => <>{target.string_name}<br /></>)}</TableCell>
    //                 <TableCell>{goal.goal_targets.map((target) => <>{target.target_type}<br /></>)}</TableCell>
    //                 <TableCell>{goal.start_date}</TableCell>
    //                 <TableCell>{getOngoingPrevious(goal)}</TableCell>
    //                 <TableCell>{goal.current_result.value}</TableCell>
    //                 <TableCell>{goal.current_result.projected_value}</TableCell>
    //                 <TableCell>{goal.target_value}</TableCell>
    //                 <TableCell>{returnTrendArrow(
    //                     goal.current_result.projected_value,
    //                     getOngoingPrevious(goal),
    //                     goal.target_value
    //                 )
    //                 }</TableCell>
    //             </TableRow>
    //             <TableRow>
    //                 <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
    //                     <Collapse in={open} timeout="auto" unmountOnExit>
    //                         <Box sx={{ margin: 1 }}>
    //                             <Typography variant="h6" gutterBottom component="div">
    //                                 Previous Periods
    //                             </Typography>
    //                             <Table size="small" aria-label="purchases">
    //                                 <TableHead>
    //                                     <TableRow>
    //                                         <TableCell>Period</TableCell>
    //                                         <TableCell>Value</TableCell>
    //                                     </TableRow>
    //                                 </TableHead>
    //                                 {/*TODO Parse Prev Periods*/}
    //                                 {/*<TableBody>*/}
    //                                 {/*    {goal.collapseData.map((collapseRow, index) => (*/}
    //                                 {/*        <TableRow key={index}>*/}
    //                                 {/*            <TableCell component="th" scope="row">*/}
    //                                 {/*                {collapseRow.period}*/}
    //                                 {/*            </TableCell>*/}
    //                                 {/*            {returnColoredCell(*/}
    //                                 {/*                collapseRow.value, goal.targetValue, goal.upGood, goal.goalType)*/}
    //                                 {/*            }*/}
    //                                 {/*        </TableRow>*/}
    //                                 {/*    ))}*/}
    //                                 {/*    <ResponsiveContainer width='100%' height={250}>*/}
    //                                 {/*        <LineChart data={goal.collapseData}*/}
    //                                 {/*                   margin={{top: 5, right: 50, left: 15, bottom: 5}}*/}
    //                                 {/*        >*/}
    //                                 {/*            <CartesianGrid strokeDasharray='3 3' />*/}
    //                                 {/*            <XAxis dataKey='period' reversed />*/}
    //                                 {/*            <YAxis />*/}
    //                                 {/*            <Tooltip />*/}
    //                                 {/*            <Line dataKey='value' />*/}
    //                                 {/*            <ReferenceLine y={goal.targetValue} label='Target' stroke='red' strokeDasharray='3 3'/>*/}
    //                                 {/*        </LineChart>*/}
    //                                 {/*    </ResponsiveContainer>*/}
    //                                 {/*</TableBody>*/}
    //                             </Table>
    //                         </Box>
    //                     </Collapse>
    //                 </TableCell>
    //             </TableRow>
    //         </>
    //     )
    // }
    //
    // const timeLineTable = (
    //     <TableContainer>
    //         <Table>
    //             <TableHead>
    //                 <TableRow>
    //                     <TableCell>Goal Name</TableCell>
    //                     <TableCell>Goal Type</TableCell>
    //                     <TableCell>Goal Owner(s)</TableCell>
    //                     <TableCell>Target Name</TableCell>
    //                     <TableCell>Target Type</TableCell>
    //                     <TableCell>Start Date</TableCell>
    //                     <TableCell>Eval Date</TableCell>
    //                     <TableCell>Prev Period</TableCell>
    //                     <TableCell>Current Value</TableCell>
    //                     <TableCell>Target Value</TableCell>
    //                     <TableCell>Projected Value</TableCell>
    //                     <TableCell>% To Go**</TableCell>
    //                     <TableCell>% Of Time To Go</TableCell>
    //                     <TableCell>Target Date</TableCell>
    //                     <TableCell>On Track</TableCell>
    //                 </TableRow>
    //             </TableHead>
    //             <TableBody>
    //                 {timelineGoals.map((goal) =>
    //                     <TableRow key={goal.id}>
    //                         <TableCell>{goal.name}</TableCell>
    //                         <TableCell>{goalTypeNameFromId(goalTypes, goal.goal_type)}</TableCell>
    //                         <TableCell>
    //                             {goal.goal_owners.map((owner) => <>{owner.string_name}<br /></>)}
    //                         </TableCell>
    //                         <TableCell>
    //                             {/*TODO Parse object type and make a link?*/}
    //                             {goal.goal_targets.map((target) => <>{target.string_name}<br /></>)}
    //                         </TableCell>
    //                         <TableCell>
    //                             {goal.goal_targets.map((target) => <>{target.target_type}<br /></>)}
    //                         </TableCell>
    //                         <TableCell>{goal.start_date}</TableCell>
    //                         <TableCell>{goal.last_result?.data_end_date ?? 'N/A'}</TableCell>
    //                         <TableCell>{formatValue(goal.initial_value, goal.goal_type, goalTypes)}</TableCell>
    //                             {goal.last_result ? returnColoredCell(
    //                                 goal.initial_value, goal.last_result.value, goal.target_value,
    //                                 goal.goal_type, goalTypes)
    //                                 : <TableCell>N/A</TableCell>
    //                             }
    //                         <TableCell>{formatValue(goal.target_value, goal.goal_type, goalTypes)}</TableCell>
    //                         {goal.last_result ? returnColoredCell(
    //                                 goal.initial_value, goal.last_result.projected_value, goal.target_value,
    //                                 goal.goal_type, goalTypes)
    //                             : <TableCell>N/A</TableCell>
    //                         }
    //                         <TableCell>{
    //                             goal.last_result ?
    //                                 toGo(goal.last_result.value, goal.target_value, goal.initial_value)
    //                                 : 'N/A'
    //                         }</TableCell>
    //                         <TableCell>{goal.last_result && goal.end_date
    //                             ? dateToGo(goal.end_date, goal.start_date, goal.last_result.data_end_date)
    //                             : 'N/A'}
    //                         </TableCell>
    //                         <TableCell>{goal.end_date}</TableCell>
    //                         <TableCell>{
    //                             goal.last_result
    //                                 ? onTrackFromPerformance(goal.last_result.performance)
    //                                 : 'N/A'
    //                         }</TableCell>
    //                     </TableRow>
    //                 )}
    //             </TableBody>
    //         </Table>
    //     </TableContainer>
    // )
    //
    // const ongoingTable = (
    //     <TableContainer>
    //         <Table>
    //             <TableHead>
    //                 <TableRow>
    //                     <TableCell>Goal Name</TableCell>
    //                     <TableCell>Goal Type</TableCell>
    //                     <TableCell>Goal Owner</TableCell>
    //                     <TableCell>Target Name</TableCell>
    //                     <TableCell>Target Type</TableCell>
    //                     <TableCell>Start Date</TableCell>
    //                     <TableCell>Prev Period</TableCell>
    //                     <TableCell>Current Period</TableCell>
    //                     <TableCell>Projected*</TableCell>
    //                     <TableCell>Target Value</TableCell>
    //                     <TableCell>Trend</TableCell>
    //                 </TableRow>
    //             </TableHead>
    //             <TableBody>
    //                 {ongoingGoals.map((goal, index) =>
    //                     <ExpandRow goal={goal} />
    //                 )}
    //             </TableBody>
    //         </Table>
    //     </TableContainer>
    // )
    //
    // const overview = (
    //     <GoalOverview />
    // )
    //
    // const overviewCard = <BodyCard header={{title: 'Goals Overview'}} body={overview}/>
    // const timeLineCard = <BodyCard header={{title: 'Timeline Goals'}} body={timeLineTable}/>
    // const ongoingCard = <BodyCard header={{title: 'Ongoing Goals'}} body={ongoingTable}/>
    //
    // const cardBody = (
    //     // <Stack>{overviewCard}{timeLineCard}{ongoingCard}</Stack>
    //     <Stack>{timeLineCard}{ongoingCard}</Stack>
    // )
    //
    // return (
    //     <HeaderSideBarBody body={cardBody} pageTitle='View Goals' navLinks={navLinks}/>
    // )
    return <>Not Implemented</>
}