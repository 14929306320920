import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from '@reduxjs/toolkit/query'
import { setToken, handleLogOut } from "./auth/authSlice"
import { AuthTokenRefreshCreateApiResponse } from "./generatedApi"
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import type { RootState } from "../store/store";
import Cookies from "universal-cookie";


const baseUrl = process.env.REACT_APP_API_BASE
const isResponse = (x: any): x is AuthTokenRefreshCreateApiResponse => x ? 'access' in x : false;
const authCookie = new Cookies().get('auth')
export const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.access_token
        if (token) {
            headers.set('authorization', `JWT ${token}`)
        }
        return headers
    }
})


export const baseQueryWithReAuth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
    > = async (args, api, extraOptions) => {

    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        // Try to get a new token
        const refreshResult: QueryReturnValue<AuthTokenRefreshCreateApiResponse | unknown> = await baseQuery(
            {url: '/auth/token/refresh/',
            method: 'POST',
            body: {refresh: authCookie}}, api, extraOptions)
        if (isResponse(refreshResult.data)) {
            api.dispatch(setToken({token: refreshResult.data.access!}))
            // Retry the initial query
            try {
                result = await baseQuery(args, api, extraOptions)
            } catch (error) {
                api.dispatch(handleLogOut)
            }
        } else {
            api.dispatch(handleLogOut)
        }
    }
    return result
}