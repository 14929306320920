import { NavLink } from "react-router-dom";
import { List, ListItem, Stack } from "@mui/material";

export interface ILink {
    to: string
    label: string
    end?: boolean
}

export interface IHeaderNavListProps {
    links?: Array<ILink>
}

const styles = {
    activeStyle: {
        color: 'blue',
        fontSize:'1.5em'
    },
    liStyle: {
        marginRight: '2em'
    },
    NavLinkStyle: {
        whiteSpace: 'nowrap' as const
    }
}

export const HeaderNavList = (props: IHeaderNavListProps) => {

    return (
        <List sx={styles.liStyle}>
            <Stack direction='row'>
                {props.links?.map((link, i) => {
                    return (
                        <ListItem key={i}>
                            <NavLink end={!!link.end}
                                     to={link.to}
                                     key={link.to}
                                     style={({ isActive }) => ({
                                         ...styles.NavLinkStyle,
                                         ...(isActive ? styles.activeStyle : null),
                                     })}
                            >
                                {link.label}
                            </NavLink>
                        </ListItem>
                    )
                })}
            </Stack>
        </List>
    )
};