import { ISideBarNavGroupItem } from "../SideBarNavGroup/SideBarNavGroup";
import { PropsWithChildren } from "react";
import { RbacComponent } from "../../../rbac/RbacComponent";
import { UserLevelCheck } from "../../../rbac/UserLevelCheck";
import { useRbac } from "../../../../util/useRbac";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";

export interface ISidebarNavModuleItemProps {
    item: ISideBarNavGroupItem['children'][number]
}

const styles = {
    nested: {
        paddingLeft: 4
    }
}

export const SideBarNavModuleItem = (props: ISidebarNavModuleItemProps) => {
    const [ canView ] = useRbac(props.item.rbacConfig ?? 'skip')

    const ConditionalRenderItem = ((
        {children, item} : {children : PropsWithChildren['children'], item: ISideBarNavGroupItem['children'][number]}
    ) => {
        if (item.rbacConfig) {
            return (
                <RbacComponent renderCheck={canView}>
                    {children}
                </RbacComponent>
            )
        } else if (item.userLevel) {
            return (
                <UserLevelCheck attrCheck={item.userLevel.attrCheck}>
                    {children}
                </UserLevelCheck>
            )
        } else {
            return <>{children}</>
        }
    })

    return (
        <ConditionalRenderItem item={props.item}>
            <ListItem key={props.item.subName} sx={styles.nested}>
                <ListItemButton
                    component={RouterLink}
                    to={props.item.link}>
                    <ListItemText primary={props.item.subName}/>
                </ListItemButton>
            </ListItem>
        </ConditionalRenderItem>
    )
}