import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { HeaderSideBarBody } from "../../../layout/headerSideBarBody/HeaderSideBarBody";
import { DatePicker } from "@mui/x-date-pickers";
import { BodyCard } from "../../../common/cards/BodyCard";
import { useSelector } from "react-redux";
import { selectDataDates } from "../../../../services/data/dataSlice";
import {
    ApiBqDashboardDataListApiResponse,
    ApiCoreAllPartnerAccountsListApiResponse,
    DashboardDataResult,
    DashboardRequestRequest,
    GoalType,
    PartnerList,
    TimeLineGoalList,
    useApiBqDashboardDataListQuery,
    useApiCoreAllPartnerAccountsListQuery,
    useApiCorePartnersListQuery,
    useApiGoalsGoalTypesListQuery,
    useApiGoalsTimelineGoalsListQuery
} from "../../../../services/generatedApi";
import { ExecutiveLandingDashTable } from "./ExecutiveLandingDashTable";
import { Backdrop, CircularProgress } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { navLinks } from "../navLinks";

interface iPartnerAccountConfig {
    name: string
    nectarId: string
    iwId: string  //number? - Query doesn't like numbers
    isSc: boolean
    customStrategy: boolean
    errorMessage?: string
    data?: DashDataCarrier
    salesGoal?: IPartnerGoalInfo
    spendGoal?: IPartnerGoalInfo
    tacosGoal?: IPartnerGoalInfo
}

interface IPartnerGoalInfo {
    projection?: string
    lowTarget?: string
    lowPerformance?: string
    middleTarget?: string
    middlePerformance?: string
    highTarget?: string
    highPerformance?: string
}

export interface IPartnerConfig {
    name: string
    partnerId: string
    accounts: iPartnerAccountConfig[]
    salesGoal?: IPartnerGoalInfo
    spendGoal?: IPartnerGoalInfo
    tacosGoal?: IPartnerGoalInfo
}

interface dashDataNode {
    sales?: number
    shippedRevenue?: number
    adSpend?: number
    tacos?: number
}

interface DashDataCarrier {
    current: dashDataNode
    comparison?: dashDataNode
}

// TODO Handle Routing and permissions, Managers and above, as well as Management and Analyst departments

export const ExecutiveLandingDash = () => {
    const today = dayjs()
    const [ partnerIds, setPartnerIds ] = useState<PartnerList['id'][]>()
    const [ partnerConfig, setPartnerConfig ] = useState<IPartnerConfig[]>([])
    const dataDates = useSelector(selectDataDates)
    const [ month, setMonth ] = useState<Date | null>(
        dataDates?.latestSCSalesData ? new Date(dataDates.latestSCSalesData) : today.toDate()
    )
    const allGoalsRes = useApiGoalsTimelineGoalsListQuery(
        {endDate: dayjs(month).endOf('month').format('YYYY-MM-DD')}
    )
    const goalTypesRes = useApiGoalsGoalTypesListQuery()
    // const [ partnerAccountsQuery ] = useApiCoreAllPartnerAccountsCreateMutation()
    const allPartnersRes = useApiCorePartnersListQuery({isActive: true})
    // const [ getKpiData ] = useApiBqDashboardDataCreateMutation()
    const [ allLoaded, setAllLoaded ] = useState<boolean>(false)

    const [
        query,
        setQuery
    ] = useState<DashboardRequestRequest | null>(null)
    const kpiDataRes = useApiBqDashboardDataListQuery(query ? {dashboardRequestRequest: query} : skipToken)
    const partnerAccountsRes = useApiCoreAllPartnerAccountsListQuery(
        partnerIds
            ? {partnerAllAccountsRequestRequest: {partners: partnerIds}}
            : skipToken)

    useEffect(() => {
        // TODO set dates based on selected date type
        const start = dayjs(month).startOf('month')
        let end = dayjs(month).endOf('month')
        const dataEnd = dayjs(dataDates.latestSCSalesData)
        if (end > dataEnd) {
            end = dataEnd
        }
        const builtQuery: DashboardRequestRequest = {
            // TODO Use MTD/Trailing 7 for date ranges
            // TODO Replace after dev
            kpis: ['SALES_SC', 'SALES_VC', 'AD_SPEND', 'TACOS'],
            start_date: start.format('YYYY-MM-DD'),
            end_date: end.format('YYYY-MM-DD'),
            compare_start: start.subtract(1, 'month').format('YYYY-MM-DD'),
            // TODO handle this properly for full and partial months
            // Probably just a separate function for full and partial months
            // Can be an issue if previous month has more or less days
            // TODO consider adding separate compare end range for VC data
            compare_end: end.subtract(1, 'month').format('YYYY-MM-DD')
        }
        setQuery(builtQuery)
    }, [month])

    if ( allPartnersRes.isSuccess && !partnerIds ) {
        setPartnerIds(allPartnersRes.data.map((partner) => partner.id))
    }

    let allGoals: TimeLineGoalList[] = []
    if ( allGoalsRes.isSuccess ) {
        allGoals = allGoalsRes.data
    }

    let goalTypes: GoalType[] = []
    if ( goalTypesRes.isSuccess ) {
        goalTypes = goalTypesRes.data
    }

    let partnerAccounts: ApiCoreAllPartnerAccountsListApiResponse = []
    if (partnerAccountsRes.isSuccess) {
        partnerAccounts = partnerAccountsRes.data
    }

    let kpiData: ApiBqDashboardDataListApiResponse = []
    if (kpiDataRes.isSuccess) {
        kpiData = kpiDataRes.data
    }

    useEffect(() => {
        const fetchData = async () => {
            setAllLoaded(false)
                if (partnerAccounts) {
                    let accountIds: string[] = []
                    const partnerConfigAcc: IPartnerConfig[] = partnerAccounts.map((account) => {
                        return {
                            name: account.partner_name,
                            partnerId: account.partner_id,
                            accounts: account.amz_accounts.map((amz_account) => {
                                // Create list of applicable IW ids for query
                                if (amz_account.iw_id && !account.has_custom_data_strategy) {
                                    accountIds.push(amz_account.iw_id.toString())
                                }
                                return {
                                    name: amz_account.name + (amz_account.is_vc ? ' (VC)' : ''),
                                    nectarId: amz_account.id,
                                    iwId: amz_account.iw_id ?amz_account.iw_id.toString() : 'no id',
                                    isSc: amz_account.is_sc,
                                    errorMessage:amz_account.iw_id ? undefined : 'No IW id',
                                    // TODO Handle custom strategies when implemented in BE
                                    customStrategy: account.has_custom_data_strategy
                                }
                            }).concat(
                                // TODO Handle this more cleanly
                                {
                                    name: 'Total',
                                    nectarId: '0',
                                    // 0 Is returned as IwId from API
                                    iwId: '0',
                                    // TODO push this with total row if all accounts are SC
                                    isSc: true,
                                    errorMessage: undefined,
                                    // TODO Handle custom strategies when implemented in BE
                                    customStrategy: account.has_custom_data_strategy
                                }
                            )
                        }
                    })

                    if ( allGoals && kpiData ) {
                        // Clear Partner config so account goals don't stay if new date has nothing to overwrite them
                        setPartnerConfig([])
                        const salesGoal = goalTypes.find(
                            (goalType) => goalType.kpi === 'TOTAL_SALES'
                                && !goalType.ongoing && goalType.period === 'MONTHLY')
                        const spendGoal = goalTypes.find(
                            (goalType) => goalType.kpi === 'AD_SPEND'
                                && !goalType.ongoing && goalType.period === 'MONTHLY')
                        const tacosGoal = goalTypes.find(
                            (goalType) => goalType.kpi === 'TACOS'
                                && !goalType.ongoing && goalType.period === 'MONTHLY')

                        let tempConfig = partnerConfigAcc
                        tempConfig.forEach(partner => {
                            const partnerGoals = allGoals.filter(
                                (goal) => goal.goal_targets.find(
                                    (target) => target.object_id === partner.partnerId)
                            )

                            const formatGoal = (goal: TimeLineGoalList | undefined) => {
                                if (!goal) {
                                    return undefined
                                }
                                return {
                                    projection: goal.last_result?.projected_value ?? undefined,
                                    lowTarget: goal.target_value_low ?? undefined,
                                    lowPerformance: goal.last_result?.performance_low ?? undefined,
                                    middleTarget: goal.target_value_middle ?? undefined,
                                    middlePerformance: goal.last_result?.performance_middle ?? undefined,
                                    highTarget: goal.target_value_high ?? undefined,
                                    highPerformance: goal.last_result?.performance_high ?? undefined,
                                }
                            }
                            const partnerSalesGoal = partnerGoals.find(
                                (goal) => goal.goal_type === salesGoal?.id
                            )
                            partner.salesGoal = formatGoal(partnerSalesGoal)

                            const partnerSpendGoal = partnerGoals.find(
                                (goal) => goal.goal_type === spendGoal?.id
                            )
                            partner.spendGoal = formatGoal(partnerSpendGoal)
                            const partnerTacosGoal = partnerGoals.find(
                                (goal) => goal.goal_type === tacosGoal?.id
                            )
                            partner.tacosGoal = formatGoal(partnerTacosGoal)
                            // Set KPI Data
                            const accountKPIs = kpiData.find(
                                (account) => account.name === partner.name
                            )
                            if (partner.accounts) {
                                // Set data for each of the accounts found
                                partner.accounts.forEach((account) => {
                                    const accountGoals = allGoals.filter(
                                        (goal) => goal.goal_targets.find(
                                            (target) => target.object_id === account.nectarId)
                                    )
                                    if (accountGoals) {
                                        // Only set account goals if there are any
                                        const accountSalesGoal = accountGoals.find(
                                            (goal) => goal.goal_type === salesGoal?.id
                                        )
                                        account.salesGoal = formatGoal(accountSalesGoal)

                                        const accountSpendGoal = accountGoals.find(
                                            (goal) => goal.goal_type === spendGoal?.id
                                        )
                                        account.spendGoal = formatGoal(accountSpendGoal)

                                        const accountTacosGoal = accountGoals.find(
                                            (goal) => goal.goal_type === tacosGoal?.id
                                        )
                                        account.tacosGoal = formatGoal(accountTacosGoal)
                                    }

                                    const kpis = accountKPIs?.data.find(
                                        (data) => {
                                            return data.account_id.toString() === account.iwId
                                        })
                                    // Get SC or VC Numbers
                                    const getScOrVcSalesAndComp = (kpi: DashboardDataResult | undefined) => {
                                        // Returns a number for sales and comp depending on if account is SC or VC
                                        // Total row already adds both numbers and returns as sales
                                        if (!kpi) { return [undefined, undefined]}
                                        if (kpi.is_total) {
                                            return [Number(kpi.product_sales), Number(kpi.product_sales_comp)]
                                        }
                                        const scSales = Number(kpis?.product_sales)
                                        const vcSales = Number(kpis?.sourcing_shipped_revenue)
                                        const scSalesComp = Number(kpis?.product_sales_comp)
                                        const vcSalesComp = Number(kpis?.sourcing_shipped_revenue_comp)

                                        const sales = scSales > vcSales ? scSales : vcSales
                                        const salesComp = scSalesComp > vcSalesComp ? scSalesComp : vcSalesComp
                                        return [sales, salesComp]
                                    }
                                    const [
                                        sales, salesComp
                                    ] = getScOrVcSalesAndComp(kpis)
                                    account.data = {
                                        current: {
                                            sales: !Number.isNaN(sales) ? sales : undefined,
                                            adSpend: kpis?.ad_spend ?
                                                Number(kpis?.ad_spend)
                                                : undefined,
                                            tacos: kpis?.tacos ?
                                                Number(kpis?.tacos)
                                                : undefined
                                        },
                                        comparison: {
                                            sales: !Number.isNaN(salesComp) ? salesComp : undefined,
                                            adSpend: kpis?.ad_spend_comp ?
                                                Number(kpis?.ad_spend_comp)
                                                : undefined,
                                            tacos: kpis?.tacos_comp ? Number(kpis.tacos_comp)
                                                : undefined
                                        }
                                    }
                                })
                            }

                        })
                        setPartnerConfig(tempConfig)
                        setAllLoaded(true)
                    }
                }

            }
        fetchData()
    }, [partnerAccounts, query, allGoals, kpiData, goalTypes])


    const monthPicker = (
        <DatePicker
            label='Month'
            views={['month', 'year']}
            value={month}
            onChange={(newMonth) => setMonth(newMonth)}
            minDate={new Date('2023-02-01')}
            maxDate={new Date(dataDates?.latestSCSalesData)}
        />
    )

    const backDrop = (
        <Backdrop open={!allLoaded} sx={{zIndex: 1}}>
            <CircularProgress />
        </Backdrop>
    )

    let tableBody
    if (allLoaded) {
        tableBody = <ExecutiveLandingDashTable partnerConfig={partnerConfig} />
    } else {
        tableBody = <>Loading</>
    }

    const cardBody = (
        <BodyCard body={<>{backDrop}{tableBody}</>} header={{title: 'All Partners', action: monthPicker}}/>
    )

    return (
        <>
            <HeaderSideBarBody body={cardBody} pageTitle={"Home"} navLinks={navLinks}/>
        </>
    )
};