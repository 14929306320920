import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/store'

export type DateState = {
    latestSCSalesData: string
    latestVCSalesData: string
    latestAdData: string
}
// TODO Actually get correct dates from Backend
function getEasternUSTimeMinus12HoursMinus2Days() {
    // Get the current date and time in the Eastern US timezone
    const now = new Date();
    const estOffset = 5 * 60; // Eastern Standard Time (EST) offset in minutes (UTC-5)
    const estTime = new Date(now.getTime() - (estOffset * 60 * 1000));
    // Subtract 12 hours
    estTime.setHours(estTime.getHours() - 12);
    // Subtract 2 days
    estTime.setDate(estTime.getDate() - 2);
    return estTime;
}

const tempInitial = getEasternUSTimeMinus12HoursMinus2Days().toISOString()

const slice = createSlice({
    name: 'dataDates',
    initialState: {
        latestSCSalesData: tempInitial,
        latestVCSalesData: tempInitial,
        latestAdData: tempInitial
    } as DateState,
    reducers: {
        setDataDate: (
            state,
            {
                payload: { latestScSalesData, latestVCSalesData, latestAdData } }: PayloadAction<{
                latestScSalesData: DateState['latestSCSalesData'],
                latestVCSalesData: DateState['latestVCSalesData'],
                latestAdData: DateState['latestAdData']
            }>
        ) => {
                state.latestSCSalesData = latestScSalesData
                state.latestVCSalesData = latestVCSalesData
                state.latestAdData = latestAdData
        }
    }
})

export const { setDataDate } = slice.actions;

export default slice.reducer;

export const selectDataDates = (state: RootState) => state.dataDates