import { AuthForm } from "../auth/AuthForm";
import { Box, Stack } from "@mui/material";
import logo from '../../resources/Ambrosia By Nectar_Logo_Horizontal.png'

const styles = {
    root: {
        backgroundColor: "whiteSmoke",
        height: '100%',
        pt: '5em'
    }
};

export const AuthPage = () => {

    return (
        <Box sx={styles.root}>
            <Stack direction='column' alignContent='center'>
                <img alt='Nectar Logo' src={logo} style={
                    {
                        maxHeight: 400,
                        maxWidth: 1000,
                        margin: 'auto'
                    }
                }/>
                <AuthForm />
            </Stack>
        </Box>
    )
};
