import {
    useApiPdmProductGroupCreateMutation,
    ProductGroupRequest,
    PartnerList,
    ProductGroup
} from "../../../services/generatedApi";
import { BaseApiForm } from "../../common/forms/BaseForm/BaseAPIForm";
import { MakeTextFormField } from "../../common/forms/BaseForm/fields/TextFormField";
import { useState } from "react";
import { useForm } from "react-hook-form";

export interface IProductGroupFormProps {
    partner: PartnerList['id']
    parentGroup: ProductGroup['id'] | undefined
    //fieldLabel: str
    refetch: () => void
}

interface IFormInput extends Omit<ProductGroupRequest, 'partner'> {}
export const ProductGroupForm = (props: IProductGroupFormProps) => {
    const initialValues: IFormInput = {name: ''}
    const makeTextFormField = MakeTextFormField
    const [ createProductGroup ] = useApiPdmProductGroupCreateMutation()
    const { control, ...useFormRest } = useForm<IFormInput>({defaultValues: initialValues})
    const formStateHooks = useState<IFormInput>(initialValues)

    const createProductGroupAction = ((formInput: IFormInput) => (
        createProductGroup({
            productGroupRequest: {
                partner: props.partner,
                parent_group: props.parentGroup,
                ...formInput
            }
        })
    ))

    const fields = [
        makeTextFormField(control, 'name', 'name', true)
    ]

    return (
        <BaseApiForm
            recordName='Product Group'
            formActionType='Create'
            fields={fields}
            useFormRest={useFormRest}
            formStateHooks={formStateHooks}
            //@ts-ignore
            formAPIAction={createProductGroupAction}
            refetch={props.refetch}
        />
    )
}
