import { PartnerBaseLayout } from "../../../layout/partnerLayout/PartnerBaseLayout";
import { BodyCard } from "../../../common/cards/BodyCard";
import { OnboardingStepper } from "../../../layout/partnerLayout/OnboardingStepper";
import { Embed } from "../../../layout/partnerLayout/Embed";
import { Switch, Box } from "@mui/material";
import React, { useState } from "react";
import { AmzAccountCreateForm } from "../../partners/partnerAccounts/AmzAccountCreateForm";
import { TempTextEmbed } from "../../../layout/partnerLayout/TempTextEmbed";

export const PartnerOnboardingLayout = () => {
    const [pdfOn, setPdfOn] = useState(true)
    const [formOn, setFormOn] = useState(false)

    const tempSteps = ['Step 1', 'Step 2', '...', 'Last Step']
    const tempCurrentStep = 1
    // TODO Also can just embed PDFs from filesystem but didn't install package for this demo
    // Or create instruction widgets to easily compose. Ex: Instruction w/ link, Instruction with image, etc
    // This is just based on how easily we want these to be editable - probably best if all changes go through me at first
    const tempPdfUrl = "https://drive.google.com/file/d/1oSLXBFj5MOVpB2KVlfUhnPlxM_pxd5EL/preview"
    const otherPdfUrl = 'https://drive.google.com/uc?export=view&id=1oSLXBFj5MOVpB2KVlfUhnPlxM_pxd5EL'
    const thirdPdfUrl = 'https://docs.google.com/document/d/e/2PACX-1vQtmqtaDib46GFiNfxz0hELmuFFz9L4warHqI5znbe2rp_phm_5bWJ155UbSHB7Pg/pub?embedded=true'

    const toggle = <>
        <Switch
            checked={!pdfOn}
            onChange={() => setPdfOn(!pdfOn)}
        /> PDF / Text
        <Switch
            checked={formOn}
            onChange={() => setFormOn(!formOn)}
        /> Form On/Off
    </>

    const fakeForm = (
        <AmzAccountCreateForm accountType='SC' partnerId='N/A' refetch={() => ('')}/>
    )

    const body = (
        <Box>
            {/*{!pdfOn && <Embed url={tempPdfUrl}/> }*/}
            {pdfOn && <Embed url={otherPdfUrl} />}
            {/*{!pdfOn && <Embed url={thirdPdfUrl}/> }*/}
            {!pdfOn && <TempTextEmbed />}
            <br/>
            {formOn && fakeForm}
            <br/>
            <OnboardingStepper steps={tempSteps} currentStep={tempCurrentStep}/>
        </Box>
    )

    return (
        <PartnerBaseLayout tempLogo body={
            <BodyCard header={{title: 'Nectar Onboarding Wizard', action: toggle}} body={body} />}
        />
    )
}