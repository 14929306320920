import { PathBase, PathParam } from "./util/PathUtil";

// const publicBase = '/public'
const spApiBase = '/sp_api_auth'
const employeeBase = '/internal'
const partnerBase = '/partner'

const authPage = new PathBase('/login', ['continue'])
const baseLandingDash = new PathBase(`${employeeBase}/`)
const amSmLandingDash = new PathBase(`${employeeBase}/am_sm`)
// TODO either handle this in router, or outlet of page based on permission
const executiveLandingDash = new PathBase(`${employeeBase}/executive`)
// TODO make a sub-page with links or define one as main and add nav links
const salesGoalOverviewDash = new PathBase(`${employeeBase}/sales_goal_overview`)
// TODO Name this better to be obvious it is internal
const basePartnerDash = new PathParam(`${employeeBase}/partner_detail`, 'id')
const listProducts = new PathBase(`${employeeBase}/products`)
const detailProduct = new PathParam(listProducts.getFullPath(), 'id')
const listingEventsOverview = new PathBase(`${listProducts.getFullPath()}/listing_events`)
const listingEventsPartnerHistory = new PathParam(
    `${listingEventsOverview.getFullPath()}/history`, 'partner')
const listingEventsDetail = new PathBase(
    `${listingEventsOverview.getFullPath()}/detail`,
    ['partner', 'date']
    )
const productGroups = new PathBase(`${employeeBase}/product_groups`)
const listPartners = new PathBase(`${employeeBase}/partners`)
const createPartner = new PathBase(
    listPartners.getDerivedPath('create'),
    ['partnerIntakeId', 'partnerName', 'partnerType']
)
const detailPartner = new PathParam(listPartners.getFullPath(), 'id')
const listPartnerAccounts = new PathParam(`${listPartners.getFullPath()}/accounts`, 'id')
const listEmployees = new PathBase(`${employeeBase}/employees`)
const createEmployee = new PathBase(listEmployees.getDerivedPath('create'))
const detailEmployee = new PathParam(listEmployees.getFullPath(), 'id')
const spAuth = new PathParam(`${spApiBase}/start`, 'id', ['repeat_auth'])
// Below breaks convention, specifying same path root twice
const spReturn = new PathBase(`${spApiBase}/return`,
    ['state', 'selling_partner_id', 'spapi_oath_code'])
const listPartnerIntakes = new PathBase(`${employeeBase}/partner_intakes`)
const createPartnerIntake = new PathBase(listPartnerIntakes.getDerivedPath('create'))
const detailPartnerIntake = new PathParam(listPartnerIntakes.getFullPath(), 'id')
const listGoals = new PathBase(`${employeeBase}/goals`)
const createGoal = new PathBase(listGoals.getDerivedPath('create'))
// TODO Remove after dev
const demoGoals = new PathBase(`${employeeBase}/goals_demo`)

//Partner URLS
const partnerOnboarding = new PathBase(`${partnerBase}/onboarding`)

export const AppURLS = {
    authPage: authPage,
    baseLandingDash: baseLandingDash,
    // TODO remove the two below after development
    amSmLandingDash: amSmLandingDash,
    executiveLandingDash: executiveLandingDash,
    salesGoalOverviewDash: salesGoalOverviewDash,
    basePartnerDash: basePartnerDash,
    listProducts: listProducts,
    detailProduct: detailProduct,
    listingEventsOverview: listingEventsOverview,
    listingEventsPartnerHistory: listingEventsPartnerHistory,
    listingEventsDetail: listingEventsDetail,
    productGroups: productGroups,
    listPartners: listPartners,
    createPartner: createPartner,
    detailPartner: detailPartner,
    listPartnerAccounts: listPartnerAccounts,
    listEmployees: listEmployees,
    createEmployee: createEmployee,
    detailEmployee: detailEmployee,
    spAuth: spAuth,
    spReturn: spReturn,
    listPartnerIntakes: listPartnerIntakes,
    createPartnerIntake: createPartnerIntake,
    detailPartnerIntake: detailPartnerIntake,
    partnerOnboarding: partnerOnboarding,
    listGoals: listGoals,
    createGoal: createGoal,
    demoGoals: demoGoals
}