import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../services/auth/authSlice";
import { PropsWithChildren } from "react";
import { User } from "../../services/generatedApi";

const ATTR_VALUES: Array<keyof User | "None"> = ["None", "is_partner", "is_nectar", "is_admin", "is_superuser"]
export interface IUserLevelCheckProps {
    attrCheck: "is_partner" | "is_nectar" | "is_admin" | "is_superuser"
}

/**
 * Can gate rendering of a component coarsely based on user attributes: is_superuser, is_admin, is_nectar, is_partner.
 * Used when granular control but higher effort configuration of {@link RbacComponent} is not necessary
 */
export const UserLevelCheck = (props: PropsWithChildren<IUserLevelCheckProps>) => {
    // TODO Test This More Thoroughly
    const currentUser = useSelector(selectCurrentUser)
    const minAccess = ATTR_VALUES.indexOf(props.attrCheck)

    if (!currentUser) {
        return null
    } else {
        let userAccessLevel = 0
        if (currentUser.is_superuser) {userAccessLevel = ATTR_VALUES.indexOf('is_superuser')}
        else if (currentUser.is_admin) {userAccessLevel = ATTR_VALUES.indexOf('is_admin')}
        else if (currentUser.is_nectar) {userAccessLevel = ATTR_VALUES.indexOf('is_nectar')}
        else if (currentUser.is_partner) {userAccessLevel = ATTR_VALUES.indexOf('is_partner')}

        if (userAccessLevel >= minAccess){
            return <>{props.children}</>
        }
    }

    return null
}