import { Box, Stack } from "@mui/material";

export interface ILogoBlockProps {
    partnerLogo: string
}

const styles = {
    spacer: {
        width: 80,
        height: 10,
        backgroundColor: "lightGray",
        transform: "skewY(-60deg)"
    },
    stack: {
        maxHeight: 250,
        maxWidth: '50%',
        marginTop: 10,
        margin: 'auto'
    },
    container: {
        height: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        objectFit: "contain" as const,
        maxHeight: 100,
        maxWidth: 500,
        height: 'auto',
        width: 'auto'
    },
    text: {
        color: '#a0cc73',
        textAlign: 'center',
        fontSize: '1.5em'
    },
    partnerStack: {
        marginLeft: 2
    }
};

export const LogoBlock = (props: ILogoBlockProps) => {
    const ambrosiaLogo = require('../../../resources/Ambrosia By Nectar_Logo_Horizontal.png')


    const spacer = (<Box  sx={styles.spacer} />)

    const partnerLogoBlock = (
        <Stack direction='column' justifyContent='center' sx={styles.partnerStack}>
            {/*<Typography sx={styles.text}>PREPARED FOR:</Typography>*/}
            {/*<Typography sx={styles.text}>With:</Typography>*/}
            <img alt='Partner Logo'
                 style={styles.image}
                 src={props.partnerLogo} />
        </Stack>
    )

    return (
        <Stack direction='row' alignItems='center' justifyContent='center' sx={styles.stack}>
            <Box sx={styles.container}>
                <img alt='Nectar Logo' style={{height: 100}} src={String(ambrosiaLogo)} />
            </Box>
            {spacer}
            {partnerLogoBlock}
        </Stack>
    )
}