import { BaseCell } from "../../../common/basicTable/cells/BaseCell";
import { asMoney, asPercent } from "../../../../util/ChartUtils";
import {isNumber} from "util";

const styles = {
    dividerBottom: {
        borderBottom: "solid"
    },
    greenText: {
        color: 'green'
    }
}

const rowNames = ['Ordered Product Sales (OPS)', '% YoY Ordered Product Sales', 'Shipped Units (not orders)',
    '% YoY Shipped Units', '% Total Monthly OPS', 'PPC Spend', 'PPC Sales', 'PPC ROAS', 'PPC ACOS', 'PPC Impressions',
    'PPC Clicks', 'PPC Advertising Orders (not units)', 'PPC Click Through Rate (CTR)', 'PPC Cost Per Click (CPC)',
    'PPC Conversion Rate (CVR)', 'PPC Cost Per Acquisition (CPA)', 'DSP Spend', 'DSP Sales', 'DSP ROAS', 'DSP ACOS',
    'DSP Impressions', 'DSP Clicks', 'DSP Total Units Sold (not orders)', 'DSP % New to Brand Sales',
    '% Sales Ad Attributed', '% Sales Organic', '% Sales Invested', 'Total ROAS', 'TACOS', 'Sessions',
    'Total Page Views', 'Repeat Shopper Rate*', 'Brand Store - Unique Visitors*', 'Total Brand Store Sales*',
    '% Brand Store Sales vs. Total Sales*', 'Brand Followers*', 'PPC Ad Budget', 'DSP Ad Budget', 'Total Ad Budget',
    'PPC Ad Spend', 'DSP Ad Spend', 'Total Ad Spend', '$ Ad Budget Spend Delta', '% Ad Budget Spend Delta']

const rowData = [
    [],
    [],
    ['1256', '1127', '1254', '1422', '1407', '1248', '1681', '2321', '2621', '2933', '1131', "-", '18401'],
    ['000', '000', '000', '000', '000', '000', '000', '000', '000', '107300', '26300', "-", '000'],
    ['750', '646', '708', '813', '821', '690', '922', '1220', '1256', '1546', '627', "-", '10000'],
    ['18405', '13407', '25150', '25711', '21768', '23464', '25361', '40962', '63168', '66966', '19459',"-",  '343821'],
    ['40847', '33049', '37998', '45108', '43062', '37132', '51967', '61092', '66698', '78647', '27431',"-",  '523030'],
    ['222', '246', '151', '175', '198', '158', '205', '149', '106', '117', '141', "-", '152'],
    ['4506', '4057', '6619', '5700', '5055', '6319', '4880', '6705', '9471', '8515', '7094', "-", '6574'],
    ['2256889', '2451048', '7679695', '7491103', '9309757', '8746099', '8911335', '9724559', '10320974', '11452823', '4133426', "-", '82477708'],
    ['20353', '18219', '32035', '29215', '29056', '25511', '25742', '26312', '28303', '31341', '10980', "-", '277067'],
    ['1256', '1127', '1254', '1422', '1407', '1248', '1681', '2321', '1733', '1843', '643', "-", '15935'],
    ['090', '074', '042', '039', '031', '029', '029', '027', '027', '027', '027', "-", '034'],
    ['090', '074', '079', '088', '075', '092', '099', '156', '223', '214', '177', "-", '124'],
    ['617', '619', '391', '487', '484', '489', '653', '882', '612', '588', '586', "-", '575'],
    ['1465', '1190', '2006', '1808', '1547', '1880', '1509', '1765', '3645', '3634', '3026', "-", '2158'],
    [''],
    [''],
    [''],
    ['000', '000', '000', '000', '000', '000', '000', '000', '000', '000', '000', '000', "-", '000'],
    [''],
    [''],
    [''],
    ['000', '000', '000', '000', '000', '000', '000', '000', '000', '000', '000', "-", '000'],
    ['73', '68', '72', '74', '70', '72', '75', '67', '71', '68', '58', "-", '70'],
    ['27', '32', '28', '26', '30', '28', '25', '33', '29', '32', '42', "-", '30'],
    ['3279', '2772', '4747', '4229', '3544', '4543', '3674', '4487', '6719', '5788', '4145', "-", '4595'],
    ['305', '361', '211', '236', '282', '220', '272', '223', '149', '173', '241', "-", '218'],
    ['3279', '2772', '4747', '4229', '3544', '4543', '3674', '4487', '6719', '5788', '4145', "-", '4595'],
    ['49736', '45953', '60959', '59357', '51632', '43688', '50676', '61329', '62563', '80616', '27828', "-", '594337'],
    ['63109', '57970', '76742', '74973', '68178', '57503', '64915', '77713', '79606', '100575', '35214', "-", '756498'],
    ['427', '677', '705', '597', '736', '818', '736', '716', '875', '936', '000', "-", '000'],
    ['3173', '2473', '6395', '6048', '5267', '4413', '4519', '5948', '5782', '4956', '1393', "-", '50367'],
    ['10546', '8932', '9483', '12559', '11813', '11166', '14594', '17114', '20430', '23842', '7895', "-", '148374'],
    ['1879', '1847', '1790', '2066', '1923', '2162', '2114', '1875', '2173', '2061', '1682', "-", '1983'],
    ['991', '1000'],
    ['18405', '13407', '25150', '25711', '21768', '23464', '25361', '40962', '63168', '67000', '62000', '68000', "-", '454396'],
    [''],
    ['18405', '13407', '25150', '25711', '21768', '23464', '25361', '40962', '63168', '67000', '62000', "-", '386396'],
    ['18405', '13407', '25150', '25711', '21768', '23464', '25361', '40962', '63168', '66966', '19459', "-", '343821'],
    [''],
    ['18405', '13407', '25150', '25711', '21768', '23464', '25361', '40962', '63168', '66966', '19459', "-", '343821'],
    ['34', '42541', '42574'],
    ['10000', '10000', '10000', '10000', '10000', '10000', '10000', '10000', '10000', '9995', '3139', "-", '8898']
]

export const tempRows = [
    {
        rowKey: rowNames[0],
        cells: [
            <BaseCell content={rowNames[0]} />,
            <BaseCell content={asMoney("56128")} />,
            <BaseCell content={asMoney("48362")} />,
            <BaseCell content={asMoney("")} />,
            <BaseCell content={asMoney("")} />,
            <BaseCell content={asMoney("")} />,
            <BaseCell content={asMoney("")} />,
            <BaseCell content={asMoney("")} />,
            <BaseCell content={asMoney("")} />,
            <BaseCell content={asMoney("")} />,
            <BaseCell content={asMoney("")} />,
            <BaseCell content={asMoney("")} />,
            <BaseCell content={asMoney("")} />,
            <BaseCell content={asMoney("")} />
        ]
    },
    {
        rowKey: rowNames[1],
        cells: [
            <BaseCell content={rowNames[1]} />,
            <BaseCell sx={styles.greenText} content={asPercent("0")} />,
            <BaseCell sx={styles.greenText} content={asPercent("0")} />,
            <BaseCell sx={styles.greenText} content={asPercent("")} />,
            <BaseCell sx={styles.greenText} content={asPercent("")} />,
            <BaseCell sx={styles.greenText} content={asPercent("")} />,
            <BaseCell sx={styles.greenText} content={asPercent("")} />,
            <BaseCell sx={styles.greenText} content={asPercent("")} />,
            <BaseCell sx={styles.greenText} content={asPercent("")} />,
            <BaseCell sx={styles.greenText} content={asPercent("")} />,
            <BaseCell sx={styles.greenText} content={asPercent("")} />,
            <BaseCell sx={styles.greenText} content={asPercent("")} />,
            <BaseCell sx={styles.greenText} content={asPercent("")} />,
            <BaseCell sx={styles.greenText} content={asPercent("")} />
        ]
    },
    {
        rowKey: rowNames[2],
        cells: [
            <BaseCell content={rowNames[2]} />,
            ...rowData[2].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? dataNum.toLocaleString() : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[3],
        cells: [
            <BaseCell content={rowNames[3]} />,
            ...rowData[3].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    sx={styles.greenText}
                    content={!isNaN(dataNum) ? asPercent(dataNum) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[4],
        cells: [
            <BaseCell sx={styles.dividerBottom} content={rowNames[4]} />,
            ...rowData[4].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    sx={styles.dividerBottom}
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    }
    ,
    {
        rowKey: rowNames[5],
        cells: [
            <BaseCell content={rowNames[5]} />,
            ...rowData[5].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asMoney(dataNum) : "-"}
                />
            })
        ]
    }
    ,
    {
        rowKey: rowNames[6],
        cells: [
            <BaseCell content={rowNames[6]} />,
            ...rowData[6].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    }
    ,
    {
        rowKey: rowNames[7],
        cells: [
            <BaseCell content={rowNames[7]} />,
            ...rowData[7].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    }
    ,
    {
        rowKey: rowNames[8],
        cells: [
            <BaseCell sx={styles.dividerBottom} content={rowNames[8]} />,
            ...rowData[8].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    sx={styles.dividerBottom}
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    }
    ,
    {
        rowKey: rowNames[9],
        cells: [
            <BaseCell content={rowNames[9]} />,
            ...rowData[9].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    }
    ,
    {
        rowKey: rowNames[10],
        cells: [
            <BaseCell  content={rowNames[10]} />,
            ...rowData[10].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[11],
        cells: [
            <BaseCell content={rowNames[11]} />,
            ...rowData[11].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[12],
        cells: [
            <BaseCell content={rowNames[12]} />,
            ...rowData[12].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[13],
        cells: [
            <BaseCell content={rowNames[13]} />,
            ...rowData[13].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[14],
        cells: [
            <BaseCell content={rowNames[14]} />,
            ...rowData[14].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[15],
        cells: [
            <BaseCell sx={styles.dividerBottom} content={rowNames[15]} />,
            ...rowData[15].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    sx={styles.dividerBottom}
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    // {
    //     rowKey: rowNames[16],
    //     cells: [
    //         <BaseCell content={rowNames[16]} />,
    //         ...rowData[16].map((data) => {
    //             const dataNum = Number(data)
    //             return <BaseCell
    //                 content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
    //             />
    //         })
    //     ]
    // },
    // {
    //     rowKey: rowNames[17],
    //     cells: [
    //         <BaseCell sx={styles.dividerBottom} content={rowNames[17]} />,
    //         ...rowData[17].map((data) => {
    //             const dataNum = Number(data)
    //             return <BaseCell
    //                 sx={styles.dividerBottom}
    //                 content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
    //             />
    //         })
    //     ]
    // },
    // {
    //     rowKey: rowNames[18],
    //     cells: [
    //         <BaseCell sx={styles.dividerBottom} content={rowNames[18]} />,
    //         ...rowData[18].map((data) => {
    //             const dataNum = Number(data)
    //             return <BaseCell
    //                 sx={styles.dividerBottom}
    //                 content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
    //             />
    //         })
    //     ]
    // },
    // {
    //     rowKey: rowNames[19],
    //     cells: [
    //         <BaseCell sx={styles.dividerBottom} content={rowNames[19]} />,
    //         ...rowData[19].map((data) => {
    //             const dataNum = Number(data)
    //             return <BaseCell
    //                 sx={styles.dividerBottom}
    //                 content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
    //             />
    //         })
    //     ]
    // },
    // {
    //     rowKey: rowNames[20],
    //     cells: [
    //         <BaseCell sx={styles.dividerBottom} content={rowNames[20]} />,
    //         ...rowData[20].map((data) => {
    //             const dataNum = Number(data)
    //             return <BaseCell
    //                 sx={styles.dividerBottom}
    //                 content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
    //             />
    //         })
    //     ]
    // },
    // {
    //     rowKey: rowNames[21],
    //     cells: [
    //         <BaseCell sx={styles.dividerBottom} content={rowNames[21]} />,
    //         ...rowData[21].map((data) => {
    //             const dataNum = Number(data)
    //             return <BaseCell
    //                 sx={styles.dividerBottom}
    //                 content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
    //             />
    //         })
    //     ]
    // },
    // {
    //     rowKey: rowNames[22],
    //     cells: [
    //         <BaseCell sx={styles.dividerBottom} content={rowNames[22]} />,
    //         ...rowData[22].map((data) => {
    //             const dataNum = Number(data)
    //             return <BaseCell
    //                 sx={styles.dividerBottom}
    //                 content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
    //             />
    //         })
    //     ]
    // },
    // {
    //     rowKey: rowNames[23],
    //     cells: [
    //         <BaseCell sx={styles.dividerBottom} content={rowNames[23]} />,
    //         ...rowData[23].map((data) => {
    //             const dataNum = Number(data)
    //             return <BaseCell
    //                 sx={styles.dividerBottom}
    //                 content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
    //             />
    //         })
    //     ]
    // },
    {
        rowKey: rowNames[24],
        cells: [
            <BaseCell content={rowNames[24]} />,
            ...rowData[24].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[25],
        cells: [
            <BaseCell content={rowNames[25]} />,
            ...rowData[25].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[26],
        cells: [
            <BaseCell content={rowNames[26]} />,
            ...rowData[26].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[27],
        cells: [
            <BaseCell content={rowNames[27]} />,
            ...rowData[27].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[28],
        cells: [
            <BaseCell sx={styles.dividerBottom} content={rowNames[28]} />,
            ...rowData[28].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    sx={styles.dividerBottom}
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[29],
        cells: [
            <BaseCell content={rowNames[29]} />,
            ...rowData[29].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[30],
        cells: [
            <BaseCell content={rowNames[30]} />,
            ...rowData[30].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[31],
        cells: [
            <BaseCell content={rowNames[31]} />,
            ...rowData[31].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[32],
        cells: [
            <BaseCell content={rowNames[32]} />,
            ...rowData[32].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[33],
        cells: [
            <BaseCell content={rowNames[33]} />,
            ...rowData[33].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[34],
        cells: [
            <BaseCell content={rowNames[34]} />,
            ...rowData[34].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[35],
        cells: [
            <BaseCell content={rowNames[35]} />,
            ...rowData[35].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[36],
        cells: [
            <BaseCell content={rowNames[36]} />,
            ...rowData[36].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[37],
        cells: [
            <BaseCell content={rowNames[37]} />,
            ...rowData[37].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[38],
        cells: [
            <BaseCell content={rowNames[38]} />,
            ...rowData[38].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[39],
        cells: [
            <BaseCell content={rowNames[39]} />,
            ...rowData[39].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[40],
        cells: [
            <BaseCell content={rowNames[40]} />,
            ...rowData[40].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[41],
        cells: [
            <BaseCell content={rowNames[41]} />,
            ...rowData[41].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[42],
        cells: [
            <BaseCell content={rowNames[42]} />,
            ...rowData[42].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    },
    {
        rowKey: rowNames[43],
        cells: [
            <BaseCell content={rowNames[43]} />,
            ...rowData[43].map((data) => {
                const dataNum = Number(data)
                return <BaseCell
                    content={!isNaN(dataNum) ? asPercent(dataNum/10000) : "-"}
                />
            })
        ]
    }
    // {content: "56,128"}, {content: "48,362"}
]