import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../common/cards/BodyCard";
import {
    useApiGoalsCreateGoalCreateMutation,
    GoalCreateRequest,
    useApiGoalsGoalTypesListQuery,
    useApiCorePartnersListQuery,
    PartnerList,
    useApiCoreUsersListQuery,
    OwnerTypeEnum,
    GoalOwnerCreateRequest,
    GoalType,
    useApiCoreAmzAccountsListQuery
} from "../../../services/generatedApi";
import { MakeTextFormField } from "../../common/forms/BaseForm/fields/TextFormField";
import { MakeChipSelectFormField } from "../../common/forms/BaseForm/fields/ChipSelectFormField";
import {
    IEnumSelectFormFieldProps,
    MakeEnumSelectFormField
} from "../../common/forms/BaseForm/fields/EnumSelectFormField";
import { selectCurrentPartner } from "../../../services/partnerConfig/partnerConfigSlice";
import { selectCurrentUser } from "../../../services/auth/authSlice";
import { useSelector } from "react-redux";
import { navLinks } from "./navLinks";
import { BaseApiForm } from "../../common/forms/BaseForm/BaseAPIForm";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { goalTypeNameWithOngoingFromId, partnerNameFromId, partnerChoiceToDisplayName, userNameFromId,
    userChoiceToDisplayName, sortUsersByLastName, goalTypeChoiceToDisplayName, amzAccountNameFromId,
    amzAccountChoiceToDisplayName } from "../../../util/FormUtil";
import { Backdrop, CircularProgress } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

interface IFormInput extends GoalCreateRequest {
    goal_operation_value_low: number
    goal_operation_value_middle: number
    goal_operation_value_high: number
    goal_favorability: 'ABOVE' | 'BELOW'
    // TODO Remove after better selection method
    goal_targets_partner: string,
    goal_targets_account: string
}

// TODO Replace with proper Date Selection
const dateChoices: IEnumSelectFormFieldProps['choicesList'] = [
    {name: 'Sept', value: 'Sept'},
    {name: 'Oct', value: 'Oct'},
    {name: 'Nov', value: 'Nov'},
    {name: 'Dec', value: 'Dec'}
]

const favorabilityChoices: IEnumSelectFormFieldProps['choicesList'] = [
    // TODO Type from Favorability ENUM when implemented
    {name: 'Above', value: 'ABOVE'},
    {name: 'Below', value: 'BELOW'}
]



export const CreateGoal = () => {
    const currentPartner = useSelector(selectCurrentPartner)
    const currentUser = useSelector(selectCurrentUser)
    const makeTextFormField = MakeTextFormField
    const makeChipSelectFormField = MakeChipSelectFormField
    const makeENUMSelectFormField = MakeEnumSelectFormField
    const [ createGoal, {isLoading: goalIsCreating} ] = useApiGoalsCreateGoalCreateMutation()
    //TODO
    //@ts-ignore
    const initialValues: IFormInput = {
        // Figure out a better pattern for this
        goal_name: '',
        //@ts-ignore
        // goal_operation: '',
        goal_type: '',
        //@ts-ignore
        goal_targets_partner: '',
        //@ts-ignore
        goal_targets_account: '',
        //@ts-ignore
        goal_owners: [],
        //@ts-ignore
        goal_operation_value: undefined,
        //@ts-ignore
        goal_favorability: undefined,
        date_ranges: []
    }
    const { control, ...useFormRest } = useForm<IFormInput>({defaultValues: initialValues});
    const formStateHooks = useState<IFormInput>(initialValues)

    const partnersRes = useApiCorePartnersListQuery({isActive: true})
    const partnerAccountsRes = useApiCoreAmzAccountsListQuery(
        currentPartner
            ? {
            partnerAmzAccountsPartnerId: currentPartner.id}
            : skipToken)
    const usersRes = useApiCoreUsersListQuery({isActive: true, isNectar: true})
    const goalTypesRes = useApiGoalsGoalTypesListQuery()

    // TODO For the below: narrow scope to currently selected Partner. Unless Director+
    // TODO Get Partners, Roles, Departments, employees (self only if not director+ ?) for owner type
    // Get Partners(already have), product groups, products, product tags for goal_targets
    // Start with just full catalog for currently selected partner

    const createGoalAction = (input: IFormInput) => {
        // This needs to be fully redone for type safety
        if (!currentPartner) {
            window.alert('Please Select a Partner')
            return
        }
        const goalOwners = input.goal_owners.map((owner) => {
               return {owner_type: 'EMPLOYEE' as OwnerTypeEnum,
                   employee: owner as unknown as GoalOwnerCreateRequest['employee']}
        }
        )
        let goalTargets
        if (input.goal_targets_partner) {
            goalTargets = {target_type: "PARTNER", partner: input.goal_targets_partner}
        } else if (input.goal_targets_account) {
            goalTargets = {target_type: "ACCOUNT", account: input.goal_targets_account}
        }
        let transformed = {
            goal_name: input.goal_name,
            goal_type: input.goal_type,
            // TODO select Partner or account
            // goal_targets: [{target_type: "PARTNER", partner: input.goal_targets}],
            goal_targets: [goalTargets],
            goal_owners: [
                {owner_type: 'PARTNER' as OwnerTypeEnum, partner: currentPartner.id},
                ...goalOwners
            ],
            goal_operation_low: {
                goal_operation: "EXACT_VALUE",
                goal_operation_value: input.goal_operation_value_low,
                // TODO set as field
                favorability: input.goal_favorability
            },
            goal_operation_middle: {
                goal_operation: "EXACT_VALUE",
                goal_operation_value: input.goal_operation_value_middle,
                // TODO set as field
                favorability: input.goal_favorability
            },
            goal_operation_high: {
                goal_operation: "EXACT_VALUE",
                goal_operation_value: input.goal_operation_value_high,
                // TODO set as field
                favorability: input.goal_favorability
            },
            // TODO Do this properly
            // @ts-ignore
            date_ranges: [input.date_ranges === 'Sept' ?
                {start_date: '2023-09-01T00:00:00.000Z', end_date: '2023-09-30T00:00:00.000Z'}
                // @ts-ignore
                : input.date_ranges === 'Oct' ? {
                        start_date: '2023-10-01T00:00:00.000Z', end_date: '2023-10-31T00:00:00.000Z'
                    } :
                    // @ts-ignore
                    input.date_ranges === 'Nov' ? {
                        start_date: '2023-11-01T00:00:00.000Z', end_date: '2023-11-30T00:00:00.000Z'
                    } :
                        // @ts-ignore
                        input.date_ranges === 'Dec' ? {
                                start_date: '2023-12-01T00:00:00.000Z', end_date: '2023-12-31T00:00:00.000Z'
                            } : undefined]
        }
        // Need to poll for long-running task if it is a report based goal
        // dispatch(setShouldPollLongRunningTasks({value: true}))
        console.log(transformed)
        return createGoal({
            // @ts-ignore
            goalCreateRequest: {...transformed}
        })
    }

    const filterPartnerChoicesToSelected = (partners: PartnerList[]) => {
        return partners.filter((partner) => partner.id === currentPartner?.id)
    }

    const filterGoalTypeChoices = (goalTypes: GoalType[]) => {
        // Also filtering out ongoing Goals
        const unsupportedGoalPeriods = ['Quarterly', 'Yearly']
        const unsupportedGoalKpis = ['ACoS']
        return goalTypes.filter((goalType) => {
            return !unsupportedGoalKpis.includes(goalType.kpi)
                && !unsupportedGoalPeriods.includes(goalType.period)
                && !goalType.ongoing
        })
    }

    // const filter


    const fields = [
        makeTextFormField(control, 'goal_name', 'Name', true),
        makeChipSelectFormField(
            // This will need to be updated as "Content Object-Object Field" or similar
            // Or generate ENUMs for enum select field with extra mapping
            // Could have a function that returns two field, filter type and select option
            // Filter field would be optional, would only execute query for relevant objects when selected
            // Or possibly create separate form for each goal type?
            // Could be multistep form - narrow down options at each step until everything is collected
            control,
            'goal_owners',
            'Goal Owners (Employees Only)',
            usersRes,
            userNameFromId,
            userChoiceToDisplayName,
            true,
            true,
            undefined,
            sortUsersByLastName
        ),
        // TODO Make this two step or that double field that was designed
        makeChipSelectFormField(
            // See above notes on goal_owners field
            control,
            'goal_targets_partner',
            'Goal Targets (Partner Full Catalog Only)',
            partnersRes,
            partnerNameFromId,
            partnerChoiceToDisplayName,
            false,
            false,  // true,
            filterPartnerChoicesToSelected
        ),
        makeChipSelectFormField(
            // See above notes on goal_owners field
            control,
            'goal_targets_account',
            'Goal Targets (Single Account Only) *Only set 1 target field*',
            partnerAccountsRes,
            amzAccountNameFromId,
            amzAccountChoiceToDisplayName,
            false,
            // true,
        ),
        // Break these out into separate fields (KPI,Period, recurring) and determine if we have a supported match
        makeChipSelectFormField(
            control,
            'goal_type',
            'Goal Type',
            goalTypesRes,
            goalTypeNameWithOngoingFromId,
            goalTypeChoiceToDisplayName,
            false,
            true,
            filterGoalTypeChoices
        ),
        makeENUMSelectFormField(
            control,
            'date_ranges',
            'Date Range (Month Only)',
            dateChoices,
            true
        ),
        makeENUMSelectFormField(
            control,
            'goal_favorability',
            'Favorability',
            favorabilityChoices,
            true
        ),
        // Only Exact Value - just filling in request for now
        // makeEnumSelectFormField(control, 'goal_operation', 'Goal Operation', goalOps),
        makeTextFormField(
            // When op type is known, format this to $ or %
            control,
            'goal_operation_value_low',
            'Target Value Low',
            false,
            true
        ),
        makeTextFormField(
            // When op type is known, format this to $ or %
            control,
            'goal_operation_value_middle',
            'Target Value Middle',
            false,
            true
        ),
        makeTextFormField(
            // When op type is known, format this to $ or %
            control,
            'goal_operation_value_high',
            'Target Value High',
            false,
            true
        )
    ]

    const form = (
        <BaseApiForm
            recordName='Goal'
            formActionType='Create'
            fields={fields}
            useFormRest={useFormRest}
            formStateHooks={formStateHooks}
            // @ts-ignore
            formAPIAction={createGoalAction}
            preFillDirtyFields={[{name: "goal_owners", value: [currentUser?.id]}]}
            // Form can only redirect to detail pages currently
            // formSuccessRedirectPathParam={AppURLS.listGoals}
        />
    )

    const tempBody = <BodyCard header={{title: 'New Goal'}} body={form}/>

    const backDrop = (
        <Backdrop open={goalIsCreating} sx={{zIndex: 1}}>
            <CircularProgress />
        </Backdrop>
    )

    return (
        <HeaderSideBarBody body={<>{backDrop}{tempBody}</>} pageTitle='Create New Goal' navLinks={navLinks}/>
    )
}