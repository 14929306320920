import { SubmitHandler, UseFormReturn} from "react-hook-form";
import { MutationActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Button, Stack, Typography} from "@mui/material";
import { PatchDialog } from "../../dialog/PatchDialog";
import { Dispatch, SetStateAction } from "react";
import { InfoView } from "../../infoView/InfoView";
import { PathParam } from "../../../../util/PathUtil";
import { useNavigate } from "react-router-dom"


export interface IBaseAPIFormProps {
    recordName: string
    refetch?: () => void
    formActionType: 'Update' | 'Create'
    fields: IField[]
    useFormRest: Omit<UseFormReturn<any>, 'control'>
    formStateHooks: [any, Dispatch<SetStateAction<any>>]
    formAPIAction: (formInput: IFormInputType) => MutationActionCreatorResult<any>
    formSuccessRedirectPathParam?: PathParam
    resetAfterSubmit?: boolean
    preFillDirtyFields?: {name: IField['meta']['fieldName'], value: any}[]
    // Implement when needed
    // formAfterSubmitAction: () => any
    // TODO Bool Display Spinner with prop message?
}

interface IFormInputType {
    [key: string]: any | any[]
}

interface IField {
    meta: {
        fieldName: string
        fieldLabel: string
        nameResolver: (value: string) => string
    }
    field: JSX.Element
}

const styles = {
    form: {
        minWidth: '30%'
    }
}

export const BaseApiForm = (props: IBaseAPIFormProps) => {
    const [ dialogOpen, setDialogOpen ] = useState(false)
    const [ formInput, setFormInput ] = props.formStateHooks
    const { handleSubmit, formState, setError } = props.useFormRest
    const { dirtyFields, isDirty } = formState
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    useEffect(() => {
        if (props.preFillDirtyFields){
            props.preFillDirtyFields.map((field) => (
                props.useFormRest.setValue(field.name, field.value, {shouldDirty: true})
            ))
        }
    }, [props.preFillDirtyFields])

    let successWord: string
    let failWord: string
    let confirmWord: string = ''
    if (props.formActionType === 'Create') {
        successWord = 'Created'
        failWord = 'creating'
        confirmWord = 'Create'
    } else if (props.formActionType === 'Update'){
        successWord = 'Updated'
        failWord = 'updating'
        confirmWord = 'Update'
    }

    const handleConfirm = async (formInput: IFormInputType) => {
        interface payloadWithId extends IFormInputType {id: string}
        function hasId(payload: any): payload is payloadWithId {
            return "id" in payload;
        }

        await props.formAPIAction(formInput)
            .unwrap()
            .then((payload) => {
                enqueueSnackbar(`${successWord} ${props.recordName}`, {variant: 'success'});
                if (props.refetch) {
                    props.refetch()
                } if (props.formSuccessRedirectPathParam && hasId(payload)) {
                    navigate(props.formSuccessRedirectPathParam.getFullPath(payload.id))
                } if (props.resetAfterSubmit) {
                    props.useFormRest.reset()
                }
            })
            .catch((error: any) => {
                enqueueSnackbar(`Error ${failWord} ${props.recordName}`, {variant: 'error'});
                Object.entries(error.data).forEach(([field, error]) => {
                    // TODO Keep ts-ignore until error responses are typed in schema
                    // @ts-ignore
                    setError(field, {type: 'server', message: error[0]});
                })
            })
    }

    const onSubmit: SubmitHandler<IFormInputType> = data => {
        const touched = Object.keys(dirtyFields)
        const values = Object.fromEntries(Object.entries(data).filter(([field]) => touched.includes(field)))
        setFormInput(values)
        setDialogOpen(true)
    }
    let dialogBody;

    if (isDirty && formInput) {
        const nodes = (Object.entries(formInput as IFormInputType).map(
            ([field, value]) => {
                const propField = props.fields.find(
                    (propField) => propField.meta.fieldName === field)
                // This is less than ideal, propField *should* always exist, but we still
                // need to initialize a function AND pass in one though meta
                let fieldLabel = field
                let nameResolver = (value: string) => value
                if (propField) {
                    ({ fieldLabel, nameResolver } = propField.meta)
                }
                let cleaned: string[]
                if (Array.isArray(value)) {
                    cleaned = value.map((el) => nameResolver(el))
                } else {
                    cleaned = [nameResolver(value)]
                }
                return {title: fieldLabel, data: cleaned}
            }
            )
        )
        dialogBody = <InfoView nodes={nodes} />
    } else {
        dialogBody = (<Typography variant='body2'>No changes to submit</Typography> )
    }

    const body = (
        <>
            <PatchDialog
                title={`${confirmWord} ${props.recordName} with the following data?`}
                open={dialogOpen}
                setOpen={setDialogOpen}
                onConfirm={handleConfirm.bind(null, formInput as IFormInputType)}
                children={dialogBody}
            />
            <form style={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <Stack gap={2} sx={{pb: 2}}>
                        {props.fields.map((field) => field.field)}
                    </Stack>
                <Button color="primary" variant="contained" fullWidth type="submit" disabled={!isDirty}>
                    Submit
                </Button>
            </form>
        </>
    )

    return (
        body
    )
}