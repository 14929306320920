import { IInfoViewProps, InfoView } from "../../../common/infoView/InfoView";
import { useApiCorePartnerIntakeRetrieveQuery, PartnerIntake, useApiCoreUsersListQuery, ApiCoreUsersListApiResponse
} from "../../../../services/generatedApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { userNameFromId } from "../../../../util/FormUtil";
import { BodyCard } from "../../../common/cards/BodyCard";
import { HeaderSideBarBody } from "../../../layout/headerSideBarBody/HeaderSideBarBody";

export const PartnerIntakeDetail = () => {
    const partnerIntakeId = useParams<{id: PartnerIntake['id']}>()
    const partnerIntakeRes = useApiCorePartnerIntakeRetrieveQuery(
        partnerIntakeId.id ? {id: partnerIntakeId.id} : skipToken
    )
    // TODO store users in state, or make this a custom hook or similar
    const usersRes = useApiCoreUsersListQuery({isActive: true, isNectar: true})

    let usersList: ApiCoreUsersListApiResponse | [] = []
    if (usersRes.isSuccess) {
        usersList = usersRes.data
    }

    let partnerIntake
    if (partnerIntakeRes.isSuccess) {
        partnerIntake = partnerIntakeRes.data
    }

    let viewNode = <Typography>Partner Intake Not Found</Typography>

    if (partnerIntake) {
        const viewNodeData: IInfoViewProps['nodes'] = [
            {title: 'Name', data: [partnerIntake.name]},
            {title:'Created By', data: [userNameFromId(usersList, partnerIntake.created_by)]},
            {title: 'Partner Type', data: [partnerIntake.partner_type]},
            {title: 'Onboarding Contact', data: [
                partnerIntake.onboarding_contact_first + ' ' + partnerIntake.onboarding_contact_last]
            },
            {title: 'Onboarding Contact Email', data: [partnerIntake.onboarding_contact_email]},
            // TODO Link should be able to be a hyperlink - InfoView needs to support this use case
            {title: 'Contract Link', data: [partnerIntake.contract_link]},
            {title: 'Pain Points', data: [partnerIntake.pain_points]},
            {title: 'Notes', data: [partnerIntake.notes]},
            {title: '# Ad Accounts', data: [partnerIntake.num_ad_accounts.toString()]},
            {title: '# SC Accounts', data: [partnerIntake.num_sc_accounts.toString()]},
            {title: '# VC Accounts', data: [partnerIntake.num_vc_accounts.toString()]},
            {title: 'Processed', data: [partnerIntake.partner ? 'Yes' : 'No']}
        ]

        viewNode = <InfoView nodes={viewNodeData} />
    }

    const bodyCard = <BodyCard header={{title: 'Partner Intake'}} body={viewNode} />

    return (
        <HeaderSideBarBody body={bodyCard} pageTitle='Partner Intake'/>
    )
}