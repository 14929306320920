import { List, ListItem, ListItemText, ListItemButton, Collapse } from '@mui/material';
import { PropsWithChildren } from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import { ISideBarNavGroupItem } from "../SideBarNavGroup/SideBarNavGroup";
import { RbacComponent } from "../../../rbac/RbacComponent";
import { UserLevelCheck } from "../../../rbac/UserLevelCheck";
import { useRbac } from "../../../../util/useRbac";
import { SideBarNavModuleItem } from "./SideBarNavModuleItem";


export interface ISideBarNavModuleProps {
    item: ISideBarNavGroupItem
}



export const SideBarNavModule = (props: ISideBarNavModuleProps) => {
    // Component will render empty nav menu categories if all items are unauthorized
    // No good way to handle because items in Collapse menu are not evaluated until menu is opened
    const [ open, setOpen ] = useState(false);
    const [ canView ] = useRbac(props.item.rbacConfig ?? 'skip')

    const handleClick = () => {
        setOpen(!open);
    };

    const ConditionalRenderGroup = ((
        {children, item} : {children : PropsWithChildren['children'], item: ISideBarNavGroupItem}
    ) => {
        if (item.rbacConfig) {
            return (
                <RbacComponent renderCheck={canView}>
                {children}
                </RbacComponent>
            )
        } else if (item.userLevel) {
            return (
                <UserLevelCheck attrCheck={item.userLevel.attrCheck}>
                    {children}
                </UserLevelCheck>
            )
        } else {
            return <>{children}</>
        }
    })


    const navModuleItems = (
        props.item.children.map((child, index) => {
           return <SideBarNavModuleItem item={child} key={index}/>
        })
    )

    return(
            <ConditionalRenderGroup item={props.item} key={props.item.category + "Conditional"}>
            <ListItem key={props.item.category}>
                <ListItemButton onClick={handleClick} >
                <ListItemText primary={props.item.category} />
                {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout='auto' unmountOnExit>
            <List component="div" disablePadding>
                {navModuleItems}
            </List>
            </Collapse>
            </ConditionalRenderGroup>
    );

};