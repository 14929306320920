import dayjs from "dayjs";
import { DateState } from "../services/data/dataSlice";

// enum DatePeriodType {
//     MoM,
//     YoY
// }

type MonthDatePeriod = "MoM" | "YoY"

export const timeAgo = (date: Date | string) => {
    /**
     * Takes a Date or date string and returns a string like: "X minutes ago"
     * @param date date to get comparison for
     * @returns string in "friendly" format
     */

    if (typeof date === 'string') {
        date = new Date(date)
    }

    const seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
        return interval + ' years ago';
    }

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return interval + ' months ago';
    }

    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + ' days ago';
    }

    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + ' hours ago';
    }

    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + ' minutes ago';
    }

    if(seconds < 10) return 'just now';

    return Math.floor(seconds) + ' seconds ago';
};

export const getStartEndCompareMonth = (
    start: Date,
    compareType: MonthDatePeriod,
    dateState: DateState) => {
    /**
     * Takes a start date along with a comparison period type and dataDate state
     * Returns the start and end dates for the month and compare period adjusted for actual data availability
     */
    // Possibly support multiple compare periods
        // Might need to be expanded for other data types (such as advertising)
    const monthStart = dayjs(start).startOf('month')
    const monthEnd = dayjs(start).endOf('month')
    let scEnd = dayjs(dateState.latestSCSalesData)
    let vcEnd = dayjs(dateState.latestVCSalesData)
    // TODO Do we need to handle no data being available for the period or should that be checked before passing in?
    if (monthEnd < scEnd) {
        // return full month if data is longer, else data end
        scEnd = monthEnd
    }
    if (monthEnd < vcEnd) {
        vcEnd = monthEnd
    }
    const scCompareStart = monthStart.subtract(1, compareType === "MoM" ? 'month'
        : compareType === "YoY" ? 'year' : undefined)
    const vcCompareStart = monthStart.subtract(1, compareType === "MoM" ? 'month'
        : compareType === "YoY" ? 'year' : undefined)
    const scCompareEnd = scEnd.subtract(1, compareType === "MoM" ? 'month'
        : compareType === "YoY" ? 'year' : undefined)
    const vcCompareEnd = vcEnd.subtract(1, compareType === "MoM" ? 'month'
        : compareType === "YoY" ? 'year' : undefined)

    return {
        start: monthStart.format('YYYY-MM-DD'),
        end: monthEnd.format('YYYY-MM-DD'),
        scEnd: scEnd.format('YYYY-MM-DD'),
        vcEnd: vcEnd.format('YYYY-MM-DD'),
        scCompareStart: scCompareStart.format('YYYY-MM-DD'),
        vcCompareStart: vcCompareStart.format('YYYY-MM-DD'),
        scCompareEnd: scCompareEnd.format('YYYY-MM-DD'),
        vcCompareEnd: vcCompareEnd.format('YYYY-MM-DD')
    }
}
// const start = dayjs(month).startOf('month')
// let end = dayjs(month).endOf('month')
// const dataEnd = dayjs(dataDates.latestSCSalesData)
// if (end > dataEnd) {
//     end = dataEnd
// }

// TODO
export const getStartEndCompareTrailing = (trailingDays: number, dateState: DateState) => {
    // Possibly support MoM or YoY instead of just PoP
    const scEnd = dayjs(dateState.latestSCSalesData)
    const vcEnd = dayjs(dateState.latestVCSalesData)
    const scStart = scEnd.subtract(trailingDays, 'day')
    const vcStart = vcEnd.subtract(trailingDays, 'day')
    // TODO Verify these dates
    const scCompareEnd = scStart.subtract(1, 'day')
    const vcCompareEnd = vcStart.subtract(1, 'day')
    const scCompareStart = scCompareEnd.subtract(trailingDays, 'day')
    const vcCompareStart = vcCompareEnd.subtract(trailingDays, 'day')
    return {
        scStart: scStart.format('YYYY-MM-DD'),
        scEnd: scEnd.format('YYYY-MM-DD'),
        scCompareStart: scCompareStart.format('YYYY-MM-DD'),
        scCompareEnd: scCompareEnd.format('YYYY-MM-DD'),
        vcStart: vcStart.format('YYYY-MM-DD'),
        vcEnd: vcEnd.format('YYYY-MM-DD'),
        vcCompareStart: vcCompareStart.format('YYYY-MM-DD'),
        vcCompareEnd: vcCompareEnd.format('YYYY-MM-DD')
    }
}