import { HeaderSideBarBody } from "../../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../../common/cards/BodyCard";
import { navLinks } from "../navLinks";
import { BasicTable } from "../../../common/basicTable/BasicTable";
import { BaseCell } from "../../../common/basicTable/cells/BaseCell";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ApiPdmGetListingEventCountListApiArg, ApiPdmGetListingEventCountListApiResponse, ListingEventCount,
    useApiPdmGetListingEventCountListQuery } from "../../../../services/generatedApi";
import { partnerNameFromId } from "../../../../util/FormUtil";
import { selectUserPartners } from "../../../../services/partnerConfig/partnerConfigSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { Button, CircularProgress, Typography, Tooltip, Box } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { AppURLS } from "../../../../appURLS";


const styles = {
    extraCounts: {
        pl: 2
    }
}


const sortEventCount = (a: ListingEventCount, b: ListingEventCount) => {
    if (a.count_total < b.count_total) return 1;
    if (a.count_total > b.count_total) return -1;
    return 0;
}

export const ListingEventsOverview = () => {
    const partners = useSelector(selectUserPartners)
    const [date, setDate] = useState<Dayjs | null>(dayjs())
    const [query, setQuery] = useState<
        ApiPdmGetListingEventCountListApiArg>({})
    const listingEventCountRes = useApiPdmGetListingEventCountListQuery(query)

    useEffect(() => {
        if (!date) return
        // TODO Consider pushing to navigation history when date changes
        setQuery((q) => {return {...q, date: date.format("YYYY-MM-DD")}})
    }, [date, setQuery]);

    let listingEventCount: ApiPdmGetListingEventCountListApiResponse = []
    if (listingEventCountRes.isSuccess) {
        listingEventCount = listingEventCountRes.data
    }

    let body = <CircularProgress />

    if ( listingEventCount && listingEventCount.length > 0 ) {
        const columns = ['Partner', 'Change Count']
        const rows = [...listingEventCount].sort(sortEventCount).map((result) => {
            return {
                rowKey: result.partner,
                cells: [
                    <BaseCell content={
                        <Tooltip title='Click to view Partner History'>
                            <Button
                                component={Link}
                                to={AppURLS.listingEventsPartnerHistory.getFullPath(result.partner)}
                            >
                                {partnerNameFromId(partners, result.partner)}
                            </Button>
                        </Tooltip>
                    }
                    />,
                    <BaseCell content={
                        <Tooltip title='Click to view Partner Day Detail'>
                            <Button component={Link} to={
                                AppURLS.listingEventsDetail.getFullPath(
                                    undefined,
                                    {
                                        partner: result.partner,
                                        date: date ?
                                            date.format("YYYY-MM-DD") :
                                            dayjs().format('YYYY-MM-DD')
                                    })
                            }>

                                        {result.count_total}
                                    {result.count_total > 0 && <Box sx={styles.extraCounts}>
                                        {/*TODO Use number badges here?*/}
                                        {`   (${result.count_high} high,
                                        ${result.count_medium} medium,
                                        ${result.count_low} low)`}
                                    </Box>}
                            </Button>
                        </Tooltip>
                    }
                    />
                ]
            }
        })
        body = (
            <BasicTable columns={columns} rows={rows}/>
        )
    } else if ( !listingEventCountRes.isLoading && listingEventCount.length === 0 ) {
        body = <Typography>{`No listing events for ${date}`}</Typography>
    }

    const dateSelect = (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label='Select Date'
                maxDate={dayjs()}
                value={date}
                onChange={(newDate) => setDate(newDate)}
            />
        </LocalizationProvider>
    )

    const cardBody = <BodyCard body={body} header={{title: 'Listing Events', action: dateSelect}} />
    return (
        <HeaderSideBarBody body={cardBody} pageTitle='Listing Events Overview' navLinks={navLinks} />
    )
}