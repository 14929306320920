import { AppURLS } from "../../../appURLS";

export const navLinks = [
    {
        to: AppURLS.listProducts.getFullPath(),
        label: 'All Products',
        end: true
    },
    {
        to: AppURLS.listingEventsOverview.getFullPath(),
        label: 'Listing Events Overview'
    }
]