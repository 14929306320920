import { ProductListingAmzMeta, useApiSpApiListingsItemCreateMutation,
    useApiSpApiCatalogItemsCreateMutation, ListingDetail } from "../../../../services/generatedApi"
import { Button, Stack, Card, Typography, CircularProgress, useTheme, Box } from "@mui/material";
import { InfoViewEdit, IInfoViewEditProps } from "../../../common/viewEditBlock/InfoViewEdit";
import { Image } from "mui-image";
import { ReactNode, useState } from "react";
import { GenericDialog } from "../../../common/dialog/GenericDialog";
import { asMoney } from "../../../../util/ChartUtils";

export interface IListingMetaViewFormProps {
    listingId: ListingDetail["id"]
    listingSku: ListingDetail['sku']
    meta: ProductListingAmzMeta
    intendedParentAsin?: ListingDetail['intended_parent_asin']
}

export const ListingMetaViewForm = (props: IListingMetaViewFormProps) => {
    // TODO Remove example comparison code used for demo and wire up properly
    const theme = useTheme()
    const styles = {
        amzButton: {
            maxWidth: 250,
            margin: 'auto'
        },
        listingsStack: {
            pb: 2
        },
        listingCard: {
            minWidth: 200
        },
        catalogListingMismatch: {
            color: theme.palette.warning.main,
        }
    }
    // TODO Re-pull data button
    const [ getListing ] = useApiSpApiListingsItemCreateMutation()
    const [ getCatalog ] = useApiSpApiCatalogItemsCreateMutation()
    const [ listingData, setListingData ] = useState<object | ReactNode>()
    const [ catalogData, setCatalogData ] = useState<object | ReactNode>()
    const [ dialogOpen, setDialogOpen ] = useState<boolean>(false)

    const getListingMetaCatalogResAction = async () => {
        setListingData(<CircularProgress />)
        setCatalogData(<CircularProgress />)
        setDialogOpen(true)
        await getListing({listingsItemRequestRequest: {listing: props.listingId}}).then(
            (res) => {
                // Handle the below with better typing
                //@ts-ignore
                if (res.error && !res.data) {
                    //@ts-ignore
                    setListingData(<pre>{JSON.stringify(res.error, null, 2)}</pre>)
                } else {
                    //@ts-ignore
                    setListingData(<pre>{JSON.stringify(res.data, null, 2)}</pre>)
                }
            }
        ).catch((e)=> console.log(e))

        await getCatalog({catalogItemsRequestRequest: {asin: props.meta.asin}}).then(
            (res) => {
                // Handle the below with better typing
                //@ts-ignore
                if (res.error && !res.data) {
                    //@ts-ignore
                    setCatalogData(<pre>{JSON.stringify(res.error, null, 2)}</pre>)
                } else {
                    //@ts-ignore
                    setCatalogData(<pre>{JSON.stringify(res.data, null, 2)}</pre>)
                }
            }
        ).catch((e)=> console.log(e))
    }

    // TODO Update to show and highlight any differences between Catalog and Listing data

    const makeMetaNodes = (meta: ProductListingAmzMeta) => {
        const imageView = (
            <InfoViewEdit
                disableEdit={true}
                gridWidth={5}
                nodes={[
                    {title: 'Main Image', data: [<Image src={meta.image_main ?? ''} height={150} width={150}/>]},
                    {title: 'Image PT01', data: [<Image src={meta.image_pt01 ?? ''} height={150} width={150}/>]},
                    {title: 'Image PT02', data: [<Image src={meta.image_pt02 ?? ''} height={150} width={150}/>]},
                    {title: 'Image PT03', data: [<Image src={meta.image_pt03 ?? ''} height={150} width={150}/>]},
                    {title: 'Image PT04', data: [<Image src={meta.image_pt04 ?? ''} height={150} width={150}/>]},
                    {title: 'Image PT05', data: [<Image src={meta.image_pt05 ?? ''} height={150} width={150}/>]},
                    {title: 'Image PT06', data: [<Image src={meta.image_pt06 ?? ''} height={150} width={150}/>]},
                    {title: 'Image PT07', data: [<Image src={meta.image_pt07 ?? ''} height={150} width={150}/>]},
                    {title: 'Image PT08', data: [<Image src={meta.image_pt08 ?? ''} height={150} width={150}/>]},
                ]}
            />
        )

        const commonMetaInfoViewNodes: IInfoViewEditProps['nodes'] = [
            {title: 'Asin', data: [meta.asin]},
            {title: 'Offer Price', data: [meta.offer_price ? asMoney(meta.offer_price) : '']},
            {title: 'Parent ASIN', data:
                    // TODO Below is proper logic, refactor into function or component to reduce boilerplate
                    // props.intendedParentAsin && meta.parent_asin !== props.intendedParentAsin ?
                    true ?
                        [meta.parent_asin,
                        <Box sx={styles.catalogListingMismatch}>*Parent ASIN does not match intended Parent ASIN</Box>] :
                        [meta.parent_asin]
                },
            {title: 'Brand', data: [meta.brand]},
            {title: 'Item Type Keyword', data: [meta.item_type_keyword]},
            {title: 'Listing Type', data: [meta.listing_type]},
            // TODO See composition of Parent ASIN above, create helper function to return that pattern
            //  return either array of both elements or separate array of one element
            {title: 'Title', data: [
                meta.title,
                    meta.title !== meta.asin ?  // TODO meta.title !== meta.title_listing ...
                        <Box sx={styles.catalogListingMismatch}>*This is an example Title mismatch from listing</Box> :
                        undefined
                ]
            },
            {title: 'Images', data:[imageView]},
            {title: 'Description', data: [meta.description]},
            {title: 'Bullet 1', data: [meta.bullet1]},
            {title: 'Bullet 2', data: [meta.bullet2]},
            {title: 'Bullet 3', data: [meta.bullet3]},
            {title: 'Bullet 4', data: [meta.bullet4]},
            {title: 'Bullet 5', data: [meta.bullet5]},
            {title: 'Generic Keywords', data: [meta.generic_keywords]},
            {title: 'Product Type', data: [meta.product_type]},
            {title: 'UPC', data: [meta.upc]},
            {title: 'Last Pulled', data: [new Date(meta.last_pulled.toString()).toLocaleString()]}
        ]
        return commonMetaInfoViewNodes
    }

    const metaNode = makeMetaNodes(props.meta)

    return (
        <Stack gap={2}>
            <GenericDialog open={dialogOpen} setOpen={setDialogOpen}>
                <>
                    <Typography variant={'h6'}>Listing Data</Typography>
                    {listingData}
                    <br />
                    <br />
                    <Typography variant={'h6'}>Catalog Data</Typography>
                    {catalogData}
                </>


            </GenericDialog>
            <Typography variant={"h5"}>Listing Amazon Meta Data</Typography>
            <Card>
                <InfoViewEdit nodes={metaNode} disableEdit={true}/>
            </Card>
            {/*// TODO This Button*/}
            <Button variant='outlined' size={"small"} sx={styles.amzButton}
                    onClick={()=> {window.alert('Not Implemented Yet')}}>
                Refresh Data From Amazon*
            </Button>
            <Button variant='outlined' size={"small"} sx={styles.amzButton}
                    onClick={()=> {window.alert('Not Implemented Yet')}}>
                Show Listing Change Events*
            </Button>
            <Button variant='outlined' size={"small"} sx={styles.amzButton}
                    onClick={()=> {window.alert('Not Implemented Yet')}}>
                View Listing Dashboard*
            </Button>
            <Button variant='outlined' size={"small"} sx={styles.amzButton}
                    onClick={() => getListingMetaCatalogResAction()}>
                {`Show Full API Data for ${props.listingSku}`}
            </Button>
        </Stack>
    )
}
