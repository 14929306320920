import { Stepper, Step, StepLabel, Box } from "@mui/material";
import { SxProps } from "@mui/system";

export interface IOnboardingStepperProps {
    steps: string[]
    currentStep: number
    sx?: SxProps
}

const styles = {
    root: {
        maxWidth: '80%',
        margin: 'auto'
    }
}

export const OnboardingStepper = (props: IOnboardingStepperProps) => {

    return (
        <Box sx={{...styles.root, ...props.sx}}>
            <Stepper activeStep={props.currentStep} alternativeLabel>
                {props.steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}