import logo from "../../../../resources/Logo_square.png";
import { Box } from "@mui/material";
import {SideBarNavGroup} from "../SideBarNavGroup/SideBarNavGroup";

export interface ISideBarProps {}

export const SideBar = (props: ISideBarProps) => {
    const styles = {
        sidebar: {
            width: 250,
            backgroundColor: "#306A8F",
            height: "100%"
        },
        logoContainer: {
            textAlign: "center" as const
        },
        logo: {
            maxWidth: "100%"
        }
        ,
    };

    return (
        <Box sx={styles.sidebar}>
            <Box sx={styles.logoContainer}>
                <img src={logo} alt='Nectar'/>
            </Box>
            <SideBarNavGroup/>
        </Box>
    )
};