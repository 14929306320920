import { MinimalScoreCard } from "../../../dataVis/scorecards/MinimalScoreCard";
import { Box, Stack, Typography } from "@mui/material";
import { getChangePct, asPercent, IGetDeltaTypeConfig, getDeltaType, asMoney } from "../../../../util/ChartUtils";

export interface IDashboardScoreCardBlockProps {
    acctType: 'SC' | 'VC'
    accountName: string
    sales: number
    salesComp: number
    shippedRev: number,
    shippedRevComp: number,
    adSales: number
    adSalesComp: number
    adSpend: number
    adSpendComp: number
}

const styles = {
    card: {
        pb: 1
    }
}

// TODO Move to "baseDeltaTypeConfig" and add baseUpGood and baseDownGood versions
const deltaTypeConfig: IGetDeltaTypeConfig = {
    severeIncreaseFloor: .1,
    moderateIncreaseFloor: .02,
    moderateDecreaseCeil: -.02,
    severeDecreaseCeil: -.10
}
const reverseDeltaTypeConfig: IGetDeltaTypeConfig = {
    // TODO Figure out why this is coming back as bad
    severeIncreaseFloor: -.1,
    moderateIncreaseFloor: -.02,
    moderateDecreaseCeil: .02,
    severeDecreaseCeil: .10
}

export const DashboardScoreCardBlock = (props: IDashboardScoreCardBlockProps) => {
    // TODO Handle SC vs VC
    // TODO Handle Nostalgia (Custom data strategy Partners)
    let sales
    let salesComp

    if (props.acctType === 'VC') {
        sales = props.shippedRev
        salesComp = props.shippedRevComp
    } else {
        sales = props.sales
        salesComp = props.salesComp
    }

    const organicSales = {
        value: sales - props.adSales,
        comparison: salesComp - props.adSalesComp,
    }

    const acos = {
        value: props.adSpend / props.adSales,
        comparison: props.adSpendComp / props.adSalesComp
    }

    const tacos = {
        value: props.adSpend / sales,
        comparison: props.adSpendComp / salesComp
    }

    return (
        <Box sx={styles.card}>
            <Typography variant='h5'>{props.accountName}</Typography>
            <Stack direction='row' gap={2} justifyContent='space-around'>
                <MinimalScoreCard
                    title={props.acctType === 'VC' ? 'Shipped Revenue' : 'Sales'}
                    value={asMoney(sales)}
                    comparison={asMoney(salesComp)}
                    comparisonPercent={asPercent(getChangePct(sales, salesComp))}
                    deltaType={getDeltaType(getChangePct(sales, salesComp), deltaTypeConfig)}
                />
                <MinimalScoreCard
                    title={'Ad Spend'}
                    value={asMoney(props.adSpend)}
                    comparison={asMoney(props.adSpendComp)}
                    comparisonPercent={asPercent(getChangePct(props.adSpend, props.adSpendComp))}
                    deltaType={getDeltaType(getChangePct(props.adSpend, props.adSpendComp), reverseDeltaTypeConfig)}
                    downGood={true}
                />
                <MinimalScoreCard
                    title={'Ad Sales'}
                    value={asMoney(props.adSales)}
                    comparison={asMoney(props.adSalesComp)}
                    comparisonPercent={asPercent(getChangePct(props.adSales, props.adSalesComp))}
                    deltaType={getDeltaType(getChangePct(props.adSales, props.adSalesComp), deltaTypeConfig)}
                />
                <MinimalScoreCard
                    title={'Organic Sales'}
                    value={asMoney(organicSales.value)}
                    comparison={asMoney(organicSales.comparison)}
                    comparisonPercent={asPercent(getChangePct(organicSales.value, organicSales.comparison))}
                    deltaType={getDeltaType(getChangePct(organicSales.value, organicSales.comparison), deltaTypeConfig)}
                />
                <MinimalScoreCard
                    title={'ACoS'}
                    value={asPercent(acos.value)}
                    comparison={asPercent(acos.comparison)}
                    comparisonPercent={asPercent(getChangePct(acos.value, acos.comparison))}
                    deltaType={getDeltaType(getChangePct(acos.value, acos.comparison), reverseDeltaTypeConfig)}
                    downGood={true}
                />
                <MinimalScoreCard
                    title={'TACoS'}
                    value={asPercent(tacos.value)}
                    comparison={asPercent(tacos.comparison)}
                    comparisonPercent={asPercent(getChangePct(tacos.value, tacos.comparison))}
                    deltaType={getDeltaType(getChangePct(tacos.value, tacos.comparison), reverseDeltaTypeConfig)}
                    downGood={true}
                />
            </Stack>
            {/*<Box>*/}
            {/*    <Typography variant='h6'>Goals</Typography>*/}
            {/*    <Stack direction='row' justifyContent='center' spacing={2}>*/}
            {/*        <div>*Ad Spend Goal - Baseline 90% : Stretch 75%</div>*/}
            {/*        <div>*Sales Goal - Baseline 85% : Stretch 72%</div>*/}
            {/*    </Stack>*/}
            {/*</Box>*/}
        </Box>
    )
}