import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from "react-redux";
import { store } from "./store/store";
import { AppURLS } from "./appURLS";
import { AuthInitializer } from "./components/auth/AuthInitializer";
import { AuthPage } from "./components/pages/AuthPage";
import { PrivateRoutes } from "./components/routing/PrivateRoutes";
import { EmployeeRoutes } from "./components/routing/EmployeeRoutes";
import { PartnerRoutes } from "./components/routing/PartnerRoutes";
import { BaseRedirect } from "./components/routing/BaseRedirect";
import { BaseLandingDash } from "./components/pages/dashboard/BaseLandingDash";
import { BasePartnerDashBoard } from "./components/pages/dashboard/partnerDashboards/BasePartnerDashboard";
import { AmSmLandingDash } from "./components/pages/dashboard/amSmLandingDash/AmSmLandingDash";
import { ExecutiveLandingDash } from "./components/pages/dashboard/executiveLandingDash/ExecutiveLandingDash";
import { SalesGoalOverviewDashboard } from "./components/pages/dashboard/SalesGoalOverviewDashboard";
import { ListProducts } from "./components/pages/products/ListProducts";
import { ProductDetail } from "./components/pages/products/ProductDetail";
import { ListingEventsOverview } from "./components/pages/products/listingChanges/ListingEventsOverview";
import { ListingEventsPartnerHistory } from "./components/pages/products/listingChanges/ListingEventsPartnerHistory";
import { ListingEventsDetail } from "./components/pages/products/listingChanges/ListingEventsDetail";
import { ProductGroups } from "./components/pages/productGroups/productGroups";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SnackbarProvider } from "notistack";
import { CreatePartner } from "./components/pages/partners/CreatePartner";
import { ListPartners } from "./components/pages/partners/ListPartners";
import { PartnerDetail } from "./components/pages/partners/partnerDetail/PartnerDetail";
import { CreateUser } from "./components/pages/users/CreateUser";
import { ListUsers } from "./components/pages/users/ListUsers";
import { UserDetail } from "./components/pages/users/userDetail/UserDetail";
import { SPAPIAuthPage } from "./components/pages/spAPIAuth/SPAPIAuthPage";
import { SPAPIAuthReturn } from "./components/pages/spAPIAuth/SPAPIAuthReturn";
import { UnprocessedPartnerIntakes } from "./components/pages/partners/partnerIntake/UnprocessedPartnerIntakes";
import { PartnerIntakeForm } from "./components/pages/partners/partnerIntake/PartnerIntakeForm";
import { PartnerIntakeDetail } from "./components/pages/partners/partnerIntake/PartnerIntakeDetail";
import { PartnerAccounts } from "./components/pages/partners/partnerAccounts/PartnerAccounts";
import { PartnerOnboardingLayout } from "./components/pages/partnerPages/partnerOnboarding/partnerOnboardingLayout";
import { GoalsTable } from "./components/pages/goals/GoalsTable";
import { CreateGoal } from "./components/pages/goals/CreateGoal";
import { badgePalette } from "./types/theme";
// TODO Remove after Dev
import { _GoalsTable } from "./components/pages/goals/_GoalsTable";


// Probably move to another file
export const theme = createTheme({
    palette: {
        ...badgePalette
    }
});

function App() {
    return (
        // TODO move provider and router to index?
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                            <BrowserRouter>
                                <AuthInitializer/>
                                <Routes>
                                    {/*Public Routes*/}
                                    <Route path={AppURLS.authPage.pathSchema()} element={<AuthPage/>}/>
                                    <Route path={AppURLS.spAuth.pathSchema()} element={<SPAPIAuthPage/>}/>
                                    <Route path={AppURLS.spReturn.pathSchema()} element={<SPAPIAuthReturn/>}/>

                                    <Route element={<PrivateRoutes/>}>
                                        <Route element={<PartnerRoutes/>}>
                                            <Route
                                                path={AppURLS.partnerOnboarding.pathSchema()}
                                                element={<PartnerOnboardingLayout/>}
                                            />
                                        </Route>
                                        <Route element={<EmployeeRoutes/>}>
                                            <Route
                                                path={AppURLS.baseLandingDash.pathSchema()}
                                                element={<BaseLandingDash />} />
                                            {/*TODO Remove the two below after development*/}
                                            <Route
                                                path={AppURLS.amSmLandingDash.pathSchema()}
                                                element={<AmSmLandingDash />} />
                                            <Route
                                                path={AppURLS.executiveLandingDash.pathSchema()}
                                                element={<ExecutiveLandingDash/>}
                                            />
                                            <Route
                                                path={AppURLS.basePartnerDash.pathSchema()}
                                                element={<BasePartnerDashBoard />}
                                            />
                                            <Route
                                                path={AppURLS.salesGoalOverviewDash.pathSchema()}
                                                element={<SalesGoalOverviewDashboard />}
                                            />
                                            <Route path={AppURLS.listProducts.pathSchema()} element={<ListProducts/>}/>
                                            <Route path={AppURLS.detailProduct.pathSchema()}
                                                   element={<ProductDetail/>}/>
                                            <Route path={AppURLS.listingEventsOverview.pathSchema()}
                                                   element={<ListingEventsOverview/>} />
                                            <Route path={AppURLS.listingEventsPartnerHistory.pathSchema()}
                                                   element={<ListingEventsPartnerHistory/>} />
                                            <Route path={AppURLS.listingEventsDetail.pathSchema()}
                                                   element={<ListingEventsDetail/>} />
                                            <Route path={AppURLS.productGroups.pathSchema()}
                                                   element={<ProductGroups/>}/>
                                            <Route path={AppURLS.createPartner.pathSchema()}
                                                   element={<CreatePartner/>}/>
                                            <Route path={AppURLS.listPartners.pathSchema()} element={<ListPartners/>}/>
                                            <Route path={AppURLS.detailPartner.pathSchema()}
                                                   element={<PartnerDetail/>}/>
                                            <Route path={AppURLS.createEmployee.pathSchema()} element={<CreateUser/>}/>
                                            <Route path={AppURLS.listEmployees.pathSchema()} element={<ListUsers/>}/>
                                            <Route path={AppURLS.detailEmployee.pathSchema()} element={<UserDetail/>}/>
                                            <Route
                                                path={AppURLS.listPartnerIntakes.pathSchema()}
                                                element={<UnprocessedPartnerIntakes/>}
                                            />
                                            <Route
                                                path={AppURLS.createPartnerIntake.pathSchema()}
                                                element={<PartnerIntakeForm/>}/>
                                            <Route
                                                path={AppURLS.detailPartnerIntake.pathSchema()}
                                                element={<PartnerIntakeDetail/>}/>
                                            <Route
                                                path={AppURLS.listPartnerAccounts.pathSchema()}
                                                element={<PartnerAccounts/>}/>
                                            <Route path={AppURLS.listGoals.pathSchema()} element={<GoalsTable/>}/>
                                            <Route path={AppURLS.createGoal.pathSchema()} element={<CreateGoal/>}/>
                                            <Route path={AppURLS.demoGoals.pathSchema()} element={<_GoalsTable/>}/>
                                        </Route>
                                        <Route path='*' element={<BaseRedirect/>}/>
                                    </Route>
                                </Routes>
                            </BrowserRouter>
                        </SnackbarProvider>
                </LocalizationProvider>
            </Provider>
        </ThemeProvider>
    );
}

export default App;
