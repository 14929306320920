import { ReactElement } from "react";
import { Box } from '@mui/material'
import { PartnerHeader } from "./PartnerHeader";

export interface IPartnerBaseLayoutProps {
    navBar?: ReactElement
    body: ReactElement
    tempLogo?: boolean
}

const styles = {
    root: {
        overflowY: 'auto',
        height: "100%",
        width: "100%",
        backgroundColor: "#F5F5F5" as const
    },
    contentBody:{
        textAlign: "center",
        justifyContent: "center",
    }
};

export const PartnerBaseLayout = (props: IPartnerBaseLayoutProps) => {
    const tempLogo = 'https://drive.google.com/uc?id=1mWNSp6o0rjMYVaHbNFR2PB6GX43y7TGE'

    return (
        <Box margin='auto' sx={styles.root}>
            <PartnerHeader partnerLogo={props.tempLogo ? tempLogo : undefined}/>
            {props.navBar}
            <Box sx={styles.contentBody}>
                {props.body}
            </Box>
        </Box>
    )
}