import { PropsWithChildren } from "react";

interface IRbacComponentProps extends PropsWithChildren {
    renderCheck: boolean
}

/**
 * Can gate rendering of a component based on boolean prop. Meant to be used with canRead or canWrite value
 * returned from {@link useRbac} Used when more granular access control is needed than {@link UserLevelCheck} provides.
 */
export const RbacComponent = (props: IRbacComponentProps) => {
    if (props.renderCheck) {
        return <>{props.children}</>
    } else {
        return null
    }

}