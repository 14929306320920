import { Box, Divider, Stack } from "@mui/material";
import { LogoBlock } from './LogoBlock'

export interface IPartnerHeaderProps {
    partnerLogo?: string
    // TODO get this from State
}

const styles = {
    headerRoot: {
        backgroundColor: "white" as const,
        paddingTop: 2
    }
};

export const PartnerHeader = (props: IPartnerHeaderProps) => {
    const logo = require('../../../resources/Ambrosia By Nectar_Logo_Horizontal.png')

    const internalLogoBlock = (
        <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
            <img alt='Nectar Logo' style={{width: 350}} src={String(logo)} />
        </Stack>
    )

    return (
        <Box sx={styles.headerRoot}>
            {props.partnerLogo ? <LogoBlock partnerLogo={props.partnerLogo} />: internalLogoBlock}
            <Divider variant='middle' style={{paddingTop:20}}/>
        </Box>
    )
}