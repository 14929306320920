import { useForm } from "react-hook-form";
import { PartnerDetailRead, PatchedPartnerDetailWriteRequest, useSlackConversationsListQuery,
    useApiCorePartnersPartialUpdateMutation, useApiCoreUsersListQuery, User } from "../../../../services/generatedApi";
import { useState } from "react";
import { BaseApiForm } from "../../../common/forms/BaseForm/BaseAPIForm";
import { MakeTextFormField } from "../../../common/forms/BaseForm/fields/TextFormField";
import { MakeChipSelectFormField } from "../../../common/forms/BaseForm/fields/ChipSelectFormField";
import { MakeBooleanFormField } from "../../../common/forms/BaseForm/fields/BooleanFormField";
import { userChoiceToDisplayName, userNameFromId, slackConversationNameFromId, slackConversationChoiceToDisplayName
} from "../../../../util/FormUtil";

export interface IPartnerDetailFormProps {
    partner: PartnerDetailRead
    refetch: () => void
}

interface IFormInput extends PatchedPartnerDetailWriteRequest {}

const partnerToForm = (partner: PartnerDetailRead): IFormInput => {
    return {
        name: partner.name,
        short_code: partner.short_code,
        is_active: partner.is_active,
        // @ts-ignore - Select needs '' instead of null for initial value
        slack_channel: partner.slack_channel ?? '',
        logo_url: partner.logo_url ?? '',
        partner_sm: partner.partner_sm?.id ?? '',
        partner_am: partner.partner_am?.id ?? ''
    }
}

const filterSMs = (users: User[]): User[] => (
    // Get this from automatic ENUM from API types
    users.filter(
        (user) => user.user_roles.map(
            (role) => role.role.name).includes('SM')
    )
)

const filterAMs = (users: User[]): User[] => (
    // Get this from automatic ENUM from API types
    users.filter(
        (user) => user.user_roles.map(
            (role) => role.role.name).includes('AM')
    )
)

export const PartnerDetailForm = (props: IPartnerDetailFormProps) => {
    const initialValues = partnerToForm(props.partner)
    const makeTextFormField = MakeTextFormField
    const makeChipSelectFormField = MakeChipSelectFormField
    const makeBooleanFormField = MakeBooleanFormField
    const { control, ...useFormRest } = useForm<IFormInput>({defaultValues: initialValues});
    const [ updatePartner ] = useApiCorePartnersPartialUpdateMutation()
    const userListQuery = useApiCoreUsersListQuery({isActive: true, isNectar: true})
    const slackChannelListQuery = useSlackConversationsListQuery()
    const formStateHooks = useState<IFormInput>(initialValues)


    const updatePartnerAction = (formInput: IFormInput) => (
        updatePartner(
            {
                id: props.partner.id,
                patchedPartnerDetailWriteRequest: {...formInput}
            }
        )
    )

    const isActiveField = makeBooleanFormField(
        control,
        'is_active',
        'Is Active'
    )

    const fields = [
        makeTextFormField(control, 'name', 'Partner Name'),
        makeTextFormField(control, 'short_code', 'Partner Short Code'),
        isActiveField,  // TODO Restrict this field
        makeBooleanFormField(control, 'has_custom_data_strategy', 'Custom Data Strategy'),
        makeChipSelectFormField(
            control,
            'slack_channel',
            'Slack Channel',
            slackChannelListQuery,
            slackConversationNameFromId,
            slackConversationChoiceToDisplayName,
            undefined,
            undefined,
            undefined,
            undefined,
            {id: '', name: 'None'}

        ),
        makeTextFormField(control, 'group_email_address', 'Group Email Address'),
        makeTextFormField(control, 'logo_url', 'Logo Url'),
        makeChipSelectFormField(
            control,
            'partner_sm',
            "Partner's SM",
            userListQuery,
            userNameFromId,
            userChoiceToDisplayName,
            undefined,
            undefined,
            filterSMs
        ),
        makeChipSelectFormField(
            control,
            'partner_am',
            "Partner's AM",
            userListQuery,
            userNameFromId,
            userChoiceToDisplayName,
            undefined,
            undefined,
            filterAMs
        )


    ]

    return (
        <BaseApiForm
            recordName='Partner'
            formActionType='Update'
            refetch={props.refetch}
            fields={fields}
            useFormRest={useFormRest}
            // @ts-ignore
            formStateHooks={formStateHooks}
            formAPIAction={updatePartnerAction}
        />
    )
}