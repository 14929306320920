import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/store'
import type { PartnerList } from "../generatedApi"

type ConfigState = {
    userPartners: PartnerList[] | []
    selectedPartner: PartnerList | null
}

const slice = createSlice({
    // TODO set All Partners to be used in ID to name functions
    //  Urls with parameters may be shared to unassigned users
    name: 'partnerConfig',
    initialState: {userPartners: [], selectedPartner: null} as ConfigState,
    reducers: {
        setUserPartners: (
            state,
            {payload: {partners} }: PayloadAction<{partners: PartnerList[]} >
        ) => {
            state.userPartners = partners
        },
        setPartner: (
            state,
            {payload: { partner } }: PayloadAction<{ partner: PartnerList }>
        ) => {
            state.selectedPartner = partner
        }
    }
})

export const { setPartner, setUserPartners } = slice.actions;

export default slice.reducer;

export const selectUserPartners = (state: RootState) => state.partnerConfig.userPartners
export const selectCurrentPartner = (state: RootState) => state.partnerConfig.selectedPartner