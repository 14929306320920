import { Navigate, Outlet, useLocation } from "react-router-dom"
import { AppURLS } from "../../appURLS";
import { selectLoggedIn } from "../../services/auth/authSlice";
import { useSelector } from "react-redux";


export const PrivateRoutes = () => {
    const loggedIn = useSelector(selectLoggedIn)
    const location = useLocation()
    const next = location.pathname + location.search

    if (loggedIn === undefined) {
        return null
    }

    return (
        loggedIn
            ? <Outlet />
            : <Navigate to={AppURLS.authPage.getFullPath(undefined, {continue: next})} />
    )
}