import { PropsWithChildren, ReactElement } from "react";
import { Box, Stack, Tooltip } from "@mui/material";
import { badgePalette } from "../../../types/theme";
import { useTheme } from "@mui/material/styles";

export interface ICardBadgeProps extends PropsWithChildren {
    color: keyof typeof badgePalette,
    icon?: ReactElement
    tooltip?: string
}

const styles = {
    // Possibly add a maxWidth, or just use in a grid
    badge: {
        opacity: .75,
        borderRadius: 12,
        cursor: 'default',
        display: 'inline-flex',
        flexGrow: 0,
        flexShrink: 0,
        lineHeight: 2,
        fontWeight: 600,
        letterSpacing: 0.5,
        minWidth: 20,
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        // Placeholders that are replaced in body by theme or prop definitions
        paddingLeft: 'placeholder for theme value',
        paddingRight: 'placeholder for theme value',
        color: 'placeholder',
        backgroundColor: 'placeholder'
    }
}
// TODO document this better, figure out how to reference types for the colors it accepts
/**
 * Base badge component that takes in a color, children, optional icon, and optional tooltip to display a colored bubble
 */
export const CardBadge = (props: ICardBadgeProps) => {
    const theme = useTheme()
    const icon = props.icon ?? null

    styles.badge.paddingLeft = theme.spacing(1)
    styles.badge.paddingRight = theme.spacing(1)
    styles.badge.backgroundColor = theme.palette[props.color].lightBackground
    styles.badge.color = theme.palette[props.color].darkText

    const ConditionalToolTip = (({children}: {children : PropsWithChildren['children']}) => {
        // Tooltips don't render with Fragment, needs to be a Box wrapper
            if (props.tooltip) {
                return (
                    <Tooltip title={props.tooltip}>
                         <Box>{children}</Box>
                     </Tooltip>
                 )
             } else {
                 return <>{children}</>
             }
         })

    return (
        <Box sx={styles.badge} >
            <ConditionalToolTip>
                <Stack alignItems='center' alignContent='center' direction='row' gap={theme.spacing(1)}>
                    {icon}
                    {props.children}
                </Stack>
            </ConditionalToolTip>
        </Box>
    )
}