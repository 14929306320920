import { Box } from "@mui/material";

export interface IEmbedProps {
    url: string
    // height: number
}

export const Embed = (props: IEmbedProps) => {

    return (
        <Box>
            <iframe
                title='Embedded Dashboard'
                id='dashFrame'
                style={{overflow: 'hidden'}}
                width="100%"
                height={800}
                // height={props.height}
                src={props.url}
                allow='autoplay'
            />
        </Box>
    )
}