import { BodyCard } from "../common/cards/BodyCard";
import { TokenObtainPairRequest, useAuthTokenCreateMutation } from "../../services/generatedApi";
import { useAppDispatch } from "../../hooks";
import { setLoggedIn, setToken } from "../../services/auth/authSlice";
import { Button, Stack, TextField } from "@mui/material"
import Cookies from "universal-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { AppURLS } from "../../appURLS";
import { Controller, useForm } from "react-hook-form";
import { batch } from "react-redux";

const styles = {
    form: {
        width: '40%',
        margin: 'auto'
    }
}

interface IFormInput extends TokenObtainPairRequest {}

// TODO Redo this form, make error handling better

export const AuthForm = () => {
    const cookies = new Cookies()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const [ login ] = useAuthTokenCreateMutation()
    const initialValues: IFormInput = {email: '', password: ''}
    const { control, handleSubmit, formState, setError } = useForm<IFormInput>({defaultValues: initialValues})
    const { isDirty } = formState

    const next = new URLSearchParams(location.search).get('continue')

    const onSubmit = async (values: TokenObtainPairRequest) => {
        try {
            const res = await login({tokenObtainPairRequest: values}).unwrap()
            if (res.access !== undefined) {
                batch(() => {
                    dispatch(setToken({token: res.access}))
                    dispatch(setLoggedIn({value: true}))
                })
                cookies.set('auth', res.refresh, {httpOnly: false})
                navigate(next ?? AppURLS.baseLandingDash.getFullPath())
            } else {
                // Probably need to remove this
                window.alert(res)
            }
        } catch (error: any) {
            Object.entries(error.data).forEach((error) => {
                // Manual Error from token function because we want to obfuscate which field is the issue
                // Keep ts-ignore until error responses are typed in schema
                // @ts-ignore
                setError('email')
                // @ts-ignore
                setError('password', {type: 'server', message: error[1]})
            })
        }
    };

    const form = (
        <form onSubmit={handleSubmit(onSubmit)}>
                <Stack gap={2} sx={{pb:2}}>
                    <Controller
                        name='email'
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}, fieldState: {error}}) => (
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}/>
                    <Controller
                        name='password'
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}, fieldState: {error}}) => (
                            <TextField
                                label="Password"
                                variant="outlined"
                                fullWidth
                                type='password'
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}/>
                </Stack>
            <Button color="primary" variant="contained" fullWidth type="submit" disabled={!isDirty}>
                Submit
            </Button>
        </form>
    )

    return <BodyCard body={form} sx={styles.form}/>
};