import { Box, Chip, MenuItem, TextField } from "@mui/material";
import { Controller, Control } from "react-hook-form"
import { IBaseAPIFormProps } from "../BaseAPIForm";
import { UseQueryStateResult } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { ITextFormFieldProps } from "./TextFormField";

interface IChoicesItem {
    id: string
    [key: string]: any
}

export interface IChipSelectFormFieldProps extends ITextFormFieldProps {
    choicesList: IChoicesItem[]
    nameFromIdFunc: (arg0: Array<any & IChoicesItem>, value: IChoicesItem['id']) => string
    choiceToDisplayFunc: (choice: any) => string
    multiple?: boolean
    noneChoice?: IChoicesItem
}

export type IChipSelectChoiceFilterFunction =
    ((arg0: Array<any & IChoicesItem>, value?: string) => Array<any & IChoicesItem>) | undefined
export type IChipSelectRecordsRes = UseQueryStateResult<any, any>
export type IChipSelectSortFunc =  ((a: any, b: any) => number) | undefined

export const MakeChipSelectFormField = (
    control: Control<any>,
    fieldName: string,
    fieldLabel: string,
    recordsRes: IChipSelectRecordsRes,
    nameFromIdFunc: IChipSelectFormFieldProps['nameFromIdFunc'],
    choiceToDisplay: IChipSelectFormFieldProps['choiceToDisplayFunc'],
    // TODO Move optional parameters to options object
    multiple: boolean | undefined = undefined,
    required: boolean | undefined = undefined,
    choiceFilterFunc: IChipSelectChoiceFilterFunction = undefined,
    sortFunc: IChipSelectSortFunc = undefined,
    noneChoice: IChipSelectFormFieldProps['noneChoice'] = undefined,
): IBaseAPIFormProps['fields'][number] => {
    let records: UseQueryStateResult<any, any> | [] = []
    if (recordsRes.isSuccess) {
        records = recordsRes.data
        if (choiceFilterFunc) {
            records = choiceFilterFunc(records)
        } if (sortFunc) {
            records = [...records].sort(sortFunc)
        } if (noneChoice) {
            records = [noneChoice, ...records]
        }
    }

    return {
        meta: {
            fieldName: fieldName,
            fieldLabel: fieldLabel,
            nameResolver: nameFromIdFunc ? nameFromIdFunc.bind(undefined, records) : (value: string) => value
        },
        field:
            <ChipSelectFormField
                key={fieldName}
                control={control}
                meta={{fieldName: fieldName, fieldLabel: fieldLabel, required: required}}
                choicesList={records}
                nameFromIdFunc={nameFromIdFunc}
                choiceToDisplayFunc={choiceToDisplay}
                multiple={multiple}
            />
    }
}

export const ChipSelectFormField = (props: IChipSelectFormFieldProps) => {
    const multipleProps = {
        multiple: true,
        renderValue: (selected:any) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: string) => (
                    <Chip key={value} label={props.nameFromIdFunc(props.choicesList, value)} />
                ))}
            </Box>
        )
    }

    const singleProps = {
        renderValue: (selected:any) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    <Chip key={selected} label={props.nameFromIdFunc(props.choicesList, selected)} />
            </Box>
        )
    }

    return (
        <Controller
            name={props.meta.fieldName}
            control={props.control}
            rules={{required: props.meta.required}}
            render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                    label={props.meta.fieldLabel}
                    select
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    SelectProps={props.multiple ? multipleProps : singleProps}
                >
                    {props.choicesList.map((choice) => (
                        <MenuItem key={choice.id} value={choice.id}>
                            {props.choiceToDisplayFunc(choice)}
                        </MenuItem>
                    ))}
                </TextField>
            )}/>
    )
}