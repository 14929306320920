import { PartnerList, Role, User, AmzAccount, AmzAdAccount, ProductGroup, ProductTag, GoalType, ConversationsList
} from "../services/generatedApi";

// Roles
export const roleNameFromId = (roles: Role[], value: Role['id']) => {
    const match = roles.find((role) => (role.id === value))
    if (!match) {return 'Role Not Found'}
    return match.department.name + "-" + match.name
}

export const roleChoiceToDisplayName = (choice: Role) => choice.department.name + ' ' + choice.name

export const sortRolesByDepartment = (a: Role, b: Role) => {
    if (a.department.name < b.department.name) {
        return -1;
    } if (a.department.name > b.department.name) {
        return 1;
    } else {
        return 0
    }
}

// User Partner
export const partnerNameFromId = (partners: PartnerList[], value: PartnerList['id']) => {
    return partners.find((partner) => (partner.id === value))?.name ?? 'Partner Not Found'
}

export const partnerChoiceToDisplayName = (partner: PartnerList) => `${partner.name} (${partner.short_code})`

export const sortPartnersByName = (a: PartnerList, b: PartnerList) => {
    if (a.name < b.name) {
        return -1;
    } if (a.name > b.name) {
        return 1;
    } else {
        return 0
    }
}

// Users
export const userNameFromId = (users: User[], value: User['id']) => {
    const match = users.find((user) => (user.id === value))
    if (!match) {return 'User Not Found'}
    return match.first_name + ' ' + match.last_name
}

export const userChoiceToDisplayName = (choice: User) => choice.first_name + ' ' + choice.last_name

export const sortUsersByLastName = (a: User, b: User) => {
    if (a.last_name < b.last_name) {
        return -1;
    } if (a.last_name > b.last_name) {
        return 1;
    } else {
        return 0
    }
}

// Amz and Amz Ad Accounts
export const amzAccountNameFromId = (accounts: AmzAccount[], value: AmzAccount['id']) => {
    const match = accounts.find((account) => (account.id === value))
    if (!match) {return 'Amz Account Not Found'}
    return match.name
}

export const amzAccountChoiceToDisplayName = (choice: AmzAccount) => choice.name

export const amzAdAccountNameFromId = (accounts: AmzAdAccount[], value: AmzAdAccount['id']) => {
    const match = accounts.find((account) => (account.id === value))
    if (!match) {return 'Amz Ad Account Not Found'}
    return match.name
}

export const amzAdAccountChoiceToDisplayName = (choice: AmzAdAccount) => choice.name

// Product Groups
// TODO Think through adding Nesting in name, then sort alphabetically in forms which should keep nesting intact
export const productGroupNameFromId = (groups: ProductGroup[], value: ProductGroup['id']) => {
    const match = groups.find((group) => (group.id === value))
    if (!match) {return 'Product Group Not Found'}
    return match.name
}

export const productGroupChoiceToDisplayName = (choice: ProductGroup) => choice.name

// ProductTags
export const productTagNameFromId = (productTags: ProductTag[], value: ProductTag['id']) => {
    const match = productTags.find((tag) => (tag.id === value))
    if (!match) {return 'Product Tag Not Found'}
    return match.name
}

export const productTagChoiceToDisplayName = (choice: ProductTag) => choice.name

// TODO Remove
// Amazon Listings
// export const productListingAmazonNameFromId = (listings: ProductListingAmazon[], value: ProductListingAmazon['id']) => {
//     const match = listings.find((listing) => listing.id === value)
//     if (!match) {return 'Amazon Listing not found'}
//     return `${match.asin} - ${match.sku}`
// }
//
// export const productListingChoiceToDisplayName = (choice: ProductListingAmazon) => `${choice.asin} - ${choice.sku}`

// Goal Types
export const goalTypeChoiceToDisplayName = (choice: GoalType) =>
    `${choice.kpi} - ${choice.period} ${choice.ongoing ? 'Recurring' : 'One Time'}`

export const goalTypeNameFromId = (goalTypes: GoalType[], value: GoalType['id']) => {
    const match = goalTypes.find((goalType) => goalType.id === value)
    if (!match) {return 'Goal Type not found'}
    return `${match.kpi} - ${match.period}`
}

export const goalTypeNameWithOngoingFromId = (goalTypes: GoalType[], value: GoalType['id']) => {
    const match = goalTypes.find((goalType) => goalType.id === value)
    if (!match) {return 'Goal Type not found'}
    return goalTypeChoiceToDisplayName(match)
}

// Slack
export const slackConversationChoiceToDisplayName = (choice: ConversationsList) => choice.name

export const slackConversationNameFromId = (conversations: ConversationsList[], value: ConversationsList['id']) => {
    // if (value === '') {return 'None'}
    const match = conversations.find((conversation) => (conversation.id === value))
    if (!match) {return 'Conversation Not Found'}
    return match.name
}