import { AsyncTask, useApiCoreAsyncTasksListQuery, useApiGoalsResolveAllGoalsCreateMutation,
    useApiPdmGetWholesalerDetailsCreateMutation } from "../../../services/generatedApi";
import { Box, Badge, Menu, MenuItem, IconButton, Typography, Divider, Button } from "@mui/material";
import { Notifications } from "@mui/icons-material";
import React, { useState, useEffect, useMemo } from "react";
import { SxProps } from "@mui/system";
import { useInterval } from "../../../util/useInterval";
import { timeAgo } from "../../../util/DateTimeUtils";
import { UserLevelCheck } from "../../rbac/UserLevelCheck";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../hooks";
import { selectShouldPollLongRunningTasks, setShouldPollLongRunningTasks } from "../../../services/auth/authSlice";
import { selectDataDates } from "../../../services/data/dataSlice";

export interface IAlertMenuProps {
    sx: SxProps
}

const styles = {
    title: {
        pl: 1,
        pr: 1
    },
    divider: {
        maxWidth: '90%',
        margin: 'auto'
    }
}

export const AlertMenu = (props: IAlertMenuProps) => {
    const asyncTasksRes = useApiCoreAsyncTasksListQuery()
    const [ anchorEl, setAnchorEl ] = useState<null | HTMLButtonElement>(null);
    const [ delay, setDelay ] = useState<number>(1000)
    const dispatch = useAppDispatch()
    const shouldPollLongRunningTasks = useSelector(selectShouldPollLongRunningTasks)
    const currentDataDates = useSelector(selectDataDates)
    const [ resolveGoals ] = useApiGoalsResolveAllGoalsCreateMutation()
    const [ pullWholesalerDetails ] = useApiPdmGetWholesalerDetailsCreateMutation()

    const checkAsync = () => {
        // Start with one-second delay for the first trigger, then increase
        if (delay === 1000) {
            setDelay(31000)
        }
        asyncTasksRes.refetch()
    }

    useInterval(checkAsync, shouldPollLongRunningTasks ? delay : undefined)

    const asyncTasks = useMemo(()=> {
        let asyncTasksTemp: AsyncTask[] | [] = []
        if (asyncTasksRes.isSuccess) {
            asyncTasksTemp = asyncTasksRes.data
        }
        return asyncTasksTemp
    },[asyncTasksRes.isSuccess, asyncTasksRes.data])

    useEffect(() => {
        //Don't continuously poll if there are no tasks, or no tasks expecting updates (currently uncompleted)
        if (asyncTasks.length === 0) {
            dispatch(setShouldPollLongRunningTasks({value: false}))
        }
        else if (asyncTasks.filter(
            // Check uppercase, because ENUM shows DB value, not name
            (task) => task.status === 'PENDING' ||
                task.status === 'STARTED').length === 0)
        {
            dispatch(setShouldPollLongRunningTasks({value: false}))
        }
        else {
            dispatch(setShouldPollLongRunningTasks({value: true}))
        }
    }, [asyncTasks, dispatch])



    const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    };

    const closeHandler = () => {
        setAnchorEl(null)
    };

    // Update when alerts API developed
    let alerts: [] = []

    const resolveGoalsButton = (
        <UserLevelCheck attrCheck="is_superuser">
            <Button onClick={() => {
                resolveGoals();
                dispatch(setShouldPollLongRunningTasks({value: true}));
            }
            }>Resolve All goals</Button>
        </UserLevelCheck>
    )

    const pullWholesalerDetailsButton = (
        <UserLevelCheck attrCheck="is_superuser">
            <Button onClick={() => {
                pullWholesalerDetails();
                dispatch(setShouldPollLongRunningTasks({value: true}));
            }
            }>Pull Wholesaler Details</Button>
        </UserLevelCheck>
    )

    return (
        <Box sx={props.sx}>
            <IconButton onClick={clickHandler}>
                <Badge
                    badgeContent={asyncTasks.length + alerts.length}
                    // Palette needs updating, warning should be yellow
                    color={shouldPollLongRunningTasks? 'warning' : 'primary'}
                >
                    <Notifications/>
                </Badge>
            </IconButton>
            <Menu open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={closeHandler}
            >
                {/* SU_DEV Remove outside of development*/}
                {resolveGoalsButton}
                {pullWholesalerDetailsButton}
                <UserLevelCheck attrCheck='is_superuser'>
                    <Typography variant='subtitle2'>
                        *Currently Polling: {shouldPollLongRunningTasks?.toString()}
                    </Typography>
                </UserLevelCheck>
                <Typography variant='h6' sx={styles.title}>Long-Running Tasks</Typography>
                <Divider sx={styles.divider} />
                {asyncTasks.length > 0 ? asyncTasks.map((task) =>
                    //TODO Clean this up - grid format, colors, etc, allow for clearing/acknowledging
                        <MenuItem key={task.id}>
                            {task.status} - {task.note} : {timeAgo(task.updated)}</MenuItem>)
                    : <MenuItem key='NoTasks'>No Tasks</MenuItem>
                }
                <Typography variant='h6' sx={styles.title}>Alerts</Typography>
                <Divider sx={styles.divider} />
                {alerts.length > 0 ?
                    alerts.map((alert) => alert)
                    //Alerts should be handleable from alert component when built - link to or surface it here
                    : <MenuItem key='NoAlerts'>No Alerts</MenuItem>
                }
                <Typography variant='h6' sx={styles.title}>Latest Data Dates</Typography>
                <Divider sx={styles.divider} />
                {currentDataDates ?
                    <Box>
                        <MenuItem key='sc'>
                            SC Sales: {new Date(currentDataDates.latestSCSalesData).toDateString()}
                        </MenuItem>
                        <MenuItem key='vc'>
                            VC Sales: {new Date(currentDataDates.latestVCSalesData).toDateString()}
                        </MenuItem>
                        <MenuItem key='ad'>
                            Ad Data: {new Date(currentDataDates.latestAdData).toDateString()}
                        </MenuItem>
                    </Box>
                    : ''
                }
            </Menu>

        </Box>
    )
}
