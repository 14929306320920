import { Stack } from "@mui/material";
import { BodyCard, IBodyCardProps } from "./BodyCard";
import { ReactElement, ReactNode } from "react";

export interface IDoubleBodyCardProps {
    leftCard: ReactElement<IBodyCardProps>
    rightCard: ReactElement<IBodyCardProps>
    title?: string
    action?: ReactNode
    // Refactor to destructure or take entire BodyCard header prop
}

const styles = {
    cardOverRide: {
        backgroundColor: 'WhiteSmoke',
        maxWidth: '90%'
    },
    root: {
        '.MuiCard-root:nth-of-type(1)': {
            minWidth: '48%',
            mt: 0
        },
        '.MuiCard-root:nth-of-type(2)': {
            minWidth: '48%',
            mt: 0
        }
    }
}

export const DoubleBodyCard = (props: IDoubleBodyCardProps) => {

    const body = (
        <Stack sx={styles.root} direction='row' gap={2}>
            {props.leftCard}{props.rightCard}
        </Stack>
    )

    return (
        <BodyCard
            header={{title: props.title, action: props.action}}
            body={body}
            sx={styles.cardOverRide}
        />
    )
}