import { Card, CardContent, CardHeader, Typography, Box } from "@mui/material";
import { SxProps } from '@mui/system'
import { ReactNode } from "react";

export interface IBodyCardProps {
    header?: {
        title?: string
        subheader?: string
        action?: ReactNode
    }
    body?: ReactNode
    footerText?: string
    footerChild?: ReactNode
    sx?: SxProps
}

const styles = {
    root: {
        maxWidth: "80%",
        margin: 'auto',
        marginTop: "5em",
        marginBottom: "2em"
    },
    headerParent: {
        position: 'relative' as const,
    },
    headerAction: {
        position: 'absolute' as const,
        top: '10px',
        right: '10px'
    }
}


export const BodyCard = (props: IBodyCardProps) => {

    let footer;
    if (props.footerChild) {
        footer = props.footerChild
    } else {
        footer = <Typography variant='h6'>{props.footerText}</Typography>
    }

    const styledAction = (
        <Box sx={styles.headerAction}>
            {props.header?.action}
    </Box>
    )

    return (
            <Card sx={{...styles.root, ...props.sx}} >
                <CardHeader title={props.header?.title}
                            sx={styles.headerParent}
                            subheader={props.header?.subheader}
                            action={styledAction}
                />
                <CardContent>{props.body}</CardContent>
                <div>
                    {footer}
                </div>
            </Card>
    );
};