import { ReactNode } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

export interface IPatchDialogProps {
    title?: string
    children: string | ReactNode
    open: boolean
    setOpen: (arg: boolean) => void
    onConfirm: (...args: any) => any
}

const styles = {
    centered: {
        margin: 'auto'
    }
}

export const PatchDialog = (props: IPatchDialogProps) => {

    return (
        <Dialog
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog" style={styles.centered}>{props.title}</DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            <Typography style={styles.centered}>Are you Sure?</Typography>
            <DialogActions style={styles.centered}>
                <Button
                    variant='contained'
                    onClick={() => {
                        props.onConfirm();
                        props.setOpen(false);
                    }}
                    color='primary'
                >
                    Yes
                </Button>
                <Button
                    variant="contained"
                    onClick={() => props.setOpen(false)}
                    color='error'
                >
                    No
                </Button>
            </DialogActions>
        </Dialog>)
};