import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Box } from "@mui/material";
import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useAppDispatch } from "../../../../hooks";
import { setPartner, setUserPartners } from "../../../../services/partnerConfig/partnerConfigSlice";
import { useApiCorePartnersListQuery, PartnerList } from "../../../../services/generatedApi";
import { usePersistedPartner } from "../../../../util/usePersistedPartner";

export interface IPartnerSelectProps {
    sx?: object
}

export const PartnerSelect = (props: IPartnerSelectProps) => {
    const [ persistedPartner, setPersistedPartner ] = usePersistedPartner()
    const currentPartner: PartnerList | null = useSelector(
        (state: RootState) => state.partnerConfig.selectedPartner)
    const dispatch = useAppDispatch();
    const partnersList = useApiCorePartnersListQuery({isActive: undefined, assignedOnly: true});
    let selectedPartner: PartnerList['id'];
    // May need to clear persisted partner if changing to user that doesn't have that Partner assigned
    // Unlikely to occur but should be handled
    selectedPartner = currentPartner
        ? currentPartner.id
        : persistedPartner
            ? persistedPartner
            : ''


    useEffect(() => {
        if (partnersList.isSuccess) {
            dispatch(setUserPartners({partners: partnersList.data}))
        }
        if (selectedPartner && !currentPartner) {
            const partner = partnersList.data?.find((partner: PartnerList) => partner.id === selectedPartner)
            if (partner) {
                dispatch(setPartner({partner: partner}))
            }
        }
    }, [selectedPartner, currentPartner, partnersList, dispatch])


    const changeHandler = (event: SelectChangeEvent) => {
        const id = event.target.value
        if (partnersList.isSuccess) {
            const partner = partnersList.data.find((partner: PartnerList) => partner.id === id)
            if (partner) {
                dispatch(setPartner({partner: partner}))
                setPersistedPartner(partner.id)
            }
        }
    }

    return (
        <Box sx={props.sx}>
            <FormControl>
                <InputLabel id='partner-select-label'>Partner</InputLabel>
                <Select
                    labelId='partner-select-label'
                    id='partner-select'
                    value={selectedPartner}
                    onChange={changeHandler}
                >
                    <MenuItem disabled value=''>Select A Partner</MenuItem>
                    {partnersList.isSuccess && [...partnersList.data].sort(
                        (a, b) => a.name.localeCompare(b.name)
                    ).map((partner: PartnerList, i: number) => {
                        return (
                            <MenuItem
                                key={i}
                                value={partner.id}>{partner.name}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}
