import { BaseCell, IBaseCellProps } from "./BaseCell";
import { Button } from "@mui/material";
import { Link } from "react-router-dom"

export interface ILinkCellProps extends IBaseCellProps {
    url: string
}
export const LinkCell = (props: ILinkCellProps) => {
    const button = (
        <Button
            component={Link}
            to={props.url}
        >
            {props.content}
        </Button>
    )

    return (
        <BaseCell content={button} toolTip={props.toolTip} sx={props.sx}/>
    )
}