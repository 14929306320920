import { HeaderSideBarBody } from "../../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../../common/cards/BodyCard";
import { useParams } from "react-router-dom";
import { PartnerDetailRead } from "../../../../services/generatedApi";
import { BasicTable } from "../../../common/basicTable/BasicTable";
import { BaseCell } from "../../../common/basicTable/cells/BaseCell";
import { asMoney, asPercent } from "../../../../util/ChartUtils";
import { tempRows } from "./TempData";


export interface IBasePartnerDashboardProps {

}

// const styles = {
//     dividerBottom: {
//         borderBottom: "solid"
//     },
//     greenText: {
//         color: 'green'
//     }
// }

export const BasePartnerDashBoard = (props: IBasePartnerDashboardProps) => {
    // Probably rename to Base Internal Partner Dash or similar
    const partnerId = useParams<{ id: PartnerDetailRead['id'] }>();
    // Handle Partners with multiple accounts (redirect or break into tabs?)
    // Just a separate dash for each type that is assigned to a Partner** (SC + VC, SC FM, Ad Only, Fully Custom, etc.)?

    // Set Date Range
    // Get Partner Details

    // TODO Build out components with dummy data first

    const tempBody = <>{partnerId.id}</>

    const columns = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September",
        "October", "November", "December", "Total"]
    // cells: [
    //     <BaseCell content={data.name} />,
    //     <BaseCell content={'Baseline'} />,
    //     ...data.data.baseline.map((num) => <BaseCell content={num} />)]

    // sx={styles.dividerBottom}
    // const tempRows = [
    //     {
    //         rowKey: 'OPS',
    //         cells: [
    //             <BaseCell content={'OPS'} />,
    //             <BaseCell content={asMoney("56128")} />,
    //             <BaseCell content={asMoney("48362")} />,
    //             <BaseCell content={asMoney("")} />,
    //             <BaseCell content={asMoney("")} />,
    //             <BaseCell content={asMoney("")} />,
    //             <BaseCell content={asMoney("")} />,
    //             <BaseCell content={asMoney("")} />,
    //             <BaseCell content={asMoney("")} />,
    //             <BaseCell content={asMoney("")} />,
    //             <BaseCell content={asMoney("")} />,
    //             <BaseCell content={asMoney("")} />,
    //             <BaseCell content={asMoney("")} />,
    //             <BaseCell content={asMoney("")} />
    //         ]
    //     },
    //     {
    //         rowKey: '% YoY OPS',
    //         cells: [
    //             <BaseCell content={'% YoY OPS'} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("0")} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("0")} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("")} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("")} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("")} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("")} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("")} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("")} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("")} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("")} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("")} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("")} />,
    //             <BaseCell sx={styles.greenText} content={asPercent("")} />
    //         ]
    //     }
    //     // {content: "56,128"}, {content: "48,362"}
    // ]

    const bodyTable = <BasicTable columns={columns} rows={tempRows} />

    const cardBody = (
        <BodyCard body={bodyTable} header={{title: 'Main Dash PoC - ' + partnerId.id}}/>
    )

    return (
        <HeaderSideBarBody body={cardBody} pageTitle={'AM Dash PoC'} />
    )
}