import { IDeptLevelConfigList } from "../rbacConfigs";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../services/auth/authSlice";

/**
 * Takes a list of {@link IDeptLevelConfig} objects and returns two values,
 * canRead and canWrite depending on current user's role. "skip" Can be passed in if needed to call conditionally
 * and results are not used - will return [false, false].
 */
export const useRbac = (configList: IDeptLevelConfigList | 'skip') :[boolean, boolean] =>  {
    const currentUser = useSelector(selectCurrentUser)

    if (!currentUser) {
        return [false, false]
    }

    if (configList === 'skip') {
        return [false, false]
    }

    if (currentUser.is_superuser) {
        return [true, true]
    }

    let canRead = false;
    let canWrite = false;

    // Ensure Management level 5 has access if not explicitly denied (level 99)
    const managementDefined = configList.filter(
        (config) => config.department === 'MANAGEMENT'
    ).length > 0

    if (!managementDefined) {
        configList.push({department: 'MANAGEMENT', minRead: 5, minWrite: 5})
    }


    for (const config of configList) {
        const { department, minRead, minWrite } = config;

        for (const userRole of currentUser.user_roles) {
            const { department: userDepartment, role_level } = userRole.role;

            if (userDepartment.name === department && role_level >= minRead) {
                canRead = true;
            }

            if (userDepartment.name === department && role_level >= minWrite) {
                canWrite = true;
            }
        }
    }

    return [canRead, canWrite]
}