import { TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { Key } from "react";
import { IBaseAPIFormProps } from "../BaseAPIForm";

// Eventually refactor to a BaseFormField
export interface ITextFormFieldProps {
    key: Key
    control: Control
    meta:
        {
            fieldName: string
            fieldLabel: string
            required?: boolean
            //Accept all react-hook form rules when needed, convenience flag for required
        },
    number?: boolean
}

export const MakeTextFormField = (
    control: Control<any>,
    fieldName: string,
    fieldLabel: string,
    required: boolean | undefined = undefined,
    number: boolean | undefined = undefined): IBaseAPIFormProps['fields'][number] => {
    const noResolve = (value: string) => value
    return {
        meta: {
            fieldName: fieldName,
            fieldLabel: fieldLabel,
            nameResolver: noResolve
        },
        field: <TextFormField
            key={fieldName}
            control={control}
            number={number}
            meta={{
                fieldName:fieldName,
                fieldLabel: fieldLabel,
                required: required}}
        />
    }
}

export const TextFormField = (props: ITextFormFieldProps) => {
    const numberType = props.number ? {type: 'number'} : {}

    return (
        <Controller
            name={props.meta.fieldName}
            control={props.control}
            rules={{required: props.meta.required}}
            render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                    label={props.meta.fieldLabel}
                    variant="outlined"
                    value={value}
                    {...numberType}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                />
            )}/>
    )
}