import { HeaderSideBarBody } from "../../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../../common/cards/BodyCard";
import { navLinks } from "../navLinks";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { Table, TableBody, TableHead, TableRow, TableContainer, TableCell, Button } from "@mui/material";
import { useApiCorePartnerIntakeGetPartnerIntakeUnprocessedListQuery, useApiCoreUsersListQuery,
    ApiCoreUsersListApiResponse, ApiCorePartnerIntakeGetPartnerIntakeUnprocessedListApiResponse
} from "../../../../services/generatedApi";
import React from "react";
import { AppURLS } from "../../../../appURLS";
import { userNameFromId } from "../../../../util/FormUtil";

export const UnprocessedPartnerIntakes = () => {
    // Users query here is probably not final implementation, implement a better way later probably through the store
    // Or a component that takes a userId and renders a link to their profile page
    const intakesRes = useApiCorePartnerIntakeGetPartnerIntakeUnprocessedListQuery()
    const usersRes = useApiCoreUsersListQuery({isActive: true, isNectar: true})

    const clickHandler = (id: string) => {
        window.open(
            AppURLS.detailPartnerIntake.getFullPath(id),
            undefined,
            'innerHeight=1000,innerWidth=1000'
        )
    }

    let usersList: ApiCoreUsersListApiResponse | [] = []
    if (usersRes.isSuccess) {
        usersList = usersRes.data
    }

    let intakesList: ApiCorePartnerIntakeGetPartnerIntakeUnprocessedListApiResponse | [] = []
    if (intakesRes.isSuccess) {
        intakesList = intakesRes.data
    }

    const table = (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell>Created By</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {intakesList.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>
                                <Tooltip title="Click to create Partner">
                                    <Button
                                        onClick={clickHandler.bind(undefined, row.id)}
                                        component={Link}
                                        to={AppURLS.createPartner.getFullPath('', {
                                                partnerName: row.name,
                                                partnerIntakeId: row.id,
                                                partnerType: row.partner_type
                                            }
                                        )
                                        }
                                    >
                                        {row.name}
                                    </Button>
                                </Tooltip>
                            </TableCell>
                            <TableCell>{row.notes}</TableCell>
                            <TableCell>{userNameFromId(usersList, row.created_by)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )

    const card = (
        <BodyCard body={table}/>
    )

    return (
        <HeaderSideBarBody body={card} navLinks={navLinks} pageTitle={"Unprocessed Partner Intakes"}/>
    )
}