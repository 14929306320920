import { useApiCoreUsersListQuery, ApiCoreUsersListApiResponse } from "../../../services/generatedApi";
import { BodyCard } from "../../common/cards/BodyCard";
import { Table, TableBody, TableHead, TableRow, TableCell, TableContainer, Button, List, ListItem } from "@mui/material";
import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { navLinks } from "./navLinks";
import { Link } from "react-router-dom";
import { AppURLS } from "../../../appURLS";
import React from "react";
import { sortUsersByLastName } from "../../../util/FormUtil";

const styles = {
    listCell: {
        padding: 0
    }
}

export const ListUsers = () => {
    const usersRes = useApiCoreUsersListQuery({isActive: true, isNectar: true})

    let users: ApiCoreUsersListApiResponse = []
    if (usersRes.isSuccess) {
        users = usersRes.data
        users = [...users].sort(sortUsersByLastName)
    }


    const table = (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Role(s)</TableCell>
                        <TableCell>Department</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>
                                <Button component={Link} to={AppURLS.detailEmployee.getFullPath(row.id)}>
                                    {row.first_name + " " + row.last_name}
                                </Button>
                            </TableCell>
                            <TableCell sx={styles.listCell}>
                                <List>
                                {row.user_roles.map((role)=>
                                    <ListItem key={role.id}>{role.role.name}</ListItem>
                                )}
                                </List>
                            </TableCell>
                            <TableCell sx={styles.listCell}>
                                <List>
                                    {row.user_roles.map((role)=>
                                        <ListItem key={role.id}>{role.role.department.name}</ListItem>)
                                    }
                                </List>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )

    const cardBody = <BodyCard body={table} header={{title: 'All Nectar Employees'}}/>

    return (
        <HeaderSideBarBody body={cardBody} navLinks={navLinks} pageTitle='All Nectar Employees'/>
    )
}