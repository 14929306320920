import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../common/cards/BodyCard";
import {BasicTable, IBasicTableProps} from "../../common/basicTable/BasicTable";
import { BaseCell } from "../../common/basicTable/cells/BaseCell";
import { asMoney, asPercent } from "../../../util/ChartUtils";

const getRandom = (min: number, max: number) => {
    return Math.floor(Math.random() * (max-min) + min)
}

const getDummyData = (partners: string[]) => {
    return partners.map((partner) => {
        const fullLength = Array(12).fill('')
        const weekLength = Array(3).fill('')
        const fourWeeks = Array(4).fill('')
        const fullMonths = Array(8).fill('')
        const monthsLeft = Array(4).fill('')
        return {
            name: partner,
            data: {
                actual: fullMonths.map(() => asMoney(getRandom(1000, 50000))).concat([asMoney(getRandom(5000,25000))]).concat(weekLength.map(() => asMoney(getRandom(1000, 15000)))),
                baseline: fullMonths.map(() => asMoney(getRandom(500, 25000))).concat(fourWeeks.map(() => asMoney(getRandom(500, 25000)/4))).concat(monthsLeft.map(() => asMoney(getRandom(500, 25000)))),
                nectar: fullMonths.map(() => asMoney(getRandom(2500, 35000))).concat(fourWeeks.map(() => asMoney(getRandom(2500, 35000)/4))).concat(monthsLeft.map(() => asMoney(getRandom(2500, 35000)))),
                stretch: fullMonths.map(() => asMoney(getRandom(10000, 50000))).concat(fourWeeks.map(() => asMoney(getRandom(1000, 50000)/4))).concat(monthsLeft.map(() => asMoney(getRandom(1000, 50000))))
            }
        }
    })
}

export const SalesGoalOverviewDashboard = () => {
    // TODO Actually get the data
    const getAllMonths = (): string[] => {
        return Array.from({ length: 12 }, (_, index) => {
            const date = new Date();
            date.setMonth(index);
            return date.toLocaleString('default', { month: 'long' });
        });
    }
    const formatHeader = (months: string[]) => {
        // TODO - Format this how we actually want weeks broken out after discussion with Andy/George
        const weeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4']
        const date = new Date()
        const month = date.getMonth()
        months[month] = months[month] + ' (MTD)'
        months.splice(month + 1, 0, ...weeks)
        months.unshift('Type')
        months.unshift('Partner')
        return months

    }
    let dates = getAllMonths()
    dates = formatHeader(dates)

    // format data into rows
    const dummyData = getDummyData(['Test Partner', 'Sony'])

    const tempData: IBasicTableProps['rows'] = []
    dummyData.map((data) => {
        tempData.push({
            rowKey: data.name,
            cells: [
                <BaseCell content={data.name} />,
                <BaseCell content={'Actual'} />,
                ...data.data.actual.map((num) => <BaseCell content={num} />)]
        })
        tempData.push({
            rowKey: data.name,
            cells: [
                <BaseCell content={data.name} />,
                <BaseCell content={'Baseline'} />,
                ...data.data.baseline.map((num) => <BaseCell content={num} />)]
        })
        tempData.push({
            rowKey: data.name,
            cells: [
                <BaseCell content={data.name} />,
                <BaseCell content={'Nectar'} />,
                ...data.data.nectar.map((num) => <BaseCell content={num} />)]
        })
        tempData.push({
            rowKey: data.name,
            cells: [
                <BaseCell content={data.name} />,
                <BaseCell content={'Stretch'} />,
                ...data.data.stretch.map((num) => <BaseCell content={num} />)]
        })
        })

    // console.log(getRandom(100, 500))

    // const fullMonths = Array(8)
    // const test = fullMonths.map((month) => {
    //     const num = getRandom(1000, 50000)
    //     console.log('fired')
    //     console.log(num)
    //     return num
    // })

    // console.log(test)

    const table = (
        <BasicTable columns={dates} rows={tempData} />
    )

    const tempBody = (
        <BodyCard body={table} header={{title: 'Sales Goal Overview'}}/>
    )

    return (
        <HeaderSideBarBody body={tempBody} />
    )
}