import { IPartnerConfig } from "./ExecutiveLandingDash";
import { BasicTable, IBasicTableProps } from "../../../common/basicTable/BasicTable";
import { BaseCell } from "../../../common/basicTable/cells/BaseCell";
import { asMoney, asPercent, getChangePct, getDeltaType, IGetDeltaTypeConfig } from "../../../../util/ChartUtils";
import { DeltaCardBadge } from "../../../dataVis/badges/DeltaCardBadge";


const styles = {
    goalRows: {
        backgroundColor: 'WhiteSmoke'
    },
    goalRows2: {
        backgroundColor: 'gainsboro'
    },
    goalRows3: {
        backgroundColor: 'lightGray'
    }
}

export interface IExecutiveLandingDashProps {
    partnerConfig: IPartnerConfig[]
}

// TODO Temporary until further discussion #discuss
const deltaTypeConfig: IGetDeltaTypeConfig = {
    severeIncreaseFloor: .1,
    moderateIncreaseFloor: .02,
    moderateDecreaseCeil: -.02,
    severeDecreaseCeil: -.10
}

export const ExecutiveLandingDashTable = (props: IExecutiveLandingDashProps) => {
    const tableColumns = ['Partner', 'Account', 'Sales (Comp)', 'Ad Spend (Comp)', 'TACoS (Comp)']
    let tableRows: IBasicTableProps['rows'] = []
    // We look up the total row several times, might be better to find it in the config first?
    const sortedPartnerConfig = [...props.partnerConfig].sort(
        (a, b) => {
            const aTotal = a.accounts.find(
                (account) => account.name === 'Total')?.data?.current?.sales ?? 0
            const bTotal = b.accounts.find(
                (account) => account.name === 'Total')?.data?.current?.sales ?? 0
            return (aTotal - bTotal) * -1
        }
    )
    sortedPartnerConfig.forEach((partner) => {
            // This should be done better, pop the Totals row out first
            const totals = partner.accounts.find(
                (account) => account.name === 'Total'
            )
            tableRows.push(
                {
                    rowKey: partner.name + partner.accounts[0]?.name,
                    cells: [
                        <BaseCell content={partner.name}/>,
                        <BaseCell content={totals?.name}/>,
                        <BaseCell content={
                            <>
                                {`${totals?.data?.current?.sales ? asMoney(totals?.data?.current?.sales) : ''}
                    (${totals?.data?.comparison?.sales ? asMoney(totals?.data?.comparison?.sales) : ''})
                    `}
                                <DeltaCardBadge deltaType={
                                    getDeltaType(
                                        getChangePct(
                                            totals?.data?.current?.sales ?? 0,
                                            totals?.data?.comparison?.sales ?? 0),
                                        deltaTypeConfig)}/>
                            </>
                        }/>,
                        <BaseCell content={
                            <>
                                {`${totals?.data?.current?.adSpend ? asMoney(totals?.data?.current?.adSpend) : ''}
                    (${totals?.data?.comparison?.adSpend ? asMoney(totals?.data?.comparison?.adSpend) : ''})
                    `}
                                <DeltaCardBadge deltaType={
                                    getDeltaType(
                                        getChangePct(
                                            totals?.data?.current?.adSpend ?? 0,
                                            totals?.data?.comparison?.adSpend ?? 0),
                                        deltaTypeConfig)}/>
                            </>
                        }/>,
                        <BaseCell content={
                            <>
                                {`${totals?.data?.current?.tacos ? asPercent(totals?.data?.current?.tacos) : ''}
                    (${totals?.data?.comparison?.tacos ? asPercent(totals?.data?.comparison?.tacos) : ''})
                    `}
                                {/*TODO Set up delta badge for negative favorability metrics*/}
                                {/*<DeltaCardBadge deltaType={*/}
                                {/*    getDeltaType(*/}
                                {/*        getChangePct(*/}
                                {/*            partner.accounts[0]?.data?.current?.tacos ?? 0,*/}
                                {/*            partner.accounts[0]?.data?.comparison?.tacos ?? 0),*/}
                                {/*        deltaTypeConfigTacos)}/>*/}
                            </>
                        }/>
                    ]
                }
            )
            // Loop over rest of accounts, in the future totals row should be removed first so this should be > 1
            if (partner.accounts.length > 2) {
                partner.accounts.filter((account) => account.name !== 'Total').forEach(
                    (account) => {
                        tableRows.push(
                            {
                                rowKey: partner.name + account.name,
                                cells: [
                                    <BaseCell content={""}/>,
                                    <BaseCell sx={styles.goalRows} content={account.name}/>,
                                    <BaseCell sx={styles.goalRows} content={
                                        <>
                                            {`${account.data?.current?.sales 
                                                ? asMoney(account.data?.current?.sales) 
                                                : ''}
                                (${account.data?.comparison?.sales 
                                                ? asMoney(account.data?.comparison?.sales) 
                                                : ''})
                                `}
                                            <DeltaCardBadge deltaType={
                                                getDeltaType(
                                                    getChangePct(
                                                        account.data?.current?.sales ?? 0,
                                                        account.data?.comparison?.sales ?? 0),
                                                    deltaTypeConfig)}/>
                                        </>
                                    }/>,
                                    <BaseCell sx={styles.goalRows} content={
                                        <>
                                            {`${account.data?.current?.adSpend 
                                                ? asMoney(account.data?.current?.adSpend) 
                                                : ''}
                                (${account.data?.comparison?.adSpend 
                                                ? asMoney(account.data?.comparison?.adSpend) 
                                                : ''})
                                `}
                                            <DeltaCardBadge deltaType={
                                                getDeltaType(
                                                    getChangePct(
                                                        account.data?.current?.adSpend ?? 0,
                                                        account.data?.comparison?.adSpend ?? 0),
                                                    deltaTypeConfig)}/>
                                        </>
                                    }/>,
                                    <BaseCell sx={styles.goalRows} content={
                                        <>
                                            {`${account.data?.current?.tacos 
                                                ? asPercent(account.data?.current?.tacos) 
                                                : ''}
                                (${account.data?.comparison?.tacos 
                                                ? asPercent(account.data?.comparison?.tacos) 
                                                : ''})
                                `}
                                            {/*TODO Set up delta badge for negative favorability metrics*/}
                                            {/*<DeltaCardBadge deltaType={*/}
                                            {/*    getDeltaType(*/}
                                            {/*        getChangePct(*/}
                                            {/*            partner.accounts[0]?.data?.current?.tacos ?? 0,*/}
                                            {/*            partner.accounts[0]?.data?.comparison?.tacos ?? 0),*/}
                                            {/*        deltaTypeConfigTacos)}/>*/}
                                        </>
                                    }/>
                                ]
                            }
                        )
                        // Push account goals if they exist
                        if (account.salesGoal || account.spendGoal || account.tacosGoal) {
                            tableRows.push(
                                {
                                    rowKey: partner.name + partner.accounts[0]?.name + 'goals_baseline',
                                    cells: [
                                        <BaseCell content={''} sx={styles.goalRows}/>,
                                        <BaseCell content={account.name + 'Baseline Goal (Projected Performance)'}
                                                  sx={styles.goalRows}/>,
                                        <BaseCell content={
                                            // TODO Here
                                            <>
                                                {`${account.salesGoal?.lowTarget 
                                                    ? asMoney(account.salesGoal.lowTarget) 
                                                    : ''}`}
                                                {`(${account.salesGoal?.projection 
                                                    ? asPercent(Number(account.salesGoal.lowPerformance)) 
                                                    : ''})`}
                                                {account.salesGoal?.lowTarget
                                                    && account.salesGoal?.projection
                                                    && <DeltaCardBadge deltaType={
                                                    getDeltaType(
                                                        getChangePct(
                                                            Number(account.salesGoal.projection),
                                                            Number(account.salesGoal.lowTarget)),
                                                        deltaTypeConfig)}/>}
                                            </>
                                        } sx={styles.goalRows}/>,
                                        <BaseCell content={
                                            <>
                                                {`${account.spendGoal?.lowTarget 
                                                    ? asMoney(account.spendGoal.lowTarget) 
                                                    : ''}`}
                                                {`(${account.spendGoal?.projection 
                                                    ? asPercent(Number(account.spendGoal.lowPerformance)) 
                                                    : ''})`}
                                                {account.spendGoal?.lowTarget
                                                    && account.spendGoal?.projection
                                                    && <DeltaCardBadge deltaType={
                                                    getDeltaType(
                                                        getChangePct(
                                                            Number(account.spendGoal.projection),
                                                            Number(account.spendGoal.lowTarget)),
                                                        deltaTypeConfig)}/>}
                                            </>
                                        } sx={styles.goalRows}/>,
                                        <BaseCell content={
                                            <>
                                                {`${account.tacosGoal?.lowTarget ? asPercent(
                                                    account.tacosGoal.lowTarget, true) : ''}`}
                                                {`(${account.tacosGoal?.projection ? asPercent(
                                                    Number(account.tacosGoal.lowPerformance)) : ''})`}
                                                {/*Implement with negative DeltaConfig and/or +- DeltaConfig*/}
                                                {/*{partner.tacosGoal?.lowTarget*/}
                                                {/*    && partner.tacosGoal?.projection*/}
                                                {/*    && <DeltaCardBadge deltaType={*/}
                                                {/*    getDeltaType(*/}
                                                {/*        getChangePct(*/}
                                                {/*            Number(partner.tacosGoal.projection),*/}
                                                {/*            Number(partner.tacosGoal.lowTarget)),*/}
                                                {/*        deltaTypeConfig)}/>}*/}
                                            </>
                                        } sx={styles.goalRows}/>
                                    ]
                                }
                            )
                        }
                    }
                )
            }
            // Currently only baseline goal for individual accounts displayed, #discuss with team
        // TODO Refactor the below to use a helper method and avoid code reuse
        if (partner.salesGoal?.lowTarget || partner.spendGoal?.lowTarget || partner.tacosGoal?.lowTarget)
        {
            tableRows.push(
                {
                    rowKey: partner.name + partner.accounts[0]?.name + 'goals_baseline',
                    cells: [
                        <BaseCell content={''} sx={styles.goalRows}/>,
                        <BaseCell content={'Baseline Goal (Projected Performance123)'} sx={styles.goalRows}/>,
                        // TODO Add numeric value of projection in tooltip over percentage display
                        <BaseCell content={
                            <>
                                {`${partner.salesGoal?.lowTarget
                                    ? asMoney(partner.salesGoal.lowTarget) : ''}`}
                                {`(${partner.salesGoal?.projection
                                    ? asPercent(Number(partner.salesGoal.lowPerformance)) : ''})`}
                                {partner.salesGoal?.lowTarget
                                    && partner.salesGoal?.projection
                                    && <DeltaCardBadge deltaType={
                                            getDeltaType(
                                                getChangePct(
                                                    Number(partner.salesGoal.projection),
                                                    Number(partner.salesGoal.lowTarget)),
                                                deltaTypeConfig)}/>}
                            </>
                        } sx={styles.goalRows}/>,
                        <BaseCell content={
                            <>
                                {`${partner.spendGoal?.lowTarget
                                    ? asMoney(partner.spendGoal.lowTarget) : ''}`}
                                {`(${partner.spendGoal?.projection
                                    ? asPercent(Number(partner.spendGoal.lowPerformance)) : ''})`}
                                {partner.spendGoal?.lowTarget
                                    && partner.spendGoal?.projection
                                    && <DeltaCardBadge deltaType={
                                        getDeltaType(
                                            getChangePct(
                                                Number(partner.spendGoal.projection),
                                                Number(partner.spendGoal.lowTarget)),
                                            deltaTypeConfig)}/>}
                            </>
                        } sx={styles.goalRows}/>,
                        <BaseCell content={
                            <>
                                {`${partner.tacosGoal?.lowTarget ? asPercent(
                                    partner.tacosGoal.lowTarget, true) : ''}`}
                                {`(${partner.tacosGoal?.projection ? asPercent(
                                    Number(partner.tacosGoal.lowPerformance)) : ''})`}
                                {/*Implement with negative DeltaConfig and/or +- DeltaConfig*/}
                                {/*{partner.tacosGoal?.lowTarget*/}
                                {/*    && partner.tacosGoal?.projection*/}
                                {/*    && <DeltaCardBadge deltaType={*/}
                                {/*    getDeltaType(*/}
                                {/*        getChangePct(*/}
                                {/*            Number(partner.tacosGoal.projection),*/}
                                {/*            Number(partner.tacosGoal.lowTarget)),*/}
                                {/*        deltaTypeConfig)}/>}*/}
                            </>
                        } sx={styles.goalRows}/>
                    ]
                }
            )
        }
        if (partner.salesGoal?.middleTarget || partner.spendGoal?.middleTarget || partner.tacosGoal?.middleTarget)
        {
            tableRows.push(
                {
                    rowKey: partner.name + partner.accounts[0]?.name + 'goals_commission',
                    cells: [
                        <BaseCell content={''} sx={styles.goalRows2}/>,
                        <BaseCell content={'Commission Goal (Projected Performance)'} sx={styles.goalRows2}/>,
                        <BaseCell content={
                            <>
                                {`${partner.salesGoal?.middleTarget
                                    ? asMoney(partner.salesGoal.middleTarget)
                                    : ''}`}
                                {`(${partner.salesGoal?.projection
                                    ? asPercent(Number(partner.salesGoal.middlePerformance))
                                    : ''})`}
                                {partner.salesGoal?.middleTarget
                                    && partner.salesGoal?.projection
                                    && <DeltaCardBadge deltaType={
                                        getDeltaType(
                                            getChangePct(
                                                Number(partner.salesGoal.projection),
                                                Number(partner.salesGoal.middleTarget)),
                                            deltaTypeConfig)}/>}
                            </>
                        } sx={styles.goalRows2}/>,
                        <BaseCell content={
                            <>
                                {`${partner.spendGoal?.middleTarget
                                    ? asMoney(partner.spendGoal.middleTarget)
                                    : ''}`}
                                {`(${partner.spendGoal?.projection
                                    ? asPercent(Number(partner.spendGoal.middlePerformance))
                                    : ''})`}
                                {partner.spendGoal?.middleTarget
                                    && partner.spendGoal?.projection
                                    && <DeltaCardBadge deltaType={
                                        getDeltaType(
                                            getChangePct(
                                                Number(partner.spendGoal.projection),
                                                Number(partner.spendGoal.middleTarget)),
                                            deltaTypeConfig)}/>}
                            </>
                        } sx={styles.goalRows2}/>,
                        <BaseCell content={
                            <>
                                {`${partner.tacosGoal?.middleTarget ? asPercent(
                                    partner.tacosGoal.middleTarget, true) : ''}`}
                                {`(${partner.tacosGoal?.projection ? asPercent(
                                    Number(partner.tacosGoal.middlePerformance)) : ''})`}
                                {/*Implement with negative DeltaConfig and/or +- DeltaConfig*/}
                                {/*{partner.tacosGoal?.middleTarget */}
                                {/*    && partner.tacosGoal?.projection */}
                                {/*    && <DeltaCardBadge deltaType={*/}
                                {/*    getDeltaType(*/}
                                {/*        getChangePct(*/}
                                {/*            Number(partner.tacosGoal.projection),*/}
                                {/*            Number(partner.tacosGoal.middleTarget)),*/}
                                {/*        deltaTypeConfig)}/>}*/}
                            </>
                        } sx={styles.goalRows2}/>
                    ]
                }
            )
        }
        if (partner.salesGoal?.highTarget || partner.spendGoal?.highTarget || partner.tacosGoal?.highTarget)
        {
            tableRows.push(
                {
                    rowKey: partner.name + partner.accounts[0]?.name + 'goals_stretch',
                    cells: [
                        <BaseCell content={''} sx={styles.goalRows3}/>,
                        <BaseCell content={'Stretch Goal (Projected Performance)'} sx={styles.goalRows3}/>,
                        <BaseCell content={
                            <>
                                {`${partner.salesGoal?.highTarget
                                    ? asMoney(partner.salesGoal.highTarget)
                                    : ''}`}
                                {`(${partner.salesGoal?.projection
                                    ? asPercent(Number(partner.salesGoal.highPerformance))
                                    : ''})`}
                                {partner.salesGoal?.highTarget
                                    && partner.salesGoal?.projection
                                    && <DeltaCardBadge deltaType={
                                        getDeltaType(
                                            getChangePct(
                                                Number(partner.salesGoal.projection),
                                                Number(partner.salesGoal.highTarget)),
                                            deltaTypeConfig)}/>}
                            </>
                        } sx={styles.goalRows3}/>,
                        <BaseCell content={
                            <>
                                {`${partner.spendGoal?.highTarget
                                    ? asMoney(partner.spendGoal.highTarget)
                                    : ''}`}
                                {`(${partner.spendGoal?.projection
                                    ? asPercent(Number(partner.spendGoal.highPerformance))
                                    : ''})`}
                                {partner.spendGoal?.highTarget
                                    && partner.spendGoal?.projection
                                    && <DeltaCardBadge deltaType={
                                        getDeltaType(
                                            getChangePct(
                                                Number(partner.spendGoal.projection),
                                                Number(partner.spendGoal.highTarget)),
                                            deltaTypeConfig)}/>}
                            </>
                        } sx={styles.goalRows3}/>,
                        <BaseCell content={
                            <>
                                {`${partner.tacosGoal?.highTarget ? asPercent(
                                    partner.tacosGoal.highTarget, true) : ''}`}
                                {`(${partner.tacosGoal?.projection ? asPercent(
                                    Number(partner.tacosGoal.highPerformance)) : ''})`}
                                {/*Implement with negative DeltaConfig and/or +- DeltaConfig*/}
                                {/*{partner.tacosGoal?.highTarget */}
                                {/*    && partner.tacosGoal?.projection */}
                                {/*    && <DeltaCardBadge deltaType={*/}
                                {/*    getDeltaType(*/}
                                {/*        getChangePct(*/}
                                {/*            Number(partner.tacosGoal.projection),*/}
                                {/*            Number(partner.tacosGoal.highTarget)),*/}
                                {/*        deltaTypeConfig)}/>}*/}
                            </>
                        } sx={styles.goalRows3}/>
                    ]
                }
            )
        }
        }
    )

    const tableBody = (
        <BasicTable columns={tableColumns} rows={tableRows} />
    )

    return (
        tableBody
    )
}