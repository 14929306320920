import { AppURLS } from "../../../appURLS";

export const navLinks = [
    {
        to: AppURLS.amSmLandingDash.getFullPath(),
        label: 'AM/SM Dash',
        exact: true
    },
    {
        to: AppURLS.executiveLandingDash.getFullPath(),
        label: 'Executive Dash',
        end: true
    }
]