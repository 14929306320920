import { useNavigate, useLocation } from "react-router-dom";
import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { PartnerBaseLayout } from "../../layout/partnerLayout/PartnerBaseLayout";
import { BodyCard } from "../../common/cards/BodyCard";
import { useApiSpApiExchangeSpApiCredentialsCreateMutation } from "../../../services/generatedApi";
import { useSnackbar } from "notistack";
import { useEffect, useCallback, useState } from "react";
import { Typography } from "@mui/material";
import { AppURLS } from "../../../appURLS";
import { selectCurrentUser } from "../../../services/auth/authSlice";
import { useSelector } from "react-redux";


export const SPAPIAuthReturn = () => {
    const user = useSelector(selectCurrentUser)
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const partnerId = params.get('state')
    const SPAPICode = params.get('spapi_oauth_code')
    const spId = params.get('selling_partner_id')
    const { enqueueSnackbar } = useSnackbar()
    const [ exchangeToken ] = useApiSpApiExchangeSpApiCredentialsCreateMutation()
    const navigate = useNavigate()
    const [ foundParams, setFoundParams ] = useState(false)

    const tempUSMarketplace = 'ATVPDKIKX0DER'

    // TODO Update here
    const makeExchange = useCallback( (SPAPICode: string, spId: string, amzAccount: string) => {
         exchangeToken({
            spApiTokenExchangeRequest: {
                sp_lwa_auth_token: SPAPICode,
                sp_id: spId,
                amz_account: amzAccount,
                marketplace: tempUSMarketplace
            }
        })
            .then(() => {
                enqueueSnackbar('Successfully Authorized SP-API', {variant: 'success'})
                // Possibly need to invalidate cache here
                if (user) {
                    const redirect = () => navigate(AppURLS.baseLandingDash.getFullPath())
                    setTimeout(redirect, 2000)
                }
            })
            .catch((error: any) => {
                enqueueSnackbar('Error Authorizing SP-API', {variant: 'error'})
                console.log(error)
            })
    }, [enqueueSnackbar, exchangeToken, navigate])

    useEffect( () => {
        if (SPAPICode && spId && partnerId) {
            setFoundParams(true)
             makeExchange(SPAPICode, spId, partnerId)
        }
    }, [SPAPICode, spId, partnerId, makeExchange, setFoundParams])


    let body = <div>No Data Provided</div>
    if (foundParams) {
        body = <Typography variant='h6'>Updating credentials for Amazon Seller: {spId}</Typography>
    }

    const bodyCard = <BodyCard body={body} header={{title: 'SP-API Authorization Return'}}/>

    return (
        // <HeaderSideBarBody body={bodyCard} pageTitle='Authorization Return' />
        <PartnerBaseLayout body={bodyCard} />
    )
}