import {
    useApiCoreAllPartnerAccountsListQuery, User, PartnerList, useApiGoalsGoalTypesListQuery, GoalType,
    // ApiCoreAllPartnerAccountsCreateApiResponse, useApiBqDashboardDataCreateMutation,
} from "../../../services/generatedApi";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../services/auth/authSlice";
import { useEffect, useState } from "react";
import { AmSmLandingDash } from "./amSmLandingDash/AmSmLandingDash";

export const BaseLandingDash = () => {
    // Gather data needed for query
    // Make query
    // generate proper dashboard with data passed in (AM/SM or Executive)
        // AM/SM should just be for anyone not a director, management, or analyst
    const currentUser = useSelector(selectCurrentUser)
    const [ userPartnerIds, setUserPartnerIds ] = useState<
        PartnerList['id'][]>()
    // const userPartnerIds = currentUser?.user_partners.map((partner) => partner.id)
    // const [ allAccounts, setAllAccounts ] = useState<
    //     ApiCoreAllPartnerAccountsCreateApiResponse
    // >()
    // const [ partnerAccountsQuery ] = useApiCoreAllPartnerAccountsCreateMutation()
    const goalTypesRes = useApiGoalsGoalTypesListQuery()

    let goalTypes: GoalType[] = []
    if ( goalTypesRes.isSuccess ) {
        goalTypes = goalTypesRes.data
    }

    // useEffect(() => {
    //     const getPartnerAccounts = async (partnerIds: string[]) => {
    //         await partnerAccountsQuery(
    //             {partnerAllAccountsRequestRequest: {partners: partnerIds}}).unwrap()
    //             .then(
    //                 (payload) => {
    //                     setAllAccounts(payload)
    //                     // return payload
    //                 }
    //             )
    //     }
    //     console.log('Fired Accounts UseEffect outer')
    //     if (userPartnerIds && !allAccounts) {
    //         console.log('Fired Accounts useEffect Inner')
    //         getPartnerAccounts(userPartnerIds)
    //         // const accounts = getPartnerAccounts(partnerIds)
    //         // console.log(accounts)
    //     }
    // }, [userPartnerIds])
    //
    // if (currentUser && !userPartnerIds) {
    //     setUserPartnerIds(currentUser.user_partners.map((partner) => partner.id))
    // }

    // TODO Breadcrumbs:
    // Build and make query
    // Create data (Partner Config) list, or just go through results and use those?
    // Can we just use skipTokens and let RTK cache handle multiple calls?
    // or separate hook like:
    // const useGetDashDataWithMonth = (dateRange, accountIds) =>
    // const useApiWithDependency = () => {
    //     const { data: api1data } = api.useApi1Query()
    //     const { data: api2data } = api.useApi2Query(
    //         api1data ? api1data.foo : skipToken,
    //     )
    //     return api.useApi3Query(
    //         api1data && api2data ? { bar: api2data.baz } : skipToken,
    //     )
    // }

    return (
        // TODO Return either base or executive dashboard
        <>
            <AmSmLandingDash />
            <pre>{JSON.stringify(currentUser, null ,2)}</pre>
            **************************
            <pre>{JSON.stringify(userPartnerIds, null,2)}</pre>
            **************************
            {/*<pre>{JSON.stringify(allAccounts,null,2)}</pre>*/}
        </>
    )
}