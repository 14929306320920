import { PropsWithChildren } from "react";
import { CardBadge, ICardBadgeProps } from "./CardBadge";
import { North, South, East, NorthEast, SouthEast} from "@mui/icons-material";


type DeltaType = 'severeDecrease' | 'moderateDecrease' | 'moderateIncrease' | 'severeIncrease' | 'even';

type PropMapping = Pick<ICardBadgeProps, 'color' | 'icon'>

type MappingObj = Record<DeltaType, PropMapping>

type ColorOnlyMapping = Record<DeltaType, Exclude<PropMapping, 'icon'>>

export interface IDeltaCardBadgeProps extends PropsWithChildren{
    deltaType: DeltaType
    downGood?: boolean
    tooltip?: string
}

const mapping: MappingObj = {
    'even': {
        color: 'badgeGrey',
        icon: <East />
    },
    'severeDecrease': {
        color: 'badgeRed',
        icon: <South />
    },
    'moderateDecrease': {
        color: 'badgeYellow',
        icon: <SouthEast />
    },
    'severeIncrease': {
        color: 'badgeGreen',
        icon: <North />
    },
    'moderateIncrease': {
        color: 'badgeBlue',
        icon: <NorthEast />
    }
}

const downGoodMapping: ColorOnlyMapping = {
    'even': {
        color: 'badgeGrey'
    },
    'severeDecrease': {
        color: 'badgeGreen'
    },
    'moderateDecrease': {
        color: 'badgeBlue'
    },
    'severeIncrease': {
        color: 'badgeRed'
    },
    'moderateIncrease': {
        color: 'badgeYellow'
    }
}

export const DeltaCardBadge = (props: IDeltaCardBadgeProps) => {
    // TODO Test tooltip
    const color = props.downGood ?
        downGoodMapping[props.deltaType].color
        : mapping[props.deltaType].color

    const icon = mapping[props.deltaType].icon

    return (
        <CardBadge
            color={color}
            icon={icon}
            tooltip={props.tooltip ?? undefined}
        >
            {props.children}
        </CardBadge>
    )
}