import { Typography, List, ListItem, ListItemText } from "@mui/material";
import { ReactElement } from "react";

export interface IInfoViewProps {
    nodes: InfoViewNode[]
    // allow overwriting of styles through prop
    // could allow more than one fieldset if useful and map returning fieldset boxes
}

interface InfoViewNode {
    title: string
    data: (string | ReactElement | undefined)[]
}

const styles = {
    fieldset: {
        border: '1px solid',
        width: '100%'
    },
    listItem: {
        pb: 0
    },
    secondaryText: {
        display: 'block'
    }
}

const isNothing = (input: any) => {
    if (Array.isArray(input) && input.length === 0) {
        return true
    } else if (input === undefined || input === '' || input === " " || input === null) {
        return true
    }
}

export const InfoView = (props:IInfoViewProps) => {

    return (
        <List>
            {props.nodes.map((node) => (
                <ListItem key={node.title} sx={styles.listItem}>
                    <ListItemText
                        primary={node.title}
                        secondary={node.data.map((item, index) => (
                            <Typography
                                key={node.title + index}
                                component='span'
                                sx={styles.secondaryText}
                            >
                                {isNothing(item) ? '--' : item}
                            </Typography>
                            ))}
                        />
                </ListItem>
            ))}
        </List>
    )
}