import { AppURLS } from "../../../appURLS";

export const navLinks = [
    {
        to: AppURLS.createGoal.getFullPath(),
        label: 'Add Goal',
        exact: true
    },
    {
        to: AppURLS.listGoals.getFullPath(),
        label: 'All Goals',
        end: true
    },
    {
        to: AppURLS.demoGoals.getFullPath(),
        label: 'Demo Goals',
        exact: true
    }
]