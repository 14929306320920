import { Collapse, Box, Button, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { IProductGroupWithChildren } from "./productGroups";
import { ProductGroupForm } from "./ProductGroupForm";

interface IProductGroupCollapseProps {
    groups: IProductGroupWithChildren[]
    nestLevel: number
    groupsRefetch: () => void
    showButtons: boolean
    expandAll: boolean
}

type ExtendedGroup = IProductGroupCollapseProps['groups'][number] & {
    groupIsOpen?: boolean
    formIsOpen?: boolean
}

const styles = {
    root: {
        mb: 1
    },
    calcRoot: {
        ml: 10
    },
    formBox: {
        maxWidth: 500,
        margin: 'auto',
        mt: 2
    },
    button: {
        maxWidth: 300,
        margin: 'auto',
        pb: 1
    }
}

export const ProductGroupNode = (props: IProductGroupCollapseProps) => {
    const [ objectList, setObjectList ] = useState<ExtendedGroup[]>([])


    useEffect(() => {
        setObjectList(
            props.groups.map(group => ({...group, groupIsOpen: false, formIsOpen: false}))
        )
    }, [props.groups])

    const handleCollapseClick = (group: ExtendedGroup) => {
        setObjectList(
            objectList.map(object => object.id === group.id ? (
                {...object, groupIsOpen: !object.groupIsOpen})
                : (object) )
        )
    }

    const makeButton = (group: ExtendedGroup) => {
        const handleClick = (group: ExtendedGroup) => {
            setObjectList(
                objectList.map(object => object.id === group.id ? (
                    {...object, formIsOpen: !object.formIsOpen})
                    : (object) )
            )
        }
        return (
            <>
                <Button
                    variant='contained'
                    size='small'
                    sx={styles.button}
                    onClick={()=> handleClick(group)}>
                    Create Child Group of {group.name}
                </Button>
                <Collapse in={group.formIsOpen}>
                    <Box sx={styles.formBox}>
                        <ProductGroupForm partner={group.partner} parentGroup={group.id} refetch={props.groupsRefetch}/>
                    </Box>
                </Collapse>
            </>
        )
    }
    // TODO Show All
    // TODO Show/hide buttons



    return (
        <>
            {
                objectList.map((group) => {
                    return (
                        <Box sx={{...styles.root, ml: props.nestLevel * styles.calcRoot.ml}}>
                            <Typography variant='h6'>{'- '.repeat(props.nestLevel) + group.name }</Typography>
                            <IconButton onClick={() => {handleCollapseClick(group)}}>
                                {group.groupIsOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>

                            <Collapse in={group.groupIsOpen || props.expandAll}>
                                <Stack>

                                    {props.showButtons && makeButton(group)}
                                    {/*{tempMakeButton(group.name)}*/}
                                    {/*{tempButton}*/}
                                    {group.children?.map((child) => {
                                        return <ProductGroupNode
                                            groups={[child]}
                                            nestLevel={props.nestLevel + 1}
                                            groupsRefetch={props.groupsRefetch}
                                            showButtons={props.showButtons}
                                            expandAll={props.expandAll}
                                        />
                                    })}

                                </Stack>
                            </Collapse>
                            {/*{props.nestLevel === 0 ? <Divider /> : <></>}*/}
                        </Box>
                    )
                })
            }

        </>


    )
}