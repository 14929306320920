import { IProductTagFormProps, ProductTagForm } from "./ProductTagForm";
import { useState } from "react";
import { List, ListItem, ListItemText, Tooltip, Box, Button } from "@mui/material";
import { ProductTag, useApiPdmProductTagsListQuery } from "../../../../services/generatedApi";
import { productTagNameFromId } from "../../../../util/FormUtil";

export interface IToggleProductTagFormProps {
    tagFormProps: IProductTagFormProps
}

const styles = {
    stopButton: {
        float: 'right'
    }
}

export const ToggleProductTagForm = (props: IToggleProductTagFormProps) => {
    const [ showForm, setShowForm ] = useState<boolean>(false)
    const tagsRes = useApiPdmProductTagsListQuery({partner: props.tagFormProps.partnerId})

    let tags: ProductTag[] = []
    if (tagsRes.isSuccess) {
        tags = tagsRes.data
    }

    const form = (
        <>
            <ProductTagForm
                partnerId={props.tagFormProps.partnerId}
                listingId={props.tagFormProps.listingId}
                productTags={props.tagFormProps.productTags}
                refetch={props.tagFormProps.refetch}
            />
            <Button size='small'
                    color={'error'}
                    sx={styles.stopButton}
                    onClick={() => setShowForm(!showForm)}>
                Stop Editing</Button>
        </>
    )

    const display = (
        <>
            {props.tagFormProps.productTags.length > 0 ? props.tagFormProps.productTags.map((tag) => (
                <List dense disablePadding key={tag + props.tagFormProps.listingId + 'list'}>
                    <ListItem key={tag + props.tagFormProps.listingId + 'li'}>
                        <ListItemText key={tag + props.tagFormProps.listingId + 'lit'}>
                            {productTagNameFromId(tags, tag)}
                        </ListItemText>
                    </ListItem>
                </List>
            ))
                : "No Tags"
            }
            </>
    )

    const toolTip = (
        <Tooltip onClick={()=> setShowForm(!showForm)} title='Click to Edit Product Tags'>
            <Box>
                {display}
            </Box>
        </Tooltip>
)

    return (
        <>
            {showForm ? form : toolTip}
        </>

    )
}