import { BaseApiForm, IBaseAPIFormProps } from "./BaseAPIForm";
import { ITextFormFieldProps, MakeTextFormField } from "./fields/TextFormField";
import { MakeBooleanFormField } from "./fields/BooleanFormField";
import { MakeChipSelectFormField, IChipSelectRecordsRes, IChipSelectChoiceFilterFunction, IChipSelectFormFieldProps,
    IChipSelectSortFunc } from "./fields/ChipSelectFormField";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

export interface ISingleFieldFormProps {
    fieldData: ITextFieldData | IChipSelectFieldData | IBooleanFieldData | INumberFieldData
    recordName: string
    actionType: IBaseAPIFormProps['formActionType']
    formAction: IBaseAPIFormProps['formAPIAction']
    refetch: IBaseAPIFormProps['refetch']
}

interface IFieldData {
    type: 'ChipSelect' | 'Text' | 'EnumSelect' | 'Boolean' | 'Number'
    fieldName: ITextFormFieldProps['meta']['fieldName']
    fieldLabel: ITextFormFieldProps['meta']['fieldLabel']
    initialValue?: string | string[] | number | boolean
    extraElement?: IBaseAPIFormProps['fields'][number]
    preFillDirty?:  string | string[] | number | boolean
}

interface ITextFieldData extends IFieldData {
    type: 'Text'
    initialValue?: string
    preFillDirty?: string
}

interface INumberFieldData extends IFieldData {
    type: 'Number'
    initialValue?: number
    preFillDirty?: number
}

interface IChipSelectFieldData extends IFieldData {
    type: 'ChipSelect'
    initialValue?: string | string[]
    preFillDirty?: string | string[]
    recordsRes: IChipSelectRecordsRes
    nameFromIdFunc: IChipSelectFormFieldProps['nameFromIdFunc']
    choiceToDisplayFunc: IChipSelectFormFieldProps['choiceToDisplayFunc']
    multiple: boolean
    choiceFilterFunc?: IChipSelectChoiceFilterFunction
    sortFunc?: IChipSelectSortFunc
    noneChoice?: IChipSelectFormFieldProps['noneChoice']
}

interface IBooleanFieldData extends IFieldData {
    type: 'Boolean'
    initialValue?: boolean
    preFillDirty?: boolean
}


interface IFormInput {}

interface IDirtyFields {name: string, value: IFieldData['preFillDirty']}

export const SingleFieldForm = (props: ISingleFieldFormProps) => {
    const initialValues: IFormInput = {[props.fieldData.fieldName]: props.fieldData.initialValue}
    const { control, ...useFormRest } = useForm<IFormInput>({defaultValues: initialValues})
    const formStateHooks = useState<IFormInput>(initialValues)
    const makeTextFormField = MakeTextFormField
    const makeBooleanFormField = MakeBooleanFormField
    const makeChipSelectFormField = MakeChipSelectFormField
    const [ dirty, setDirty ] = useState<IDirtyFields[] | undefined>(undefined)

    useEffect(() => {
        if (props.fieldData.preFillDirty) {
            setDirty([{name: props.fieldData.fieldName, value: props.fieldData.preFillDirty}])
        }
    },[props.fieldData.preFillDirty, props.fieldData.fieldName])


    const makeFields = (fieldData: ISingleFieldFormProps['fieldData']) : IBaseAPIFormProps['fields'] => {
        let returnFields: IBaseAPIFormProps['fields'] = []
        if (fieldData.extraElement) {
            returnFields.push(fieldData.extraElement)
        }
        switch (fieldData.type) {
            case 'Text':
                returnFields.unshift(makeTextFormField(control, fieldData.fieldName, fieldData.fieldLabel))
                break
            case 'Boolean':
                returnFields.unshift(makeBooleanFormField(control, fieldData.fieldName, fieldData.fieldLabel))
                break
            case "Number":
                returnFields.unshift(makeTextFormField(
                    control,
                    fieldData.fieldName,
                    fieldData.fieldLabel,
                    false,
                    true)
                )
                break
            case 'ChipSelect':
                returnFields.unshift(makeChipSelectFormField(
                    control,
                    fieldData.fieldName,
                    fieldData.fieldLabel,
                    fieldData.recordsRes,
                    fieldData.nameFromIdFunc,
                    fieldData.choiceToDisplayFunc,
                    fieldData.multiple,
                    false,
                    fieldData.choiceFilterFunc,
                    fieldData.sortFunc,
                    fieldData.noneChoice)
                )
                break
            default:
                throw Error('Unsupported Field Type Passed To Single Field Form')
        }
        return returnFields
    }

    const fields = makeFields(props.fieldData)

    return (
        <BaseApiForm
            recordName={props.recordName}
            formActionType={props.actionType}
            fields={fields}
            useFormRest={useFormRest}
            formStateHooks={formStateHooks}
            formAPIAction={props.formAction}
            refetch={props.refetch}
            preFillDirtyFields={dirty ?? undefined}
        />
    )
}