import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { ReactElement } from "react";
import {SxProps} from "@mui/system";

export interface IBasicTableProps {
    columns: string[]
    rows: IBasicTableRow[]
    sx?: SxProps
}

interface IBasicTableRow {
    rowKey: string | number
    cells: ReactElement[]
}

export const BasicTable = (props: IBasicTableProps) => {

    return (
       <TableContainer sx={props.sx}>
           <Table>
               <TableHead>
                   {props.columns.map((column, index) => (
                       <TableCell key={column + index}>{column}</TableCell>
                   ))}
               </TableHead>
               <TableBody>
                   {props.rows.map((row, index) => (
                       <TableRow id={row.rowKey.toString()} key={row.rowKey.toString() + index}>
                           {row.cells}
                       </TableRow>
                   ))}
               </TableBody>
           </Table>
       </TableContainer>
    )
}