import {
    useApiPdmProductGroupListQuery,
    ProductGroup
} from "../../../services/generatedApi";
import { ProductGroupForm } from "./ProductGroupForm";
import { HeaderSideBarBody } from "../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../common/cards/BodyCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { navLinks } from "../products/navLinks";
import { skipToken } from "@reduxjs/toolkit/query";
import { Typography, Switch, Card } from "@mui/material";
import { ProductGroupNode } from "./productGroupNode";
import { ChangeEvent, useState } from "react";

export interface IProductGroupWithChildren extends ProductGroup {
    children?: IProductGroupWithChildren[]
}

const styles = {
    cards: {
        mb: 3,
        backgroundColor: '#F8F8F8'
    }
}

export const ProductGroups = () => {
    const [ showButtons, setShowButtons ] = useState<boolean>(true)
    const [ expandAll, setExpandAll ] = useState<boolean>(false)
    const currentPartner = useSelector((state: RootState) => state.partnerConfig.selectedPartner)
    const productGroupRes = useApiPdmProductGroupListQuery(currentPartner ? {partner:currentPartner.id} : skipToken)

    let productGroups: ProductGroup[] | [] = []
    if (productGroupRes.isSuccess) {
        productGroups = productGroupRes.data
    }

    // TODO Replace with fallback component when made
    if (!currentPartner) {
        const fallBackCard = <BodyCard body='Please Select A Partner'/>
        return <HeaderSideBarBody body={fallBackCard} pageTitle='Product Groups'/>
    }

    const handleButtonToggle = (event: ChangeEvent<HTMLInputElement>) => {
        setShowButtons(event.target.checked)
    }

    const handleExpandToggle = (event: ChangeEvent<HTMLInputElement>) => {
        setExpandAll(event.target.checked)
    }

    const buttonToggle = <>
        <Switch
        checked={showButtons}
        onChange={handleButtonToggle}
        /> Show Create Buttons
    </>

    const expandToggle = <>
        <Switch
            checked={expandAll}
            onChange={handleExpandToggle}
        /> Expand All
    </>


    let flat: IProductGroupWithChildren[] | [] = JSON.parse(JSON.stringify(productGroups))
    let root: IProductGroupWithChildren[] = []
    flat.forEach((node) => {
        if (!node.parent_group) return root.push(node)

        const parentIndex = flat.findIndex(el => el.id === node.parent_group)
        if (!flat[parentIndex].children) {
           return flat[parentIndex].children = [node]
        }
       //@ts-ignore
        flat[parentIndex].children.push(node)
    })


    const nodes = (
        root.map((node) => (
            <Card sx={styles.cards}>

                <ProductGroupNode
                    groups={[node]}
                    nestLevel={0}
                    groupsRefetch={productGroupRes.refetch}
                    showButtons={showButtons}
                    expandAll={expandAll}
                />
            </Card>
        ))
    )

    const parentForm = (
        <>
            <Typography>Create New Parent Group</Typography>
            <ProductGroupForm partner={currentPartner.id} parentGroup={undefined} refetch={productGroupRes.refetch}/>
            <br />
            <Typography variant='h5'>Existing Groups:</Typography>
            {buttonToggle}
            {expandToggle}
        </>

    )


    const bodyCard = <BodyCard body={
        <>
            {parentForm}
            {<br/>}
            {nodes}
        </>
    } header={{title: `${currentPartner.name} Product Groups`}}/>

    return (
        <HeaderSideBarBody body={bodyCard} pageTitle='Product Groups' navLinks={navLinks}/>
    )
}