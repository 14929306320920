import { Link, useParams } from "react-router-dom";
import { HeaderSideBarBody } from "../../../layout/headerSideBarBody/HeaderSideBarBody";
import { BodyCard } from "../../../common/cards/BodyCard";
import { PartnerDetailForm } from "./PartnerDetailForm";
import { Button, Typography, Stack, CircularProgress, Backdrop } from "@mui/material";
import { useApiCorePartnersRetrieveQuery, PartnerDetailRead, useApiPdmGetListingsForPartnerCreateMutation,
    useApiPdmGetWholesalerOffersForPartnerCreateMutation, useApiPdmTempSendSlackXlsToSlackCreateMutation,
    useApiSpApiCreateNotificationSubscriptionsCreateMutation } from "../../../../services/generatedApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { navLinks } from "../navLinks";
import { DoubleBodyCard } from "../../../common/cards/DoubleBodyCard";
import { IInfoViewProps, InfoView } from "../../../common/infoView/InfoView";
import { AppURLS } from "../../../../appURLS";
import { UserLevelCheck } from "../../../rbac/UserLevelCheck";
import { useAppDispatch } from "../../../../hooks";
import { setShouldPollLongRunningTasks } from "../../../../services/auth/authSlice";


export const PartnerDetail = () => {
    const partnerId = useParams<{ id: PartnerDetailRead['id'] }>();
    const [ getListings, { isLoading: listingsLoading } ] = useApiPdmGetListingsForPartnerCreateMutation()
    const [ getWholesalers, { isLoading: wholesalersLoading } ] = useApiPdmGetWholesalerOffersForPartnerCreateMutation()
    const [ sendCsvToSlack, { isLoading: slackCsvLoading } ] = useApiPdmTempSendSlackXlsToSlackCreateMutation()
    const [ createNotificationSubscriptions, { isLoading: notificationSubscriptionsLoading }
    ] = useApiSpApiCreateNotificationSubscriptionsCreateMutation()
    const partnerRes = useApiCorePartnersRetrieveQuery(partnerId.id ? {id: partnerId.id} : skipToken)
    const dispatch = useAppDispatch()

    let partner: PartnerDetailRead | undefined = undefined
    if (partnerRes.isSuccess) {
        partner = partnerRes.data
    }

    // Handle more gracefully, loading states and better error presentation
    const fallback = <Typography>Partner Not Found</Typography>
    let viewNode = fallback
    let editNode = fallback

    const generateIntakeLink = (input: PartnerDetailRead['intake_form'] | undefined) => {
        let link
        if (input) {
            link = (
                <Button component={Link} to={AppURLS.detailPartnerIntake.getFullPath(input)}>
                    View Intake Form
                </Button>
            )
        }
        return link
    }

    if (partner) {
        const viewNodeData: IInfoViewProps['nodes'] = [
            {title: 'Name', data: [partner.name]},
            {title: 'Short code', data: [partner.short_code]},
            {title: 'Custom Data Strategy', data:[partner.has_custom_data_strategy?.toString()]},
            {title: 'Is Active', data: [partner.is_active?.toString()]},
            {title: 'Slack Channel', data: [partner.slack_channel?.toString()]},
            {title: 'Group Email Address', data: [partner.group_email_address]},
            {title: 'Logo Url', data: [partner.logo_url?.toString()]},
            {title: 'Has Active SP-API Credentials', data: [partner.active_sp_api_credentials?.toString()]},
            {title: "Partner's SM", data: partner.partner_sm ?
                    [`${partner.partner_sm.first_name} ${partner.partner_sm.last_name}`] : ['']},
            {title: "Partner's AM", data: partner.partner_am ?
                    [`${partner.partner_am.first_name} ${partner.partner_am.last_name}`] : ['']},
            {title: "Partner's Employees", data: partner.partner_employees.length !== 0 ?
                    partner.partner_employees.map(
                (employee) => (`${employee.first_name} ${employee.last_name}`)) : ['']},
            {title: "Partner's Accounts", data: ['**To Do']},
            {title: 'Intake Form', data: [generateIntakeLink(partner.intake_form)]}
        ]
        viewNode = (
            <>
                <InfoView nodes={viewNodeData}/>
                {/*Role check here eventually when admin dept handles onboarding*/}
                <UserLevelCheck attrCheck='is_superuser'>
                    <Button
                        component={Link}
                        to={AppURLS.listPartnerAccounts.getFullPath(partner.id)}>
                        Manage Partner Accounts
                    </Button>
                </UserLevelCheck>


            </>
    )

        editNode = (
            <PartnerDetailForm partner={partner} refetch={partnerRes.refetch}/>
        )
    }

    const employeeButton = (
        //User Role Check Here
        <Button onClick={() => window.alert('Not implemented yet')}>**Manage Partner Employees</Button>
    )

     const getListingsButton = (
         <UserLevelCheck attrCheck="is_superuser">
             <Button onClick={() => {
                 getListings({partnerOnlyRequest: {partner: partnerId.id ?? ''}});
                 dispatch(setShouldPollLongRunningTasks({value: true}));
             }
             }>Get Partner's Listings</Button>
         </UserLevelCheck>
     )

    const updateListingsButton = (
        // Will be moved/removed when daily update system in place
        <UserLevelCheck attrCheck="is_superuser">
            <Button onClick={() => {
                getListings({partnerOnlyRequest: {partner: partnerId.id ?? ''}, update: true});
                dispatch(setShouldPollLongRunningTasks({value: true}))
            }
            }>*Update Partner's Listings</Button>
        </UserLevelCheck>
    )

    const getWholesalersButton = (
        <UserLevelCheck attrCheck="is_superuser">
            <Button onClick={() => {
                getWholesalers({partnerOnlyRequest: {partner: partnerId.id ?? ''}});
                dispatch(setShouldPollLongRunningTasks({value: true}));
            }
            }>Get Partner's Wholesalers</Button>
        </UserLevelCheck>
    )

    const sendSlackButton = (
        <UserLevelCheck attrCheck="is_superuser">
            <Button onClick={() => {
                sendCsvToSlack({partner: partnerId.id ?? ''});
                dispatch(setShouldPollLongRunningTasks({value: true}));
            }
            }>Send Listing Events To Slack Channel</Button>
        </UserLevelCheck>
    )

    const subscribeToNotificationsButton = (
        <UserLevelCheck attrCheck="is_superuser">
            <Button onClick={() => {
                createNotificationSubscriptions({partnerOnlyRequest: {partner: partnerId.id ?? ''}});
                dispatch(setShouldPollLongRunningTasks({value: true}));
            }
            }>Subscribe To Notifications</Button>
        </UserLevelCheck>
    )

    const bodyCard = (
        <DoubleBodyCard
            title="Partner Detail"
            action={<Stack>
                {employeeButton}
                {getListingsButton}
                {updateListingsButton}
                {getWholesalersButton}
                {sendSlackButton}
                {subscribeToNotificationsButton}
        </Stack>}
            leftCard={<BodyCard header={{title: 'Information'}} body={viewNode} />}
            rightCard={<BodyCard header={{title: 'Edit Form'}} body={editNode} />}
        />
    )
    const backDrop = (
        <Backdrop open={
            listingsLoading || wholesalersLoading || slackCsvLoading || notificationSubscriptionsLoading}
                  sx={{zIndex: 1}}>
            <CircularProgress />
        </Backdrop>
    )
    return (
        <HeaderSideBarBody body={<>{backDrop}{bodyCard}</>} pageTitle='Partner Detail' navLinks={navLinks} />
    )
}