import { TimeLineGoalList } from "../../../../services/generatedApi";
import { asMoney, asPercent } from "../../../../util/ChartUtils";
import {LinearProgress, Stack, Box, Typography} from "@mui/material";


export interface IAmSmLandingDashGoalViewProps {
    goals: TimeLineGoalList[]
}

const styles = {
    progressBar: {
        maxWidth: '10%'
    }
}

export const AmSmLandingDashGoalView = (props: IAmSmLandingDashGoalViewProps) => {
    // Return compact table with goal name, projected value, and colored progress bars for projected performance
    // TODO Handle different goal types (asMoney vs asPercent)
    if (props.goals.length === 0) {
        return null  // TODO Should return false, might need to update react typings
    }

    return (
        <>
            {
                props.goals.map((goal) => {
                    // TODO Structure either as table or Grid with colored progress bars and labels
                    let lowProgress
                    let midProgress
                    let highProgress

                    if (goal.last_result.performance_low) {
                        lowProgress =
                            <Box>
                                <Typography>{asPercent(goal.last_result.performance_low ?? 'N/A')}</Typography>
                                <LinearProgress
                                    sx={styles.progressBar}
                                    variant='determinate'
                                    value={Math.min(Number(goal.last_result.performance_low) * 100, 100)}
                                />
                            </Box>
                    }
                    if (goal.last_result.performance_middle) {
                        midProgress =
                            <LinearProgress
                                sx={styles.progressBar}
                                variant='determinate'
                                value={Math.min(Number(goal.last_result.performance_middle) * 100, 100)}
                            />
                    }
                    if (goal.last_result.performance_high) {
                        highProgress =
                            <LinearProgress
                                sx={styles.progressBar}
                                variant='determinate'
                                value={Math.min(Number(goal.last_result.performance_high) * 100, 100)}
                            />
                    }
                        return (
                            // <Stack direction='row' >
                            //     {lowProgress}
                            //     {midProgress}
                            //     {highProgress}
                            // </Stack>
                            // <Stack direction='row'>
                            <>
                                {goal.name}
                                - Projected: {asMoney(goal.last_result.projected_value)} {"   "}
                                - Low: {asPercent(goal.last_result.performance_low ?? 'N/A',)}{"   "}
                                {/*{lowProgress}*/}
                                - Mid: {asPercent(goal.last_result.performance_middle ?? 'N/A')}{"   "}
                                - High: {asPercent(goal.last_result.performance_high ?? 'N/A')}
                            {/*// </Stack>*/}
                            </>
                        )
                    }
                )
            }
        </>
    )
}