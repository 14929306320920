import { BaseApiForm } from '../../../common/forms/BaseForm/BaseAPIForm'
import { PatchedUserUpdateRequest, useApiCorePartnersListQuery,User,
    useApiCoreRolesListQuery, useApiCoreUsersPartialUpdateMutation } from "../../../../services/generatedApi";
import { MutationActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { MakeTextFormField } from "../../../common/forms/BaseForm/fields/TextFormField";
import { MakeChipSelectFormField } from "../../../common/forms/BaseForm/fields/ChipSelectFormField";
import { roleChoiceToDisplayName, roleNameFromId,
    partnerChoiceToDisplayName, partnerNameFromId } from "../../../../util/FormUtil";

export interface IUserDetailFormProps {
    record: User
    refetch: () => void
}

interface IFormInput extends PatchedUserUpdateRequest {}

const userToFormInput = (user: User): IFormInput => {
    return {
        user_roles: user.user_roles.map((userRole) => userRole.role.id),
        user_partners: user.user_partners.map((userPartner) => userPartner.id),
        first_name: user.first_name,
        last_name: user.last_name,
        slack_id: user.slack_id ?? ''
    }
}

export const UserDetailForm = (props: IUserDetailFormProps) => {
    const initialValues = userToFormInput(props.record)
    const makeTextFormField = MakeTextFormField
    const makeChipSelectFormField = MakeChipSelectFormField
    const [ updateUser ] = useApiCoreUsersPartialUpdateMutation()
    const rolesRes = useApiCoreRolesListQuery()
    // Update below to true when deactivating Partners removes user_partners record
    const partnersRes = useApiCorePartnersListQuery({isActive: undefined})
    const { control, ...useFormRest } = useForm<IFormInput>({defaultValues: initialValues})
    const formStateHooks = useState<IFormInput>(initialValues)

    const updateUserAction = (formInput: IFormInput): MutationActionCreatorResult<any> => (
        updateUser(
            {
                id: props.record.id,
                patchedUserUpdateRequest: {...formInput}
            }
        )
    )

    const fields = [
        makeTextFormField(control, 'first_name', 'First Name'),
        makeTextFormField(control, 'last_name', 'Last Name'),
        makeTextFormField(control, 'slack_id', 'Slack Id'),
        makeChipSelectFormField(
            control,
            'user_roles',
            'Roles',
            rolesRes,
            roleNameFromId,
            roleChoiceToDisplayName,
            true
        ),
        makeChipSelectFormField(
            control,
            'user_partners',
            'Partners',
            partnersRes,
            partnerNameFromId,
            partnerChoiceToDisplayName,
            true
        )
    ]

    return (
        <BaseApiForm
            recordName='User'
            refetch={props.refetch}
            formActionType='Update'
            fields={fields}
            useFormRest={useFormRest}
            formStateHooks={formStateHooks}
            formAPIAction={updateUserAction}
        />
    )
}